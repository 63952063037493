import { request } from "../../helpers";
import moment from 'moment-timezone';

// Actions

const ENTITY_PRODUCT_INDEX = "ENTITY_PRODUCT_INDEX";
const ENTITY_PRODUCT_INDEX_SUCCESS = "ENTITY_PRODUCT_INDEX_SUCCESS";
const ENTITY_PRODUCT_INDEX_FAIL = "ENTITY_PRODUCT_INDEX_FAIL";

export function fetchAllProducts() {
    return request(ENTITY_PRODUCT_INDEX, "get", '/products');
}

const ENTITY_TAX_RATE_INDEX = "ENTITY_TAX_RATE_INDEX";

export function fetchAllTaxRates() {
    return request(ENTITY_TAX_RATE_INDEX, "get", '/tax_rates');
}

const ENTITY_PRODUCT_CREATE = 'ENTITY_PRODUCT_CREATE';

export function createProduct(data)
{
    return request(ENTITY_PRODUCT_CREATE, 'post', '/products', data)
}

const ENTITY_PRODUCT_DELETE = 'ENTITY_PRODUCT_DELETE'

export function deleteProduct(data)
{
    return request(ENTITY_PRODUCT_DELETE, 'delete', '/products', data)
}

const ENTITY_PRODUCT_UPDATE = 'ENTITY_PRODUCT_UPDATE';

export function updateProduct(data)
{
    return request(ENTITY_PRODUCT_UPDATE, 'put', '/products', data)
}

const ENTITY_PRODUCT_SEARCH_BY_CATEGORY = 'ENTITY_PRODUCT_SEARCH_BY_CATEGORY';

export function searchProductsOneShotFromCategory(data)
{
    return request(ENTITY_PRODUCT_SEARCH_BY_CATEGORY, 'post', '/products/search/one-time', data)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityStripeProductsReducer(state = defaultState, action) {
    switch (action.type) {
        case ENTITY_PRODUCT_INDEX:
            return { ...state, loading: true };
        case ENTITY_PRODUCT_INDEX_FAIL:
            return { ...state, loading: false };
        case ENTITY_PRODUCT_INDEX_SUCCESS:
            return {
                ...state,
                loading: false,
                index: action.payload.data,
                lastUpdate: moment
            };
    }
    return state;
}
