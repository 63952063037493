import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import moment from 'moment-timezone';
import Modal from "../../../components/Modal";
import { Col, Row } from "reactstrap";

import {
  fetchSuscribers,
  deleteSubscribers,
  postSubscribers
} from "../../../data/entities/Newsletter";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";
import NewsletterSubscriberCreateModal from "../NewsletterSubscriber/NewsletterSubscriberCreateModal";
import {importNewsletterSubscribers, exportNewsletterSubscribers} from "../../../data/entities/NewsletterSubscriber";

class NewsletterSubscribersModal extends Component {
  state = {
    data: null,
    inputSubscribers: "",
    limit: 20,
    showNewsletterSubscriberCreateModal: false,
    importing: false,
    exporting: false
  };

  file = React.createRef()

  componentDidMount() {
    this.fetchAll()
  }

  fetchAll() {
    this.props.fetchSuscribers(this.props.id).then(res => {
      this.setState({ data: res.payload.data });
    });
  }

  _deleteSubscribers = target => {
    this.props.deleteSubscribers(this.props.id, target).then(() => {
      this.props.fetchSuscribers(this.props.id).then(res => {
        this.setState({
          data: res.payload.data
        });

        this.props.onUpdateSubscribers();
      });
    });
  };

  handleChange = event => {
    this.setState(
      {
        inputSubscribers: event.target.value
      },
      () => {
        if (!this.state.inputSubscribers) {
          this.props.fetchSuscribers(this.props.id).then(res => {
            this.setState({
              data: res.payload.data
            });
          });
        } else if (this.state.inputSubscribers.length >= 3) {
          this.props
            .postSubscribers(this.props.id, this.state.inputSubscribers)
            .then(res => {
              this.setState({
                data: res.payload.data
              });
            });
        }
      }
    );
  };

  handleFile = event => {
    if (!this.file.current.files.length) return;

    this.setState({ importing: true })

    this.props.importNewsletterSubscribers(this.props.id, { file: this.file.current.files[0] })
        .then(res => {
          this.setState({ importing: false })
          this.fetchAll()
          this.props.onUpdateSubscribers()

          toast.success(res.payload.data.message)
        })
        .catch(error => {
          console.log(error)
          this.setState({ importing: false })
        })
  }

  exportFile = () => {
    this.setState({ exporting: true});
    this.props.exportNewsletterSubscribers(this.props.id)
        .then((res) => {
          this.download('abonnés.csv', res.payload.data.content);
        })
        .catch((error) => {
          console.log(error);
          this.setState({ exporting: false });
        })
  };

  download = (name, data) => {
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    a.setAttribute('download', name);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.setState({ exporting: false });
  };

  // _getSubscribers = event => {
  //   this.setState({
  //       inputSubscribers: event.target.value
  //     },
  //     this.props.getSubscribers(this.props.id, data).then(() => {
  //       if (!this.state.inputSubscribers.length) {
  //         this.props.fetchSuscribers(this.props.id).then(res => {
  //           this.setState({
  //             data: res.payload.data
  //           });
  //         });
  //       } else if (this.state.inputSubscribers.length > 3) {
  //         this.props.getSubscribers()
  //       }
  //     }
  //   )
  // };

  renderSubscribers() {
    if (!this.state.data) return <ContentLoader />;
    if (this.state.data.length === 0) return <Empty text={this.state.inputSubscribers.length ? "Aucun résultat" : "Aucun abonné pour l'instant"} />;

    return (
      <div>
        {this.state.data.map((subscriber, i) => {
          if (i + 1 > this.state.limit) return null;
          return (
              <Row className="pb-3" key={subscriber.id}>
                <Col className="df">
                  <h6>
                    {subscriber.email}
                  </h6>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <button
                      onClick={() => this._deleteSubscribers(subscriber.id)}
                      className="btn btn-danger"
                  >
                    {this.state.data.subscribers} Désabonner
                  </button>
                </Col>
              </Row>
          )
        })}
        {this.state.data.length > this.state.limit ? (
            <button
                onClick={() => this.setState({ limit: this.state.limit + 20 })}
                className="btn btn-primary w-100">Afficher plus</button>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <Modal title="Abonnés" onClose={() => this.props.onClose(false)}>
        <input
            accept="text/csv"
            ref={this.file}
            type="file"
            onChange={e => this.handleFile(e)}
            className="d-none"
        />
        <div className="row">
          <div className="col-4">
            <input
                type="text"
                value={this.state.inputSubscribers}
                onChange={e => this.handleChange(e)}
                placeholder="Rechercher..."
                className="form-control d-inline-block mb-4"
                style={{ maxWidth: 200 }}
            />
          </div>
          <div className="col-8 text-right">
            <button
                onClick={() => this.setState({ showNewsletterSubscriberCreateModal: true })}
                className="btn btn-primary mr-2">Ajouter un abonné</button>
            <button
                onClick={() => this.file.current.click()}
                disabled={this.state.importing}
                className="btn btn-warning mr-2">Importer (CSV)</button>
            <button
                onClick={() => this.exportFile()}
                disabled={this.state.exporting}
                className="btn btn-light">Exporter (CSV)</button>
            <button className="btn btn-danger mr-2"
                    onClick={() => this._deleteSubscribers(null)}
            >
              Supprimer tous les abonnées
            </button>
          </div>
        </div>
        {this.renderSubscribers()}
        {this.state.showNewsletterSubscriberCreateModal ? (
            <NewsletterSubscriberCreateModal
              id={this.props.id}
              onClose={(subscriber) => {
                this.setState({ showNewsletterSubscriberCreateModal: false })
                if (subscriber) {
                  this.setState({ data: [subscriber, ...this.state.data] })
                }
              }}
            />
        ) : null}
      </Modal>
    );
  }
}

export default connect(
  null,
  { fetchSuscribers, deleteSubscribers, postSubscribers, importNewsletterSubscribers, exportNewsletterSubscribers }
)(NewsletterSubscribersModal);
