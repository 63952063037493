import React, {Component} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import Autocomplete from "./Autocomplete";
import {searchAll} from "../../../data/entities/User";
import {connect} from "react-redux";

class AuthorFilter extends Component
{
    state = {
        open: false,
        loading: false,
        query: '',
        queryResponse: []
    }

    _fetch = () => {
        const {query} = this.state

        if (query.trim().length === 0)
            return this.setState({ queryResponse: [], loading: false })

        this.setState({ loading: true })

        this.props.searchAll(this.state.query)
            .then(res => {
                if (res.payload.data)
                    this.setState({ queryResponse: res.payload.data.slice(0, 14) })
            })
            .catch((e) => {
                this.setState({ loading: false})
            })
    }

    render() {
        return (
            <>
                <div className="d-inline-block author-filter mr-3">{this.renderDropdown()}</div>
            </>
        );
    }

    renderDropdown()
    {
        return (
            <Dropdown isOpen={this.state.open}
                      toggle={() => this.setState({ open: !this.state.open })}>
                <DropdownToggle caret>
                    {this.renderLabel(this.props.value)}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem
                        onClick={() => this.props.onChange(null)}>
                        Tous les auteurs
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => this.props.onChange('me')}>
                        Mes articles
                    </DropdownItem>
                    <div>
                        <Autocomplete
                            createEntity={false}
                            placeholder={"Rechercher un auteur..."}
                            className={"filter"}
                            renderDropdownItem={(author) => (
                                <span>{author.fullname}</span>
                            )}

                            query={this.state.query}
                            queryResponse={this.state.queryResponse}
                            handleChange={(e) => this.setState({ query: e.target.value }, this._fetch)}
                            escape={() => this.setState({ query: '', queryResponse: [] })}
                            selectEntity={(id, author) => {
                                this.props.onChange(author)
                                this.setState({ query: '', queryResponse: [], open: false })
                            }}
                        />
                    </div>
                </DropdownMenu>
            </Dropdown>
        )
    }

    renderLabel(author)
    {
        if (author == null) {
            return "Tous les auteurs"
        }

        if (author == 'me') {
            return "Mes articles"
        }

        return (
            <span><i className="fa fa-user"/> {author.fullname}</span>
        )
    }
}

export default connect(null, {searchAll})(AuthorFilter);
