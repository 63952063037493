import moment from 'moment-timezone';
import { request } from "../../helpers";

// Actions

const ENTITY_NEWSLETTER_INDEX = "ENTITY_NEWSLETTER_INDEX";
const ENTITY_NEWSLETTER_INDEX_SUCCESS = "ENTITY_NEWSLETTER_INDEX_SUCCESS";
const ENTITY_NEWSLETTER_INDEX_FAIL = "ENTITY_NEWSLETTER_INDEX_FAIL";

export function fetchAll() {
  return request(ENTITY_NEWSLETTER_INDEX, "get", "/newsletters");
}

export function searchAll(query) {
  return request(ENTITY_NEWSLETTER_INDEX, "post", "/newsletters/search", {
    query
  });
}

const ENTITY_NEWSLETTER_RETRIEVE = "ENTITY_NEWSLETTER_RETRIEVE";
const ENTITY_NEWSLETTER_RETRIEVE_SUCCESS = "ENTITY_NEWSLETTER_RETRIEVE_SUCCESS";
const ENTITY_NEWSLETTER_RETRIEVE_FAIL = "ENTITY_NEWSLETTER_RETRIEVE_FAIL";

export function fetchNewsletter(id) {
  return request(ENTITY_NEWSLETTER_RETRIEVE, "get", `/newsletters/${id}`);
}

const ENTITY_NEWSLETTER_PREVIEW = "ENTITY_NEWSLETTER_PREVIEW";

export function previewNewsletter(id) {
  return request(ENTITY_NEWSLETTER_PREVIEW, "get", `/newsletters/${id}/preview`);
}

const ENTITY_NEWSLETTER_CREATE = "ENTITY_NEWSLETTER_CREATE";

export function createNewsletter() {
  return request(ENTITY_NEWSLETTER_CREATE, "post", "/newsletters", {});
}

const ENTITY_NEWSLETTER_UPDATE = "ENTITY_NEWSLETTER_UPDATE";

export function updateNewsletter(id, data) {
  return request(ENTITY_NEWSLETTER_UPDATE, "patch", `/newsletters/${id}`, data);
}

const ENTITY_NEWSLETTER_DELETE = "ENTITY_NEWSLETTER_DELETE";

export function deleteNewsletter(id) {
  return request(ENTITY_NEWSLETTER_DELETE, "delete", `/newsletters/${id}`);
}

const ENTITY_NEWSLETTER_TRIGGER = "ENTITY_NEWSLETTER_TRIGGER";

export function triggerNewsletter(id) {
  return request(ENTITY_NEWSLETTER_TRIGGER, "POST", `/newsletters/trigger/${id}`)
}

const ENTITY_NEWSLETTER_SUBSCRIBERS_INDEX = `ENTITY_NEWSLETTER_SUBSCRIBERS_INDEX`;

export function fetchSuscribers(id) {
  return request(
      ENTITY_NEWSLETTER_SUBSCRIBERS_INDEX,
      "get",
      `/newsletters/${id}/subscribers`
  );
}

const ENTITY_NEWSLETTER_SUBSCRIBERS_CREATE = `ENTITY_NEWSLETTER_SUBSCRIBERS_CREATE`;

export function createSubscriber(id, email) {
  return request(
      ENTITY_NEWSLETTER_SUBSCRIBERS_CREATE,
      "put",
      `/newsletters/${id}/subscribers`,
      { email }
  );
}

const ENTITY_NEWSLETTER_SUBSCRIBERS_DELETE = `ENTITY_NEWSLETTER_SUBSCRIBERS_DELETE`;

export function deleteSubscribers(id, target) {
  return request(
      ENTITY_NEWSLETTER_SUBSCRIBERS_DELETE,
      "delete",
      `/newsletters/${id}/subscribers/${target}`
  );
}

const ENTITY_NEWSLETTER_SUBSCRIBERS_POST = `ENTITY_NEWSLETTER_SUBSCRIBERS_POST`;

export function postSubscribers(id, query) {
  return request(
      ENTITY_NEWSLETTER_SUBSCRIBERS_POST,
      "post",
      `/newsletters/${id}/subscribers`,
      { query }
  );
}

const ENTITY_NEWSLETTER_ENTITY_INDEX = `ENTITY_NEWSLETTER_ENTITY_INDEX`;
export function fetchNewsletterEntity(type, id) {
  return request(ENTITY_NEWSLETTER_ENTITY_INDEX, "get", `/newsletters/${id}/${type}`);
}

const ENTITY_NEWSLETTER_ENTITY_ADD = `ENTITY_NEWSLETTER_ENTITY_ADD`;
export function addNewsletterEntity(type, id, entity) {
  return request(ENTITY_NEWSLETTER_ENTITY_ADD, "put", `/newsletters/${id}/${type}/${entity}`);
}

const ENTITY_NEWSLETTER_ENTITY_ADD_ARTICLES = `ENTITY_NEWSLETTER_ENTITY_ADD_ARTICLES`;
export function addNewsletterEntityArticles(type, id, entities) {
  return request(ENTITY_NEWSLETTER_ENTITY_ADD_ARTICLES, "put", `/newsletters/${id}/${type}`, {entities});
}

const ENTITY_NEWSLETTER_ENTITY_MOVE = `ENTITY_NEWSLETTER_ENTITY_MOVE`;
export function moveNewsletterEntity(type, id, entity, position) {
  return request(ENTITY_NEWSLETTER_ENTITY_MOVE, "patch", `/newsletters/${id}/${type}/${entity}`, {position});
}

const ENTITY_NEWSLETTER_ENTITY_DELETE = `ENTITY_NEWSLETTER_ENTITY_DELETE`;
export function deleteNewsletterEntity(type, id, entity) {
  return request(ENTITY_NEWSLETTER_ENTITY_DELETE, "delete", `/newsletters/${id}/${type}/${entity}`);
}

// Store

const defaultState = {
  index: [],
  loading: false,
  lastUpdate: null
};

// Reducer

export default function EntityNewsletterReducer(state = defaultState, action) {
  switch (action.type) {
    case ENTITY_NEWSLETTER_INDEX:
    case ENTITY_NEWSLETTER_RETRIEVE:
      return { ...state, loading: true };
    case ENTITY_NEWSLETTER_INDEX_FAIL:
    case ENTITY_NEWSLETTER_RETRIEVE_FAIL:
      return { ...state, loading: false };
    case ENTITY_NEWSLETTER_INDEX_SUCCESS:
    case ENTITY_NEWSLETTER_RETRIEVE_SUCCESS:
      return {
        ...state,
        loading: false,
        index: action.payload.data,
        lastUpdate: moment()
      };
  }
  return state;
}
