import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify'
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";
import moment from 'moment-timezone'

import ContentLoader from "../../../components/ContentLoader";

import {fetchOffer, fetchOfferCandidates} from '../../../data/entities/Offer'
import OfferUpdateModal from "./OfferUpdateModal";
import OfferDeleteModal from "./OfferDeleteModal";
import {Link} from "react-router-dom";
import Modal from "../../../components/Modal";

class OfferViewPage extends Component
{
    state = {
        id: this.props.match.params.id,
        data: null,
        loading: true,
        candidates: null,

        showOfferCandidatesModal: false,
        showOfferUpdateModal: false,
        showOfferDeleteModal: false
    }

    componentDidMount()
    {
        this.props.fetchOffer(this.state.id)
            .then((res) => {
                this.setState({ loading: false, data: res.payload.data })

                this.props.fetchOfferCandidates(this.state.id)
                    .then((res) => {
                        this.setState({ candidates: res.payload.data })
                    })
            })
            .catch((e) => {
                console.log(e)
                if (e.error && e.error.response && e.error.response.data && e.error.response.data.error)
                    toast.error(<FormattedMessage id={e.error.response.data.error} />)
                this.props.history.push('/entities/offer')
            })
    }

    render()
    {
        return (
            <div className="container">
                <Helmet title="Offer" />
                <p>
                    <Link to="/entities/offer"><i className="fa fa-angle-left"/> Retour aux offres</Link>
                </p>
                {this.state.loading ?
                    <ContentLoader />
                    :
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <h3>{this.state.data.name}</h3>
                        <div>
                            <button
                                onClick={() => this.setState({ showOfferUpdateModal: true })}
                                className="btn btn-primary">Modifier</button>
                            <button
                                onClick={() => this.setState({ showOfferDeleteModal: true })}
                                className="btn btn-danger ml-2">Supprimer</button>
                        </div>
                    </div>
                }
                {this.renderEntity()}
            </div>
        )
    }

    renderEntity()
    {
        if (this.state.loading) return <ContentLoader/>

        const {
            contact_name, contact_phone, contact_email, appliances, external_link, external_logo, logo,
            company, city, district, country, offer_function, offer_contract, offer_activity_area, offer_study_level, offer_experience,
            salary, skills, description, company_description, publication_status, publication_start, publication_end, applications_stop_at
        } = this.state.data

        return (
            <div>
                <div className={"mb-4"}>
                    <h4>{company}</h4>

                    <p>
                        <i className="fas fa-map-marker-alt mr-2"/>
                        {city}, {district && `${district}, `}{country && country}
                        {!external_link ? <button title="Candidats intéressés"
                                onClick={() => this.setState({ showOfferCandidatesModal: true })}
                                className="btn btn-link ml-3">
                            <i className="fa fa-users"/> {appliances} candidats intéressés
						</button> : null}
                    </p>

                    <div className="card">
                        <div className="card-body">
                            <span><b>Status de la publication: </b> {publication_status === 1 ? "Offre publiée" : "Offre non publiée"}</span><br/>
                            {publication_status === 1 &&
                            <Fragment>
                                <span><b>Date de début de publication: </b>{moment(publication_start).format("DD/MM/YYYY")}</span><br/>
                                <span><b>Date de fin de publication: </b>{publication_end ? moment(publication_end).format("DD/MM/YYYY") : "Non renseignée"}</span><br/>
                                <span><b>Date limite de candidature: </b>{applications_stop_at ? moment(applications_stop_at).format("DD/MM/YYYY") : "Non renseignée"}</span><br/>
                            </Fragment>}
                        </div>
                    </div>
                    <br />

                    {external_link ? (
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p><a href={external_link} target="_blank">Consulter l'offre externe <i className="fa fa-external-link-alt"/></a></p>
                                    <img src={external_logo} alt="Logo" style={{ height: 50}}/>
                                </div>
                            </div>
                            <br />
                        </div>
                    ) : null}

                    <div className="card">
                        <div className="card-header">
                            <h5>Description du poste</h5>
                        </div>
                        <div className="card-body">
                            <p>{description ? description : "Aucune description"}</p>
                        </div>
                    </div>

                    <br/>

                    <div className="card">
                        <div className="card-header">
                            <h5>Entreprise</h5>
                        </div>
                        <div className="card-body">
                            {logo ? (
                                <p>
                                    <img src={logo} style={{ maxWidth: 400, maxHeight: 200 }} />
                                </p>
                            ) : null}
                            <p>{company_description ? company_description : "Aucune description"}</p>
                            {!external_link ? (
                                <>
                                    <p><strong>Contact :</strong> {contact_name ? contact_name : "Non renseigné"}</p>
                                    <p><strong>Contact (e-mail) :</strong> {contact_email ? contact_email : "Non renseigné"}</p>
                                    <p><strong>Contact (téléphone) :</strong> {contact_phone ? contact_phone : "Non renseigné"}</p>
                                </>
                            ) : null}
                        </div>
                    </div>

                    {!external_link ? (
                        <div>
                            <br/>

                            <div className="card">
                                <div className="card-body">
                                    <span><b>Fonction: </b>{offer_function ? offer_function.name : "Non renseigné"}</span><br/>
                                    <span><b>Type d'offre: </b>{offer_contract ? offer_contract.name : "Non renseigné"}</span><br/>
                                    <span><b>Secteur d'activité: </b>{offer_activity_area ? offer_activity_area.name : "Non renseigné"}</span><br/>
                                    <span><b>Niveau d'étude requis: </b>{offer_study_level ? offer_study_level.name : "Non renseigné"}</span><br/>
                                    <span><b>Expérience requise: </b>{offer_experience ? offer_experience.name : "Non renseigné"}</span><br/>
                                    <span><b>Rémunération: </b>{salary ? salary : "Selon expérience"}</span>
                                </div>
                            </div>

                            <br/>

                            <div className="card">
                                <div className="card-header">
                                    <h5>Compétences requises</h5>
                                </div>
                                <div className="card-body">
                                    <p>{skills ? skills : "Non renseigné"}</p>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <br/>
                </div>

                {this.state.showOfferUpdateModal ? (
                    <OfferUpdateModal
                        data={this.state.data}
                        onClose={(data) => {
                            if (data) this.setState({ data })
                            this.setState({ showOfferUpdateModal: false })
                        }}
                    />
                ) : null}
                {this.state.showOfferDeleteModal ? (
                    <OfferDeleteModal
                        data={this.state.data}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.props.history.push('/entities/offer')
                            }
                            else this.setState({ showOfferDeleteModal: false })
                        }}
                    />
                ) : null}

                {this.state.showOfferCandidatesModal ? (
                    <Modal title="Candidats intéressés" onClose={() => this.setState({ showOfferCandidatesModal: false })}>
                        {this.state.candidates ? (
                            <div>
                                {this.state.candidates.map((candidate) => (
                                    <div className="card mb-2">
                                        <div className="card-body">
                                            <span className="float-right">
                                                {moment(candidate.applied_at).format('DD/MM/YYYY')}
                                            </span>
                                            <p><strong>{candidate.fullname}</strong></p>
                                            <p className="mb-0">
                                                {candidate.email}
                                                { candidate.resume ?
                                                    <a className={'float-right'} href={candidate.resume} target={'_blank'}>CV <i className={'fa fa-file'}></i></a>
                                                    : null
                                                }
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : <ContentLoader/>}
                    </Modal>
                ) : null}
            </div>
        )
    }
}

export default connect(({ offer_entity }) => ({ offer_entity }), { fetchOffer, fetchOfferCandidates })(OfferViewPage)
