import { SketchPicker } from 'react-color';
import React, {Component} from 'react';
import "./CategoryModal.css";
import Modal from "../../../components/Modal";
import {connect} from "react-redux";
import {Form, Submit} from "react-forms";
import "./CategoryModal.css";



class ColorPickerModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        color: this.props.data
    };

    _submit = (event) => {
        event.preventDefault();
        this.setState({ _loading: true });
        this.props.onClose(this.state.color);
    };

    handleChangeComplete = (color) => {
        this.setState({ color: color.hex });
    };

    render()
    {
        return (
            <Modal title={"Choisissez une couleur"} onClose={() => this.props.onClose(false)}>

                    <div className={"color-container"}>
                        <div className={"color-container-child"}>
                            <SketchPicker
                                color={ this.state.color }
                                onChangeComplete={ this.handleChangeComplete }
                            />
                        </div>
                    </div>

                    <button className={"btn btn-primary"} onClick={this._submit}>Valider</button>
            </Modal>
        )
    }
}

export default connect()(ColorPickerModal)
