import {OAUTH_LOGOUT} from "./OAuth";
import {request} from '../helpers'

// Actions

const USER_FETCH_PROFILE = 'USER_FETCH_PROFILE'
const USER_FETCH_PROFILE_INFO = 'USER_FETCH_PROFILE_INFO'
const USER_FETCH_PROFILE_SUCCESS = 'USER_FETCH_PROFILE_SUCCESS'
const USER_FETCH_PROFILE_FAIL = 'USER_FETCH_PROFILE_FAIL'

export function fetchProfile()
{
    return request(USER_FETCH_PROFILE, 'get', '/user')
}
export function fetchProfileWithPurchases()
{
    return request(USER_FETCH_PROFILE_INFO, 'get', '/user/authentification-login')
}
// Store

const defaultState = {
    profile: null
}

// Reducer

export default function UserReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case OAUTH_LOGOUT:
            return defaultState
        case USER_FETCH_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload.data
            }
    }
    return state
}
