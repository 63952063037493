import React, {Component} from 'react';
import {MASTER_API_ENDPOINT} from "./config/parameters";
import Empty from "./components/Empty";

export default class ApiProvider extends Component
{
    state = {
        error: false,
    }

    componentDidMount()
    {
        const host = window.location.host
        const parts = host.split('.')
        let slug = parts[0]

        if (slug.indexOf('localhost:') !== -1 || host.indexOf('127.0.0.1') !== -1)
        {
            slug = 'aircosmos'
        }

        const form = new FormData()
        form.append('slug', slug)

        fetch(MASTER_API_ENDPOINT + '/external/api-keys', {
            method: "POST",
            body: form
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.error) throw data.error

            localStorage.setItem('APP_NAME', data.name)
            localStorage.setItem('APP_URL', data.url)
            localStorage.setItem('OAUTH_IDENTIFIER', data.oauth_id)
            localStorage.setItem('OAUTH_SECRET', data.oauth_secret)
            localStorage.setItem('API_ENDPOINT', data.endpoint)
            setTimeout(() => {
                this.setState({
                    ready: true
                })
            }, 100)
        })
        .catch(() => {
            this.setState({
                error: true
            })
        })
    }

    render() {
        if (this.state.error)
            return (
                <div className="text-center text-muted">
                    <br/>
                    <br/>
                    <Empty text="Cette plateforme n'existe pas, ou est en préparation !"/>
                    <br/>
                    <br/>
                    <span className="text-muted"><a href="https://rewyer.dev">En savoir plus sur Rewyer</a></span>
                </div>
            )

        if (!this.state.ready)
            return (
                <p className="text-center text-muted">
                    <i className="fa fa-circle-notch fa-spin mt-5 mr-2"/>
                    <span>Connexion sécurisée</span>
                </p>
            )

        return this.props.children
    }
}
