import React, {Component} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

class OrderByFilter extends Component
{
    state = {
        open: false
    }

    render() {
        return (
            <>
                {this.renderOrderDirectionSwitch()}
                <div className="d-inline-block">{this.renderOrderBy()}</div>
            </>
        );
    }

    renderOrderDirectionSwitch()
    {
        return (
            <button
                onClick={() => this.props.onChange({ order_direction: this.props.order_direction == 'DESC' ? 'ASC' : 'DESC' })}
                className="btn btn-link">
                <i className={"fa fa-arrow-" + (this.props.order_direction == 'DESC' ? 'down' : 'up')}/>
            </button>
        )
    }

    renderOrderBy()
    {
        return (
            <Dropdown isOpen={this.state.open}
                      toggle={() => this.setState({ open: !this.state.open })}>
                <DropdownToggle caret>
                    {this.renderOrderByText(this.props.order_by)}
                </DropdownToggle>
                <DropdownMenu>
                    {['created_at', 'updated_at', 'publication_start'].map((key) => {
                        return (
                            <DropdownItem
                                onClick={() => this.props.onChange({ order_by: key })}>
                                {this.renderOrderByText(key)}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
        )
    }

    renderOrderByText(key)
    {
        return {
            created_at: 'Date de création',
            updated_at: 'Date de modification',
            publication_start: 'Date de publication',
        }[key]
    }
}

export default OrderByFilter;
