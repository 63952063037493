import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import moment from 'moment-timezone'

import ContentLoader from "../../../components/ContentLoader";
import "./ArticleDashboard.scss"
import {connect} from "react-redux";
import {fetchArticleComments} from "../../../data/entities/Article";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import CommentDeleteModal from "../../../pages/entities/Comment/CommentDeleteModal";
import Empty from "../../../components/Empty";

class ArticleDashboard extends Component {

  state = {
    comments: null,
    deleted_id: null,
    showCommentDeleteModal: null
  };

  render() {
    console.log("dashboard props--->", this.props.data);
    return (
        <Fragment>
          <Helmet title="Article" />
          {this.renderEntity()}
        </Fragment>
    );
  }

  componentDidMount() {
    this.fetchComments()
  }

  fetchComments() {
    this.props.fetchArticleComments(this.props.data.id)
        .then(res => {this.setState({
          comments: res.payload.data }
        )})
        .catch((e) => {
          if (
              e.error &&
              e.error.response &&
              e.error.response.data &&
              e.error.response.data.error
          )
            toast.error(<FormattedMessage id={e.error.response.data.error} />);
          this.props.history.push("/entities/article");
        });
  }

  renderEntity() {
    console.log(this.state.comments)

    if (this.props.loading) return <ContentLoader />;
    return (
        <div className={"article-info-container"}>
          <div className={"article-info"}>

            <div className={"section general"}>
              <div className="sub-section author">
                <div className="section-title">
                  <div className="icon author"><i class="fas fa-user"></i></div>
                  <label>{this.props.data.author_name ? this.props.data.author_name : "Auteur non renseigné"}</label>
                </div>
                <div className="section-body">
                  <label>{this.props.data.author_email}</label>
                </div>
              </div>

              {this.props.data.source_platform_uid !== null ? (
                  <div className="sub-section">
                    <div className="section-title">
                      <div className="icon text-white bg-primary"><i className="fa fa-globe"/></div>
                      <label>
                        Article importé depuis <strong><a target="_blank" href={"https://rewyer.dev/go/" + this.props.data.source_platform_uid + '/' + this.props.data.source_article_id}>{this.props.data.source_platform_name}</a></strong>
                      </label>
                    </div>
                  </div>
              ) : null}

              {this.props.data.publication_status === 1 ?
                  <div className="sub-section">
                    <div className="section-title">
                      <div className="icon publicated"><i className="fas fa-check"></i></div>
                      <label>Article publié</label>
                    </div>
                    <div className="section-body">
                      <label>Article publié le {moment(this.props.data.publication_start).format("DD/MM/YYYY")}</label>
                      {this.props.data.publication_end === 1 ?
                          <label>Fin de publication le {moment(this.props.data.publication_end).format("DD/MM/YYYY")}</label>
                          :
                          <label>Pas de date de fin</label>}
                    </div>
                  </div>
                  :
                  <div>
                    { this.props.data.slug === "article-brouillon" && this.props.data.publication_start === null  ?
                        <div className="sub-section">
                          <div className="section-title">
                            <div className="icon publicated"><i className="fas fa-ellipsis-h"></i></div>
                            <label>L'article n'est pas encore publié.</label>
                          </div>
                        </div>
                        :
                        <div className="sub-section">
                          <div className="section-title">
                            <div className="icon publicated"><i className="fas fa-ellipsis-h"></i></div>
                            <label>Cet article a été dépublié</label>
                          </div>
                        </div>
                    }
                  </div>
              }

              {this.props.data.highlight_status === 1 ?
                  <div className="sub-section">
                    <div className="section-title">
                      <div className="icon highlighted"><i class="fas fa-star"></i></div>
                      <label>Article mis en avant</label>
                    </div>
                    <div className="section-body">
                      <label>Article mis en avant le {moment(this.props.data.highlight_start).format("DD/MM/YYYY")}</label>
                      {this.props.data.publication_end === 1 ?
                          <label>Fin de de mis en avant le {moment(this.props.data.highlight_end).format("DD/MM/YYYY")}</label>
                          :
                          <label>Pas de date de fin</label>}
                    </div>
                  </div>
                  :
                  <div className="sub-section">
                    <div className="section-title">
                      <div className="icon highlighted"><i class="fas fa-star"></i></div>
                      <label>L'article n'est pas mis en avant.</label>
                    </div>
                  </div>
              }

            </div>

            <div className="tags-categories-col">
              <div className={"section section-category"}>
                <h5>CATEGORIE(S)</h5>
                <div className="categories-tags">
                  {this.props.data.categories.length ?
                      this.props.data.categories.map(category =>
                          <div className={"category mr-2 mb-2"}>
                            <span>{category.name.toUpperCase()}</span>
                          </div>
                      )
                      : "Cet article n'appartient à aucune catégorie."}
                </div>
              </div>

              <div className={"section"}>
                <h5>TAGS</h5>
                <div className={"categories-tags"}>
                  {this.props.data.tags.length ?
                      this.props.data.tags.map(tag =>
                          <div className={"tag"}>
                            <span>{tag.name}</span>
                          </div>
                      ) : "Cet article n'a pas de tag."}
                </div>
              </div>
            </div>
          </div>


          <div className={"section"}>
            <h5>COMMENTAIRES ({this.props.data.comments})</h5>

            <div className={"comments"}>
              {this.state.comments !== null ?
                  this.state.comments.map(comment =>
                      <div className={"comment"}>
                        <div className={"head mb-2"}>
                          <div className="col-md-10">
                            <div className={"user d-inline-block"}>
                              <i className="fas fa-user-circle mr-2"></i>
                              <label>{comment.author_name}</label>
                            </div>
                            <span>{moment(comment.created_at).format("YYYY-MM-DD HH:mm")}</span>
                          </div>
                          <div className="col-md-2">
                            <div
                                className={"delete-btn"}
                                onClick={() =>  this.setState({showCommentDeleteModal: comment.id})}>
                              <div className="icon"><i className="fas fa-trash-alt"></i></div>
                            </div>
                          </div>
                        </div>
                        <p className={"body"}>
                          {comment.content}
                        </p>
                      </div>
                  ) : (
                      <Empty text={"Aucun commentaire"}/>
                  )}
              {this.state.showCommentDeleteModal !== null ? (
                  <CommentDeleteModal
                      id={this.state.showCommentDeleteModal}
                      onClose={deleted => {
                        if (deleted) {
                          this.fetchComments()
                        }
                        this.setState({ showCommentDeleteModal: null });
                      }}
                  />
              ) : null}

            </div>
          </div>
        </div>
    );
  }
}

export default connect(null, {fetchArticleComments})(ArticleDashboard);
