import React, {Component} from 'react';
import Modal from "../../../components/Modal";
import {Col, Row} from "reactstrap";
import {toast} from "react-toastify";
import {deleteContactMessage} from "../../../data/entities/Contact";
import {connect} from 'react-redux';


class ContactDeleteModal extends Component
{
    state = {
        loading: false
    };

    delete = () => {

        this.setState({loading: true});

        this.props.deleteContactMessage(this.props.data.id)
            .then((res) => {
                if (res.payload.data.deleted) {
                    this.props.onDelete();
                }
                this.setState({loading: false});
            })
            .catch((err) => {
                toast.error(err);
                this.setState({loading: false});
            })
    };

    render() {

        const fontSize = {
            fontSize: '16px'
        };

        return(
            <Modal title={'Message'} onClose={() => this.props.onClose()}>
                <div style={fontSize}>

                    <Row className={'mb-5'}>
                        <Col>
                            Souhaitez-vous vraiment supprimer ce message ?
                        </Col>
                    </Row>

                    <div className="text-center">
                        <button
                            onClick={() => this.delete()}
                            className="btn btn-danger m-auto"
                            disabled={this.state.loading}
                        >
                            {this.state.loading ?
                                <i className="fa fa-circle-notch fa-spin"/> :
                                'Supprimer'
                            }
                        </button>

                        <button
                            onClick={() => this.props.onClose()}
                            className="btn btn-primary ml-3"
                        >
                            Annuler
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default connect(null, {deleteContactMessage})(ContactDeleteModal);
