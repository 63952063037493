import React, { Component } from "react";
import {connect} from 'react-redux'
import Modal from "../../../components/Modal";
import {Form, Submit} from "react-forms";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import {deleteProduct} from "../../../data/entities/StripeProduct";


class ProductDeleteModal extends Component
{
    state = {
        loading: false
    };

    _submit = () => {
        this.setState({ loading: true })

        this.props.deleteProduct({
            'price_id': this.props.price_id,
            'product_id': this.props.product_id,
        })
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(true)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data) {
                    if (e.error.response.data.error) toast.error(<FormattedMessage id={e.error.response.data.error} />)
                    else if (e.error.response.data.message) toast.error(<FormattedMessage id={e.error.response.data.message} />)
                }
                this.setState({ loading: false })
            })
    }

    render() {
        return (
            <Modal title={this.props.type === 'recurring' ? 'Supprimer cet abonnement' : 'Supprimer ce produit'} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit}>
                    <p style={{ color: '#000' }}>
                        {
                            this.props.type === 'recurring' ?
                                <><strong>Attention !</strong> Si vous supprimez cet abonnement, vos utilisateurs ne pourront plus s'y abonner. Veuillez vous assurer que personne n'est abonné à ce produit, et qu'il n'est lié à aucun document confidentiel.</>
                                :
                                <><strong>Attention !</strong> Si vous supprimez ce produit, vos utilisateurs ne pourront plus l'acquérir.</>
                        }
                    </p>
                    <Submit disabled={this.state.loading} className="btn btn-danger">OK, supprimer</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, {deleteProduct})(ProductDeleteModal);
