import React, {Component} from 'react';
import $ from 'jquery';
import {
    getArticles,
    getArticlesExpert,
    getArticlesSearch,
    getDraftRssArticles,
    getPartners,
    getUnpublishedArticles
} from "../../data/Curation";
import {connect} from "react-redux";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import {fetchAll, highlightArticle} from "../../data/entities/Article";
import Loader from "../../components/Loader";
import moment from "moment";
import ContentLoader from "../../components/ContentLoader";
import Empty from "../../components/Empty";
import './CurationPage.scss'
import CurationArticle from "./CurationArticle";
import ArticlesTabs from "../../components/ArticlesTabs";
import RssArticle from "./RssArticle";
import ArticleCard from "../entities/Article/ArticleCard";
import {fetchUser} from "../../data/entities/User";
import ArticleUnpublished from "../entities/Article/ArticleUnpublished";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
class ContributionsArticlesPage extends Component
{
    state = {
        platform: null,
        partner_selector_open: false,
        search: '',
        loading: false,
        results: null,
        published_before: null,
        rss_articles: null,
        limit: 200,
    }

    componentDidMount() {

        this.fetchunpublishedarticle();

        this.submit();

    }

    fetchunpublishedarticle(){
        this.props.getUnpublishedArticles().then((data) => {
            //console.log('article mine ::: ::: ',data.payload.data);
            this.setState({
                art_articles: data.payload.data,
                limit: this.state.limit + 200,
            })
        })
    }

    deleteRssArticles(e){

        var array = this.state.rss_articles;
        var index = array.indexOf(e)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({
                rss_articles: array
            });
        } }


    submit = (e = null) => {
        if (e) e.preventDefault()

        this.setState({
            loading: true,
            results: []
        })

        let published_before = moment().format('YYYY-MM-DD HH:mm:ss')

        // this.props.getArticles(this.state.search, this.state.platform, published_before)
        this.props.getArticlesSearch(this.state.search)
            .then((data) => {
                const articles = data.payload.data

                this.setState({
                    loading: false,
                    results: articles,
                    published_before: articles.length ? articles[articles.length - 1].publication_start : published_before
                })
            })

        return false;
    }

    loadMore = () => {
        this.setState({
            loading: true,
            limit: this.state.limit + 200
        })

        this.props.getArticlesExpert(this.state.limit)
            .then((data) => {
                const articles = data.payload.data

                this.setState({
                    loading: false,
                    art_articles: [...this.state.results, ...articles],
                    published_before: articles.length ? articles[articles.length - 1].publication_start : this.state.published_before,

                })
            })
        console.log('after limit ::: after limit', this.state.limit)
    }

    render()
    {
        return (
            <div className="Contribution">
                <ArticlesTabs tab="Contribution"/>
                <form onSubmit={this.submit}>
                    <div className="d-flex mt-4">
                        <input type="text"
                               value={this.state.search}
                               onChange={(e) => this.setState({ search: e.target.value })}
                               placeholder="Rechercher un titre d'article..."
                               className="form-control mx-1" style={{ flex: 1 }}/>
                        <button className="btn btn-primary">
                            <i className="fa fa-search"/>{' '}Rechercher
                        </button>

                    </div>
                </form>
                {this.renderExplorer()}
            </div>
        );
    }

    renderExplorer()
    {
        const { loading, results } = this.state

        return (
            <>
                <div className="row my-4">
                    {this.renderUnpublishedArticlemine()}
                </div>

                <div className="text-center mb-3">
                    <button onClick={this.loadMore} disabled={loading || !results || results.length == 0} className="btn btn-primary px-5">
                        {loading ? <Loader/> : "Voir plus"}
                    </button>
                </div>
            </>
        )
    }

    renderUnpublishedArticlemine(){
        if (! this.state.art_articles) return <div />

        const { art_articles, loading } = this.state

        if (!art_articles) return <ContentLoader/>

        if (art_articles.length === 0) {
            return loading ? <ContentLoader/> : <Empty/>
        }

        return art_articles.map((article) => {
            return <ArticleUnpublished key={article.id}
                                       article={article}
            />  })
    }

    renderArticles()
    {
        if (!this.props.curation.partners) return <ContentLoader/>;

        const { results, loading } = this.state

        if (!results) return <ContentLoader/>

        if (results.length == 0) {
            return loading ? <ContentLoader/> : <Empty/>
        }

        return results.map((article) => {
            const partner = this.props.curation.partners.find((p) => p.id == article.platform)
            return <CurationArticle article={article} partner={partner} />
        })
    }

    renderPartners()
    {
        return (
            <Dropdown isOpen={this.state.partner_selector_open}
                      toggle={() => this.setState({ partner_selector_open: !this.state.partner_selector_open })}>
                <DropdownToggle caret>
                    {this.renderActivePartner()}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={(e) => this.setState({ platform: null },() => this.submit(e))}>
                        <strong>Toutes les communautés</strong>
                    </DropdownItem>
                    {this.props.curation.partners ? this.props.curation.partners.map((partner) => {
                        return (
                            <DropdownItem onClick={(e) => this.setState({ platform: partner.id }, () => this.submit(e))}>
                                {this.renderPartner(partner)}
                            </DropdownItem>
                        )
                    }) : (
                        <DropdownItem disabled>Chargement...</DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        )
    }

    renderActivePartner()
    {
        const {platform} = this.state
        if (!platform) return "Toutes les communautés"

        const partner = this.props.curation.partners.find((partner) => partner.id == platform)
        if (!partner) return "Toutes les communautés"

        return this.renderPartner(partner)
    }

    renderPartner(partner)
    {
        return (
            <>
                <img src={partner.logo} alt="" style={{ maxHeight: 30, maxWidth: 50 }}/>
                {' '}
                <span>{partner.name}</span>
            </>
        )
    }
}

export default connect(
    ({ user_entity, role_entity, user , curation }) => ({ user_entity, role_entity, user , curation }),
    { getPartners, getArticles ,getArticlesExpert,getArticlesSearch, getDraftRssArticles, fetchAll, highlightArticle, fetchUser, getUnpublishedArticles}
)(ContributionsArticlesPage);
