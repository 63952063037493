import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from 'react-tooltip'
import { FormattedMessage } from 'react-intl';
import ContentLoader from '../../../components/ContentLoader'
import { Link } from "react-router-dom";

import { fetchArticle } from "../../../data/entities/Article";

import ArticleDeleteModal from "./ArticleDeleteModal";
import ArticleDashboard from './ArticleDashboard';
import ArticleStats from './ArticleStats';
import ArticleUpdateForm from './ArticleUpdateForm';
import ArticleOverviewModal from "./ArticleOverviewModal"

import "./ArticleViewPage.scss";
import {checkPrivilege} from "../../../helpers";

class ArticleViewPage extends Component {

    state = {
        activeTab: "info",
        id: this.props.match.params.id,
        data: null,
        loading: true,

        showArticleDeleteModal: false,
        showArticleOverviewModal: false
    };

    componentDidMount() {
        this.props.fetchArticle(this.state.id)
            .then(res => {this.setState({
                loading: false, data: res.payload.data }
            )})
            .catch(e => { console.log(e);
                if (
                    e.error &&
                    e.error.response &&
                    e.error.response.data &&
                    e.error.response.data.error
                )
                    toast.error(<FormattedMessage id={e.error.response.data.error} />);
                this.props.history.push("/entities/article");
            });
    }


    renderToolTip(id, message, place) {
        return (
            <ReactTooltip id={id} effect={"solid"} place={place}>
                <div>{message}</div>
            </ReactTooltip>
        )
    }

    render() {
        console.log("state", this.state.data);
        return (
            <div className="article-view-page">
                {this.renderHeader()}
                {this.renderTabs()}
                {this.renderTabsContent()}

                {this.renderToolTip("published", "Publié", "bottom")}
                {this.renderToolTip("highlighted", "Mis en avant", "bottom")}

                {this.state.showArticleDeleteModal &&
                <ArticleDeleteModal
                    data={this.state.data}
                    onClose={deleted => {
                        if (deleted) {
                            this.props.history.push("/entities/article");
                        } else this.setState({ showArticleDeleteModal: false });
                    }}
                />}

            {this.state.showArticleOverviewModal &&
                <ArticleOverviewModal
                    data={this.state.data}
                    onClose={() => this.setState({ showArticleOverviewModal: false })}/>
            }
            </div>
        )
    }

    renderStatusIcons() {
        const publication_status = parseInt(this.state.data.publication_status)
        const highlight_status = parseInt(this.state.data.highlight_status)

        if (publication_status === 1 && highlight_status === 1) {
            return (
                <Fragment>
                    <div data-tip data-for="published" className={"published"} id={"published"}><i className="fas fa-check"/></div>
                    <div data-tip data-for="highlighted" className={"highlighted"} id={"highlighted"}><i className="fas fa-star"/></div>
                </Fragment>
            )
        } else if (publication_status === 1) {
            return <div title="Article publié" data-for="published" className={"published published-only"}><i className="fas fa-check"/></div>

        } else if (highlight_status === 1) {
            return <div title="Article mis en avant" data-for="highlighted" className={"highlighted"}><i className="fas fa-star"/></div>
        }
    }

    renderHeader() {
        if (this.state.loading) return <ContentLoader />;

        return (
            <Fragment>
                <div className={"header"}>
                    <div className={"title"}>
                        <h4>{this.state.data.title}</h4>
                        {this.renderStatusIcons()}
                    </div>

                    <div className="btn-group">
                        <div
                            className={"preview-btn btn"}
                            onClick={() => this.setState({ showArticleOverviewModal: true })}>
                            <i className="fas fa-search"/>Aperçu
                        </div>

                        {checkPrivilege(this.props.user, 'article:manage') ? (
                            <Link
                                to={"/editor/article/" + this.state.id}
                                //onClick={() => this.setState({ showArticleUpdateModal: true })}
                                className="edit-btn"
                                style={{textDecoration: "none"}}>
                                <div className="icon"><i className="fas fa-pencil-alt"/></div>
                                <div className="edit">Éditer</div>
                            </Link>
                        ) : null}

                        {checkPrivilege(this.props.user, 'article:delete') ? (
                            <div
                                className={"delete-btn"}
                                onClick={() => this.setState({showArticleDeleteModal: true})}>
                                <div className="icon"><i className="fas fa-trash-alt"/></div>
                                <div className="delete">Supprimer</div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Fragment>
        )
    }

    renderTabs() {
        return (
            <div className="navbar" id="nav-tab" role="tablist">
                <a
                    onClick={() => this.setState({ activeTab: "info" })}
                    className={`info ${this.state.activeTab === "info" && "active"}`}
                    id="nav-info-tab" data-toggle="tab" href="#nav-info" role="tab" aria-controls="nav-info" aria-selected="true">
                    <i className="far fa-lightbulb mr-3"/>
                    INFORMATIONS
                </a>
                <a
                    onClick={() => this.setState({ activeTab: "stats" })}
                    className={`stats ${this.state.activeTab === "stats" && "active"}`}
                    id="nav-stats-tab" data-toggle="tab" href="#nav-stats" role="tab" aria-controls="nav-stats" aria-selected="true">
                    <i className="far fa-chart-bar mr-3"/>
                    STATISTIQUES
                </a>
                {checkPrivilege(this.props.user, 'article:manage') ? (
                    <a
                        onClick={() => this.setState({ activeTab: "settings" })}
                        className={`settings ${this.state.activeTab === "settings" && "active"}`}
                        id="nav-settings-tab" data-toggle="tab" href="#nav-settings" role="tab" aria-controls="nav-settings" aria-selected="true">
                        <i className="fas fa-cog mr-3"/>
                        PARAMÈTRES
                    </a>
                ) : null}
            </div>
        )
    }

    renderTabsContent() {
        if (this.state.loading) return <ContentLoader />;
        return (
            <div className="tab-content" id="nav-tabContent">
                <div className={`tab-pane fade ${this.state.activeTab === "info" && "active show"}`} id="nav-info" role="tabpanel" aria-labelledby="nav-info-tab">
                    <ArticleDashboard data={this.state.data} loading={this.state.loading}/>
                </div>
                <div className={`tab-pane fade ${this.state.activeTab === "stats" && "active show"}`} id="nav-stats" role="tabpanel" aria-labelledby="nav-stats-tab">
                    <ArticleStats id={this.state.id} loading={this.state.loading}/>
                </div>
                <div className={`tab-pane fade ${this.state.activeTab === "settings" && "active show"}`} id="nav-settings" role="tabpanel" aria-labelledby="nav-settings-tab">

                    <p className="alert alert-info">
                        Pour améliorer votre expérience, toutes les fonctionnalités de modification de l'article ont été transférées vers l'éditeur.
                        Cliquez sur <strong>Éditer</strong> en haut à droite pour modifier votre article.
                    </p>

                    {/*
                        <ArticleUpdateForm data={this.state.data} onClose={(updated) => this.setState({ data: updated, activeTab: "info" })} />
                    */}
                </div>
            </div>
        )
    }
}

export default connect(({ article_entity, user }) => ({ article_entity, user }), { fetchArticle })(ArticleViewPage);
