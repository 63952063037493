import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_CHANNEL_CATEGORY_INDEX = 'ENTITY_CHANNEL_CATEGORY_INDEX'
const ENTITY_CHANNEL_CATEGORY_INDEX_SUCCESS = 'ENTITY_CHANNEL_CATEGORY_INDEX_SUCCESS'
const ENTITY_CHANNEL_CATEGORY_INDEX_FAIL = 'ENTITY_CHANNEL_CATEGORY_INDEX_FAIL'

export function fetchAll(channel)
{
    return request(ENTITY_CHANNEL_CATEGORY_INDEX, 'get', `/channel/${channel}/categories`)
}

export function searchAll(channel, query)
{
    return request(ENTITY_CHANNEL_CATEGORY_INDEX, 'post', `/channel/${channel}/categories/search`, { query })
}

const ENTITY_CHANNEL_CATEGORY_RETRIEVE = 'ENTITY_CHANNEL_CATEGORY_RETRIEVE'

export function fetchChannelCategory(channel, id)
{
    return request(ENTITY_CHANNEL_CATEGORY_RETRIEVE, 'get', `/channel/${channel}/categories/${id}`)
}

const ENTITY_CHANNEL_CATEGORY_CREATE = 'ENTITY_CHANNEL_CATEGORY_CREATE'

export function createChannelCategory(channel, data)
{
    return request(ENTITY_CHANNEL_CATEGORY_CREATE, 'post', `/channel/${channel}/categories`, data)
}

const ENTITY_CHANNEL_CATEGORY_UPDATE = 'ENTITY_CHANNEL_CATEGORY_UPDATE'

export function updateChannelCategory(channel, id, data)
{
    return request(ENTITY_CHANNEL_CATEGORY_UPDATE, 'patch', `/channel/${channel}/categories/${id}`, data)
}


const ENTITY_CHANNEL_CATEGORY_DELETE = 'ENTITY_CHANNEL_CATEGORY_DELETE'

export function deleteChannelCategory(channel, id)
{
    return request(ENTITY_CHANNEL_CATEGORY_DELETE, 'delete', `/channel/${channel}/categories/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityChannelCategoryReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_CHANNEL_CATEGORY_INDEX:
            return {...state, loading: true}
        case ENTITY_CHANNEL_CATEGORY_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_CHANNEL_CATEGORY_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
