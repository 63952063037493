import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, Input, TextArea} from "react-forms";
import {Button} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';
import Modal from "../../../components/Modal";
import MediaSelectModal from "../Media/MediaSelectModal"
import Datepicker from "../../../components/Datepicker"
import ManageCategory from "./ManageCategory"
import ManageTag from "./ManageTag"
import ArticleExpertPublishModal from "./ArticleExpertPublishModal";
import "./ArticleUpdateForm.scss"

import {updateArticle, addArticleCover, deleteArticleCover, deleteMarketplaceTag, createMarketplaceTag} from "../../../data/entities/Article";
import {fetchAll as fetchAllUsers, fetchUser} from "../../../data/entities/User";
import ArticleCategoriesModal from "./ArticleCategoriesModal";
import {getPlatform} from "../../../data/Platform";
import {fetchAllProducts} from "../../../data/entities/StripeProduct"
import ContentLoader from "../../../components/ContentLoader";
import {checkPrivilege} from "../../../helpers";

import { verifyImageCover } from "../../../components/imageUtils";

class ArticleUpdateForm extends Component
{
    img = {
        file: this.props.data.cover ? this.props.data.cover.file : null,
        file_thumbnail: this.props.data.cover ? this.props.data.cover.file_thumbnail : null,
        file_thumbnail_medium: this.props.data.cover ? this.props.data.cover.file_thumbnail_medium : null,
        file_thumbnail_small: this.props.data.cover ? this.props.data.cover.file_thumbnail_small : null,
        file_thumbnail_wp: this.props.data.cover ? this.props.data.cover.file_thumbnail_wp : null
    }
    state = {
        _formData: null,
        _loading: false,
        showMediaSelectModal: false,
        showPublicationDatePickers: false,
        showHighlightDatePickers: false,

        authors: [],
        channels: [],

        publication_start_error: false,
        highlight_start_error: false,

        cover: this.props.data.cover,
        iscontribution: this.props.data.iscontribution,
        author_email: this.props.data.author_email,
        author_name: this.props.data.author_name,
        title: this.props.data.title,
        publishExpert: this.props.data.publishExpert,
        publishAdmin: this.props.data.publishAdmin,
        slug: this.props.data.slug,
        categories: this.props.data.categories,
        publication_start: this.props.data.publication_start,
        publication_end: this.props.data.publication_end,
        highlight_start: this.props.data.highlight_start,
        highlight_end: this.props.data.highlight_end,
        hat: this.props.data.hat,
        summary: this.props.data.summary,
        publication_status: this.props.data.publication_status,
        highlight_status: this.props.data.highlight_status,
        showCategoriesModal: false,
        subscribers_only: this.props.data.subscribers_only,
        scoop: this.props.data.scoop,
        marketplace_tags: this.props.data.marketplace_tags,
        marketplace_tag: '',
        display_marketplace_tags: false,
        free_period_end: this.props.data.free_period_end,

        stripe_products_ids: this.props.data.stripe_products.one_time,
        stripe_products_subs_ids: this.props.data.stripe_products.recurring,
        e_shop_url_product: this.props.data.e_shop_url_product,
        e_shop_url_product_sub: this.props.data.e_shop_url_product_sub,
        isChecked: false,
        article_status: false,
        data_images:this.img
    };


    componentDidMount() {
        this.props.fetchAllUsers()
            .then(res => this.setState(
                { authors: res.payload.data }
            ))

        this.props.getPlatform()
            .then((res) => {
                let data = res.payload.data;

                if(data['extra.marketplace_token'] && data['extra.marketplace_endpoint'])
                    this.setState({ display_marketplace_tags: true })

                if (data['extra.article_products'] && data['extra.article_products'].length > 0 && this.state.stripe_products_ids.length === 0) {
                    this.setState({ stripe_products_ids: data['extra.article_products'].split(',')})
                }

                if (data['extra.article_subscriptions'] && data['extra.article_subscriptions'].length > 0 && this.state.stripe_products_subs_ids.length === 0) {
                    this.setState({ stripe_products_subs_ids: data['extra.article_subscriptions'].split(',')})
                }
            })

        this.props.fetchAllProducts()


        // if(this.props.stripe_products_entity.index && this.props.stripe_products_entity.index.recurring ){
        //     this.props.stripe_products_entity.index.recurring.map((product) => {
        //         console.log('ppp')
        //         console.log(product)
        //     })
        // }

        this.checkImageInsideRSSData()
    this.setState({article_status: this.props.data.publication_status === 1 ? true : false})
        console.log('data ::::', this.props.data)
    }
    publishAS = () => {
        this.setState(
            {
                publishExpert: this.state.publishExpert == 1 ? null : 1,
            },
            () => {
                this.setState(
                    (prevState) => ({
                        publication_status:
                            prevState.publishExpert == null
                                ? prevState.publication_status === 1
                                    ? 0
                                    : 1
                                : prevState.publishExpert === 1
                                    ? 1
                                    : prevState.publication_status === 1
                                        ? 0
                                        : 1,
                        publication_start: prevState.publishExpert === 1 ? moment() : null,
                    }),
                    () => {
                        this.handleKeyUp();
                        console.log('publishAS');
                        console.log(this.state);
                    }
                );
            }
        );
    };
    handleDateChange = (input, date) => {
        console.log("INPUT --->", input, "DATE --->", date)
        this.setState({ [input]: date === null ? null :
                    moment(date)//.format("YYYY-MM-DD HH:mm")
            },
            ()=> this.handleKeyUp()
        );
    };

    // freePeriodMaxDate = () => {
    //     return this.state.publication_start ? moment(this.state.publication_start).add(30, 'days') : moment().add(30, 'days');
    // }

    freePeriodDate = () => {
        return this.state.free_period_end ? this.state.free_period_end :
            (
                this.state.publication_start ? moment(this.state.publication_start).add(7, 'days') : moment().add(7, 'days')
            );
    }

    uploadMedia = (articleId, media) => {
        this.props.addArticleCover(this.props.data.id, { media: media.id })
            .then(res => {
                // Mise à jour de l'état de la couverture
                this.setState({
                    cover: res.payload.data.cover,
                    data_images: {
                        file: res.payload.data.cover.file ? res.payload.data.cover.file : null,
                        file_thumbnail: res.payload.data.cover.file_thumbnail ? res.payload.data.cover.file_thumbnail : null,
                        file_thumbnail_medium: res.payload.data.cover.file_thumbnail_medium ? res.payload.data.cover.file_thumbnail_medium :null,
                        file_thumbnail_small: res.payload.data.cover.file_thumbnail_small ? res.payload.data.cover.file_thumbnail_small : null,
                        file_thumbnail_wp: res.payload.data.cover.file_thumbnail_wp ? res.payload.data.cover.file_thumbnail_wp : null
                    }
                }, () => {
                    console.log("COVER--->", this.state.cover);
                    console.log("data_images--->", this.state.data_images)
                });
            })

    }

    deleteCover = (id) => {
        this.props.deleteArticleCover(id)
            .then(res => {if (res.payload.data.deleted === true) {
                this.setState({
                    cover: null,
                    data_images: {
                        file: null,
                        file_thumbnail: null,
                        file_thumbnail_medium: null,
                        file_thumbnail_small: null,
                        file_thumbnail_wp: null
                    }
                })
            }})
    }

    handleAuthorChange = (id) => {
        let author = this.state.authors.find(author => author.id === parseInt(id))

        this.setState({
            author_name: author.fullname,
            author_email: author.email
        })
    }
    togglePublishModal = () => {
        this.setState({ showPublishModal: !this.state.showPublishModal });
    };


    handleKeyUp = () => {
        console.log('handleKeyUp', this.state.cover)
        console.log(this.state.publication_start ,this.state.publication_status)
        if(this.state.publishAdmin != true && this.state.publishExpert != true) {
            this.setState({publication_status: false, publication_start: null, publication_end: null})
        }

        // if(this.state.iscontribution === true){
        //     this.setState({publishAdmin:  true})
        // }
        if(this.state.stripe_products_subs_ids.length === 0) {
            var select = document.getElementById('stripe-products-subs')
            console.log('----------------------------select')
            console.log(select)
            if(select) {
                const selected_ids = Array.from(select.selectedOptions, option => option.value);
                this.setState({stripe_products_subs_ids: selected_ids})
            }
        }
        console.log(this.state.publication_status === true && this.state.publication_start === null)
        if (this.state.publication_status === true && this.state.publication_start === null ) {
            this.setState({publication_start_error: true})
        }
        if (this.state.highlight_status === true && this.state.highlight_start === null) {
            this.setState({ highlight_start_error: true})
        }
        if (this.state.highlight_start_error || this.state.publication_start_error) {
            return
        }
        if (this.state.scoop === 1 && (this.state.subscribers_only === 1 || this.state.highlight_status === 1 || this.state.publication_status === 1)) {
            return toast.error("Cet article est un scoop, il ne sera donc pas publié. Merci de désactiver ces trois options : - aricle publié - article mis en avant - article réservé aux abonnés")
        }

        console.log('this.state.subscribers_only',this.state.subscribers_only)
        console.log('this.state.stripe_products_ids.length',this.state.stripe_products_ids.length)
        console.log('this.state.stripe_products_subs_ids.length',this.state.stripe_products_subs_ids.length)
        if (this.state.subscribers_only === 1 && (this.state.stripe_products_ids.length === 0 && this.state.stripe_products_subs_ids.length === 0)) {
            return toast.error("Cet article est réservé aux abonnés. Veuillez lier l'article à au moins un produit/abonnement Stripe.")
        }


        const data = {...this.state}
        if (data.cover) data.cover = this.state.cover.id
        console.log(':::: publication_start :::', data.publication_start)
        console.log(':::: state publication_start :::', this.state.publication_start)
        console.log(':::: publication_status :::', data.publication_status)
        console.log(':::: state publication_status :::', this.state.publication_status)
        console.log(':::: expert :::', data.publishExpert)
        console.log(':::: data :::', data)

        if (data.subscribers_only === 1 && data.publication_start && !data.free_period_end) {
            data.free_period_end = moment(data.publication_start).add(7, 'days');
        } else if (data.subscribers_only === 0) {
            data.free_period_end = null;
        }

        let stripe_products_ids_formatted = this.state.stripe_products_ids.map((product) => {
            return {
                id: product,
                type: 'one_time'
            }
        });

        let stripe_products_subs_ids_formatted = this.state.stripe_products_subs_ids.map((product) => {
            return {
                id: product,
                type: 'recurring'
            }
        });

        data.stripe_products_ids = stripe_products_ids_formatted.concat(stripe_products_subs_ids_formatted);
        // this.setState(prevState => ({
        //     isChecked: !prevState.isChecked
        // }));
        delete data._loading
        delete data._formData
        delete data.authors
        delete data.showMediaSelectModal
        delete data.publication_start_error
        delete data.highlight_start_error
        delete data.stripe_products_subs_ids
        console.log('onkeyup changed data',data)

        this.props.updateArticle(this.props.data.id, data)
            .then((res) => {
                this.props.onClose(res.payload.data);
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })
    }

    _submit = () => {
        // if(this.state.iscontribution === true){
        //     this.setState({publishAdmin:  true})
        // }
        console.log('state cover +++',this.state.cover)
        if(this.state.stripe_products_subs_ids.length === 0) {
            var select = document.getElementById('stripe-products-subs')
            console.log('----------------------------select')
            console.log(select)
            if(select) {
                const selected_ids = Array.from(select.selectedOptions, option => option.value);
                this.setState({stripe_products_subs_ids: selected_ids})
            }
        }
        if (this.state.publication_status === true && this.state.publication_start === null ) {
            this.setState({publication_start_error: true})
        }
        if (this.state.highlight_status === true && this.state.highlight_start === null) {
            this.setState({ highlight_start_error: true})
        }
        if (this.state.highlight_start_error || this.state.publication_start_error) {
            return
        }
        if (this.state.scoop === 1 && (this.state.subscribers_only === 1 || this.state.highlight_status === 1 || this.state.publication_status === 1)) {
            return toast.error("Cet article est un scoop, il ne sera donc pas publié. Merci de désactiver ces trois options : - aricle publié - article mis en avant - article réservé aux abonnés")
        }

        console.log('this.state.subscribers_only',this.state.subscribers_only)
        console.log('this.state.stripe_products_ids.length',this.state.stripe_products_ids.length)
        console.log('this.state.stripe_products_subs_ids.length',this.state.stripe_products_subs_ids.length)
        if (this.state.subscribers_only === 1 && (this.state.stripe_products_ids.length === 0 && this.state.stripe_products_subs_ids.length === 0)) {
            return toast.error("Cet article est réservé aux abonnés. Veuillez lier l'article à au moins un produit/abonnement Stripe.")
        }

        this.setState({ _loading: true })
        const data = {...this.state}
        if (data.cover) data.cover = this.state.cover.id

        if (data.subscribers_only === 1 && data.publication_start && !data.free_period_end) {
            data.free_period_end = moment(data.publication_start).add(7, 'days');
        } else if (data.subscribers_only === 0) {
            data.free_period_end = null;
        }

        let stripe_products_ids_formatted = this.state.stripe_products_ids.map((product) => {
            return {
                id: product,
                type: 'one_time'
            }
        });

        let stripe_products_subs_ids_formatted = this.state.stripe_products_subs_ids.map((product) => {
            return {
                id: product,
                type: 'recurring'
            }
        });

        data.stripe_products_ids = stripe_products_ids_formatted.concat(stripe_products_subs_ids_formatted);
        console.log('cover :::',this.state.cover,this.state.publication_status)
        if ( this.state.cover &&  (
            this.state.data_images.file !== null
            || this.state.data_images.file_thumbnail !== null
            || this.state.data_images.file_thumbnail_medium !== null
            || this.state.data_images.file_thumbnail_small !== null
            || this.state.data_images.file_thumbnail_wp !== null )
            )
        {
            verifyImageCover(this.state.data_images).then((statusCodes) => {
                console.log('status code ::', statusCodes)
                console.log('status ARTICLE  ::', this.state.publication_status)
                if (this.state.publication_status == 1 && (statusCodes.includes(404) || statusCodes.includes(500))) {
                    toast.error("Votre image uploadée n’est pas reconnue, veuillez en choisir une autre pour pouvoir publier votre article.")
                    this.setState({_loading: false})
                    return
                }else{
                    this.props.updateArticle(this.props.data.id, data)
                        .then((res) => {
                            toast.success("Parfait ! Les modifications ont été appliquées.")
                            console.log("DATA ON CLOSE: " ,  res.payload.data)
                            this.setState({ _loading: false })
                            this.props.onClose(res.payload.data);
                            setTimeout(() => window.location.reload(), 1500);
                        })
                        .catch((e) => {
                            if (e.error && e.error.response && e.error.response.data)
                                this.setState({ _formData: e.error.response.data })
                            this.setState({ _loading: false })
                        })
                }
            });
        }else if( this.state.cover == null &&  (
            this.state.data_images.file === null
            && this.state.data_images.file_thumbnail === null
            && this.state.data_images.file_thumbnail_medium === null
            && this.state.data_images.file_thumbnail_small === null
            && this.state.data_images.file_thumbnail_wp === null )
        ){
            this.props.updateArticle(this.props.data.id, data)
                .then((res) => {
                    toast.success("Parfait ! Les modifications ont été appliquées.")
                    console.log("DATA ON CLOSE: " ,  res.payload.data)
                    this.setState({ _loading: false })
                    this.props.onClose(res.payload.data);
                    setTimeout(() => window.location.reload(), 1500);
                })
                .catch((e) => {
                    if (e.error && e.error.response && e.error.response.data){
                        console.log('eorror',e.error.response.data)
                        this.setState({ _formData: e.error.response.data })
                        this.setState({ _loading: false })
                    }

                })
        }
        delete data._loading
        delete data._formData
        delete data.authors
        delete data.showMediaSelectModal
        delete data.publication_start_error
        delete data.highlight_start_error
        delete data.stripe_products_subs_ids

    };

    deleteMarketplaceTag = (tag) => {

        this.setState({ _loading: true })

        this.props.deleteMarketplaceTag(this.props.data.id, tag)
            .then(res => {
                this.setState({ _loading: false, marketplace_tags: res.payload.data.marketplace_tags })
            })
            .catch(e => {
                this.setState({ _loading: false, _formData: e.error.response.data })
            })
    }

    createMarketplaceTag = (e) => {

        const { marketplace_tag } = this.state

        if (e.key === "Enter" && marketplace_tag) {

            this.setState({ _loading: true })

            this.props.createMarketplaceTag(this.props.data.id, marketplace_tag)
                .then(res => {
                    this.setState({ _loading: false, marketplace_tags: res.payload.data.marketplace_tags, marketplace_tag: '' })
                })
                .catch(e => {
                    this.setState({ _loading: false, _formData: e.error.response.data })
                })
        }
    }

    toggleStripeProducts = (e) => {
        const selected_ids = Array.from(e.target.selectedOptions, option => option.value);
        this.setState({ stripe_products_ids: selected_ids },
            this.handleKeyUp

        )
    }
    checkSubscribersOnly = (e) => {
        this.setState({
                subscribers_only: this.state.subscribers_only === 1 ? 0 : 1
            },
            this.defaultValForSubscriptions,

        )
    }

    defaultValForSubscriptions = (e) => {
        if(this.state.subscribers_only === 1){
            var select=document.getElementById('stripe-products-subs')
            if(select) {
                const selected_ids = Array.from(select.selectedOptions, option => option.value);
                this.setState({stripe_products_subs_ids: selected_ids},
                    this.handleKeyUp
                )
            }
        }else{
            this.setState({},
                this.handleKeyUp
            )
        }

    }
    toggleStripeProductsSubs = (e) => {
        const selected_ids = Array.from(e.target.selectedOptions, option => option.value);
        this.setState({ stripe_products_subs_ids: selected_ids },
            this.handleKeyUp
        )

    }
    checkImageInsideRSSData = (e) => {
        var article=this.props.data
        var image=""
        var cover={file: "", label: "",type:""}
        if(typeof article.rss_data !== 'undefined'  && article.rss_data && article.rss_data.length > 0){
            if(typeof article.rss_data[0]['image'] !== 'undefined' && article.rss_data[0]['image'] && article.rss_data[0]['image'].length > 0 && typeof article.rss_data[0]['imageDeleted'] === 'undefined'){
                if(Array.isArray(article.rss_data[0]['image'])){
                    image=article.rss_data[0]['image'][0]
                }else{
                    image=article.rss_data[0]['image']
                }
                cover.file= image
                image=image.split('.')
                cover.type= image[image.length-1]
                if(!this.state.cover) {
                    this.setState({cover: cover})
                }
            }
        }
    }

    handleinputstatus = (e)=>{
        this.setState({
                publication_status:this.state.publishExpert == null ? ( this.state.publication_status === 1 ? 0 : 1) : ( this.state.publishExpert == 1 ? this.state.publishExpert:( this.state.publication_status === 1 ? 0 : 1)) ,
                publishAdmin:   this.state.publishAdmin === true ? false : true,
            },
            () => this.setState({
                publication_start: this.state.publication_status === 0 ? null : moment(),
                publication_end: this.state.publication_status === 0 ? null : this.state.publication_end,
            }, this.handleKeyUp()),
        )
    }

    onChangeScoopValue = (e) => {
        this.setState({
                scoop: this.state.scoop === 1 ? 0 : 1,
            },
            this.handleKeyUp

        )
    }

    render() {
        const  userrole= this.props.user.profile;
        const roles = userrole.roles
        console.log('******************************************userrole')
        console.log(userrole)
        const is_contributor = userrole.privileges.includes("article:mine") ? true : false;

        if (this.props.stripe_products_entity.loading)
            return <ContentLoader />;

        return (
            <div className="article-update-form">
                <Form onSubmit={this._submit} data={this.state._formData}>

                    <div className="cover-container">
                        <label>Couverture</label>
                        {this.state.cover ?
                            <div className={"cover"}>
                                <img src={this.state.cover.file} alt={this.state.cover.label}/>
                            </div>
                            :
                            <div className={"cover empty"}>
                                <i className="fas fa-image"></i>
                            </div>
                        }

                        <div onClick={() => this.setState({showMediaSelectModal: true})}
                             className={"btn btn-sm btn-outline-primary mr-2 cover-btn"}>
                            {this.state.cover ? "Modifier la couverture" : "Ajouter une couverture"}
                        </div>

                        {this.state.cover &&
                            <div
                                className={"btn btn-sm btn-outline-danger cover-btn"}
                                onClick={() => this.deleteCover(this.props.data.id)}>Supprimer la cover
                            </div>}

                    </div>

                    <Input
                        value={this.state.title}
                        onChangeValue={(title) => this.setState({title})}
                        onFocus={(e) => e.target.select()}
                        placeholder="title" label="Titre" type="text"
                        onKeyUp={this.handleKeyUp}
                    />
                    {!is_contributor ?
                        <div className={"form-group"}>
                            <label>Catégories</label>
                            <div className="row">
                                <div className="col-md-11">
                                    <ManageCategory
                                        createArticleCategory={(selected) => {
                                            this.setState({categories: selected})
                                        }}
                                        deleteArticleCategory={() => this.setState({categories: null})}
                                        data={this.props.data}
                                        categories={this.state.categories}
                                    />
                                </div>
                                <div className="col-md-1">
                                    <Button
                                        color={'warning'}
                                        className={'button-categories-modal'}
                                        onClick={() => this.toggleCategoriesModal()}
                                    >
                                        <i className="fas fa-plus"></i>
                                    </Button>
                                </div>
                            </div>
                            <p className="text-muted mb-0">Votre article s'affichera dans la première catégorie
                                spécifiée. Ajouter d'autres catégories permettra de mieux l'indéxer.</p>
                        </div>
                        : null}
                    {!is_contributor ?
                        <div className={"form-group"}>
                            <label>Tags</label>
                            <ManageTag data={this.props.data}/>
                        </div>
                        : null}
                    {this.state.display_marketplace_tags ?
                        <div className={"form-group"}>
                            <label>Tags Marketplace</label>

                            <div className={"entity-list"}>
                                {this.state.marketplace_tags && this.state.marketplace_tags.split(',').map((tag) => {
                                    return (
                                        <div className={"btn btn-sm btn-primary m-1"}>
                                            {tag}
                                            <i onClick={() => this.deleteMarketplaceTag(tag)}
                                               className="fas fa-times ml-2"/>
                                        </div>
                                    )
                                })}
                                <div className="tag">
                                    <div className="entity-select">
                                        <input
                                            placeholder="Ajouter un tag pour le marketplace"
                                            autoComplete="off"
                                            type="text"
                                            value={this.state.marketplace_tag}
                                            onChange={(e) => this.setState({marketplace_tag: e.target.value})}
                                            onKeyUp={this.createMarketplaceTag}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}

                    <Input
                        value={this.state.author_name} onChangeValue={(author_name) => this.setState({author_name})}
                        onKeyUp={this.handleKeyUp}
                        placeholder="Auteur" label="Auteur" type="text"/>

                    <Input
                        value={this.state.author_email} onChangeValue={(author_email) => this.setState({author_email})}
                        placeholder="Email de l'auteur" label="Email de l'auteur" type="text"/>

                    {this.state.iscontribution && !is_contributor ?
                        <div className={'form-group mb-5 mt-3'}>
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    // onChange={() =>
                                    // {this.setState({
                                    //         // publication_status: this.state.publication_status === 1 ? 0 : 1,
                                    //         // publication_status:this.state.publishExpert == null ? ( this.state.publication_status === 1 ? 0 : 1) : ( this.state.publishExpert == 1 || this.state.publishAdmin == 1 ? 1 :( this.state.publication_status === 1 ? 0 : 1)) ,
                                    //         // publication_start: this.state.publication_status !== 0 ? null : moment(),
                                    //         publishExpert:  !this.state.publishExpert,
                                    //
                                    //         //  publishAdmin: this.state.publishExpert === 0 ? 1 : 0,
                                    //     },
                                    //     () => this.setState({
                                    //             publication_status:(!this.state.publishExpert && this.state.publishExpert == null ) ? ( this.state.publication_status === 1 ? 0 : 1) : ( this.state.publishExpert == true || this.state.publishAdmin == true ? 1 :( this.state.publication_status === 1 ? 0 : 1)) ,
                                    //             publication_start: this.state.publishExpert == true || this.state.publishAdmin == true ?  moment():null,
                                    //             publication_end: this.state.publishExpert == true || this.state.publishAdmin == true ?  this.state.publication_end:null,
                                    //
                                    //             // publishAdmin: this.state.publishExpert === 0 ? 1 : 0,
                                    //         },
                                    //         this.handleKeyUp()
                                    //     )
                                    // )
                                    // }
                                    // }
                                    onChange={() => {
                                        this.setState(
                                            {
                                                publishExpert: !this.state.publishExpert,
                                            },
                                            () => {
                                                this.setState(
                                                    {
                                                        publication_status: this.state.publishExpert == 1
                                                            ? 1
                                                            : this.state.publishAdmin == 1
                                                                ? 1
                                                                : this.state.publication_status === 1
                                                                    ? 0
                                                                    : 1,
                                                        publication_start:
                                                            this.state.publishExpert == 1 || this.state.publishAdmin == 1
                                                                ? moment()
                                                                : null,
                                                        publication_end:
                                                            this.state.publishExpert == 1 || this.state.publishAdmin == 1
                                                                ? this.state.publication_end
                                                                : null,
                                                    },
                                                    () => {
                                                        // Additional logic after setting state if needed
                                                        this.handleKeyUp();
                                                    }
                                                );
                                            }
                                        );
                                    }

                                    }
                                    checked={this.state.publishExpert == true ? 1 : 0}
                                    className="custom-control-input custom-control-expert" id="scoopE"/>
                                <label className="custom-control-label input-expert" htmlFor="scoopE">Article publié en tant qu'expert </label>
                            </div>
                            {
                                // this.state.publication_status === 1 && this.state.publishExpert === true &&
                                this.state.publishExpert === true ?
                                    <Fragment>
                                        <div className={"date-pickers date-pickers-expert"}>
                                            <div>
                                                <label>Date de début de publication:</label>
                                                <Datepicker
                                                    showTodayButton={true}
                                                    type={"datetime"}
                                                    handleDateChange={(date) => this.handleDateChange("publication_start", date)}
                                                    selectedDate={this.state.publication_start}/>
                                            </div>

                                        </div>
                                        {/*<div className="date-pickers date-pickers-expert">*/}
                                        {/*    <div>*/}
                                        {/*        <label>Date de fin de publication:</label>*/}
                                        {/*        <div className={"radios"}>*/}
                                        {/*            <div className="custom-control custom-radio option-1">*/}
                                        {/*                <input*/}
                                        {/*                    onChange={() => this.handleDateChange("publication_end", moment())}*/}
                                        {/*                    value={"updated_at"} type="radio" id="publication-end-expert"*/}
                                        {/*                    name="publication_end_expert" className="custom-control-input"*/}
                                        {/*                    checked={!!this.state.publication_end}*/}
                                        {/*                />*/}
                                        {/*                <label className={"custom-control-label"}*/}
                                        {/*                       htmlFor="publication-end-expert">*/}
                                        {/*                    <Datepicker*/}
                                        {/*                        showTodayButton={true}*/}
                                        {/*                        onOpen={() => this.handleDateChange("publication_end", moment())}*/}
                                        {/*                        type={"datetime"} custom-control-inline*/}
                                        {/*                        handleDateChange={(date) => this.handleDateChange("publication_end", date)}*/}
                                        {/*                        selectedDate={this.state.publication_end}/>*/}
                                        {/*                </label>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="custom-control custom-radio option-2">*/}
                                        {/*                <input*/}
                                        {/*                    onChange={() => {*/}
                                        {/*                        this.handleDateChange("publication_end", null)*/}
                                        {/*                    }}*/}
                                        {/*                    type="radio" id="publication-end-2" name="publication_end"*/}
                                        {/*                    className="custom-control-input"*/}
                                        {/*                    checked={!this.state.publication_end}/>*/}
                                        {/*                <label className="custom-control-label"*/}
                                        {/*                       htmlFor={"publication-end-2"}>Indéterminée</label>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </Fragment>
                                    : null}
                        </div>
                        : null
                    }
                    {!is_contributor ?

                        <div className={"form-group mt-4"}>
                            <div className={"form-group date mb-0"}>
                                <div className="custom-control custom-switch">
                                    <input type="checkbox"
                                        //        onChange={()=>
                                        //        {this.setState({
                                        //                    publication_status:this.state.publishExpert == null ? ( this.state.publication_status === 1 ? 0 : 1) : ( this.state.publishExpert === 1 ? this.state.publishExpert:( this.state.publication_status === 1 ? 0 : 1)) ,
                                        //                    // publishAdmin:   this.state.publishAdmin === true ? false : true,
                                        //                },
                                        //                () => this.setState({
                                        //                        publication_start: this.state.publication_status == true ?  moment() : null,
                                        //                        publication_end: this.state.publication_status == true ?  this.state.publication_end : null,
                                        //                        publishAdmin:   this.state.publication_status === true ? true : false,
                                        //                    },
                                        //                    this.handleKeyUp()
                                        //                ),
                                        //            )
                                        //            console.log(('/////////////////////////'))
                                        //            console.log(this.state.publication_status)
                                        //        }
                                        // }
                                           onChange={() => {
                                               this.setState(
                                                   (prevState) => ({
                                                       publication_status:
                                                           prevState.publishExpert == 1
                                                               ? 1
                                                               : prevState.publication_status === 1
                                                                   ? 0
                                                                   : 1,
                                                   }),
                                                   () => {
                                                       this.setState(
                                                           (prevState) => ({
                                                               publication_start: prevState.publication_status
                                                                   ? (prevState.publication_start === null ? moment() : prevState.publication_start)
                                                                   : null,
                                                               publication_end: prevState.publication_end,
                                                               publishAdmin: prevState.publishExpert == null ? (prevState.publication_status === 1 ? 1 : 0) : (prevState.publishAdmin == 1 ? 0 : 1),
                                                               isChecked: !prevState.isChecked
                                                           }),
                                                           () => {
                                                               // this.handleKeyUp();
                                                               console.log('/////////////publishAdmin////////////');
                                                               console.log(this.state.publishAdmin);
                                                               console.log('/////////////publishExpert////////////');
                                                               console.log(this.state.publishExpert);
                                                           }
                                                       );
                                                   }
                                               );
                                               // this.setState(prevState => ({
                                               //     isChecked: !prevState.isChecked
                                               // }));
                                           }
                                           }

                                           checked={((this.state.publication_status == true && this.state.publishAdmin == 1) || (this.state.publication_status == true && this.state.publishExpert == null)) ? 1 : 0}
                                        // checked={( this.state.publication_status === 1 && (this.state.publishExpert === null )) || (this.state.publication_status === 1 && this.state.publishAdmin === true)}
                                        // checked={( this.state.publishAdmin === true && this.state.publication_status === 1 ) === true || ( this.state.publication_status === 1 && this.state.publishExpert === null ) === true}
                                        // checked={( this.state.publishAdmin == 1 && this.state.publication_status  === true ) || ( this.state.publication_status === 1 && this.state.publishExpert  === true)}
                                        //    checked={ this.state.publishExpert == null? this.state.publication_status : this.state.publishAdmin }
                                           className="custom-control-input" id="publication_status"/>
                                    <label className="custom-control-label" htmlFor="publication_status">Article publié</label>
                                </div>
                            </div>

                            {
                                // ( (this.state.publication_status === 1  &&  this.state.publishAdmin === true)  ||  (this.state.publication_status === 1 && (this.state.publishExpert === null ) ) ) ?
                                // ( (this.state.publication_status === 1  &&  this.state.publishAdmin === true)  ||  (this.state.publication_status === 1 && (this.state.publishExpert === null ) ) ) ?
                                // ( this.state.publishAdmin === true && this.state.publication_status === 1 ) || ( this.state.publication_status === 1 && this.state.publishExpert === null ) ?
                                (this.state.publishAdmin == 1 && this.state.publication_status == true) || (this.state.publication_status === 1 && this.state.publishExpert === null) ?


                                    <Fragment>

                                        <div className={"date-pickers"}>
                                            <div>
                                                <label>Date de début de publication:</label>
                                                <Datepicker
                                                    showTodayButton={true}
                                                    type={"datetime"}
                                                    handleDateChange={(date) => this.handleDateChange("publication_start", date)}
                                                    selectedDate={this.state.publication_start}/>

                                            </div>

                                        </div>
                                        <div className="date-pickers">
                                            <div>
                                                <label>Date de fin de publication:</label>
                                                <div className={"radios"}>
                                                    <div className="custom-control custom-radio option-1">
                                                        <input
                                                            onChange={() => this.handleDateChange("publication_end", moment())}
                                                            value={"updated_at"} type="radio" id="publication-end-1"
                                                            name="publication_end" className="custom-control-input"
                                                            checked={!!this.state.publication_end}
                                                        />
                                                        <label className={"custom-control-label"}
                                                               htmlFor="publication-end-1">
                                                            <Datepicker
                                                                showTodayButton={true}
                                                                onOpen={() => this.handleDateChange("publication_end", moment())}
                                                                type={"datetime"} custom-control-inline
                                                                handleDateChange={(date) => this.handleDateChange("publication_end", date)}
                                                                selectedDate={this.state.publication_end}/>
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio option-2">
                                                        <input
                                                            onChange={() => {
                                                                this.handleDateChange("publication_end", null)
                                                            }}
                                                            type="radio" id="publication-end-2" name="publication_end"
                                                            className="custom-control-input"
                                                            checked={!this.state.publication_end}/>
                                                        <label className="custom-control-label"
                                                               htmlFor={"publication-end-2"}>Indéterminée</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                    : null}
                        </div>
                        : null
                    }
                    {!is_contributor ?
                        <div className={"form-group"}>
                            <div className={"form-group date mb-0"}>
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        onChange={() => {
                                            this.setState({
                                                    highlight_status: this.state.highlight_status === 1 ? 0 : 1
                                                },
                                                () => this.setState({
                                                        highlight_start: this.state.highlight_status === 0 ? null : moment(),
                                                        highlight_end: this.state.highlight_status === 0 ? null : this.state.highlight_end
                                                    },
                                                    this.handleKeyUp()
                                                ),
                                            )

                                        }
                                        }
                                        checked={this.state.highlight_status === 1}
                                        className="custom-control-input" id="highlight_status"/>
                                    <label className="custom-control-label" htmlFor="highlight_status">Article mis en
                                        avant</label>
                                </div>
                            </div>

                            {
                                this.state.highlight_status === 1 &&
                                <Fragment>
                                    <div className={"date-pickers"}>
                                        <div>
                                            <label>Date de début de mise en avant:</label>
                                            <Datepicker
                                                showTodayButton={true}
                                                type={"datetime"}
                                                handleDateChange={(date) => this.handleDateChange("highlight_start", date)}
                                                selectedDate={this.state.highlight_start}/>
                                        </div>
                                    </div>

                                    <div className={"date-pickers"}>
                                        <div>
                                            <label>Date de fin de mise en avant:</label>
                                            <div className={"radios"}>
                                                <div className="custom-control custom-radio option-1">
                                                    <input
                                                        onChange={() => this.handleDateChange("highlight_end", moment())}
                                                        value={"updated_at"} type="radio" id="highlight-end-1"
                                                        name="highlight_end"
                                                        className="custom-control-input"
                                                        checked={!!this.state.highlight_end}
                                                    />
                                                    <label className={"custom-control-label"}
                                                           htmlFor={"highlight-end-1"}>
                                                        <Datepicker
                                                            showTodayButton={true}
                                                            onOpen={() => this.handleDateChange("highlight_end", moment())}
                                                            type={"datetime"}
                                                            handleDateChange={(date) => this.handleDateChange("highlight_end", date)}
                                                            selectedDate={this.state.highlight_end}/>
                                                    </label>
                                                </div>

                                                <div className="custom-control custom-radio option-2">
                                                    <input
                                                        onChange={() => {
                                                            this.handleDateChange("highlight_end", null)
                                                        }}
                                                        value={"null"} type="radio" id="highlight-end-2"
                                                        name="highlight_end"
                                                        className="custom-control-input"
                                                        checked={!this.state.highlight_end}/>
                                                    <label className="custom-control-label"
                                                           htmlFor={"highlight-end-2"}>Indéterminée</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                        : null
                    }
                    {!is_contributor ?
                        <div className={'form-group'}>
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    onChange={(e) => this.checkSubscribersOnly(e)}
                                    checked={this.state.subscribers_only === 1}
                                    className="custom-control-input" id="subscribers_only"/>
                                <label className="custom-control-label" htmlFor="subscribers_only">Article réservé aux
                                    abonnés</label>
                            </div>
                        </div>
                        : null
                    }
                    {!is_contributor ?
                        <>
                            {
                                this.state.subscribers_only === 1 ?
                                    <>
                                        <div className={"date-pickers mt-2"}>
                                            <div>
                                                <label>Réserver aux abonnés à partir de:</label>
                                                <Datepicker
                                                    showTodayButton={true}
                                                    type={"datetime"}
                                                    handleDateChange={(date) => this.handleDateChange("free_period_end", date)}
                                                    selectedDate={this.freePeriodDate()}
                                                    // maxDate={this.freePeriodMaxDate()}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style={{display: checkPrivilege(this.props.user, 'reserve-abonnes') || roles.indexOf('ROLE_ADMIN') !== -1 ? 'block' : 'none'}}>
                                            <div className="form-group">
                                                <label htmlFor="stripe-products">Veuillez choisir à
                                                    quels <b>produits</b> Stripe cet article est rattaché</label>
                                                <select name="stripe-products" id="stripe-products"
                                                        className={'form-control'} multiple
                                                        onChange={(e) => this.toggleStripeProducts(e)}>
                                                    {
                                                        this.props.stripe_products_entity.index && this.props.stripe_products_entity.index.one_time && this.props.stripe_products_entity.index.one_time.map((product) => {
                                                            return (
                                                                <option value={product.price_id}
                                                                        selected={this.state.stripe_products_ids.includes(product.price_id)}>{product.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group mb-5">
                                                <label htmlFor="stripe-products">L'URL de
                                                    redirection <b>produit</b> (doit
                                                    commencer par "http" ou "https"; si laissée vide, la redirection se
                                                    fera
                                                    sur le produit le moins cher)</label>
                                                <input
                                                    type="text"
                                                    className={'form-control'}
                                                    placeholder={'URL de redirection lorsque l\'utilisateur cliquera sur le bouton du produit'}
                                                    onChange={(e) => this.setState({e_shop_url_product: e.target.value})}
                                                    value={this.state.e_shop_url_product}
                                                    onKeyUp={this.handleKeyUp}
                                                />
                                            </div>
                                            <div className="form-group">

                                                <label htmlFor="stripe-products-subs">Veuillez choisir à
                                                    quels <b>abonnements</b> Stripe cet article est rattaché </label>
                                                <select name="stripe-products-subs" id="stripe-products-subs"
                                                        className={'form-control'} multiple
                                                        onChange={(e) => this.toggleStripeProductsSubs(e)}>
                                                    {
                                                        this.props.stripe_products_entity.index && this.props.stripe_products_entity.index.recurring && this.props.stripe_products_entity.index.recurring.map((product) => {
                                                            return (
                                                                <option value={product.price_id}
                                                                        selected={!!(product.name === "Abonnement Web Air&Cosmos" || this.state.stripe_products_subs_ids.includes(product.price_id))}>{product.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="stripe-products">L'URL de
                                                    redirection <b>abonnement</b> (doit commencer par "http" ou "https";
                                                    si
                                                    laissée vide, la redirection se fera sur l'abonnement le moins
                                                    cher)</label>
                                                <input
                                                    type="text"
                                                    className={'form-control'}
                                                    placeholder={'URL de redirection lorsque l\'utilisateur cliquera sur le bouton d\'abonnement'}
                                                    onChange={(e) => this.setState({e_shop_url_product_sub: e.target.value})}
                                                    value={this.state.e_shop_url_product_sub}
                                                    onKeyUp={this.handleKeyUp}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }
                        </>
                        : null
                    }
                    {!is_contributor ?
                        <div className={'form-group mb-5 mt-3'}>
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    onChange={(e) =>
                                        this.onChangeScoopValue(e)
                                    }
                                    checked={this.state.scoop === 1}
                                    className="custom-control-input" id="scoop"/>
                                <label className="custom-control-label" htmlFor="scoop">Cet article est un scoop (ne sera pas publié)</label>
                            </div>
                        </div>
                        : null

                    }


                    <div style={{opacity: 0.5}}>
                        <TextArea
                            value={this.state.summary}
                            onChangeValue={(summary) => this.setState({summary})}
                            id="summary" name="summary" label="Résumé"
                            onKeyUp={this.handleKeyUp}
                        />
                        <p className="text-muted">Ce champ a peu d'importance pour l'instant.</p>
                    </div>

                    <br/>

                    <TextArea
                        value={this.state.hat}
                        onChangeValue={(hat) => this.setState({hat})}
                        id="hat" name="hat" label="Chapeau"
                        onKeyUp={this.handleKeyUp}
                    />
                    <p className="text-muted">Ce champ est à remplir de préférence. Son contenu s'affichera en introduction de votre article, et sur les vignettes d'articles.</p>

                    <br/>


                    {is_contributor ?
                        <div className={'form-group mb-5 mt-3'}>
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    onChange={() => this.publishAS()}
                                    checked={this.state.publishExpert == 1 ? 1 : 0}
                                    className="custom-control-input" id="scoop"/>
                                <label className="custom-control-label" htmlFor="scoop">Publier mon article en tant qu’expert</label>


                            </div>
                        </div>
                        : null

                    }

                    {this.state.showPublishModal &&
                        <ArticleExpertPublishModal
                            data={this.props.data}
                            onClose={() => this.togglePublishModal()}
                        />
                    }

                    {!is_contributor ? (
                            !this.state.iscontribution ? (
                                this.state.article_status ? (
                                    <Submit className="btn btn-primary submit"
                                            disabled={!this.state.isChecked}>Dépublier l’article</Submit>
                                ) : (
                                    <Submit className="btn btn-primary submit"
                                            disabled={!this.state.isChecked}>Publier l’article</Submit>
                                )
                            ) : (
                                <Submit className="btn btn-primary submit"> Sauvegarder </Submit>
                            )
                        ) :
                        (
                            <Submit className="btn btn-primary submit"> Sauvegarder </Submit>
                        )
                    }
                    {/*<p  onClick={this.togglePublishModal} className="font-weight-bold submit mt-3">Date de début de publication de l’expert</p>*/}
                    {/*   <div  onClick={this.formPublishExpert} className="btn btn-secondary submit mt-3">Publier mon article en tant qu’expert</div>*/}
                </Form>
                {this.state.showMediaSelectModal &&
                    <MediaSelectModal
                        onClose={(uploaded) => {
                            if (uploaded) this.setState({showMediaSelectModal: false}, this.uploadMedia(null, uploaded))
                            this.setState({showMediaSelectModal: false})
                        }}
                        requiredType={"image"}/>}

                {this.state.showCategoriesModal &&
                    <ArticleCategoriesModal
                        id={this.props.data.id}
                        onClose={() => this.toggleCategoriesModal()}
                        updated={(data) => this.updated(data)}
                    />
                }
            </div>
        );
    }

    toggleCategoriesModal = () => {
        this.setState({ showCategoriesModal: !this.state.showCategoriesModal });
    }

    updated = (data) => {
        this.toggleCategoriesModal();
        this.setState({ categories: data.categories });
    }
}

export default connect(
    ({user_entity, role_entity,user, stripe_products_entity}) => ({user_entity, role_entity,user, stripe_products_entity}),
    { updateArticle, addArticleCover, deleteArticleCover, fetchAllUsers, deleteMarketplaceTag, createMarketplaceTag, getPlatform, fetchAllProducts, fetchUser })(ArticleUpdateForm)
