import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';
import Modal from "../../../components/Modal";

import {updateBlockType} from "../../../data/entities/BlockType";

class BlockTypeUpdateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        name: this.props.data.name,
        slug: this.props.data.slug,
    }

    _submit = () => {
        this.setState({ _loading: true })

        const data = {...this.state}
        delete data._loading
        delete data._formData

        this.props.updateBlockType(this.props.data.id, data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })
    }

    render()
    {
        return (
            <Modal title={this.props.data.id} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
					<Input
					    value={this.state.name} onChangeValue={(name) => this.setState({ name })}
					    placeholder="name" label="name" type="text"/>
					
					<Input
					    value={this.state.slug} onChangeValue={(slug) => this.setState({ slug })}
					    placeholder="slug" label="slug" type="text"/>
					
					
                    <Submit disabled={this.state._loading}>Sauvegarder</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { updateBlockType })(BlockTypeUpdateModal)
