import React, {Component} from 'react';
import Modal from "../../../components/Modal"
import Datepicker from "../../../components/Datepicker";
import moment from "moment-timezone";
import {connect} from 'react-redux';
import {Form, Submit} from "react-forms";
import {toast} from "react-toastify";
import {updateArticle} from "../../../data/entities/Article";
import "./ArticleUpdateForm.scss";


class ArticleExpertPublishedModal extends Component
{
    state = {
        loading: false,
        publication_start: this.props.data.publication_start,
        publication_end: this.props.data.publication_start,
        publication_status: true
    };

    handleDateChange = (input, date) => {
        this.setState({ [input]: date === null ? null : moment(date) });
    };

    _submit = (e) => {
        if (!this.state.publication_start)
            return;

        if (this.state.publication_end && (this.state.publication_end < this.state.publication_start || this.state.publication_end < moment()))
            return;

        this.setState({ _loading: true });

        const data = Object.assign(this.props.data, {...this.state});

        this.props.updateArticle(this.props.data.id, data)
            .then((res) => {
                toast.success("Parfait ! Les modifications ont été appliquées.");
                this.setState({ _loading: false });
                this.props.onClose()
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data);
                this.setState({ _formData: e.error.response.data });
                this.setState({ _loading: false })
            })
    };

    render() {

        const buttonStyle = {
            width: 'fit-content'
        };

        return(
            <Modal title={'Date de début de publication de l’expert'} onClose={() => this.props.onClose()}>
                <div className="article-update-form">
                    <Form >
                        <div className={"date-pickers"}>
                            <div>
                                <Datepicker
                                    showTodayButton={true}
                                    type={"datetime"}
                                    handleDateChange={(date) => this.handleDateChange("publication_start", date)}
                                    selectedDate={this.state.publication_start}/>
                            </div>

                        </div>
                        <div onClick={this._submit} className="btn btn-primary" style={buttonStyle}>Publier</div>
                    </Form>
                </div>
            </Modal>
        )
    }
}

export default connect(null, {updateArticle})(ArticleExpertPublishedModal);
