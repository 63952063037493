const locale = {
    // Validator
    "validator.form-error": "Veuillez vérifier le formulaire.",
    "validator.email": "Cette adresse e-mail est invalide.",
    "validator.email.unknown": "Adresse e-mail inconnue.",
    "validator.email.taken": "Adresse e-mail déjà utilisée.",
    "validator.min_length": "Trop court.",
    "validator.max_length": "Trop long.",

    // Validator (custom)

    "validator.form-error:auth.login": "Ces identifiants sont incorrects.",
    "validator.min_length:password:auth.register": "Ce mot de passe est trop court (min. 6 caractères).",

    "validator.email:email:admin:register": "L'adresse e-mail ne correspond pas au format attendu.",
    "validator.min_length:password:admin:register": "Ce mot de passe est trop court (min. 6 caractères).",
    "validator.email.taken:email:admin:register": "Adresse e-mail déjà utilisée.",
    "validator.password.updated": "Mot de passe mis à jour.",

    // ...
};

export default locale;
