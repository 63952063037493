import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet'
import './Auth.scss'

import { register } from '../../data/OAuth'
import {Form, Input, Submit} from "react-forms";
import {toast} from "react-toastify";

class RegisterPage extends Component
{
    state = {
        email: '',
        password: '',
        formData: null
    }

    _submit = () => {
        this.props.register(this.state.email, this.state.password)
            .then((e) => {
                toast.success(e.payload.data.message)
                this.props.history.push('/auth/login')
            })
            .catch((e) => {
                this.setState({ formData: e.error && e.error.response ? e.error.response.data : null })
            })
    }

    render()
    {
        return (
            <div className="container auth-container">
                <Helmet title="Inscription" />
                <Form onSubmit={this._submit} data={this.state.formData} toast>
                    <h1 className="h3 mb-3 font-weight-normal">Créer un compte</h1>
                    <Input
                        type="email"
                        name="email"
                        placeholder="Adresse e-mail"
                        value={this.state.email}
                        onChangeValue={(email) => this.setState({ email })}
                        required autoFocus
                    />
                    <Input
                        type="password"
                        name="password"
                        placeholder="Mot de passe"
                        value={this.state.password}
                        onChangeValue={(password) => this.setState({ password })}
                        required
                    />
                    <div className="row mb-3">
                        <div className="col">
                            <Link to="/auth/login">Vous avez déjà un compte ?</Link>
                        </div>
                    </div>
                    <Submit>Inscription</Submit>
                </Form>
            </div>
        )
    }
}

export default connect(null, { register })(RegisterPage)
