import moment from 'moment-timezone';
import { request } from "../../helpers";

// Actions

const ENTITY_CHANNEL_MENU_INDEX = "ENTITY_CHANNEL_MENU_INDEX";
const ENTITY_CHANNEL_MENU_INDEX_SUCCESS = "ENTITY_CHANNEL_MENU_INDEX_SUCCESS";
const ENTITY_CHANNEL_MENU_INDEX_FAIL = "ENTITY_CHANNEL_MENU_INDEX_FAIL";

export function fetchAll(channel) {
  return request(ENTITY_CHANNEL_MENU_INDEX, "get", `/channel/${channel}/menus`);
}

const ENTITY_CHANNEL_MENU_CREATE = "ENTITY_CHANNEL_MENU_CREATE";
const ENTITY_CHANNEL_FIND_BY_STATUS = "ENTITY_CHANNEL_FIND_BY_STATUS";


export function createChannelMenu(channel, data) {
  return request(
    ENTITY_CHANNEL_MENU_CREATE,
    "post",
    `/channel/${channel}/menus`,
    data
  );
}

export function countStatus(channel) {
  return request(
      ENTITY_CHANNEL_FIND_BY_STATUS,
      "get",
      `/count_status/${channel}`);
}


const ENTITY_CHANNEL_MENU_UPDATE = "ENTITY_CHANNEL_MENU_UPDATE";

export function updateChannelMenu(channel, id, data) {
  return request(
    ENTITY_CHANNEL_MENU_UPDATE,
    "patch",
    `/channel/${channel}/menus/${id}`,
    data
  );
}

const ENTITY_CHANNEL_MENU_UPDATE_POSITION =
  "ENTITY_CHANNEL_MENU_UPDATE_POSITION";

export function moveChannelMenu(channel, id, data) {
  return request(
    ENTITY_CHANNEL_MENU_UPDATE_POSITION,
    "patch",
    `/channel/${channel}/menus/${id}/move`,
    data
  );
}

const ENTITY_CHANNEL_MENU_DELETE = "ENTITY_CHANNEL_MENU_DELETE";

export function deleteChannelMenu(channel, id) {
  return request(
    ENTITY_CHANNEL_MENU_DELETE,
    "delete",
    `/channel/${channel}/menus/${id}`
  );
}

// Store

const defaultState = {
  index: [],
  loading: false,
  lastUpdate: null
};

// Reducer

export default function EntityChannelMenuReducer(state = defaultState, action) {
  switch (action.type) {
    case ENTITY_CHANNEL_MENU_INDEX:
      return { ...state, loading: true };
    case ENTITY_CHANNEL_MENU_INDEX_FAIL:
      return { ...state, loading: false };
    case ENTITY_CHANNEL_MENU_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        index: action.payload.data,
        lastUpdate: moment()
      };
  }
  return state;
}
