import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_STATIC_PAGE_INDEX = 'ENTITY_STATIC_PAGE_INDEX'
const ENTITY_STATIC_PAGE_INDEX_SUCCESS = 'ENTITY_STATIC_PAGE_INDEX_SUCCESS'
const ENTITY_STATIC_PAGE_INDEX_FAIL = 'ENTITY_STATIC_PAGE_INDEX_FAIL'
const ENTITY_STATIC_ICONS_FONTAWSOME = 'ENTITY_STATIC_ICONS_FONTAWSOME'

export function fetchAll(excludingUnpublished = false)
{
    let url = '/static_pages';

    if (excludingUnpublished) url = url + '?excludingUnpublished=1';

    return request(ENTITY_STATIC_PAGE_INDEX, 'get', url)
}

export function searchAll(query)
{
    return request(ENTITY_STATIC_PAGE_INDEX, 'post', '/static_pages/search', { query })
}

const ENTITY_STATIC_PAGE_RETRIEVE = 'ENTITY_STATIC_PAGE_RETRIEVE'

export function fetchStaticPage(id)
{
    return request(ENTITY_STATIC_PAGE_RETRIEVE, 'get', `/static_pages/${id}`)
}

const ENTITY_STATIC_PAGE_CREATE = 'ENTITY_STATIC_PAGE_CREATE'

export function createStaticPage(data)
{
    return request(ENTITY_STATIC_PAGE_CREATE, 'post', '/static_pages', data)
}

const ENTITY_STATIC_PAGE_UPDATE = 'ENTITY_STATIC_PAGE_UPDATE'

export function updateStaticPage(id, data)
{
    return request(ENTITY_STATIC_PAGE_UPDATE, 'patch', `/static_pages/${id}`, data)
}

const ENTITY_STATIC_PAGE_UPDATE_POSITION = 'ENTITY_STATIC_PAGE_UPDATE_POSITION'

export function updateStaticPagePosition(id, data) {
    return request(ENTITY_STATIC_PAGE_UPDATE_POSITION, 'patch', `/static_pages/${id}/move`, data)
}

const ENTITY_STATIC_PAGE_DELETE = 'ENTITY_STATIC_PAGE_DELETE'

export function deleteStaticPage(id)
{
    return request(ENTITY_STATIC_PAGE_DELETE, 'delete', `/static_pages/${id}`)
}

export function fetchALLIons()
{
    return request(ENTITY_STATIC_ICONS_FONTAWSOME, 'get', '/fonticonspage')
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityStaticPageReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_STATIC_PAGE_INDEX:
            return {...state, loading: true}
        case ENTITY_STATIC_PAGE_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_STATIC_PAGE_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
