import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify'
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import ContentLoader from "../../../components/ContentLoader";

import {fetchAdvertisingBanner} from '../../../data/entities/AdvertisingBanner'
import AdvertisingBannerUpdateModal from "./AdvertisingBannerUpdateModal";
import AdvertisingBannerDeleteModal from "./AdvertisingBannerDeleteModal";

import "./AdvertisingBannerViewPage.scss"

class AdvertisingBannerViewPage extends Component
{
    state = {
        id: this.props.match.params.id,
        data: null,
        loading: true,

        showAdvertisingBannerUpdateModal: false,
        showAdvertisingBannerDeleteModal: false
    }

    componentDidMount()
    {
        this.props.fetchAdvertisingBanner(this.state.id)
            .then((res) => {
                this.setState({ loading: false, data: res.payload.data })
            })
            .catch((e) => {
                console.log(e)
                if (e.error && e.error.response && e.error.response.data && e.error.response.data.error)
                    toast.error(<FormattedMessage id={e.error.response.data.error} />)
                this.props.history.push('/entities/advertising_banner')
            })
    }

    render() {
        console.log("state data", this.state.data)

        return (
            <div className="container advertising-banner-view-page">
                <Helmet title="AdvertisingBanner" />
                <div className="header">
                    <h1>Banière publicitaire</h1>
                    <div>
                        <button
                            onClick={() => this.setState({ showAdvertisingBannerUpdateModal: true })}
                            className="btn btn-primary">Modifier</button>
                        <button
                            onClick={() => this.setState({ showAdvertisingBannerDeleteModal: true })}
                            className="btn btn-danger ml-2">Supprimer</button>
                    </div>
                </div>
                {this.renderEntity()}
            </div>
        )
    }

    renderEntity()
    {
        if (this.state.loading) return <ContentLoader/>

        return (
            <div>
                <div className="section">
                    {
                        this.state.data.publication_status ?
                            <div className="sub-section">
                                <div className="section-title">
                                    <div className="icon publicated"><i className="fas fa-check"/></div>
                                    <label>Campagne affichée.</label>
                                </div>
                            </div>
                            :
                            <div className="sub-section">
                                <div className="section-title">
                                    <div className="icon publicated"><i className="fas fa-ellipsis-h"/></div>
                                    <label>La campagne n'est pas encore affichée.</label>
                                </div>
                            </div>
                    }

                    <div className="sub-section">
                        <div className="section-title">
                            <div className="icon clicks"><i className="fas fa-eye"/></div>
                            <label>
                                Lieu d'affichage : <b>{this.state.data.type.name}</b>
                                {this.state.data.type.slug == 'newsletter' ? (
                                    this.state.data.target_newsletter ? (` (${this.state.data.target_newsletter.name})`) : ' (Toutes)'
                                ) : null}
                            </label>
                        </div>
                    </div>

                    <div className="sub-section">
                        <div className="section-title">
                            <div className="icon link"><i className="fas fa-external-link-alt"/></div>
                            <label>
                                Action au clic : {this.state.data.imageType === 'link' ?
                                <span>ouverture de <a href={this.state.data.link} target={"_blank"}>{this.state.data.link}</a></span>
                                :
                                (this.state.data.newsletter ? <span>inscription à  la newsletter {this.state.data.newsletter.name}</span> :
                                <span>personnalisée (code HTML)</span>)
                            }
                            </label>
                        </div>
                    </div>

                    {this.state.data.imageType ? (
                        <div className="sub-section">
                            <div className="section-title">
                                <div className="icon clicks"><i className="fas fa-mouse-pointer"/></div>
                                <label>Nombre de clics : <b>{this.state.data.clicks}</b></label>
                            </div>
                        </div>
                    ) : null}
                </div>

                {this.state.data.html === null ?
                    <div className="banner mb-5">
                        <label className="font-weight-bold">Aperçu de la bannière</label>
                        <img src={this.state.data.image}/>
                    </div>
                    : null}

                {this.state.showAdvertisingBannerUpdateModal ? (
                    <AdvertisingBannerUpdateModal
                        id={this.state.id}
                        data={this.state.data}
                        onClose={(data) => {
                            if (data) this.setState({ data })
                            this.setState({ showAdvertisingBannerUpdateModal: false })
                        }}
                    />
                ) : null}
                {this.state.showAdvertisingBannerDeleteModal ? (
                    <AdvertisingBannerDeleteModal
                        id={this.state.id}
                        data={this.state.data}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.props.history.goBack()
                            }
                            else this.setState({ showAdvertisingBannerDeleteModal: false })
                        }}
                    />
                ) : null}
            </div>
        )
    }
}

export default connect(({ advertising_banner_entity }) => ({ advertising_banner_entity }), { fetchAdvertisingBanner })(AdvertisingBannerViewPage)
