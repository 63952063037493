import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	CardHeader,
	CardTitle, CardBody, Card
} from "reactstrap";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";
import Modal from "../../../components/Modal";

import OfferCreateModal from "./OfferCreateModal";
import OfferContractIndexPage from "../OfferContract/OfferContractIndexPage";
import OfferFunctionIndexPage from "../OfferFunction/OfferFunctionIndexPage";
import OfferStudyLevelIndexPage from "../OfferStudyLevel/OfferStudyLevelIndexPage";
import OfferExperienceIndexPage from "../OfferExperience/OfferExperienceIndexPage";
import OfferActivityAreaIndexPage from "../OfferActivityArea/OfferActivityAreaIndexPage"

import {fetchAll, searchAll, fetchProvider} from '../../../data/entities/Offer'
import moment from "moment";
import OfferImportModal from "./OfferImportModal";

class OfferIndexPage extends Component {
	state = {
		search: '',
		entities_dropdown: false,
		showOfferCreateModal: false,
		showOfferActivityAreaModal: false,
		showOfferContractModal: false,
		showOfferExperienceModal: false,
		showOfferFunctionModal: false,
		showOfferStudyLevelModal: false,
		showOfferImportModal: false
	}

	_search = (search) => {
		this.setState({search}, () => {
			this._fetch()
		})
	}

	_fetch = () => {
		if (this.state.search.length) this.props.searchAll(this.state.search)
		else this.props.fetchAll()
	}

	componentDidMount() {
		this.props.fetchAll()
		this.props.fetchProvider()
	}

	render() {
		return (
				<Container>
					<Helmet title="Offres d'emploi"/>
					<Row className="pb-5 pt-3 d-flex align-items-center">
						<Col>
							<h1>Offres d'emploi</h1>
						</Col>
						<Col className="text-right pt-2 d-flex align-items-center justify-content-end">
							<Loader display={this.props.offer_entity.loading}/>
							<input
									type="text"
									value={this.state.search}
									onChange={(e) => this._search(e.target.value)}
									placeholder="Rechercher..."
									className="form-control d-inline-block ml-2"
									style={{maxWidth: 200}}
							/>
							<button
									onClick={() => this.setState({showOfferCreateModal: true})}
									className="btn btn-primary ml-2 mr-2 px-2">Créer
							</button>
							{this.props.offer_entity.provider && this.props.offer_entity.provider.endpoint ? (
							<button
									onClick={() => this.setState({showOfferImportModal: true})}
									className="btn btn-warning mr-2 px-2">Importer
							</button> ) : null}
							{this.renderEntitiesDropdown()}
						</Col>
					</Row>
					{this.renderEntities()}
					{this.state.showOfferCreateModal ? (
						<OfferCreateModal
							onClose={(created) => {
								if (created) this._fetch()
								this.setState({showOfferCreateModal: false})
							}}
						/>
					) : null}
					{this.state.showOfferImportModal ? (
						<OfferImportModal
							onClose={(created) => {
								if (created) this._fetch()
								this.setState({showOfferImportModal: false})
							}}
						/>
					) : null}
					{this.state.showOfferActivityAreaModal ? (
						<Modal additionnalClass={"btn-right"} onClose={() => this.setState({showOfferActivityAreaModal: false})}>
							<OfferActivityAreaIndexPage
							/></Modal>
					) : null}
					{this.state.showOfferExperienceModal ? (
						<Modal additionnalClass={"btn-right"} onClose={() => this.setState({showOfferExperienceModal: false})}>
							<OfferExperienceIndexPage
							/></Modal>
					) : null}
					{this.state.showOfferContractModal ? (
						<Modal additionnalClass={"btn-right"} onClose={() => this.setState({showOfferContractModal: false})}>
							<OfferContractIndexPage
							/></Modal>
					) : null}
					{this.state.showOfferStudyLevelModal ? (
						<Modal additionnalClass={"btn-right"} onClose={() => this.setState({showOfferStudyLevelModal: false})}>
							<OfferStudyLevelIndexPage/></Modal>
					) : null}
					{this.state.showOfferFunctionModal ? (
						<Modal additionnalClass={"btn-right"} onClose={() => this.setState({showOfferFunctionModal: false})}>
							<OfferFunctionIndexPage
							/></Modal>
					) : null}
				</Container>
		)
	}

	renderEntities() {
		if (this.props.offer_entity.loading && !this.props.offer_entity.lastUpdate)
			return <ContentLoader/>

		if (!this.props.offer_entity.index.length) {
			return <Empty/>
		}

		return (
				<table className="table table-striped mt-3">
					{this.props.offer_entity.index.map((entity) => {
						const online = entity.publication_status && (moment(entity.publication_start) <= moment() && (entity.publication_end === null  || moment(entity.publication_end) > moment()))
						return (
							(
								<Card
									style={{ cursor: 'pointer' }}
									className="shadow-sm mb-3"
									onClick={() => this.props.history.push("/entities/offer/" + entity.id)}>
									<div className="row">
										<div className="col-md-9">
											<CardBody>
												<h5>{entity.name}</h5>
												<p className="text-muted mb-0">
													<i className="fa fa-building"/> {entity.company}
												</p>
											</CardBody>
										</div>
										<div className="col-md-3">
											<p className="text-right mr-3 mt-3" style={{ fontSize: 14, color: online ? 'green' : 'gray' }}>
												<i className="fa fa-circle mr-2" style={{ fontSize: 8 }}/>
												{online ? 'En ligne' : 'Hors ligne'}
											</p>
											<p className="text-right mr-3">
											{!entity.external_link ? (
												<span title="Candidats intéressés">
													<i className="fa fa-users"/> {typeof entity.appliances !== 'undefined' ? entity.appliances : '?'}
												</span>

											) : (
												<span>
													<i className="fa fa-globe"/> Offre externe
												</span>
											)}
											</p>
										</div>
									</div>
								</Card>
							)
						)
					})}
				</table>
		)
	}

	renderEntitiesDropdown()
	{
		return (
			<Dropdown isOpen={this.state.entities_dropdown}
					  toggle={() => this.setState({ entities_dropdown: !this.state.entities_dropdown })}>
				<DropdownToggle caret>
					Gestion des champs
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem onClick={() => this.setState({showOfferActivityAreaModal: true})}>
						Secteurs d'activité
					</DropdownItem>
					<DropdownItem onClick={() => this.setState({showOfferExperienceModal: true})}>
						Expériences
					</DropdownItem>
					<DropdownItem onClick={() => this.setState({showOfferContractModal: true})}>
						Types de contrat
					</DropdownItem>
					<DropdownItem onClick={() => this.setState({showOfferStudyLevelModal: true})}>
						Niveaux d'étude
					</DropdownItem>
					<DropdownItem onClick={() => this.setState({showOfferFunctionModal: true})}>
						Fonctions
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		)
	}
}

export default connect(({offer_entity}) => ({offer_entity}), {fetchAll, searchAll, fetchProvider})(OfferIndexPage)
