import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import moment from 'moment-timezone';

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import AdvertisingCreateModal from "./AdvertisingCreateModal";

import {fetchAll, searchAll} from '../../../data/entities/Advertising'

class AdvertisingIndexPage extends Component
{
	state = {
		search: '',
		showAdvertisingCreateModal: false,
	}

	_search = (search) => {
		this.setState({ search }, () => {
			this._fetch()
		})
	}

	_fetch = () => {
		if (this.state.search.length) this.props.searchAll(this.state.search)
		else this.props.fetchAll()
	}

	componentDidMount()
	{
		this.props.fetchAll()
	}

	render() {
		return (
				<Container>
					<Helmet title="Publicités" />
					<Row className="pb-5 pt-3">
						<Col>
							<h1>Campagnes publicitaires</h1>
						</Col>
						<Col className="text-right pt-2">
							<Loader display={this.props.advertising_entity.loading}/>
							<input
									type="text"
									value={this.state.search}
									onChange={(e) => this._search(e.target.value)}
									placeholder="Rechercher..."
									className="form-control d-inline-block ml-2"
									style={{ maxWidth: 200 }}
							/>
							<button
									onClick={() => this.setState({ showAdvertisingCreateModal: true })}
									className="btn btn-primary ml-2">Nouvelle campagne</button>
						</Col>
					</Row>
					{this.renderEntities()}
					{this.state.showAdvertisingCreateModal ? (
							<AdvertisingCreateModal
									onClose={(created) => {
										if (created) this._fetch()
										this.setState({ showAdvertisingCreateModal: false })
									}}
							/>
					) : null}
				</Container>
		)
	}

	renderEntities()
	{
		if (this.props.advertising_entity.loading && !this.props.advertising_entity.lastUpdate)
			return <ContentLoader/>

		if (!this.props.advertising_entity.index.length) {
			return <Empty/>
		}
	console.log(this.props.advertising_entity.index);
		return (
				<table className="table">
					<thead>
					<tr>
						<th scope="col" style={{ paddingLeft: 30 }}>
							Titre
						</th>
						<th scope="col">Statut</th>
						<th scope="col">Début</th>
						<th scope="col">Fin</th>
						<th scope="col" style={{ textAlign: "right", paddingRight: 30 }}>
							Actions
						</th>
					</tr>
					</thead>
					<tbody>
					{this.props.advertising_entity.index.map((entity) => (
							<tr>
								<td scope="row">{entity.name}</td>
								<td scope="row">{entity.publication_status ? 'Activée' : 'Désactivée'}</td>
								<td scope="row">{entity.publication_start && moment(entity.publication_start).format("YYYY/MM/DD HH:mm")}</td>
								<td scope="row">{entity.publication_end && moment(entity.publication_end).format("YYYY/MM/DD HH:mm")}</td>
								<td style={{ textAlign: "right" }}>
									<Link
											className="btn btn-primary mr-2"
											to={"/entities/advertising/" + entity.id}
									>
										Consulter
									</Link>
								</td>
							</tr>
					))}
					</tbody>
				</table>
		)
	}
}

export default connect(({ advertising_entity }) => ({ advertising_entity }), { fetchAll, searchAll })(AdvertisingIndexPage)
