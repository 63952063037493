import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';
import Modal from "../../../components/Modal";

import {updateAdvertisingBannerType} from "../../../data/entities/AdvertisingBannerType";

class AdvertisingBannerTypeUpdateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        name: this.props.data.name,
        slug: this.props.data.slug,
        width: this.props.data.width,
        height: this.props.data.height
    }

    _submit = () => {
        this.setState({ _loading: true })

        const data = {...this.state}
        delete data._loading
        delete data._formData

        this.props.updateAdvertisingBannerType(this.props.data.id, data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })
    }

    render()
    {
        return (
            <Modal title={"Modifier le type"} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
					<Input
					    value={this.state.name} onChangeValue={(name) => this.setState({ name })}
					    placeholder="name" label="Nom" type="text"/>
					
					<Input
					    value={this.state.slug} onChangeValue={(slug) => this.setState({ slug })}
					    placeholder="slug" label="Slug" type="text"/>
					
                    <div className="form-group">
                        <label>Dimensions de la bannière</label>
                        <div className="input-group mb-2" style={{ width: "200px"}}>
                            <input 
                                onChange={(e) => this.setState({ width: e.target.value })}
                                value={this.state.width}
                                className="form-control"
                                type="number"></input>
                            <div className="input-group-append">
                                <span className="input-group-text">Largeur (px)</span>
                            </div>
                        </div>

                        <div className="input-group" style={{width: "200px"}}>
                            <input 
                                onChange={(e) => this.setState({ height: e.target.value })}
                                value={this.state.height}
                                className="form-control"
                                type="number"></input>
                            <div className="input-group-append">
                                <span className="input-group-text">Hauteur (px)</span>
                            </div>
                        </div>
                    </div>
					
                    <Submit disabled={this.state._loading}>Modifier</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { updateAdvertisingBannerType })(AdvertisingBannerTypeUpdateModal)
