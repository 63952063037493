import React, {Component} from 'react';
import {Card, CardBody, CardText, CardTitle} from "reactstrap";
import {Link} from "react-router-dom";
import './SuggestedArticleCard.scss';

class SuggestedArticleCard extends Component
{
    render() {

        const article = this.props.article;

        if (!article)
            return null;

        const style = article.cover ? { backgroundImage: `url(${article.cover})` } : {};

        return (
            <div className={"col-md-3 mb-3 suggested-article-card"} key={'article-' + article.id}>
                <Card>
                    <div className="suggested-article-card-thumbnail" style={style}>
                        {!article.cover ? <i className="fas fa-image"/> : null}
                        <div className="suggested-article-card-actions">
                            <Link to={"/entities/suggested_article/" + article.id} className="btn btn-primary">
                                <i className="fa fa-eye"/>
                                <span className="btn-tooltip">Voir</span>
                            </Link>

                        </div>
                    </div>
                    <CardBody>
                        <div style={{ minHeight: 78 }}>
                            <CardTitle>
                                <strong>{article.title}</strong>
                            </CardTitle>
                        </div>
                        <CardText>
                            <i className="fa fa-user text-muted mr-2"/>
                            {article.user ? article.user.fullname : "Anonyme"}
                        </CardText>
                    </CardBody>

                </Card>
            </div>
        )
    }
}

export default SuggestedArticleCard;

