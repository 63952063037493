import React, {Component} from 'react';
import {fetchAllProducts} from "../../../data/entities/StripeProduct";
import { connect } from "react-redux";
import ContentLoader from "../../../components/ContentLoader";
import {Row, Col, Card, CardBody, CardText, Button, CardTitle} from 'reactstrap';
import "./Products.scss";
import ProductDeleteModal from "./ProductDeleteModal";
import ProductUpdateModal from "./ProductUpdateModal";
import {checkPrivilege} from "../../../helpers";
import Empty from "../../../components/Empty";


class ProductsView extends Component
{
    state = {
        data: null,
        showProductDeleteModal: false,
        showProductUpdateModal: false,
        price_id: null,
        product_id: null
    };

    componentDidMount() {
        this._fetch();
    }

    _fetch() {
        this.props.fetchAllProducts();
    }

    toggleProductUpdateModal = (update_product) => {
        this.setState({ update_product: update_product ? update_product : null, showProductUpdateModal: !this.state.showProductUpdateModal });
    };

    toggleRecurringProductDeleteModal = (price_id = null, product_id = null) => {
        this.setState({ price_id: price_id ? price_id : null, product_id: product_id ? product_id : null, showProductDeleteModal: !this.state.showProductDeleteModal });
    };

    render() {

        if (this.props.stripe_products_entity.loading && !this.props.stripe_products_entity.lastUpdate)
            return <ContentLoader />;

        let products;

        if (this.props.type === 'recurring') {

            if (!this.props.stripe_products_entity.index || !this.props.stripe_products_entity.index.recurring || !this.props.stripe_products_entity.index.recurring.length)
                return <Empty />;

            products = this.props.stripe_products_entity.index.recurring;
        } else {

            if (!this.props.stripe_products_entity.index || !this.props.stripe_products_entity.index.one_time || !this.props.stripe_products_entity.index.one_time.length)
                return <Empty />;

            products = this.props.stripe_products_entity.index.one_time;
        }

        return (
            <div className={'subscriptions'}>
                <Row>
                    {
                        products.map((product) => {

                                let images_length = 0
                                   if(product.images){
                                        images_length = product.images.length;
                                   }

                                const image = images_length > 0 ? product.images[0] : '';

                                return (
                                    <Col lg={3} md={4} key={product.price_id} className={'article-card my-3'}>
                                        <Card>
                                            <div className={'delete-button'}>
                                                {
                                                    checkPrivilege(this.props.user, 'stripe-product:update') ?
                                                        <Button
                                                            color={'warning'}
                                                            onClick={() => this.toggleProductUpdateModal(product)}
                                                            className={'mb-2'}
                                                        >Modifier</Button>
                                                        : null
                                                }

                                                {
                                                    checkPrivilege(this.props.user, 'stripe-product:delete') ?
                                                        <Button
                                                            color={'danger'}
                                                            onClick={() => this.toggleRecurringProductDeleteModal(product.price_id, product.stripe_id)}
                                                        >Supprimer</Button>
                                                        : null
                                                }
                                            </div>
                                            <div className={'card-container'}>
                                                {
                                                    image ?
                                                        <div className={'image'} style={{ backgroundImage: `url(${image})` }}/>
                                                        :
                                                        <div className={'image image-missing'}>
                                                            <i className="far fa-image fa-2x"/>
                                                            <p>Image manquante</p>
                                                        </div>
                                                }

                                                <CardBody>
                                                    <CardTitle>
                                                        <strong style={{ color: product.color }}>{product.name}</strong>
                                                    </CardTitle>

                                                    <CardText>
                                                        {
                                                            product.unit_amount ?
                                                                product.unit_amount / 100 + '€' + (this.props.type === 'recurring' ? ' / ' + product.interval_formatted : '')
                                                                : 'Prix non défini'
                                                        }
                                                    </CardText>
                                                </CardBody>
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            })
                    }
                </Row>

                {
                    this.state.showProductDeleteModal &&
                    <ProductDeleteModal
                        price_id={this.state.price_id}
                        product_id={this.state.product_id}
                        type={'recurring'}
                        onClose={(deleted) => {
                            if (deleted) this._fetch()
                            this.setState({ showProductDeleteModal: false })
                        }}
                    />
                }

                {
                    this.state.showProductUpdateModal &&
                    <ProductUpdateModal
                        product ={this.state.update_product}
                        onClose={(updated) => {
                            if (updated) this._fetch()
                            this.setState({ showProductUpdateModal: false })
                        }}
                    />
                }
            </div>
        )
    }
}

export default connect(({ stripe_products_entity, user }) => ({ stripe_products_entity, user }), {fetchAllProducts})(ProductsView);
