import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Submit,
  CheckBox,
  FilePicker,
  DatePicker,
  Input,
  TextArea
} from "react-forms";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import moment from 'moment-timezone';
import Modal from "../../../components/Modal";

import { updateRole } from "../../../data/entities/Role";

class RoleUpdateModal extends Component {
  state = {
    _formData: null,
    _loading: false,
    label: this.props.data.label,
    name: this.props.data.name,
    list: this.props.data.list,
    privileges: this.props.data.privileges
  };

  _submit = () => {
    this.setState({ _loading: true });

    const data = { ...this.state };
    delete data._loading;
    delete data._formData;
    delete data.name;

    this.props
      .updateRole(this.props.data.id, data)
      .then(res => {
        if (res.payload.data.message)
          toast.success(<FormattedMessage id={res.payload.data.message} />);
        this.props.onClose(res.payload.data);
      })
      .catch(e => {
        if (e.error && e.error.response && e.error.response.data)
          this.setState({ _formData: e.error.response.data });
        this.setState({ _loading: false });
      });
  };

  render() {
    return (
      <Modal
        title={this.props.data.label}
        onClose={() => this.props.onClose(false)}
      >
        <Form onSubmit={this._submit} data={this.state._formData}>
          <Input
            value={this.state.label}
            onChangeValue={label => this.setState({ label })}
            onFocus={e => e.target.select()}
            placeholder="Exemple : journaliste"
            label="Nom du rôle"
            type="text"
          />
          <Input
            value={this.state.name}
            onChangeValue={name => this.setState({ name })}
            label="Identifiant technique"
            type="text"
            disabled={true}
          />
          <Submit disabled={this.state._loading}>Sauvegarder</Submit>
        </Form>
      </Modal>
    );
  }
}

export default connect(
  null,
  { updateRole }
)(RoleUpdateModal);
