import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import Loader from '../../../components/Loader'
import MenuViewPage from './MenuViewPage'
import CategoryViewPage from './CategoryViewPage'

import ChannelUpdateModal from "./ChannelUpdateModal";
import ChannelDeleteModal from "./ChannelDeleteModal";
import ChannelHomePage from "./ChannelHomePage";

import { fetchChannel } from "../../../data/entities/Channel";

class ChannelViewPage extends Component {

  state = {
    channelId: parseInt(this.props.match.params.id, 10),
    data: null,
    showChannelDeleteModal: false,
    showChannelUpdateModal: false,
    _loading: true
  }

  _fetch = () => {
    this.props
      .fetchChannel(this.state.channelId)
      .then(res => {
        this.setState({ _loading: false, data: res.payload.data });
      })
      .catch(e => {
        console.log(e);
        if (
          e.error &&
          e.error.response &&
          e.error.response.data &&
          e.error.response.data.error
        )
          toast.error(<FormattedMessage id={e.error.response.data.error} />);
        this.props.history.push("/entities/channel");
      });
  };

  componentDidMount() {
    this._fetch()
  }

  render() {
    if (this.state._loading) {
      return <Loader />
    }
    else {
      return (
        <div className="mx-4">
          <div className="d-flex justify-content-between align-items-center">
            <h1>Canal: {this.state.data.name}</h1>
            <div>
              <button
                  onClick={() =>
                      this.setState({
                        showChannelUpdateModal: true
                      })
                  }
                  className="btn btn-primary ml-2"
              >
                Modifier le canal
              </button>
              <button
                  onClick={() =>
                      this.setState({
                        showChannelDeleteModal: true
                      })
                  }
                  className="btn btn-danger ml-2"
              >
                Supprimer le canal
              </button>
            </div>
          </div>

          <ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="menu-tab" data-toggle="tab" href="#menu" role="tab" aria-controls="menu" aria-selected="true">
                Menu
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="category-tab" data-toggle="tab" href="#category" role="tab" aria-controls="category" aria-selected="false">
                Catégories de la page d'accueil
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="homepage-tab" data-toggle="tab" href="#homepage" role="tab"
                 aria-controls="homepage" aria-selected="false">
                Choix de la page d'accueil
              </a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="menu" role="tabpanel" aria-labelledby="menu-tab">
              <MenuViewPage id={this.state.channelId} data={this.state.data}/>
            </div>
            <div class="tab-pane fade" id="category" role="tabpanel" aria-labelledby="category-tab">
              <CategoryViewPage id={this.state.channelId}/>
            </div>
            <div className="tab-pane fade" id="homepage" role="tabpanel" aria-labelledby="homepage-tab">
              <ChannelHomePage id={this.state.channelId} data={this.state.data}/>
            </div>
          </div>
          {this.state.showChannelUpdateModal ? (
              <ChannelUpdateModal
                  data={this.state.data}
                  onClose={updated => {
                    this.setState({
                      showChannelUpdateModal: false
                    });
                    if (updated) this._fetch();
                  }}
              />
          ) : null}
          {this.state.showChannelDeleteModal ? (
              <ChannelDeleteModal
                  data={this.state.data}
                  onClose={deleted => {
                    if (deleted) this.props.history.push("/entities/channel");
                    else
                      this.setState({
                        showChannelDeleteModal: false
                      });
                  }}
              />
          ) : null}
        </div>
      )
    }
  }
}

export default connect(null, { fetchChannel })(ChannelViewPage)
