import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {Row, Col, Container} from "reactstrap";


import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import OfferStudyLevelCreateModal from "./OfferStudyLevelCreateModal";
import OfferStudyLevelUpdateModal from "./OfferStudyLevelUpdateModal";
import OfferStudyLevelDeleteModal from "./OfferStudyLevelDeleteModal";

import {fetchAll, searchAll} from '../../../data/entities/OfferStudyLevel'

class OfferStudyLevelIndexPage extends Component
{
    state = {
        search: '',
        showOfferStudyLevelCreateModal: false,
        showOfferStudyLevelUpdateModal: null,
        showOfferStudyLevelDeleteModal: null
    }

    _search = (search) => {
        this.setState({ search }, () => {
            this._fetch()
        })
    }

    _fetch = () => {
        if (this.state.search.length) this.props.searchAll(this.state.search)
        else this.props.fetchAll()
    }

    componentDidMount()
    {
        this.props.fetchAll()
    }

    render() {
        return (
            <Container>
                <Helmet title="Niveau d'étude"/>
                <Row className={"pb-3"}>
                    <Col>
                        <h2>Niveau d'étude</h2>
                    </Col>
                    <Col className="text-right">
                        <Loader display={this.props.offer_study_level_entity.loading}/>
                        <input
                            type="text"
                            value={this.state.search}
                            onChange={(e) => this._search(e.target.value)}
                            placeholder="Rechercher..."
                            className="form-control d-inline-block ml-2"
                            style={{ maxWidth: 200 }}
                        />
                        <button
                            onClick={() => this.setState({ showOfferStudyLevelCreateModal: true })}
                            className="btn btn-primary ml-2">Ajouter</button>
                    </Col>
                </Row>
                {this.renderEntities()}
                {this.state.showOfferStudyLevelCreateModal ? (
                    <OfferStudyLevelCreateModal
                        onClose={(created) => {
                            if (created) this._fetch()
                            this.setState({ showOfferStudyLevelCreateModal: false })
                        }}
                    />
                ) : null}
                {this.state.showOfferStudyLevelUpdateModal !== null ? (
                    <OfferStudyLevelUpdateModal data={this.state.showOfferStudyLevelUpdateModal}
                                                onClose={(updated) => {
                                                    if (updated) this._fetch();
                                                    this.setState({showOfferStudyLevelUpdateModal: null})
                                                }}
                    />
                ) : null}
                {this.state.showOfferStudyLevelDeleteModal !== null ? (
                    <OfferStudyLevelDeleteModal data={this.state.showOfferStudyLevelDeleteModal}
                                                onClose={(deleted) => {
                                                    if (deleted) this._fetch();
                                                    this.setState({showOfferStudyLevelDeleteModal:null})
                                                }}
                    />
                ) : null }
            </Container>
        )
    }

    renderEntities()
    {
        if (this.props.offer_study_level_entity.loading && !this.props.offer_study_level_entity.lastUpdate)
            return <ContentLoader/>

        if (!this.props.offer_study_level_entity.index.length) {
            return <Empty/>
        }

        return (
            <table className="table table-striped mt-3">
                {this.props.offer_study_level_entity.index.map((entity) => (
                    <tr>
                        <td>
                            <h5>{entity.name}</h5>
                        </td>
                        <td style={{textAlign: "right"}}>
                            <button
                                onClick={() => this.setState({ showOfferStudyLevelUpdateModal : entity })}
                                className="btn btn-primary">Modifier
                            </button>
                            <button
                                onClick={() => this.setState({ showOfferStudyLevelDeleteModal: entity })}
                                className="btn btn-danger ml-2">Supprimer
                            </button>
                        </td>
                    </tr>
                ))}
            </table>
        )
    }
}

export default connect(({ offer_study_level_entity }) => ({ offer_study_level_entity }), { fetchAll, searchAll })(OfferStudyLevelIndexPage)
