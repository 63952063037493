import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {Row, Col, Container} from "reactstrap";


import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import OfferActivityAreaCreateModal from "./OfferActivityAreaCreateModal";

import {fetchAll, searchAll} from '../../../data/entities/OfferActivityArea'
import OfferActivityAreaUpdateModal from "./OfferActivityAreaUpdateModal";
import OfferActivityAreaDeleteModal from "./OfferActivityAreaDeleteModal";

class OfferActivityAreaIndexPage extends Component
{
    state = {
        search: '',
        showOfferActivityAreaCreateModal: false,
        showOfferActivityAreaUpdateModal: null,
        showOfferActivityAreaDeleteModal: null
    }

    _search = (search) => {
        this.setState({ search }, () => {
            this._fetch()
        })
    }

    _fetch = () => {
        if (this.state.search.length) this.props.searchAll(this.state.search)
        else this.props.fetchAll()
    }

    componentDidMount()
    {
        this.props.fetchAll()
    }

    render() {
        return (
            <Container>
                <Helmet title="Secteurs d'activité" />
                <Row className={"pb-3"}>
                    <Col>
                        <h2>Secteurs d'activité</h2>
                    </Col>
                    <Col className="text-right">
                        <Loader display={this.props.offer_activity_area_entity.loading}/>
                        <input
                            type="text"
                            value={this.state.search}
                            onChange={(e) => this._search(e.target.value)}
                            placeholder="Rechercher..."
                            className="form-control d-inline-block ml-2"
                            style={{ maxWidth: 200 }}
                        />
                        <button
                            onClick={() => this.setState({ showOfferActivityAreaCreateModal: true })}
                            className="btn btn-primary ml-2">Ajouter</button>
                    </Col>
                </Row>
                {this.renderEntities()}
                {this.state.showOfferActivityAreaCreateModal ? (
                    <OfferActivityAreaCreateModal
                        onClose={(created) => {
                            if (created) this._fetch()
                            this.setState({ showOfferActivityAreaCreateModal: false })
                        }}
                    />
                ) : null}
                {this.state.showOfferActivityAreaUpdateModal !== null ? (
                    <OfferActivityAreaUpdateModal data={this.state.showOfferActivityAreaUpdateModal}
                                              onClose={(updated) => {
                                                  if (updated) this._fetch();
                                                  this.setState({showOfferActivityAreaUpdateModal: null})
                                              }}
                    />
                ) : null}

                {this.state.showOfferActivityAreaDeleteModal !== null ? (
                    <OfferActivityAreaDeleteModal data={this.state.showOfferActivityAreaDeleteModal}
                                              onClose={(deleted) => {
                                                  if (deleted) this._fetch();
                                                  this.setState({showOfferActivityAreaDeleteModal: null})
                                              }}
                    />
                ) : null}
            </Container>
        )
    }

    renderEntities()
    {
        if (this.props.offer_activity_area_entity.loading && !this.props.offer_activity_area_entity.lastUpdate)
            return <ContentLoader/>

        if (!this.props.offer_activity_area_entity.index.length) {
            return <Empty/>
        }
        console.log(this.props.offer_activity_area_entity.index)
        return (
            <table className="table table-striped mt-3">
                {this.props.offer_activity_area_entity.index.map((entity) => (
                    <tr>
                        <td>
                            <h5>{entity.name}</h5>
                        </td>
                        <td style={{textAlign: "right"}}>
                            <button
                                onClick={() => this.setState({ showOfferActivityAreaUpdateModal : entity })}
                                className="btn btn-primary">Modifier
                            </button>
                            <button
                                onClick={() => this.setState({ showOfferActivityAreaDeleteModal: entity })}
                                className="btn btn-danger ml-2">Supprimer
                            </button>
                        </td>
                    </tr>
                ))}
            </table>
        )
    }
}

export default connect(({ offer_activity_area_entity }) => ({ offer_activity_area_entity }), { fetchAll, searchAll })(OfferActivityAreaIndexPage)
