import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify'
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import moment from 'moment-timezone'

import ContentLoader from "../../../components/ContentLoader";

import {fetchAdvertising} from '../../../data/entities/Advertising';
import {fetchAdvertisingBanners} from '../../../data/entities/AdvertisingBanner';

import AdvertisingUpdateModal from "./AdvertisingUpdateModal";
import AdvertisingDeleteModal from "./AdvertisingDeleteModal";
import AdvertisingBannerCreateModal from "../AdvertisingBanner/AdvertisingBannerCreateModal";

import "./AdvertisingViewPage.scss"

class AdvertisingViewPage extends Component
{
    state = {
        id: this.props.match.params.id,
        data: null,
        loading: true,

        banners: [],

        showAdvertisingUpdateModal: false,
        showAdvertisingDeleteModal: false,
        showBannerCreateModal: false
    }

    _fetchBanners() {
        this.props.fetchAdvertisingBanners(this.state.id)
            .then(res => this.setState({ banners: res.payload.data }))
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ loading: false })
            })
    }

    _fetchAdvertising() {
        this.props.fetchAdvertising(this.state.id)
        .then((res) => {
            this.setState({ loading: false, data: res.payload.data })
        })
        .catch((e) => {
            console.log(e)
            if (e.error && e.error.response && e.error.response.data && e.error.response.data.error)
                toast.error(<FormattedMessage id={e.error.response.data.error} />)
            this.props.history.push('/entities/advertising')
        })
    }

    componentDidMount() {
        this._fetchAdvertising()
        this._fetchBanners()
    }

    render() {
        console.log("banners --->, ", this.state.banners)
        if (this.state.loading) return <ContentLoader />
        return (
            <div className="container advertising-view-page">
                <Helmet title="Publicité" />
                <div className="header">
                    <h2>{this.state.data.name}</h2>

                    <div>
                        <button
                            onClick={() => this.setState({ showBannerCreateModal: true })}
                            className="btn btn-warning">Nouvelle bannière</button>
                        <button
                            onClick={() => this.setState({ showAdvertisingUpdateModal: true })}
                            className="btn btn-primary ml-2">Paramètres</button>
                        <button
                            onClick={() => this.setState({ showAdvertisingDeleteModal: true })}
                            className="btn btn-danger ml-2">Supprimer</button>
                    </div>
                </div>

                <div className="section">
                    {
                        this.state.data.publication_status ?
                            <div className="sub-section">
                                <div className="section-title">
                                <div className="icon publicated"><i className="fas fa-check"></i></div>
                                <label>Campagne affichée</label>
                                </div>
                                <div className="section-body">
                                <label>Affichée {moment(this.state.data.publication_start).format("DD/MM/YYYY HH:mm")}</label>
                                {this.state.data.publication_end ?
                                    <label>Fin d'affichage le {moment(this.state.data.publication_end).format("DD/MM/YYYY HH:mm")}</label>
                                    :
                                    <label>Pas de date de fin</label>}
                                </div>
                            </div>
                            :
                            <div className="sub-section">
                                <div className="section-title">
                                <div className="icon publicated"><i className="fas fa-ellipsis-h"></i></div>
                                <label>La campagne n'est pas encore affichée</label>
                                </div>
                            </div>
                    }
                </div>

                {this.renderEntity()}
            </div>
        )
    }

    renderEntity()
    {
        console.log('aaa')
        console.log(this.state.banners)
        if (this.state.loading) return <ContentLoader/>

        return (
            <Fragment>

              <div className="entities">


                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Lieu d'affichage</th>
                            <th scope="col">Type de publicité</th>
                            <th scope="col" style={{ textAlign: "right" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.banners.length ?
                            this.state.banners.map(entity => (
                                <tr  key={entity.id}>
                                    <td>{entity.type.name} {entity.type.slug == 'newsletter' ? (entity.target_newsletter ? `(${entity.target_newsletter.name})` : '(Toutes)') : null}</td>
                                    <td>{entity.imageType === 'link' ? 'Bannière pointant vers un lien : ' + entity.link : (entity.imageType === "newsletter" ? 'Bannière pointant vers une newsletter : ' + (entity.newsletter ? entity.newsletter.name : 'Erreur') : 'Code HTML' )}</td>
                                    <td style={{ textAlign: "right" }}>
                                        <Link className="btn btn-primary " to={"/entities/advertising_banner/"+entity.id}>
                                            <i className="fas fa-eye"/>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                            :
                            null
                        }
                    </tbody>
                </table>
              </div>

                {this.state.showAdvertisingUpdateModal &&
                    <AdvertisingUpdateModal
                        id={this.state.id}
                        data={this.state.data}
                        onClose={(updated) => {
                            if (updated) {
                                this._fetchAdvertising()
                                this.setState({ showAdvertisingUpdateModal: false })
                            }
                            else this.setState({ showAdvertisingUpdateModal: false })
                        }}/>

                }

                {this.state.showAdvertisingDeleteModal &&
                    <AdvertisingDeleteModal
                        data={this.state.data}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.props.history.push('/entities/advertising')
                            }
                            else this.setState({ showAdvertisingDeleteModal: false })
                        }}/>
                }

                {this.state.showBannerCreateModal &&
                    <AdvertisingBannerCreateModal
                        id={this.state.id}
                        onClose={(created) => {
                            if (created) {
                                this._fetchBanners()
                                this.setState({ showBannerCreateModal: false})
                            }
                            else this.setState({ showBannerCreateModal: false })
                        }}/>
                }
            </Fragment>
        )
    }
}

export default connect(({ advertising_entity }) => ({ advertising_entity }), { fetchAdvertising, fetchAdvertisingBanners })(AdvertisingViewPage)
