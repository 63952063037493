import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_NEWSLETTER_BLOCK_INDEX = 'ENTITY_NEWSLETTER_BLOCK_INDEX'
const ENTITY_NEWSLETTER_BLOCK_INDEX_SUCCESS = 'ENTITY_NEWSLETTER_BLOCK_INDEX_SUCCESS'
const ENTITY_NEWSLETTER_BLOCK_INDEX_FAIL = 'ENTITY_NEWSLETTER_BLOCK_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_NEWSLETTER_BLOCK_INDEX, 'get', '/newsletter_blocks')
}

export function searchAll(query)
{
    return request(ENTITY_NEWSLETTER_BLOCK_INDEX, 'post', '/newsletter_blocks/search', { query })
}

const ENTITY_NEWSLETTER_BLOCK_RETRIEVE = 'ENTITY_NEWSLETTER_BLOCK_RETRIEVE'

export function fetchNewsletterBlock(id)
{
    return request(ENTITY_NEWSLETTER_BLOCK_RETRIEVE, 'get', `/newsletter_blocks/${id}`)
}

const ENTITY_NEWSLETTER_BLOCK_CREATE = 'ENTITY_NEWSLETTER_BLOCK_CREATE'

export function createNewsletterBlock(data)
{
    return request(ENTITY_NEWSLETTER_BLOCK_CREATE, 'post', '/newsletter_blocks', data)
}

const ENTITY_NEWSLETTER_BLOCK_UPDATE = 'ENTITY_NEWSLETTER_BLOCK_UPDATE'

export function updateNewsletterBlock(id, data)
{
    return request(ENTITY_NEWSLETTER_BLOCK_UPDATE, 'patch', `/newsletter_blocks/${id}`, data)
}


const ENTITY_NEWSLETTER_BLOCK_DELETE = 'ENTITY_NEWSLETTER_BLOCK_DELETE'

export function deleteNewsletterBlock(id)
{
    return request(ENTITY_NEWSLETTER_BLOCK_DELETE, 'delete', `/newsletter_blocks/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityNewsletterBlockReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_NEWSLETTER_BLOCK_INDEX:
            return {...state, loading: true}
        case ENTITY_NEWSLETTER_BLOCK_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_NEWSLETTER_BLOCK_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
