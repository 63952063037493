import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

import './LargeLayout.scss'
import {logout} from "../data/OAuth";

class LargeLayout extends Component {

    render() {
        return (
            <div className="LargeLayout">
                <div className="LargeLayout-wrapper">
                    <div className="LargeLayout-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }

    renderPath()
    {
        return (
            <p className="text-center">Bienvenue sur l'éditeur. Vos modifications sont enregistrées automatiquement.</p>
        )
    }
}


export default connect(({ user, oauth, layout }) => ({ user, oauth, layout }), { logout })(LargeLayout)
