import axios from "axios";
import {applyMiddleware, combineReducers, createStore} from "redux";
import reduxAxiosMiddleware from 'redux-axios-middleware';
import ReduxThunk from 'redux-thunk';
import {toast} from 'react-toastify';

import OAuthReducer, {logout} from "../data/OAuth";
import UserReducer from "../data/User";
import EditorReducer from "../data/Editor";
import LayoutReducer from "../data/Layout"

import EditorDragDropReducer from "../data/EditorDragDrop";

import entities from '../data/entities'
import CurationReducer from "../data/Curation";

const client = axios.create({
    //baseURL: API_ENDPOINT,
    responseType: 'json'
});

const axiosMiddlewareOptions = {
    returnRejectedPromiseOnError: true,
    interceptors: {
        request: [
            ({ getState }, config) => {
                config.url = localStorage.getItem('API_ENDPOINT') + config.url
                let data = config.reduxSourceAction.payload.request.data;

                // Requête POST, PUT, PATCH avec fichier(s)
                if (data && Object.keys(data).filter((key) => data[key] instanceof File || data[key] instanceof FileList || (data[key] instanceof Array && data[key][0] instanceof File)).length)
                {
                    let form = new FormData();

                    Object.keys(data).map((key) => {
                        if (data[key] instanceof FileList || (data[key] instanceof Array && data[key][0] instanceof File)) {
                            for(let i = 0; i < data[key].length; i++ ){
                                let file = data[key][i];
                                form.append(key + '[' + i + ']', file);
                            }
                        }
                        else form.append(key, data[key]);
                        return null
                    });

                    if (config.method.toLowerCase() !== 'post') {
                        form.append('_method', config.method);
                        config.method = 'post';
                    }

                    config.data = form;
                    config.headers['Content-Type'] = 'multipart/form-data';
                }

                config.crossdomain = true;
                config.headers['Accept'] = 'application/json';

                if (config.reduxSourceAction.payload.request.url == '/token' && config.reduxSourceAction.payload.request.method == 'post') {
                    console.log('Token request')
                    config.headers['Authorization'] = 'Bearer ' + data.refresh_token

                    // The OAuth2 server requires a specific Content-Type (application/x-www-form-urlencoded) for the token request
                    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                    const form = new FormData();
                    Object.keys(data).map((key) => {
                        form.append(key, data[key]);
                        return null
                    });
                    config.data = form;
                }
                else if (getState().oauth.access_token) {
                    config.headers['Authorization'] = 'Bearer ' + getState().oauth.access_token
                }

                return config
            }
        ],
        response: [
            {
                success: ({ dispatch }, res) => {
                    return res
                },
                error: ({ dispatch, getState }, err) => {
                    if (err.response.status === 401 && !getState().oauth.ready) {
                        toast.error("Vous avez été déconnecté.")
                        dispatch(logout())
                    }
                    throw err;
                    return Promise.reject(err)
                }
            }
        ]
    }
};

const rootReducer = combineReducers({
    oauth: OAuthReducer,
    user: UserReducer,
    editor: EditorReducer,
    layout: LayoutReducer,
    editor_drag_drop: EditorDragDropReducer,
    curation: CurationReducer,
    ...entities
})

const store = createStore(
    rootReducer,
    applyMiddleware(
        ReduxThunk,
        reduxAxiosMiddleware(client, axiosMiddlewareOptions)
    )
);

export default store
