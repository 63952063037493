import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";

import Loader from "../../../components/Loader";
import Empty from "../../../components/Empty";
import Pagination from "../../../components/Pagination"
import Media from "./Media"
import MediaSelectModal from "./MediaSelectModal"
import MediaLightbox from './MediaLightbox'
import MediaCreateModal from "./MediaCreateModal";

import "./MediaIndexPage.scss";

import Gallery from 'react-grid-gallery';
import {fetchAll, searchAll} from '../../../data/entities/Media'
import ContentLoader from "../../../components/ContentLoader";

class MediaIndexPage extends Component
{
    state = {
        search: '',
        showMediaCreateModal: false,
        showMediaSelectModal: false,
        mediaModalData: null,
        showMediaLightbox: false,
        filters: [
            {
                name: "chunks",
                value: "24"
            },
            {
                name: "page",
                value: 1
            }
        ]
    };

    _search = search => {
        this.setState({ search }, () => {
            if (search.length) {
                this.handleChange("query", this.state.search)
            } else {
                this.handleChange("query", "default")
            }
        });
    };

    _fetch = () => {
        this.props.fetchAll()
    }


    getMediaModal = (data, type) => {
        this.setState({
            showMediaLightbox: true,
            mediaModalData: data
        })
    }

    handleChange = (name, value) => {
        if (name !== "page") {
            this.setState(
                { filters: this.state.filters.map(filter => {
                    if (filter.name === "page")
                        filter.value = 1
                    return filter
                    })
                })
        }

        if (value === "default") {
            this.setState(
                { filters: this.state.filters.filter(filter => filter.name !== name)} ,
                () => this._submitFilters())
        }

        else if (this.state.filters.find(filter => filter.name === name)) {
            this.setState(
                { filters:
                        this.state.filters.map(filter => {
                            if (filter.name === name) { filter.value = value }
                            return filter
                        })
                },
                () => this._submitFilters())
        }

        else {
            this.setState(
                { filters: [...this.state.filters, { name: name, value: value }] },
                () => this._submitFilters())
        }
    }

    _submitFilters = () => {
        console.log("submiting filters")
        const filters = this.state.filters

        if (filters.length) {
            let query_params = ""
            for (let i = 0; i < filters.length; i++) {
                if (i === filters.length -1) {
                    query_params = `${query_params}${filters[i].name}=${filters[i].value}`
                } else {
                    query_params = `${query_params}${filters[i].name}=${filters[i].value}&`
                }
            }
            this.props.fetchAll(query_params)
        }
        else {
            this._fetch()
        }
    }

    resetFilters = () => {
        this.setState({ filter: [] }, () => this._fetch())
    }

    getEntitiesPerPage = () => {
        if (this.state.filters.find(filter => filter.name === "chunks") === undefined) {
            return 12
        } else {
            return this.state.filters.find(filter => filter.name === "chunks")
        }
    }

    componentDidMount() {
        this._submitFilters()
        //this.props.fetchAll()
    }

    render() {
        return (
            <div>
                <Helmet title="Media" />
                <div>
                    <div className="media-page-head">
                        <h1>Médiathèque</h1>
                        <div>
                            {this.props.media_entity.loading && <Loader/>}
                            <button
                                onClick={() => this.setState({ showMediaCreateModal: true })}
                                className="btn btn-primary ml-2">
                                Importer
                            </button>
                            <input
                                type="text"
                                value={this.state.search}
                                onChange={(e) => this._search(e.target.value)}
                                placeholder="Rechercher..."
                                className="form-control d-inline-block ml-2"
                                style={{ maxWidth: 200 }}
                            />
                        </div>
                    </div>
                    {this.renderTabs()}
                    {this.renderEntities()}
                    {this.renderPagination()}

                </div>

                {this.state.showMediaCreateModal &&
                    <MediaCreateModal
                        onClose={(created) => {
                            if (created) this._submitFilters()
                            this.setState({ showMediaCreateModal: false })
                        }}
                    />
                }

                {this.state.showMediaLightbox &&
                    <MediaLightbox
                        data={this.state.mediaModalData}
                        croppable={this.state.mediaModalData.nature == 'image' ? true : false}
                        onClose={(changes) => {
                            if (changes) this._submitFilters()
                            this.setState({ showMediaLightbox: false })
                        }}/>
                }

                {this.state.showMediaSelectModal &&
                    <MediaSelectModal
                        onClose={ (media) => {
                            this.setState({ showMediaSelectModal: false })
                        }}
                    />
                }

            </div>
        )
    }

    renderTabs() {
        return (
            <div className="sort">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a onClick={() => this.handleChange("type", "default")} className="nav-link active" id="all-types-tab" data-toggle="tab" href="#all-types" role="tab" aria-controls="all-types" aria-selected="true">Tous les types</a>
                    </li>
                    <li onClick={() => this.handleChange("type", "image")} className="nav-item">
                        <a className="nav-link" id="image-tab" data-toggle="tab" href="#image" role="tab" aria-controls="image" aria-selected="false">Image</a >
                    </li>
                    <li onClick={() => this.handleChange("type", "video")} className="nav-item">
                        <a className="nav-link" id="video-tab" data-toggle="tab" href="#video" role="tab" aria-controls="video" aria-selected="false">Vidéo</a>
                    </li>
                    <li onClick={() => this.handleChange("type", "audio")} className="nav-item">
                        <a className="nav-link" id="audio-tab" data-toggle="tab" href="#audio" role="tab" aria-controls="audio" aria-selected="false">Audio</a>
                    </li>
                    <li onClick={() => this.handleChange("type", "application")} className="nav-item">
                        <a className="nav-link" id="document-tab" data-toggle="tab" href="#document" role="tab" aria-controls="document" aria-selected="false">Document</a>
                    </li>
                </ul>
            </div>
        )
    }

    renderEntities() {
        if (!this.props.media_entity.index.index) return <ContentLoader />

        return (
            <div className="mt-2 mb-3 clearfix">
                <Gallery
                    enableImageSelection={false}
                    enableLightbox={false}
                    thumbnailImageComponent={(item) => {
                        if (item.item.nature == 'image' || item.item.nature == 'video')
                            return <img {...item.imageProps} />

                        return (
                            <div style={{...item.imageProps.style, paddingTop: 50 }}>
                                <div className="text-center" style={{ fontSize: 30 }}>
                                    {item.item.nature == 'audio' ? <i className="fa fa-music"/> : <i className="fa fa-file"/>}
                                </div>
                                <div className="text-center" style={{ fontSize: 15 }}>
                                    <span>{item.item.label}</span>
                                </div>
                            </div>
                        )
                    }}
                    onClickThumbnail={(index) => {
                        this.setState({
                            mediaModalData: this.props.media_entity.index.index[index],
                            showMediaLightbox: true
                        })
                    }}
                    images={this.props.media_entity.index.index.map((item) => {
                        return {
                            ...item,
                            src: item.file,
                            thumbnail: item.file_thumbnail
                        }
                    })}/>
            </div>
        )

        return (
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="all-types" role="tabpanel" aria-labelledby="all-types-tab">
                    {
                        !this.props.media_entity.index || !this.props.media_entity.index.index || !this.props.media_entity.index.index.length ?
                        <div className="no-result">
                            <Empty/>
                        </div>
                    :
                        <div className="medias">
                            {
                                this.props.media_entity.index.index.map((entity) => (
                                    <Media
                                        allowModal={true}
                                        getMediaModal={(data, type) => this.getMediaModal(data, type)}
                                        data={entity}
                                        labelCrop={30}/>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderPagination() {
        return (
                <div className="row">
                    <div className="col-9">
                        <Pagination
                            data={this.props.media_entity.index}
                            chunks={this.getEntitiesPerPage()}
                            onChange={(value) => this.handleChange('page', value)}/>
                    </div>
                    <div className={"col-3 text-right"}>
                        <select name={"chunks"}
                                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                className={"custom-select custom-select-sm"}>
                            <option value={"24"}>24 éléments par page</option>
                            <option value={"48"}>48 éléments par page</option>
                            <option value={"96"}>96 éléments par page</option>
                        </select>
                    </div>
                </div>
        )
    }
}

export default connect(({ media_entity }) => ({ media_entity }), { fetchAll, searchAll })(MediaIndexPage)
