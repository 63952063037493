import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet'
import './Auth.scss'

import { login } from '../../data/OAuth'
import {Form, Input, Submit} from "react-forms";

class LoginPage extends Component
{
    state = {
        email: '',
        password: '',
        backoffice: 'backoffice',
        formData: null
    }

    _submit = () => {
        this.props.login(this.state.email, this.state.password, this.state.backoffice)
            .catch(() => {
                this.setState({
                    formData: { error: 'validator.form-error:auth.login' }
                })
            })
    }

    render() {
        return (
            <div className="container auth-container">
                <Helmet title="Connexion" />
                <Form onSubmit={this._submit} data={this.state.formData} toast>
                    <h1 className="h3 mb-3 font-weight-normal">Accéder à mon compte</h1>
                    <Input
                        type="email"
                        name="email"
                        placeholder="Adresse e-mail"
                        value={this.state.email}
                        onChangeValue={(email) => this.setState({ email })}
                        required autoFocus
                    />
                    <Input
                        type="password"
                        name="password"
                        placeholder="Mot de passe"
                        value={this.state.password}
                        onChangeValue={(password) => this.setState({ password })}
                        required
                    />
                    <Input
                        type="hidden"
                        name="backoffice"
                        value={this.state.backoffice}
                    />
                    <Submit>Connexion</Submit>
                </Form>
            </div>
        )
    }

    renderLinks() {
        return (
            <div className="row mb-3">
                <div className="col">
                    <Link to="/auth/forgot-password">Mot de passe oublié ?</Link>
                </div>
                <div className="col text-right">
                    <Link to="/auth/register">Pas de compte ?</Link>
                </div>
            </div>
        )
    }
}

export default connect(null, { login })(LoginPage)
