import React, {Component} from "react";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import Modal from "../../../components/Modal";
import {Form, Submit} from "react-forms";
import {connect} from "react-redux";
import {TranslateArticle} from "../../../data/entities/Article";
import {useHistory, withRouter} from "react-router-dom";
import {Dropdown} from "reactstrap";
class ArticleTranslateModal extends Component
{
    state = {
        loading: false,
        sourcelang: null,
        finallang: null,
        modalData :[],
    }
    _submit = () => {
        this.setState({ loading: true })
        this.state.modalData.push(this.state.sourcelang, this.state.finallang)
        this.props.TranslateArticle(this.props.data.id,this.state.modalData )

            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                    this.setState({ loading: false })
                    this.props.history.push(`/editor/article/${res.payload.data.id}`)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data) {
                    if (e.error.response.data.error) toast.error(<FormattedMessage id={e.error.response.data.error} />)
                    else if (e.error.response.data.message) toast.error(<FormattedMessage id={e.error.response.data.message} />)
                }
                this.setState({ loading: false })
            })
    }

    render()
    {
        return (
            <Modal title="Traduction de l'article " onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit}>
                    <select
                        value={this.state.sourcelang}
                        onChange={(e) => this.setState({ sourcelang: e.target.value })}
                        onFocus= {(e) => this.setState({ sourcelang: e.target.value })}
                        className="custom-select mb-3 form-control" id="page">
                        <option value="fr" className="dropdown-item" href="#">Francais</option>
                        <option value="en" className="dropdown-item" href="#">Anglais</option>

                    </select>

                    <select
                        value={this.state.finallang}
                        onChange={(e) => this.setState({ finallang: e.target.value })}
                        onFocus= {(e) => this.setState({ finallang: e.target.value })}
                        className="custom-select mb-3 form-control" id="page">
                        <option value="fr" className="dropdown-item" href="#">Francais</option>
                        <option value="en" className="dropdown-item" href="#">Anglais</option>

                    </select>

                    <Submit disabled={this.state.loading} className="btn btn-info float-right">Traduire</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null,{ TranslateArticle })(withRouter(ArticleTranslateModal))