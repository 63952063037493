// Actions

const OPEN_DROPDOWN = 'OPEN_DROPDOWN'
const CLOSE_DROPDOWN = 'CLOSE_DROPDOWN'

export function openDropdown(name) {
  return {
    type: OPEN_DROPDOWN,
    payload: {
      name
    }
  }
}

export function closeDropdown(name) {
  return {
    type: CLOSE_DROPDOWN,
    payload: {
      name
    }
  }
}

// Store

const defaultState = ['Publication']

// Reducer

const LayoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_DROPDOWN:
      return [
        ...state,
        action.payload.name
      ]

    break; 

    case CLOSE_DROPDOWN:
      return state.filter((n) => (n !== action.payload.name))

    default:
      return state
  }
}

export default LayoutReducer