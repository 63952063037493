import React, {Component} from 'react';

import {connect} from "react-redux";

import ContentLoader from "../../components/ContentLoader";
import Empty from "../../components/Empty";
import './CurationPage.scss'

import ArticlesTabs from "../../components/ArticlesTabs";
import RssArticle from "./RssArticle";

import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import {Helmet} from "react-helmet";

import {highlightArticle, searchAll, fetchAllRssArticles, fetchUserFavoriteCategories} from "../../data/RssArticle";
// import { fetchAll } from "../../data/entities/Article";
import CategoryFilter from "../entities/Article/CategoryFilter";
import CategoryFilterMultiple from "../entities/Article/CategoryFilterMultiple";
import AuthorFilter from "../entities/Article/AuthorFilter";
import {FormGroup, Input, Label} from "reactstrap";
import {toast} from "react-toastify";
import {importRssArticleIntoArticles} from "../../data/entities/Article";

class RssArticlePage extends Component
{
    state = {
        search: '',
        _loading: false,
        filters: [],
        page: 1,
        chunks: 12,
        order_direction: 'DESC',
        order_by: 'created_at',
        category: null,
        author: null,
        highlighted: false,
        scoops: false,
    }


    componentDidMount() {

        let user = this.props.user.profile.id

        this.props.fetchUserFavoriteCategories(user)
            .then((res) => {
                if (res.payload.data)
                    this.setState({ page: 1,category: res.payload.data.favoris },this._fetchRss)
            })
            .catch((e) => {
                console.log('---------------ERROR')
                console.log(e)
            })

        // this._fetchRss();

    }

    _fetchRss = () => {
        console.log('_fetchRss INFOS -------')
        console.log(this.state)
        let {page, chunks, filters, search, order_by, order_direction, author, category, highlighted, scoops} = this.state
        if (author && author != 'me') author = author.id
        else if (author != 'me') author = ''
        if (category && category.length > 0){
            this.setState({ category: category })
            var arrayCategories=[]
            for (var i=0; i < category.length; i++) {
                    arrayCategories.push(category[i].id)
                    console.log(category[i].id, '-',arrayCategories)
            }
        }
          //  category = category[0].id
        else {
            arrayCategories = ''
        }
        let params = `page=${page}&chunks=${chunks}&order_by=${order_by}&` +
            `order_direction=${order_direction}&query=${encodeURIComponent(search.trim())}&` +
            `author=${author}&category=${arrayCategories}&highlighted=${(highlighted ? 1 : 0)}&scoops=${(scoops ? 1 : 0)}`

        this.props.fetchAllRssArticles(params);


    };
    activeCursor(){

    }
    changeFilter(cat){
        console.log('cat')
        console.log(cat)
        var categories=this.state.category
        // category=category.filter((item, index) => category.indexOf(item) === index);
            if(cat) {
                categories=categories.concat([cat])
                const result = categories.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        JSON.stringify(t) === JSON.stringify(thing)
                    ))
                )
                categories=result
            }else{
                categories=[]
            }
            this.setState({page: 1,category:categories },this._fetchRss)

    }
    removeCategory(cat){
        var categories=this.state.category
            const index = categories.indexOf(cat);
            if (index > -1) { // only splice array when item is found
                categories.splice(index, 1); // 2nd parameter means remove one item only
            }
            this.setState({category:categories },this._fetchRss)
    }
    render()
    {

        console.log('RSS INFOS -------')
        console.log(this.props,this.state)
        if (this.props.article_rss_entity.loading && !this.props.article_rss_entity.lastUpdate)
            return <ContentLoader />;

        if (!this.props.article_rss_entity.index || !this.props.article_rss_entity.index.index)
            return <Empty />;

        return (
            <div className="flux-rss articles-index">
                <Helmet title="Flux RSS"/>
                {this.renderHeader()}
                <ArticlesTabs tab="flux-rss"/>
                <form onSubmit={this.submit}>
                    <div className="d-flex mt-4">
                        <input type="text"
                               value={this.state.search}
                               onChange={(e) => this.setState({ search: e.target.value, page: 1 }, this._fetchRss)}
                               placeholder="Rechercher un article..."
                               className="form-control mx-1" id="search-input" />
                        <span className="input-group-append">
                            <label className="input-group-text bg-transparent" for="search-input" style={{border: "2px solid #ced4da !important", cursor: "pointer"}} onClick={this.activeCursor}><i className="fa fa-search"></i></label>
                        </span>

                    </div>
                </form>
                <div className="mt-3 row">
                    <div className="col">
                        <CategoryFilterMultiple
                            value={this.state.category}
                            // onChange={(cat) =>
                            onChange={(cat) =>
                                // this.setState({ category:cat?(!this.state.category.includes(cat)?this.state.category.concat([cat]):null):[], page: 1 },this._fetchRss)
                                this.changeFilter(cat)
                            }
                             onDelete={(c) =>this.removeCategory(c)}
                        />
                    </div>
                </div>
                {this.renderExplorer()}
            </div>
        );
    }
    renderHeader()
    {
        return (
            <div>
                <Loader display={this.props.article_rss_entity.loading}/>
            </div>
        )
    }
    renderExplorer()
    {
            return (

                <>
                    <div className="row my-4 article-container">
                        {this.renderEntities()}
                    </div>

                    <div>
                        <Pagination
                            data={this.props.article_rss_entity.index}
                            chunks={this.state.chunks}
                            onChange={(page) => this.setState({page}, this._fetchRss)}/>
                    </div>
                </>
            )

    }
    renderEntities()
    {
        return (

            this.props.article_rss_entity.index.index.length ?
                this.props.article_rss_entity.index.index.map(article => this.renderArticle(article))
                :
                <Empty/>

        );
    }
    renderArticle(article) {

        return (
            <RssArticle
                key={article.id}
                importRssArticleIntoArticles={this.props.importRssArticleIntoArticles}
                fetchRss ={this._fetchRss}
                onDelete={this._fetchRss}
                article={article}
                updated={this._fetchRss}
            />
        )
    }

}

export default connect(
    ({ article_rss_entity,user, searchAll }) => ({ article_rss_entity,user, searchAll }),
    { fetchAllRssArticles,fetchUserFavoriteCategories,importRssArticleIntoArticles}
)(RssArticlePage);
