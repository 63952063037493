import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_ARTICLE_TYPE_INDEX = 'ENTITY_ARTICLE_TYPE_INDEX'
const ENTITY_ARTICLE_TYPE_INDEX_SUCCESS = 'ENTITY_ARTICLE_TYPE_INDEX_SUCCESS'
const ENTITY_ARTICLE_TYPE_INDEX_FAIL = 'ENTITY_ARTICLE_TYPE_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_ARTICLE_TYPE_INDEX, 'get', '/article_types')
}

export function searchAll(query)
{
    return request(ENTITY_ARTICLE_TYPE_INDEX, 'post', '/article_types/search', { query })
}

const ENTITY_ARTICLE_TYPE_RETRIEVE = 'ENTITY_ARTICLE_TYPE_RETRIEVE'

export function fetchArticleType(id)
{
    return request(ENTITY_ARTICLE_TYPE_RETRIEVE, 'get', `/article_types/${id}`)
}

const ENTITY_ARTICLE_TYPE_CREATE = 'ENTITY_ARTICLE_TYPE_CREATE'

export function createArticleType(data)
{
    return request(ENTITY_ARTICLE_TYPE_CREATE, 'post', '/article_types', data)
}

const ENTITY_ARTICLE_TYPE_UPDATE = 'ENTITY_ARTICLE_TYPE_UPDATE'

export function updateArticleType(id, data)
{
    return request(ENTITY_ARTICLE_TYPE_UPDATE, 'patch', `/article_types/${id}`, data)
}


const ENTITY_ARTICLE_TYPE_DELETE = 'ENTITY_ARTICLE_TYPE_DELETE'

export function deleteArticleType(id)
{
    return request(ENTITY_ARTICLE_TYPE_DELETE, 'delete', `/article_types/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityArticleTypeReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_ARTICLE_TYPE_INDEX:
            return {...state, loading: true}
        case ENTITY_ARTICLE_TYPE_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_ARTICLE_TYPE_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}