import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";

import './BaseLayout.scss'
import {logout} from "../data/OAuth";
import {openDropdown, closeDropdown} from "../data/Layout"
import {checkPrivilege} from "../helpers";
import {createArticle} from "../data/entities/Article";
import SuggestedArticlesCount from "../components/SuggestedArticlesCount";


class BaseLayout extends Component {

    state = {
        open: []
    }

    toggleDropdown = (name) => {
        if (this.props.layout.indexOf(name) === -1)
        {
            this.props.openDropdown(name)
        }
        else
        {
            this.props.closeDropdown(name)
        }
    }

    toggleBackground = (e) => {
        e.target.classList.toggle("focus")
    }

    createArticle = () => {
        this.props.createArticle()
            .then((res) => {
                this.props.history.push(`/editor/article/${res.payload.data.id}`)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    alert(e.error.response.data);

            })
    }

    render() {
        return (
            <div className="BaseLayout">
                <div className="BaseLayout-wrapper">
                    <div className="BaseLayout-content">
                        {this.props.children}
                    </div>
                    <div className="BaseLayout-header">
                        <div className="left-section">
                            {this.renderPath()}
                        </div>
                        {this.renderUser()}
                    </div>
                </div>
                <div className="BaseLayout-sidebar">
                    <h1 className="solution">Rewyer</h1>
                    <h1 className="brand"><i className="fas fa-globe"/> {localStorage.getItem("APP_NAME")}</h1>
                    <a className="brand-link" href={localStorage.getItem('APP_URL')} target="_blank"><i className="fas fa-external-link-alt"/> Voir le site</a>

                    <div className="section">
                        <Link to="/" className="section-title">
                                <span>
                                  <i className="fas fa-columns"/>
                                  Tableau de bord
                                </span>
                        </Link>
                    </div>

                    <div className="section">
                        <div className="section-title" onClick={() => this.createArticle()}>
                                <span>
                                  <i className="fas fa-plus"/>
                                  Rédiger un article
                                </span>
                        </div>
                    </div>


                    {this.renderSection('Publication', 'pencil-alt', [
                        checkPrivilege(this.props.user, 'article') ? <Link to="/entities/article">Flux d'articles</Link> : null,
                        checkPrivilege(this.props.user, 'media') ? <Link to="/entities/media">Médiathèque</Link> : null,
                        checkPrivilege(this.props.user, 'Contribution') ? <Link to="/Contribution">Contributions <SuggestedArticlesCount/></Link> : null
                    ])}

                    {this.renderSection('Réseau', 'globe', [
                        checkPrivilege(this.props.user, 'curation') ? <Link to="/curation">Curation</Link> : null,
                        checkPrivilege(this.props.user, 'user') ? <Link to="/entities/user">Utilisateurs</Link> : null,
                    ])}

                    {this.renderSection('Modules', 'box-open', [
                        checkPrivilege(this.props.user, 'offer') ? <Link to="/entities/offer">Offres d'emploi</Link> : null,
                        checkPrivilege(this.props.user, 'newsletter') ? <Link to="/entities/newsletter">Newsletters</Link> : null,
                        checkPrivilege(this.props.user, 'ad') ? <Link to="/entities/advertising">Publicités</Link> : null,
                        checkPrivilege(this.props.user, 'contact-messages') ? <Link to="/entities/contact">Formulaire de contact</Link> : null,
                        checkPrivilege(this.props.user, 'confidential-document') ? <Link to="/entities/confidential-documents">Documents confidentiels</Link> : null,
                        checkPrivilege(this.props.user, 'stripe-product') ? <Link to="/entities/stripe-products">Abonnements / Produits</Link> : null
                    ])}

                    {this.renderSection('Configuration', 'cog', [
                        this.props.user.profile.roles.indexOf('ROLE_ADMIN') !== -1 ? <Link to="/platform">Plateforme</Link> : null,
                        checkPrivilege(this.props.user, 'role') ? <Link to="/entities/role">Rôles utilisateurs</Link> : null,
                        checkPrivilege(this.props.user, 'static-page') ? <Link to="/entities/static_page">Pages statiques</Link> : null,
                        checkPrivilege(this.props.user, 'category') ? <Link to="/entities/category">Catégories d'article</Link> : null,
                        checkPrivilege(this.props.user, 'channel') ? <Link to="/entities/channel">Menus et accueil</Link> : null,
                        this.props.user.profile.roles.indexOf('ROLE_ADMIN') !== -1 ? <Link to="/manage-ads">Manage ADS File</Link> : null,

                    ])}
                </div>
            </div>
        )
    }

    renderPath()
    {
        return null

        return (
            <span className="path">
                <i className="fas fa-home"/>
                <Link to="#">Accueil</Link>
                &nbsp;/&nbsp;
                <Link to="#">Configuration</Link>
                &nbsp;/&nbsp;
                <Link to="#">Pages permanentes</Link>
            </span>
        )
    }

    renderSection(title, icon, children)
    {
        const open = this.props.layout.indexOf(title) !== -1
        children = children.filter((child) => child !== null)

        if (!children.length) return null

        return (
            <div className={"section " + (open ? 'open' : '')} key={icon}>
                <div className="section-title" onClick={() => this.toggleDropdown(title)}>
                    <span><i className={`fas fa-${icon}`}/> {title}</span>
                    <i className={`fas fa-angle-${open ? 'up' : 'down'}`}/>
                </div>
                <div className="dropdown">
                    {children}
                </div>
            </div>
        )
    }

    renderUser()
    {
        return (
            <div className="user-dropdown">
                <button onFocus={this.toggleBackground} onBlur={this.toggleBackground} type="button"
                        className="user-profile-btn btn dropdown-toggle" data-toggle="dropdown">
                    <i className="fas fa-user-circle"/>
                    <span>{this.props.user.profile.email}</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    <Link to={`/entities/user/${this.props.user.profile.id}`} className="dropdown-item">Mon compte</Link>
                    <button className="dropdown-item" type="button" onClick={() => this.props.logout()}>Déconnexion</button>
                </div>
            </div>
        )
    }
}


export default connect(({ user, oauth, layout }) => ({ user, oauth, layout }), { logout, openDropdown, closeDropdown, createArticle })(withRouter(BaseLayout))
