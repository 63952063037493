import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, Input } from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import Modal from "../../../components/Modal";
import { isUrl } from '../../../helpers';
import ContentLoader from "../../../components/ContentLoader"

import {updateAdvertisingBanner} from "../../../data/entities/AdvertisingBanner";
import { fetchAll as fetchAllBannersTypes } from "../../../data/entities/AdvertisingBannerType"
import { fetchAll as fetchAllNewsletters } from "../../../data/entities/Newsletter"
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";

class AdvertisingBannerUpdateModal extends Component
{
    state = {
        _formData: null,
        _loading: true,

        _banners_types: [],
        _newsletters: [],

        advertising_banner_type: this.props.data.type,
        newsletter: this.props.data.newsletter ? this.props.data.newsletter.id : null,
        link: this.props.data.link,
        target_newsletter: this.props.data.target_newsletter ? this.props.data.target_newsletter.id : null,
        publication_status: this.props.data.publication_status === true ? 1 : 0,
        image: this.props.data.image,
        html: this.props.data.html,
        image_type: this.props.data.imageType,

        activeTab: this.props.data.newsletter ? '2' : '1',
        uploadedImage: ""
    };

    _submit = () => {
        this.setState({ _loading: true })

        if (this.state.image_type === "link" && (this.state.link === null || this.state.link === "" || !isUrl(this.state.link))) {
            this.setState({ newsletter: null, _loading: false })
            toast.error("Vérifiez le format de l'URL")
        }

        else if (this.state.image_type === "newsletter" && this.state.newsletter === null) {
            this.setState({ link: null, _loading: false })
        }

        else {
            const data = {...this.state}

            if (data.image_type === "link") {
                data.newsletter = null
            }
            else if (data.image_type === "newsletter") {
                data.link = null
            }

            delete data._loading
            delete data._formData
            delete data._banners_types
            delete data._newsletters
            typeof data.image === "string" ? delete data.image : (data.image = this.fileRef.files[0])
            delete data.uploadedImage
            data.advertising_banner_type = data.advertising_banner_type.id

            if (data.html) {
                delete data.link;
                delete data.newsletter;
            }

            if (!data.target_newsletter)
                delete data.target_newsletter

            console.log("SENT DATA", data)

            this.props.updateAdvertisingBanner(this.props.id, data)
                .then((res) => {
                    if (res.payload.data.message)
                        toast.success(<FormattedMessage id={res.payload.data.message} />)
                    this.props.onClose(res.payload.data)
                })
                .catch((e) => {
                    if (e.error && e.error.response && e.error.response.data)
                        this.setState({ _formData: e.error.response.data })
                    this.setState({ _loading: false })
                })
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    onChangeLink(link) {
        this.setState({ link });
        this.setState({ image_type: 'link' });
    }

    onChangeNewsletter(newsletter) {
        this.setState({ newsletter });
        this.setState({ image_type: 'newsletter' });
    }


    renderHTML() {

        if(this.state.html && !this.state.typeImage) {
            return(
                <div>
                    <label className="mt-2">Code HTML</label>
                <textarea className={"form-control mb-4"} placeholder={"Veuillez saisir le code html"}
                          onChange={(e) => this.setState({ html: e.target.value } )}>{this.state.html}</textarea>
                </div>
            )
        }

    }


    componentDidMount() {
        this.props.fetchAllBannersTypes()
            .then(res => this.setState(
                { _banners_types: res.payload.data },
                () => {
                    this.setState({ _loading: false })
                    console.log("banners_types:", this.state._banners_types)
                }
            ))
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })

        this.props.fetchAllNewsletters()
            .then(res => this.setState({ _newsletters: res.payload.data }))
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })
    }

    render()
    {
        if (this.state._loading)  return <ContentLoader />

        return (
            <Modal title={"Modifier la bannière"} onClose={() => this.props.onClose(false)} additionnalClass="advertising-banner-create-modal">
                <Form onSubmit={this._submit} data={this.state._formData}>

                    {
                        this.props.data.type.slug == 'newsletter' ? (
                            <div className="form-group my-4">
                                <label>Dans quelle newsletter doit-elle apparaitre ?</label>
                                <select
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value)
                                        this.setState({
                                            target_newsletter: isNaN(value) ? null : value
                                        })
                                    }}
                                    value={this.state.target_newsletter}
                                    className="custom-select">
                                    <option value={null}>Toutes</option>
                                    {
                                        this.state._newsletters.length &&
                                        this.state._newsletters.map(newsletter =>
                                            <option value={newsletter.id}>{newsletter.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        ) : null
                    }

                    {/*Si state.html === null && state.image_type === "link" ------> afficher un input de modification de link */}
                    {/*Si state.html === null && state.image_type === "newsletter" ------> afficher un select de modification de newsletter */}

                    {this.state.image_type ?
                        <div>
                            <p><strong>Action au clic :</strong></p>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}
                                    >
                                        Ouvrir une page web
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}
                                    >
                                        Inscription à une newsletter
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Input
                                        value={this.state.link} onChangeValue={(link) => this.onChangeLink(link)}
                                        placeholder="Veuillez saisir l'URL de la page web" label="" type="text" className="form-control my-4"/>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="form-group my-4">
                                        <label>Choisissez votre newsletter</label>
                                        <select
                                            value={this.state.newsletter}
                                            onChange={(e) => this.onChangeNewsletter(e.target.value)}
                                            className="custom-select">
                                            <option value={null}>--- Newsletter ---</option>
                                            {
                                                this.state._newsletters.length &&
                                                this.state._newsletters.map(newsletter =>
                                                    <option value={newsletter.id}>{newsletter.name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                        : null }

                    {this.renderHTML()}

                    <div className={"form-group date mb-4"}>
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                onChange={() => this.setState({ publication_status: this.state.publication_status === 1 ? 0 : 1})}
                                checked={this.state.publication_status === 1}
                                className="custom-control-input" id="publication_status"/>
                            <label className="custom-control-label" htmlFor="publication_status">Bannière active</label>
                        </div>
                    </div>

                    <Submit disabled={this.state._loading}>Modifier</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, {
    updateAdvertisingBanner,
    fetchAllBannersTypes,
    fetchAllNewsletters
})(AdvertisingBannerUpdateModal)
