import moment from 'moment-timezone';
import { request } from "../../helpers";

// Actions

const ENTITY_ARTICLE_INDEX = "ENTITY_ARTICLE_INDEX";
const ENTITY_ARTICLE_INDEX_SUCCESS = "ENTITY_ARTICLE_INDEX_SUCCESS";
const ENTITY_ARTICLE_INDEX_FAIL = "ENTITY_ARTICLE_INDEX_FAIL";
const ENTITY_IMPORT_RSS_ARTICLE_INTO_ARTICLES = "ENTITY_IMPORT_RSS_ARTICLE_INTO_ARTICLES";

export function fetchAll(query_params) {
  return request(ENTITY_ARTICLE_INDEX, "get", `/articles?${query_params}`);
}

export function searchAll(query) {
  return request(ENTITY_ARTICLE_INDEX, "get", "/articles?query=" + encodeURIComponent(query));
}

const ENTITY_ARTICLE_RETRIEVE = "ENTITY_ARTICLE_RETRIEVE";

export function fetchArticle(id) {
  return request(ENTITY_ARTICLE_RETRIEVE, "get", `/articles/${id}`);
}

const ENTITY_ARTICLE_PREVIEW = "ENTITY_ARTICLE_PREVIEW";

export function previewArticle(id) {
  return request(ENTITY_ARTICLE_PREVIEW, "get", `/articles/${id}/preview`);
}

const ENTITY_ARTICLE_TAG_RETRIEVE = "ENTITY_ARTICLE_TAG_RETRIEVE";

export function fetchArticleTags(id) {
  return request(ENTITY_ARTICLE_TAG_RETRIEVE, "get", `/articles/${id}/article_tags`)
}

const ENTITY_ARTICLE_CATEGORY_RETRIEVE = "ENTITY_ARTICLE_CATEGORY_RETRIEVE";

export function fetchArticleCategories(id) {
  return request(ENTITY_ARTICLE_CATEGORY_RETRIEVE, "get", `/articles/${id}/article_categories`)
}

const ENTITY_COMMENTS_RETRIEVE = "ENTITY_COMMENTS_RETRIEVE";

export function fetchComments(page) {
  return request(ENTITY_COMMENTS_RETRIEVE, "get", `/comments?page=` + page)
}

const ENTITY_ARTICLE_COMMENT_RETRIEVE = "ENTITY_ARTICLE_COMMENT_RETRIEVE";

export function fetchArticleComments(id) {
  return request(ENTITY_ARTICLE_COMMENT_RETRIEVE, "get", `/comments/${id}`)
}

const ENTITY_ARTICLE_STATS_RETRIEVE = "ENTITY_ARTICLE_STATS_RETRIEVE";

export function fetchArticleStats(id) {
  return request(ENTITY_ARTICLE_STATS_RETRIEVE, "get", `/article/stats/${id}`)
}

const ENTITY_ARTICLES_STATS_RETRIEVE = "ENTITY_ARTICLES_STATS_RETRIEVE";

export function fetchArticlesStats() {
  return request(ENTITY_ARTICLES_STATS_RETRIEVE, "get", `/articles/stats`)
}

const ENTITY_ARTICLE_DELETE_COMMENT = "ENTITY_ARTICLE_DELETE_COMMENT"

export function deleteArticleComment(id) {
  return request(ENTITY_ARTICLE_DELETE_COMMENT, "delete", `/comments/${id}`)
}

const ENTITY_ARTICLE_CREATE = "ENTITY_ARTICLE_CREATE";

export function createArticle() {
  return request(ENTITY_ARTICLE_CREATE, "post", "/articles", {});
}

const ENTITY_ARTICLE_UPDATE = "ENTITY_ARTICLE_UPDATE";

export function updateArticle(id, data) {
  return request(ENTITY_ARTICLE_UPDATE, "patch", `/articles/${id}`, data);
}

const ENTITY_ARTICLE_HIGHLIGHT = "ENTITY_ARTICLE_HIGHLIGHT";

export function highlightArticle(id, highlighted) {
  return request(ENTITY_ARTICLE_HIGHLIGHT, "patch", `/articles/${id}/highlight`, { action: highlighted ? 'highlight' : 'unhighlight' });
}
export function importRssArticleIntoArticles(id_rss_article) {
  return request(ENTITY_IMPORT_RSS_ARTICLE_INTO_ARTICLES, "get", `/ExportToArticles/${id_rss_article}`);
}


const ENTITY_ARTICLE_ADD_COVER = "ENTITY_ARTICLE_ADD_COVER"

export function addArticleCover(articleId, mediaId) {
  return request(ENTITY_ARTICLE_ADD_COVER, "patch", `/articles/${articleId}/cover`, mediaId)
}

const ENTITY_ARTICLE_DELETE_COVER = "ENTITY_ARTICLE_DELETE_COVER"

export function deleteArticleCover(articleId) {
  return request(ENTITY_ARTICLE_DELETE_COVER, "delete", `/articles/${articleId}/cover`)
}

const ENTITY_ARTICLE_CREATE_TAG = "ENTITY_ARTICLE_CREATE_TAG";

export function createArticleTag(articleId, tagId) {
  return request(ENTITY_ARTICLE_CREATE_TAG, "put", `/articles/${articleId}/tag/${tagId}`)
}

const ENTITY_ARTICLE_CREATE_CATEGORY = "ENTITY_ARTICLE_CREATE_CATEGORY";

export function createArticleCategory(articleId, categoryId) {
  return request(ENTITY_ARTICLE_CREATE_CATEGORY, "put", `/articles/${articleId}/categories`, categoryId)
}

const ENTITY_ARTICLE_CREATE_CATEGORIES = "ENTITY_ARTICLE_CREATE_CATEGORIES";

export function createArticleCategories(articleId, categoriesIds) {
  return request(ENTITY_ARTICLE_CREATE_CATEGORIES, "put", `/articles/${articleId}/categories_entities`, {categoriesIds})
}

const ENTITY_ARTICLE_DELETE = "ENTITY_ARTICLE_DELETE";

export function deleteArticle(id) {
  return request(ENTITY_ARTICLE_DELETE, "delete", `/articles/${id}`);
}

const ENTITY_ARTICLE_TRANSLATE = "ENTITY_ARTICLE_TRANSLATE";


export function TranslateArticle(articleId, data) {
  return request(ENTITY_ARTICLE_TRANSLATE, "post", `/translate-articles/${articleId}`, {data});
  console.log(ENTITY_ARTICLE_TRANSLATE);
}

const ENTITY_ARTICLE_TAG_DELETE = "ENTITY_ARTICLE_TAG_DELETE";

export function deleteArticleTag(articleId, tagId) {
  return request(ENTITY_ARTICLE_TAG_DELETE, "delete", `/articles/${articleId}/article_tag/${tagId}`)
}

const ENTITY_ARTICLE_CATEGORY_DELETE = "ENTITY_ARTICLE_CATEGORY_DELETE";

export function deleteArticleCategory(articleId, categoryId) {
  return request(ENTITY_ARTICLE_CATEGORY_DELETE, "delete", `/articles/${articleId}/category`, categoryId)
}

const ENTITY_ARTICLE_DUPLICATE = "ENTITY_ARTICLE_DUPLICATE";

export function duplicateArticle(id) {
  return request(ENTITY_ARTICLE_DUPLICATE, "post", `/articles/${id}/duplicate`);
}

const ENTITY_ARTICLE_DELETE_MARKETPLACE_TAG = "ENTITY_ARTICLE_DELETE_MARKETPLACE_TAG";

export function deleteMarketplaceTag(id, tag) {
  return request(ENTITY_ARTICLE_DELETE_MARKETPLACE_TAG, "delete", `/articles/${id}/marketplace_tag/${tag}`)
}

const ENTITY_ARTICLE_CREATE_MARKETPLACE_TAG = "ENTITY_ARTICLE_CREATE_MARKETPLACE_TAG";

export function createMarketplaceTag(id, tag) {
  return request(ENTITY_ARTICLE_CREATE_MARKETPLACE_TAG, "post", `/articles/${id}/marketplace_tag/${tag}`)
}

// Store

const defaultState = {
  index: null,
  loading: false,
  lastUpdate: null
};

// Reducer

export default function EntityArticleReducer(state = defaultState, action) {
  switch (action.type) {
    case ENTITY_ARTICLE_INDEX:
      return { ...state, loading: true };
    case ENTITY_ARTICLE_INDEX_FAIL:
      return { ...state, loading: false };
    case ENTITY_ARTICLE_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        index: action.payload.data,
        lastUpdate: moment()
      };
  }
  return state;
}
