import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify'
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";

import ContentLoader from "../../../components/ContentLoader";

import {fetchAdvertisingBannerType} from '../../../data/entities/AdvertisingBannerType'
import AdvertisingBannerTypeUpdateModal from "./AdvertisingBannerTypeUpdateModal";
import AdvertisingBannerTypeDeleteModal from "./AdvertisingBannerTypeDeleteModal";

class AdvertisingBannerTypeViewPage extends Component
{
    state = {
        id: this.props.match.params.id,
        data: null,
        loading: true,

        showAdvertisingBannerTypeUpdateModal: false,
        showAdvertisingBannerTypeDeleteModal: false
    }

    componentDidMount()
    {
        this.props.fetchAdvertisingBannerType(this.state.id)
            .then((res) => {
                this.setState({ loading: false, data: res.payload.data })
            })
            .catch((e) => {
                console.log(e)
                if (e.error && e.error.response && e.error.response.data && e.error.response.data.error)
                    toast.error(<FormattedMessage id={e.error.response.data.error} />)
                this.props.history.push('/entities/advertising_banner_type')
            })
    }

    render() {
        return (
            <div className="container">
                <Helmet title="AdvertisingBannerType" />
                <h1>AdvertisingBannerType #{this.state.id}</h1>
                {this.renderEntity()}
            </div>
        )
    }

    renderEntity()
    {
        if (this.state.loading) return <ContentLoader/>

        return (
            <div>
                <p>Données de l'entitié :</p>
                <pre>{JSON.stringify(this.state.data)}</pre>
                <p>Actions :</p>
                <p>
                    <button
                        onClick={() => this.setState({ showAdvertisingBannerTypeUpdateModal: true })}
                        className="btn btn-primary">Modifier</button>
                    <button
                        onClick={() => this.setState({ showAdvertisingBannerTypeDeleteModal: true })}
                        className="btn btn-danger ml-2">Supprimer</button>
                </p>
                {this.state.showAdvertisingBannerTypeUpdateModal ? (
                    <AdvertisingBannerTypeUpdateModal
                        data={this.state.data}
                        onClose={(data) => {
                            if (data) this.setState({ data })
                            this.setState({ showAdvertisingBannerTypeUpdateModal: false })
                        }}
                    />
                ) : null}
                {this.state.showAdvertisingBannerTypeDeleteModal ? (
                    <AdvertisingBannerTypeDeleteModal
                        data={this.state.data}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.props.history.push('/entities/advertising_banner_type')
                            }
                            else this.setState({ showAdvertisingBannerTypeDeleteModal: false })
                        }}
                    />
                ) : null}
            </div>
        )
    }
}

export default connect(({ advertising_banner_type_entity }) => ({ advertising_banner_type_entity }), { fetchAdvertisingBannerType })(AdvertisingBannerTypeViewPage)
