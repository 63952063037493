import React, {Component} from 'react';
import Modal from '../../../components/Modal';
import { fetchAll, searchAll } from '../../../data/entities/Article';
import {connect} from 'react-redux';
import ContentLoader from '../../../components/ContentLoader';
import Empty from '../../../components/Empty';
import ArticleNewsletterCard from '../Article/ArticleNewsletterCard';
import Pagination from '../../../components/Pagination';
import {Button} from 'reactstrap';
import './NewsletterArticlesModal.scss';
import {addNewsletterEntityArticles} from "../../../data/entities/Newsletter";
import {toast} from "react-toastify";
import {Input} from "react-forms";


class NewsletterArticlesModal extends Component
{
    state = {
        selected_articles: [],
        page: 1,
        search: null
    };

    componentDidMount() {
        this._fetch();
    }

    _fetch = () => {
        let {page} = this.state;
        let params = `page=${page}`;

        this.props.fetchAll(params);
    };

    _submit = () => {

        const {selected_articles} = this.state;

        this.props.addNewsletterEntityArticles('articles', this.props.id, selected_articles)
            .then(() => {
                this.props.updated();
            })
            .catch(e => {
                if (
                    e.error &&
                    e.error.response &&
                    e.error.response.data &&
                    e.error.response.data.error
                )
                    toast.error(e.error.response.data.error);
            });
    };

    _search = (search) => {
        this.setState({ search }, () => {
            this.props.searchAll(this.state.search);
        })
    };

    render() {

        if (this.props.article_entity.loading && !this.props.article_entity.lastUpdate)
            return <ContentLoader />;

        if (!this.props.article_entity.index || !this.props.article_entity.index.index)
            return <Empty />;

        return(
            <div className="newsletter-articles-modal">
                <Modal title={"Veuillez cliquer sur un article pour pouvoir l'ajouter à la newsletter"} onClose={() => this.props.onClose()}>

                    <div>
                        <Input
                            value={this.state.search}
                            onChangeValue={(data) => this._search(data)}
                            placeholder={"Rechercher par nom"}
                            type="text"
                            autocomplete={'off'}
                        />
                    </div>

                    {this.renderEntities()}

                    <div>
                        <Pagination
                            data={this.props.article_entity.index}
                            onChange={(page) => this.setState({ page }, this._fetch)}
                            className={'m-auto'}
                        />
                    </div>

                    <div className="row mt-4">
                        <div className="col text-center">
                            <Button color={'primary mr-2'} onClick={() => this._submit()}>Confirmer l'ajout</Button>
                            <Button color={'danger'} onClick={() => this.props.onClose()}>Annuler</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    renderEntities()
    {
        return (
            <div className={"row my-4 article-container"}>
                {
                    this.props.article_entity.index.index.length ?
                        this.props.article_entity.index.index.map(article => this.renderArticle(article))
                        :
                        <Empty/>
                }
            </div>
        );
    }

    renderArticle(article) {

        return (
            <ArticleNewsletterCard
                key={article.id}
                onDelete={this._fetch}
                article={article}
                selectArticle={(id) => this.toggleSelectedArticles(id)}
                selected={this.state.selected_articles.includes(article.id)}
            />
        )
    }

    toggleSelectedArticles = (article_id) => {

        let { selected_articles } = this.state;

        if (!selected_articles.includes(article_id))
            selected_articles.push(article_id);
        else {
            let index = selected_articles.indexOf(article_id);
            if (index > -1) {
                selected_articles.splice(index, 1);
            }
        }

        this.setState({ selected_articles });
    }
}

export default connect(
    ({ article_entity }) => ({ article_entity }),
    { fetchAll, addNewsletterEntityArticles, searchAll }
)(NewsletterArticlesModal);
