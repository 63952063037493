import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

import ContentLoader from "../../../components/ContentLoader";

import { deleteChannelMenu } from "../../../data/entities/ChannelMenu";
import { updateChannelMenuPosition } from "../../../data/entities/Channel";
import ChannelUpdateMenuItem from "./ChannelUpdateMenuItem";
import ChannelAddMenuItem from "./ChannelAddMenuItem";


class ChannelMenuItem extends Component {
  state = {
    showChannelAddMenuItem: false,
    showChannelUpdateMenuItem: false
  };

  updatePosition = (a, b) => {
    this.props.updateChannelMenuPosition(this.props.channel, a.id, {position: b.position})
        .then(() => this.props.onChange())
  }

  render() {
    return (
        <Fragment>
          <div className="menu-item">
            <div className={"d-flex"}>
              <span className={"mr-2"} style={{ backgroundColor: this.props.data.color, width: '8px', height: '8px', borderRadius: '50px', marginTop: '14px'}}></span>
              <h6 className="py-1">{this.props.data.name}
                <button
                    onClick={() => this.props.updatePositionUp()}
                    disabled={this.props.disableUpBtn}
                    className="btn btn-sm btn-primary ml-2">
                  <i className="fas fa-arrow-up"></i>
                </button>

                <button
                    onClick={() => this.props.updatePositionDown()}
                    disabled={this.props.disableDownBtn}
                    className="btn btn-sm btn-primary ml-1">
                  <i className="fas fa-arrow-down"></i>
                </button>

                <button
                    className="btn btn-sm btn-primary ml-1"
                    onClick={() => this.setState({ showChannelUpdateMenuItem: true })}>
                  <i className="fas fa-pen"></i>
                </button>

                <button
                    onClick={() => this.setState({showChannelAddMenuItem: true})}
                    className="btn btn-sm btn-primary ml-1">
                  <i className="fas fa-plus"></i>
                </button>

                <button onClick={() => {
                  this.props.deleteChannelMenu(this.props.channel, this.props.data.id)
                      .then(() => this.props.onChange());
                }}
                        className="btn btn-sm btn-danger ml-1">
                  <i className="fas fa-trash-alt"></i>
                </button>
              </h6>

            </div>

            <div>
              <div className="ml-5 mt-3 pl-3" style={{ borderLeft: "3px solid #eee" }}>
                {this.renderChildren()}
              </div>
            </div>
          </div>

          {this.state.showChannelAddMenuItem &&
          <ChannelAddMenuItem
              channel={this.props.channel}
              parent={this.props.data.id}
              onClose={created => {
                this.setState({ showChannelAddMenuItem: false });
                if (created) this.props.onChange();
              }}
          />}
          {this.state.showChannelUpdateMenuItem &&
          <ChannelUpdateMenuItem
              data={this.props.data}
              channel={this.props.channel}
              onClose={updated => {
                this.setState({ showChannelUpdateMenuItem: false });
                if (updated) {
                  this.props.onChange();
                }
              }}
          />}
        </Fragment>
    );
  }

  renderChildren() {
    const items = this.props.items.filter(
        item => item.parent === this.props.data.id
    );

    if (items.length > 0)
      return (
          <div className="menu-items">
            {items.map((child, i) => (
                <ConnectedChannelMenuItem
                    items={this.props.items}
                    data={child}
                    channel={this.props.channel}
                    onChange={() => {this.props.onChange()}}
                    updatePositionUp={() => this.updatePosition(child, items[i-1])}
                    updatePositionDown={() => this.updatePosition(child, items[i+1])}
                    disableUpBtn={i === 0}
                    disableDownBtn={i === items.length-1}/>
            ))}
          </div>
      );

    return null; // <p className="pt-3 pb-2">Aucun enfant</p>;
  }
}

const ConnectedChannelMenuItem = connect(
    ({ channel_entity }) => ({ channel_entity }),
    { deleteChannelMenu, updateChannelMenuPosition }
)(ChannelMenuItem);

export default ConnectedChannelMenuItem;
