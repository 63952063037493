import moment from 'moment-timezone';
import { request } from "../helpers";

// Actions

const ENTITY_ARTICLE_RSS_INDEX = "ENTITY_ARTICLE_RSS_INDEX";
const ENTITY_ARTICLE_RSS_INDEX_SUCCESS = "ENTITY_ARTICLE_RSS_INDEX_SUCCESS";
const ENTITY_ARTICLE_RSS_INDEX_FAIL = "ENTITY_ARTICLE_RSS_INDEX_FAIL";
const ENTITY_USER_CATEGORIES = "ENTITY_USER_CATEGORIES";

export function fetchAllRssArticles(q_params) {
    return request(ENTITY_ARTICLE_RSS_INDEX, "get", `/rssarticles?${q_params}`);
}
export function fetchUserFavoriteCategories(userID) {
    return request(ENTITY_USER_CATEGORIES, "get", `/showFavoriteCategories?${userID}`);
}

// Store

const defaultState = {
    index: null,
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityArticleReducer(state = defaultState, action) {

    switch (action.type) {
        case ENTITY_ARTICLE_RSS_INDEX:
            return { ...state, loading: true };
        case ENTITY_ARTICLE_RSS_INDEX_FAIL:
            return { ...state, loading: false };
        case ENTITY_ARTICLE_RSS_INDEX_SUCCESS:
            return {
                ...state,
                loading: false,
                index: action.payload.data,
                lastUpdate: moment()
            };
    }
    return state;
}
