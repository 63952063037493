import React, {Component} from 'react';
import ColorPicker from "../../components/ColorPicker";

class BlockButton extends Component
{
    state = {
        text: this.props.target.data.text,
        link: this.props.target.data.link,
        text_color: this.props.target.data.text_color ? this.props.target.data.text_color : '#FFFFFF',
        background_color: this.props.target.data.background_color ? this.props.target.data.background_color : '#007bff'
    };

    onChange(type, value) {
        this.setState({[type]: value}, () => {
            this.props.onChange(this.state);
        })
    }

    render() {
        const labelStyle = {
            marginBottom: '15px'
        };

        return (
            <div className={'block-button'}>
                <div className="row">
                    <div className="col-6">
                        <input
                            type="text"
                            value={this.state.text}
                            onChange={e => this.onChange('text', e.target.value)}
                            placeholder="Texte"
                            className="form-control d-inline-block ml-2"
                        />
                    </div>
                    <div className="col-6">
                        <input
                            type="text"
                            value={this.state.link}
                            onChange={e => this.onChange('link', e.target.value)}
                            placeholder="Lien de redirection (ex: https://air-cosmos.com/ ou /home)"
                            className="form-control d-inline-block ml-2"
                        />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-6 text-center">
                        <label style={labelStyle}>Couleur du texte</label>
                        <ColorPicker
                            value={this.state.text_color}
                            onChange={color => this.onChange('text_color', color)}
                        />
                    </div>
                    <div className="col-6 text-center">
                        <label style={labelStyle}>Couleur du bouton</label>
                        <ColorPicker
                            value={this.state.background_color}
                            onChange={color => this.onChange('background_color', color)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default BlockButton;
