import React, { Component } from 'react'
import Modal from "../../../components/Modal"

import "./ArticleOverviewModal.scss"
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import ContentLoader from "../../../components/ContentLoader";
import {previewArticle} from "../../../data/entities/Article";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {MASTER_API_ENDPOINT} from "../../../config/parameters";


class ArticleOverviewModal extends Component
{
    state = {
        loading: true,
        data: null,
        ready: false
    }

    componentDidMount = () => this._load()

    _load = () => {
        this.setState({ loading: true })

        this.props.previewArticle(this.props.data.id)
            .then(res => {
                this.setState({ loading: false, data: res.payload.data, ready: true })
            })
            .catch(e => {
                if (e.error && e.error.response && e.error.response.data) {
                    if (e.error.response.data.error) toast.error(<FormattedMessage id={e.error.response.data.error} />)
                    else if (e.error.response.data.message) toast.error(<FormattedMessage id={e.error.response.data.message} />)
                }
                this.setState({ loading: false })
            })
    }

    render()
    {
        const {data} = this.state;
        const endpoint = localStorage.getItem('API_ENDPOINT').slice(0, -3);

        return (
            <Modal title={!data ? "Article" : data.article.title}
                   additionnalClass="article-overview-modal"
                   onClose={() => this.props.onClose(false)}>
                <div className={'head-options mb-3'}>
                    {this.props.link ? (
                        <Link to={`/entities/article/${this.props.data.id}`}>
                            <i className="fa fa-eye mr-2"/>
                            Voir toutes les informations concernant cet article
                            <i className="fa fa-angle-right ml-2"/>
                        </Link>
                    ) : null}
                    <a
                        className={'btn btn-primary float-right'}
                        href={endpoint + 'article/download_pdf/' + this.props.data.id}
                        target={'_blank'}
                    >
                        {
                            this.state.loading ?
                                <i className={'fa fa-circle-notch fa-spin'}/>
                                :
                                <>Télécharger en .PDF</>
                        }
                    </a>
                </div>
                {!this.state.ready ? <ContentLoader/> : this.renderPreview()}
            </Modal>
        )
    }

    renderPreview()
    {
        const {data} = this.state
        const {article} = data

        return (
            <div className="article-preview">
                {article.category ? (
                    <div className="category" style={{ backgroundColor: article.category.color }}>
                        <h5>{article.category.name}</h5>
                    </div>
                ) : null}
                {article.cover ? (
                    <div className="cover">
                        <img src={article.cover.file} alt={article.title}/>
                        <span className="copyright">&copy; {article.cover.copyright}</span>
                        <div className="blur-cover" style={{ backgroundImage: `url(${article.cover.file})`}}/>
                    </div>
                ) : null}
                <div className="metas">
                    <p style={{ color: article.category ? article.category.color : 'white' }}>
                        <span className="author">
                            <a href="#">{article.author_name ? article.author_name : (article.author_email ? article.author_email : "La Rédaction")}</a>
                        </span>
                    </p>
                </div>
                <h1 className="title mb-3">{article.title}</h1>
                {article.hat ? <p><strong>{article.hat}</strong></p> : null}
                <div className="blocks">
                    {this.renderBlocks()}
                </div>
            </div>
        )
    }

    renderBlocks(parent = null)
    {
        const {data} = this.state
        if (!data) return null

        return data.blocks.filter(b => b.parent == parent).map(block => this.renderBlock(block))
    }

    renderBlock(block)
    {
        const {data} = this.state

        switch (block.type)
        {
            case 'ARTICLE_BLOCK_ARTICLE':
                if (!block.article) return null
                return (
                    <div className="block-article-preview">
                        <a href="#">
                            <header>{block.article.title}</header>
                            <footer>
                                Voir l'article <i className="fa fa-angle-right"/>
                            </footer>
                        </a>
                    </div>
                )
                break;
            case 'ARTICLE_BLOCK_MEDIA':
                return (
                    <div className="block-media-preview">
                        <figure>
                            {this.renderMedia(block)}
                        </figure>
                    </div>
                )
                break;
            case 'ARTICLE_BLOCK_COLUMN':
                return (
                    <div className="block-column-preview">{this.renderBlocks(block.id)}</div>
                )
                break;
            case 'ARTICLE_BLOCK_ROW':
                return (
                    <div className="block-row-preview row">
                        {data.blocks.filter(b => b.parent == block.id).map(child => (
                            <div className="block-row-col col">
                                {this.renderBlock(child)}
                            </div>
                        ))}
                    </div>
                )
                break;
            case 'ARTICLE_BLOCK_HTML':
            case 'ARTICLE_BLOCK_EMBED':
                return (
                    <div className="block-html-preview" dangerouslySetInnerHTML={{ __html: block.data.html ? block.data.html : '' }}/>
                )
                break;
        }

        return <div className="alert alert-danger">Unknown block</div>
    }

    renderMedia(data)
    {
        if (!data.media) return null

        if (data.media.nature == 'image') {
            return (
                <>
                    <img src={data.media.file} alt={data.media.label}/>
                    <figcaption>{data.label} &copy; {data.media.copyright}</figcaption>
                </>
            )
        }

        if (data.media.nature == 'video') {
            return (
                <>
                    <video src={data.media.file_proxy} type={data.media.type} controls/>
                    <figcaption>{data.label} &copy; {data.media.copyright}</figcaption>
                </>
            )
        }

        if (data.media.nature == 'audio') {
            return (
                <>
                    <audio src={data.media.file_proxy} type={data.media.type} controls/>
                    <figcaption>{data.label} &copy; {data.media.copyright}</figcaption>
                </>
            )
        }

        return (
            <a href="#">
                <p className="mb-0"><i className="fa fa-download"/> {data.label} (&copy; {data.media.copyright})</p>
                <p className="text-muted">Cliquer pour télécharger ce fichier.</p>
            </a>
        )
    }
}

export default connect(null, {previewArticle})(ArticleOverviewModal)
