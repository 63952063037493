import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Form,
    Submit,
    Input, OptionGroup, Option
} from "react-forms";
import { toast } from "react-toastify";
import moment from 'moment-timezone';

import { updateNewsletter } from "../../../data/entities/Newsletter";
import {Nav, NavItem, NavLink, TabContent, TabPane, Button} from "reactstrap";
import Datepicker from "../../../components/Datepicker";
import NewsletterArticles from "./NewsletterArticles";
import NewsletterCategories from "./NewsletterCategories";
import FrequencyEditor from "./FrequencyEditor";

import './NewsletterUpdateForm.scss';
import {checkPrivilege} from "../../../helpers";
import Autocomplete from "../Article/Autocomplete";
import { searchAll as searchOffers } from '../../../data/entities/Offer';
import NewsletterArticleHighlighted from "./NewsletterArticleHighlighted";
import NewsletterArticlesModal from "./NewsletterArticlesModal";

class NewsletterUpdateForm extends Component {
    state = {
        _formData: null,
        _loading: false,

        name: this.props.data.name,
        manual: this.props.data.manual,
        schedule: this.props.data.schedule ? moment(this.props.data.schedule) : moment(),
        publication_status: !!this.props.data.publication_status,
        is_default: !!this.props.data.is_default,
        sender_email: this.props.data.sender_email,
        sender_name: this.props.data.sender_name,

        // Manual
        schedule_date: this.props.data.schedule_date ? moment(this.props.data.schedule_date) : moment(),

        // Automatic
        frequency: this.props.data.frequency ? this.props.data.frequency : 'daily',
        frequency_data: this.props.data.frequency_data ? JSON.parse(this.props.data.frequency_data) : [],
        articles_count: this.props.data.articles_count ? this.props.data.articles_count : 1,
        max_article_age: this.props.data.max_article_age ? this.props.data.max_article_age : 7,

        has_offer: this.props.data.offer !== null,
        offer: this.props.data.offer,
        offers: [],
        offer_query: '',

        highlight: null,

        displayArticlesModal: false
    };

    _submit = () => {
        const s = this.state

        let data = {
            name: s.name,
            manual: s.manual ? 1 : 0,
            schedule: s.schedule ,//.format('HH:mm'),
            publication_status: s.publication_status ? 1 : 0,
            is_default: s.is_default ? 1 : 0,
            highlight: this.state.highlight ? this.state.highlight.id : null,
            sender_email: s.sender_email,
            sender_name: s.sender_name
        };

        if (s.manual) {
            data.schedule_date = s.schedule_date.format('YYYY-MM-DD')
        }
        else {
            data.frequency = s.frequency
            data.frequency_data = JSON.stringify(s.frequency_data)
            data.articles_count = parseInt(s.articles_count)
            data.max_article_age = parseInt(s.max_article_age)
        }

        if (this.state.has_offer && this.state.offer) {
            data.offer = this.state.offer.id
        }

        this.setState({ _loading: true });

        this.props
            .updateNewsletter(this.props.data.id, data)
            .then(res => {
                toast.success("Modifications appliquées.");
                this.setState({ _loading: false, _formData: null })
                this.props.onClose(res.payload.data);
            })
            .catch(e => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data });
                this.setState({ _loading: false });
            });
    };

    render() {

        return (
            <Form onSubmit={this._submit} data={this.state._formData}>
                <Input
                    value={this.state.name}
                    onChangeValue={name => this.setState({ name })}
                    label="Nom de la newsletter"
                    type="text"
                />

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={this.state.manual ? "active" : ""}
                            onClick={() => { this.setState({ manual: true }) }}>
                            Manuelle
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.manual ? "" : "active"}
                            onClick={() => { this.setState({ manual: false }) }}>
                            Automatique
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="p-3 newsletter-tabs">
                    <TabContent activeTab={this.state.manual ? "1" : "2"}>
                        <TabPane tabId="1">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6>Articles</h6>
                                    <p className="text-muted">La modification de cette liste est instantanée et irréversible.</p>
                                    <NewsletterArticles editable={checkPrivilege(this.props.user, 'newsletter:edit')} id={this.props.data.id} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="input">Prochaine date d'envoi</label>
                                    <Datepicker
                                        type="date"
                                        selectedDate={this.state.schedule_date}
                                        handleDateChange={(schedule_date) => this.setState({ schedule_date })}
                                    />

                                    <br/>

                                    <label htmlFor="input">Heure d'envoi</label>
                                    <Datepicker
                                        type="time"
                                        selectedDate={this.state.schedule}
                                        handleDateChange={(schedule) => this.setState({ schedule })}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <Button color={'warning'}
                                            onClick={this.toggleArticlesModal}
                                    >Voir tous les articles</Button>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6>Article mis en avant</h6>
                                    <p className="text-muted">Vous avez la possibilité de mettre un article en avant</p>
                                    <NewsletterArticleHighlighted
                                        editable={checkPrivilege(this.props.user, 'newsletter:edit')}
                                        id={this.props.data.id}
                                        updateHighlight={(highlight) => this.setState({ highlight })}
                                    />

                                    <h6>Catégories</h6>
                                    <p className="text-muted">La modification de cette liste est instantanée et irréversible.</p>
                                    <NewsletterCategories editable={checkPrivilege(this.props.user, 'newsletter:edit')} id={this.props.data.id} />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        placeholder="0"
                                        label="Nombre d'articles par catégorie"
                                        type="number"
                                        value={this.state.articles_count}
                                        onChangeValue={(articles_count) => this.setState({ articles_count })}
                                    />

                                    <Input
                                        placeholder="0"
                                        label="Age maximum des articles (jours)"
                                        type="number"
                                        value={this.state.max_article_age}
                                        onChangeValue={(max_article_age) => this.setState({ max_article_age })}
                                    />

                                    <OptionGroup
                                        onChangeValue={(frequency) => this.setState({ frequency, frequency_data: [] })}
                                        value={this.state.frequency}
                                        label="Fréquence d'envoi"
                                    >
                                        <Option value="daily">Journalière</Option>
                                        <Option value="weekly">Hebdomadaire</Option>
                                        <Option value="monthly">Mensuelle</Option>
                                    </OptionGroup>

                                    <FrequencyEditor
                                        type={this.state.frequency}
                                        data={this.state.frequency_data}
                                        onChangeData={(frequency_data) => this.setState({ frequency_data })}
                                    />

                                    <label htmlFor="input">Heure d'envoi</label>
                                    <Datepicker
                                        type="time"
                                        selectedDate={this.state.schedule}
                                        handleDateChange={(schedule) => this.setState({ schedule })}
                                    />
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </div>

                <hr />

                <div className="row">
                    <div className="col">
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                onChange={() => this.setState({ has_offer: !this.state.has_offer })}
                                checked={!!this.state.has_offer}
                                className="custom-control-input" id="has_offer"/>
                            <label className="custom-control-label" htmlFor="has_offer">
                                Afficher une offre d'emploi
                                {this.state.has_offer && this.state.offer ? (
                                    <strong className="ml-2">: {this.state.offer.name}</strong>
                                ) : null}
                            </label>
                        </div>
                        {this.renderOfferPicker()}
                    </div>
                </div>

                <br/>

                <div className="row">
                    <div className="col">
                        <Input
                            value={this.state.sender_email}
                            onChangeValue={sender_email => this.setState({ sender_email })}
                            label="Email d'un expéditeur"
                            type="text"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Input
                            value={this.state.sender_name}
                            onChangeValue={sender_name => this.setState({ sender_name })}
                            label="Nom d'un expéditeur"
                            type="text"
                        />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col">
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                onChange={() => this.setState({ publication_status: !this.state.publication_status })}
                                checked={!!this.state.publication_status}
                                className="custom-control-input" id="publication_status"/>
                            <label className="custom-control-label" htmlFor="publication_status">Newsletter activée</label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                onChange={() => this.setState({ is_default: !this.state.is_default })}
                                checked={!!this.state.is_default}
                                className="custom-control-input" id="is_default"/>
                            <label className="custom-control-label" htmlFor="is_default">Newsletter par défaut</label>
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right mb-5">
                            <Submit disabled={this.state._loading || !checkPrivilege(this.props.user, 'newsletter:edit')}>Sauvegarder</Submit>
                        </div>
                    </div>
                </div>

                {this.state.displayArticlesModal ?
                    <NewsletterArticlesModal
                        onClose={this.toggleArticlesModal}
                        updated={() => this.updated()}
                        id={this.props.data.id}
                    />
                    : null
                }
            </Form>
        );
    }

    renderOfferPicker()
    {
        if (!this.state.has_offer)
            return null

        return (
            <span className="bg-light d-inline-block ml-5">
                <Autocomplete
                    createEntity={false}
                    selectEntity={(id, offer) => {
                        this.setState({ offers: [], offer_query: '', offer })
                    }}
                    escape={() => {
                        this.setState({ offers: [], offer_query: '' })
                    }}
                    query={this.state.offer_query}
                    renderDropdownItem={(item) => item.name}
                    className="border-secondary border-1"
                    placeholder="Sélectionner une offre..."
                    queryResponse={this.state.offers}
                    handleChange={(e) => {
                        this.setState({ offer_query: e.target.value })
                        if (!e.target.value.length) {
                            this.setState({ offers: [] })
                        }
                        else {
                            this.props.searchOffers(e.target.value).then((res) => {
                                this.setState({ offers: res.payload.data.slice(0, 14) })
                            })
                        }
                    }}
                />
            </span>
        )
    }

    toggleArticlesModal = () => {
        this.setState({ displayArticlesModal: !this.state.displayArticlesModal });
    };

    updated = () => {
        this.toggleArticlesModal();
        this.props.updated();
    };
}

export default connect(
    ({user})=>({user}),
    { updateNewsletter, searchOffers }
)(NewsletterUpdateForm);
