import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import CategoryCreateModal from "./CategoryCreateModal";

import { fetchAll, searchAll } from "../../../data/entities/Category";
import TrCategoryModal from "./TrCategoryModal";

// import CategoryUpdateModal from "./CategoryViewPage";
// import CategoryUpdateModal from "./CategoryUpdateModal";
// import CategoryDeleteModal from "./CategoryDeleteModal";

class CategoryIndexPage extends Component {
  state = {
    search: "",
    showCategoryCreateModal: false
  };

  _search = search => {
    this.setState({ search }, () => {
      this._fetch();
    });
  };

  _fetch = () => {
    if (this.state.search.length) this.props.searchAll(this.state.search);
    else this.props.fetchAll();
  };

  componentDidMount() {
    this.props.fetchAll();
  }

  render() {
    return (
        <Container>
          <Helmet title="Catégories" />
          <Row className="pb-5 pt-3">
            <Col>
              <h1>Catégories</h1>
            </Col>
            <Col className="text-right">
              <Loader display={this.props.category_entity.loading} />
              <input
                  type="text"
                  value={this.state.search}
                  onChange={e => this._search(e.target.value)}
                  placeholder="Rechercher..."
                  className="form-control d-inline-block ml-2"
                  style={{ maxWidth: 200 }}
              />
              <button
                  onClick={() => this.setState({ showCategoryCreateModal: true })}
                  className="btn btn-primary ml-2"
              >
                <i className="fa fa-plus" />
              </button>
            </Col>
          </Row>
          {this.renderEntities()}
          {this.state.showCategoryCreateModal ? (
              <CategoryCreateModal
                  onClose={created => {
                    if (created) this._fetch();
                    this.setState({ showCategoryCreateModal: false });
                  }}
              />
          ) : null}
        </Container>
    );
  }

  renderEntities() {
    if (
        this.props.category_entity.loading &&
        !this.props.category_entity.lastUpdate
    )
      return <ContentLoader />;

    if (!this.props.category_entity.index.length) {
      return <Empty />;
    }
    return (
        <Fragment>
          <table className="table">
            <thead>
            <tr>
              <th>Nom</th>
              <th>Couleur</th>
              <th className="text-right">Actions</th>
            </tr>
            </thead>
            <tbody>
            {this.props.category_entity.index.map((entity) => {
              console.log(entity)
              return (
                  <TrCategoryModal
                      onChange={() => this._fetch()}
                      data={entity}
                  />
              )
            })}
            </tbody>
          </table>
        </Fragment>
    );
  }
}

export default connect(
    ({ category_entity }) => ({ category_entity }),
    { fetchAll, searchAll }
)(CategoryIndexPage);
