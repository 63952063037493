import React, {Component} from 'react';
import {Card, CardBody, CardTitle} from "reactstrap";
import "./CategoryCard.scss";

class CategoryCard extends Component
{
    render() {

        const { category } = this.props;

        const style = category.color ? { backgroundColor: category.color } : {};

        return(
                <div
                    className={"col-md-3 mb-3 category-card"}
                    key={'category-' + category.id}
                    onClick={() => this.props.selectCategory(category.id)}
                >
                    <Card className={this.props.selected ? 'selected' : ''}>
                        <CardBody>
                            <CardTitle className={'mb-3 text-center'}>{category.name}</CardTitle>
                            <div className="color-container" style={style}> </div>
                        </CardBody>
                    </Card>
                </div>
        )
    }
}

export default CategoryCard;
