import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, Input} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import Modal from "../../../components/Modal";
import {createUser, fetchUser} from "../../../data/entities/User";
import {fetchAll} from '../../../data/entities/Role';
import {checkPrivilege} from "../../../helpers";
import ContentLoader from "../../../components/ContentLoader";

class UserCreateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        email: null,
        firstname: null,
        lastname: null,
        pseudo: null,
        password: null,
        roles: null,
        role: null
    };

    componentDidMount() {
        this.props.fetchAll()
            .then((res) => {
                this.setState({ roles: res.payload.data });
                if (res.payload.data.length > 0)
                    this.setState({ role: res.payload.data[0].id })
            })
            .catch((e) => {
                if (
                    e.error &&
                    e.error.response &&
                    e.error.response.data &&
                    e.error.response.data.error
                )
                    toast.error(<FormattedMessage id={e.error.response.data.error} />);
            });
    }

    _submit = () => {
        this.setState({ _loading: true });

        const data = {...this.state};
        delete data._loading;
        delete data._formData;

        this.props.createUser(data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />);
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data) {

                    let data = e.error.response.data;

                    this.setState({ _formData: data })

                    if (data.errors) {
                        Object.keys(data.errors).map((key) => {
                            return toast.error(<FormattedMessage id={data.errors[key].error}/>)
                        })
                    }
                }
                this.setState({ _loading: false })
            })
    };

    render()
    {
        const editable = checkPrivilege(this.props.user, 'user:edit');

        return (
            <Modal title={"Nouvel utilisateur"} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={() => this._submit()} className="edit-form" data={this.state._formData}>

                    {this.state.roles ?
                        this.renderRoles()
                        :
                        <ContentLoader/>
                    }

                    <Input
                        value={this.state.email}
                        onChangeValue={(email) => this.setState({email})}
                        label="Adresse e-mail" type="text" required/>

                    <Input
                        value={this.state.firstname} onChangeValue={(firstname) => this.setState({firstname})}
                        label="Prénom" type="text" disabled={!editable}/>

                    <Input
                        value={this.state.lastname} onChangeValue={(lastname) => this.setState({lastname})}
                        label="Nom de famille" type="text" disabled={!editable}/>

                    <Input
                        value={this.state.pseudo} onChangeValue={(pseudo) => this.setState({pseudo})}
                        label="Pseudo" type="text" disabled={!editable}/>

                    <Input
                        value={this.state.password} onChangeValue={(password) => this.setState({password})}
                        label="Mot de passe" type="password" required/>

                    {editable ? <Submit disabled={this.state.loading} className="btn btn-primary">Sauvegarder</Submit> : null}
                </Form>
            </Modal>
        )
    }

    renderRoles = () => {

        const {roles} = this.state;

        return(
            <>
                <label htmlFor="role">Rôle</label>
                <select name={'role'} id={'role'} className={"custom-select mb-3"} style={{borderWidth: '2px'}}
                        onChange={(e) => this.setState({role: parseInt(e.target.value)})}
                >
                    {roles.map((role) =>  {
                        return (
                            <option value={role.id}>{role.label}</option>
                        )
                    })}
                </select>
            </>
        )
    }
}

export default connect(({ user, roles }) => ({ user, roles }), { fetchUser, createUser, fetchAll })(UserCreateModal)
