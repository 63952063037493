export function request(type, method, url, data = {}, meta = {}) {
    return {
        type: type,
        payload: {
            request: {
                url: url,
                method: method,
                data: data
            }
        },
        ...meta
    }
}

export function isUrl(str) {
    const urlRegex = /^(https?:\/\/)?(www\.)?[^\s/$.?#].[^\s]*$/i;
    // check if the input string matches the URL regex and contains at least 2 occurrences of "." if it contains "www"
    if (urlRegex.test(str)) {
        if (str.includes("www") && str.split(".").length - 1 < 2) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

export function checkPrivilege(user, privileges) {
    const roles = user.profile.roles
    const user_privileges = user.profile.privileges

    return (Array.isArray(privileges) ? (
        (privileges.map((privilege) => user_privileges.indexOf(privilege) !== -1)).indexOf(false) === -1
    ) : (
        user_privileges.indexOf(privileges) !== -1
    )) || roles.indexOf('ROLE_ADMIN') !== -1
}

export function getIntervals()
{
    return [
        { id: 'day', value: 'Jour' },
        { id: 'week', value: 'Semaine' },
        { id: 'month', value: 'Mois' },
        { id: 'year', value: 'An' },
    ];
}
