import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { checkPrivilege } from '../../../helpers'
import Autocomplete from './Autocomplete'

import { searchAll } from '../../../data/entities/Tag'
import { createArticleTag, fetchArticleTags, deleteArticleTag } from '../../../data/entities/Article'
import { createTag } from '../../../data/entities/Tag'

class ManageTag extends Component {

    state = {
        articleData: this.props.data,
        query: "",
        queryResponse: [],
        tags: [],
        isSelectingTag: false,
        isCreatingTag: false,

        _formData: null
    }


    componentDidMount() {
        this._fetchArticleTags()
    }

    _fetchArticleTags = () => {
        this.props.fetchArticleTags(this.state.articleData.id)
            .then(res => this.setState({
                tags: res.payload.data,
                query: "",
                queryResponse: [],
                isSelectingTag: false,
                isCreatingTag: false,
            }))
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data, isSelectingTag: false })
            })
    }

    _createArticleTag = (articleId, tagId) => {

        this.setState({ isSelectingTag: true })
        this.setState({ isCreatingTag: true })

        this.props.createArticleTag(articleId, tagId)
            .then(res => {
                if (res.payload.data) {
                    this._fetchArticleTags()
                }})
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
            })
    }


    handleChange = (e) => {
        this.setState(
            {
                query: e.target.value
            },
            () => this.searchTag()
        )
    }

    searchTag = () => {
        if (this.state.query.length > 0) {
            this.props.searchAll(this.state.query)
                .then(res => this.setState({queryResponse: res.payload.data.slice(0, 14) }))
        } else if (this.state.query.length === 0) {
            this.setState({ queryResponse: [] })
        }
    }

    selectTag = (id, entity) => {
        let tagsNames = []; //tagsNames.includes(tagWithID.tag.name.toLowerCase())
        this.state.tags.map(tagWithID => tagsNames.push(tagWithID.tag.name.toLowerCase()))
        
        // console.log('+++++++ ha hyaa l\'entity', entity); //OOOOOOOK

        if (this.state.tags.find(tag => tag.tag.name.toLowerCase() === entity.name.toLowerCase()) === undefined) {
            !this.state.isSelectingTag && this._createArticleTag(this.state.articleData.id, id)
        }
        document.getElementById('add-tag-input').focus()
    }

    createTag = () => {
        if (!checkPrivilege(this.props.user, 'tag')) {
            return false
        } else {

            if (this.state.query.length && !this.state.queryResponse.find((entity) => entity.name.toLowerCase() === this.state.query.toLowerCase())) {
                    this.props.createTag({name: this.state.query.toLowerCase()})
                        .then(res => {if (res.payload.data) {
                            !this.state.isCreatingTag && this._createArticleTag(this.state.articleData.id, res.payload.data.id)
                        }})
                    document.getElementById('add-tag-input').focus()
            } else if (this.state.queryResponse.find((entity) => entity.name.toLowerCase() === this.state.query.toLowerCase())) {
                return false
            }
            return true;

        }
    }

    deleteArticleTag = (tagId) => {
        this.props.deleteArticleTag(this.state.articleData.id, tagId)
            .then(res => {if (res.payload.data.deleted) {
                this._fetchArticleTags()
            }})
    }

    renderDropdownItemTag = (tag) => {
        return (
            <span> { tag.name.length > 50 ? `${tag.name.slice(0, 45)}...` : tag.name } </span>
        )
    }

    render() {
        console.log('TAGS', this.state.tags)
        console.log("check privileges", checkPrivilege(this.props.user, 'tag'))
        return (
            <div className={"entity-list"}>
                {
                    this.state.tags.map(entity => (
                        <div className={"btn btn-sm btn-primary m-1"}>
                            {entity.tag.name}
                            <i onClick={() => this.deleteArticleTag(entity.id)}
                               className="fas fa-times ml-2"/>
                        </div>
                    ))
                }
                <Autocomplete
                    article={this.state.articleData}
                    query={this.state.query}
                    queryResponse={this.state.queryResponse}
                    handleChange={this.handleChange}
                    escape={() => this.setState({ query: "", queryResponse: [] })}
                    createEntity={() => this.createTag()}
                    selectEntity={(id, entity) => this.selectTag(id, entity)}
                    renderDropdownItem={(tag) => this.renderDropdownItemTag(tag)}
                    placeholder="Ajouter un tag"
                    className={"tag"}
                />
            </div>
        )
    }
}

export default connect(
    ({ user }) => ({ user }),
    {
        searchAll,
        createArticleTag,
        fetchArticleTags,
        deleteArticleTag,
        createTag
    })(ManageTag)
