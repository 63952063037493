import {request} from '../helpers'

// Actions

const CURATION_FETCH_PARTNERS = 'CURATION_FETCH_PARTNERS'
const CURATION_FETCH_PARTNERS_SUCCESS = 'CURATION_FETCH_PARTNERS_SUCCESS'

export function getPartners()
{
    return request(CURATION_FETCH_PARTNERS, 'get', '/curation/partners')
}

const CURATION_FETCH_ARTICLES = 'CURATION_FETCH_ARTICLES'
const CURATION_FETCH_DRAFT_RSS_ARTICLES = 'CURATION_GET_ARTICLES'

export function getArticles(search, platform, published_before)
{
    return request(CURATION_FETCH_ARTICLES, 'post', '/curation/search', { search, platform, published_before })
}
const CONTRIBUTION_ARTICLES_SEARCH = 'CONTRIBUTION_ARTICLES_SEARCH'

export function getArticlesSearch(search)
{
    return request(CONTRIBUTION_ARTICLES_SEARCH, 'post', '/contribution/search', { search })
}
const CONTRIBUTION_ARTICLES = 'CONTRIBUTION_ARTICLES'

export function getArticlesExpert(limit)
{
    return request(CONTRIBUTION_ARTICLES, 'post', `/articles-expert/search?limit=${limit}`)
}
export function getRssArticles(limit)
{
    return request(CONTRIBUTION_ARTICLES, 'post', `/rss-articles/search?limit=${limit}`)
}
export function getDraftRssArticles()
{
    return request(CURATION_FETCH_DRAFT_RSS_ARTICLES, 'get', '/curation/drafted-rss-articles')
}

export function getUnpublishedArticles()
{
    return request(CURATION_FETCH_ARTICLES, 'get', '/curation/unpublished-art-articles')
}


const CURATION_IMPORT_ARTICLE = 'CURATION_IMPORT_ARTICLE'

export function importArticle(platform, article)
{
    return request(CURATION_IMPORT_ARTICLE, 'post', '/curation/import', { platform, article })
}

// Store

const defaultState = {
    partners: null,
    partnerships: null,
}

// Reducer

export default function CurationReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case CURATION_FETCH_PARTNERS_SUCCESS:
            if (!Array.isArray(action.payload.data)) return state

            let partners = []
            let partner_ids = []

            action.payload.data.map((partnership) => {
                partnership.partners.map((partner) => {
                    if (partner_ids.indexOf(partner.id) === -1) {
                        partners.push(partner)
                        partner_ids.push(partner.id)
                    }
                })
            })

            return {
                ...state,
                partnerships: action.payload.data,
                partners
            }
    }
    return state
}
