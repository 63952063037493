import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Submit, Input } from "react-forms";
import Modal from "../../../components/Modal";

import { updateChannelMenu } from "../../../data/entities/ChannelMenu";
import { fetchAll as fetchAllCategories } from "../../../data/entities/Category"
import { fetchAll as fetchAllPages } from "../../../data/entities/StaticPage"
import ColorPickerModal from "../Category/ColorPickerModal";

class ChannelUpdateMenuItem extends Component {
    state = {
        name: this.props.data.name,
        status: this.props.data.status,
        parent: this.props.data.parent,
        category: this.props.data.category ? this.props.data.category.id : null,
        page: this.props.data.page ? this.props.data.page.id : null,
        url: this.props.data.url,
        color: this.props.data.color ? this.props.data.color : '#58ffbd',

        categories: [],
        pages: [],

        _formData: null,
        _loading: false,
        showColorPickerModal: false

    };

    componentDidMount() {
        console.log('mount', this.props.data)
        this.props.fetchAllCategories()
            .then(res => this.setState({ categories: res.payload.data}))

        this.props.fetchAllPages()
            .then(res => this.setState({pages: res.payload.data}))
    }


    _submit = () => {
        this.setState({ _loading: true });

        const data = { ...this.state };
        delete data._loading;
        delete data._formData;
        delete data.categories;
        delete data.pages;

        if (this.state.url == null && this.state.category == null && this.state.page == null) {
            this.setState({ _formData: "Incomplete Form", _loading: false })
            return;
        }

        if (this.state.name.length) {
            this.props
                .updateChannelMenu(this.props.channel, this.props.data.id, data)
                .then(res => {
                    this.props.onClose(true);
                })
                .catch(e => {
                    if (e.error && e.error.response && e.error.response.data)
                        this.setState({ _formData: e.error.response.data });
                    this.setState({ _loading: false });
                });
        }
    };

    render() {
        console.log("STATE", this.state)

        const { category, page, url } = this.state
        return (
            <Modal
                title="Modifier un élément"
                onClose={() => this.props.onClose(false)}
            >
                <Form onSubmit={this._submit} data={this.state._formData}>

                    <p className="pt-3 pb-3 font-weight-bold">Cible</p>

                    <div className="ml-3">
                        {this.state._formData === "Incomplete Form" &&
                        <div className="alert alert-warning" role="alert">
                            Veuillez séléctionner une option.
                        </div>
                        }

                        <div className="my-3">
                            <input
                                onClick={() => this.setState({
                                    url: null,
                                    category: null,
                                    page: null,
                                    _formData: ""
                                })}
                                checked={category === null && page === null && url === null}
                                type="radio" name="cible" aria-label="aucun" style={{marginLeft: '13px', marginRight: '14px'}}/>
                            <label htmlFor="aucun">Aucun</label><br/>
                        </div>

                        <span>Page web</span>
                        <div className="input-group mb-4 mt-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <input
                                        onClick={() => this.setState({
                                            url: "",
                                            category: null,
                                            page: null,
                                            _formData: ""
                                        })}
                                        checked={url !== null}
                                        name="cible" type="radio"/>
                                </div>
                            </div>
                            <input
                                onFocus={(e) => {
                                    this.setState({
                                        url: this.props.data.url ? this.props.data.url : "",
                                        category: null,
                                        page: null,
                                        _formData: ""
                                    })
                                    e.target.select()
                                }}
                                value={this.state.url ? this.state.url : ""}
                                onChange={(e) => this.setState({ url: e.target.value })}
                                type="text" className="form-control" placeholder="Adresse URL"/>
                        </div>

                        <span>Catégorie</span>
                        <div className="input-group mb-4 mt-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <input
                                        onClick={() => this.setState({
                                            url: null,
                                            category: null,
                                            page: null,
                                            _formData: ""
                                        })}
                                        checked={category !== null}
                                        name="cible" type="radio"/>
                                </div>
                            </div>
                            <select
                                onFocus={() => this.setState({
                                    url: null,
                                    page: null,
                                    _formData: ""
                                })}
                                value={this.state.category}
                                onChange={(e) => {
                                    let id = isNaN(e.target.value) ? null : parseInt(e.target.value)
                                    let category = this.state.categories.find((c) => c.id == id)
                                    console.log(e.target.value, id, category)
                                    this.setState({
                                        category: id,
                                        name: category ? category.name : this.state.name,
                                        color: category ? category.color : this.state.color,
                                    })
                                }}
                                className="custom-select">
                                <option>-- Catégories --</option>
                                {
                                    this.state.categories.map(entity => (
                                        <option value={entity.id}>{entity.name}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <span>Page</span>
                        <div className="input-group mb-4 mt-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <input
                                        onClick={() => this.setState({
                                            url: null,
                                            category: null,
                                            page: "",
                                            _formData: ""
                                        })}
                                        checked={page !== null}
                                        name="cible" type="radio"/>
                                </div>
                            </div>

                            <select
                                onFocus={() => this.setState({
                                    url: null,
                                    category: null,
                                    _formData: ""
                                })}
                                value={this.state.page}
                                onChange={(e) => {
                                    let id = isNaN(e.target.value) ? null : parseInt(e.target.value)
                                    let page = this.state.pages.find((p) => p.id == id)
                                    this.setState({
                                        page: id,
                                        name: page ? page.title : this.state.name
                                    })
                                }}
                                className="custom-select">
                                <option>-- Pages --</option>
                                {
                                    this.state.pages.map(entity => (
                                        <option value={entity.id}>{entity.title}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <hr/>

                    <Input
                        value={this.state.name}
                        onChangeValue={name => this.setState({ name })}
                        placeholder="Nom de l'élément"
                        label="Nom" type="text"
                    />
                    <Input
                        className={"color-div mt-2 ml-2"}
                        type={'checkbox'}
                        value={this.state.status}
                        checked = {this.state.status ==='temporaire'? true: false}
                        onChange={(value) => this.setState({ status: value.target.checked ? "temporaire" : "permanent" })}
                        label="Catégorie temporaire : "
                    />
                    <span>Couleur</span>

                    <div className={"color-div mt-2"}
                         style={{ backgroundColor: this.state.color, height: '40px', marginBottom: '20px', cursor: 'pointer' }}
                         onClick={() =>
                             this.setState({ showColorPickerModal: true })
                         }>
                    </div>

                    {this.state.showColorPickerModal ? (
                        <ColorPickerModal
                            data={this.state.color}
                            onClose={(color) => {
                                this.setState({ showColorPickerModal: false });
                                if (color){ this.setState({color: color}) }
                                console.log(color)
                            }}
                        />
                    ) : null}

                    <div className="text-right pt-3">
                        <Submit disabled={this.state._loading || !this.state.name.length}>Sauvegarder</Submit>
                    </div>
                </Form>
            </Modal>
        );
    }
}

export default connect(null,
    {
        updateChannelMenu,
        fetchAllCategories,
        fetchAllPages
    }
)(ChannelUpdateMenuItem);
