import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_OFFER_CONTRACT_INDEX = 'ENTITY_OFFER_CONTRACT_INDEX'
const ENTITY_OFFER_CONTRACT_INDEX_SUCCESS = 'ENTITY_OFFER_CONTRACT_INDEX_SUCCESS'
const ENTITY_OFFER_CONTRACT_INDEX_FAIL = 'ENTITY_OFFER_CONTRACT_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_OFFER_CONTRACT_INDEX, 'get', '/offer_contracts')
}

export function searchAll(query)
{
    return request(ENTITY_OFFER_CONTRACT_INDEX, 'post', '/offer_contracts/search', { query })
}

const ENTITY_OFFER_CONTRACT_RETRIEVE = 'ENTITY_OFFER_CONTRACT_RETRIEVE'

export function fetchOfferContract(id)
{
    return request(ENTITY_OFFER_CONTRACT_RETRIEVE, 'get', `/offer_contracts/${id}`)
}

const ENTITY_OFFER_CONTRACT_CREATE = 'ENTITY_OFFER_CONTRACT_CREATE'

export function createOfferContract(data)
{
    return request(ENTITY_OFFER_CONTRACT_CREATE, 'post', '/offer_contracts', data)
}

const ENTITY_OFFER_CONTRACT_UPDATE = 'ENTITY_OFFER_CONTRACT_UPDATE'

export function updateOfferContract(id, data)
{
    return request(ENTITY_OFFER_CONTRACT_UPDATE, 'patch', `/offer_contracts/${id}`, data)
}


const ENTITY_OFFER_CONTRACT_DELETE = 'ENTITY_OFFER_CONTRACT_DELETE'

export function deleteOfferContract(id)
{
    return request(ENTITY_OFFER_CONTRACT_DELETE, 'delete', `/offer_contracts/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityOfferContractReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_OFFER_CONTRACT_INDEX:
            return {...state, loading: true}
        case ENTITY_OFFER_CONTRACT_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_OFFER_CONTRACT_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}