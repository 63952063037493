import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit} from "react-forms";
import Modal from "../../../components/Modal";
import {toast} from "react-toastify";
import {duplicateArticle} from "../../../data/entities/Article";


class ArticleDuplicateModal extends Component
{
    state = {
        loading: false
    };

    _submit = () => {
        this.setState({ _loading: true });

        this.props.duplicateArticle(this.props.data.id)
            .then((res) => {
                toast.success("La duplication a été appliquée.");
                this.setState({ _loading: false });
                this.props.duplicated()
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data);
                this.setState({ _formData: e.error.response.data });
                this.setState({ _loading: false })
            })
    };

    render() {
        return(
            <Modal title={'Dupliquer un article'} onClose={() => this.props.onClose()}>
                <Form onSubmit={this._submit}>
                    <p>Êtes-vous sûr de vouloir dupliquer cet article ?</p>
                    <Submit disabled={this.state.loading} className="btn btn-primary">Dupliquer</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, {duplicateArticle})(ArticleDuplicateModal);
