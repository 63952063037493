import React, {Component} from "react";
import {connect} from 'react-redux';
import {
    addNewsletterEntity,
    deleteNewsletterEntity,
    fetchNewsletter,
} from "../../../data/entities/Newsletter";
import {searchAll} from "../../../data/entities/Article";
import Empty from "../../../components/Empty";
import Autocomplete from "../Article/Autocomplete";

class NewsletterArticleHighlighted extends Component
{
    state = {
        data: null,
        loading: false,
        results: [],
        query: ''
    };

    componentDidMount() {
        this._fetch()
    }

    _fetch = () => {
        this.setState({ loading: true });
        this.props.fetchNewsletter(this.props.id).then((res) => {
            this.setState({ data: res.payload.data.highlight, loading: false })
        })
    };

    update = (data) => {
        this.setState({ data }, this.props.updateHighlight(data));
    };

    render() {

        return(
            <div className={'mb-3'}>
                {this.renderHighlight()}
                {this.renderForm()}
            </div>
        )
    }

    renderForm()
    {
        if (!this.props.editable || this.state.data) return null;

        return (
            <Autocomplete
                createEntity={false}
                selectEntity={(id, article) => {
                    this.setState({ results: [], query: '' })
                    this.update(article);
                }}
                escape={() => {
                    this.setState({ results: [], query: '' })
                }}
                query={this.state.query}
                renderDropdownItem={(item) => {
                    return item.title
                }}
                className={"newsletter"}
                placeholder="Ajouter un article..."
                queryResponse={this.state.results}
                handleChange={(e) => {
                    this.setState({
                        query: e.target.value
                    });

                    if (!e.target.value.length) {
                        this.setState({ results: [] })
                    }
                    else {
                        this.props.searchAll(e.target.value).then((res) => {
                            this.setState({ results: res.payload.data.index })
                        })
                    }
                }}
            />
        )
    }

    renderHighlight() {

        if (!this.state.data)
            return null

        const article = this.state.data;

        return (
            <div className="list-group" style={{position: 'relative'}}>
                <div className={'list-group-item'} style={{height: '50px'}}>
                    {article.title}
                </div>
                <button className="btn btn-default" style={{position: 'absolute', right: 10, top: 7, zIndex: 1000 }} onClick={(e) => {
                    e.preventDefault();
                    this.setState({ loading: true });
                    this.update(null);
                    return false;
                }}>
                    <i className="fa fa-trash"/>
                </button>
            </div>
        )
    }
}

export default connect(null, {fetchNewsletter, addNewsletterEntity, deleteNewsletterEntity, searchAll})(NewsletterArticleHighlighted);