import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import ChannelCreateModal from "./ChannelCreateModal";

import { fetchAll, searchAll } from "../../../data/entities/Channel";

class ChannelIndexPage extends Component {
  state = {
    search: "",
    showChannelCreateModal: false
  };

  _search = search => {
    this.setState({ search }, () => {
      this._fetch();
    });
  };

  _fetch = () => {
    if (this.state.search.length) this.props.searchAll(this.state.search);
    else this.props.fetchAll();
  };

  componentDidMount() {
    this.props.fetchAll();
  }

  render() {
    return (
      <Container>
        <Helmet title="Menus et accueil" />
        <Row className="pb-5 pt-3">
          <Col>
            <h1>Menus et accueil</h1>
          </Col>
          <Col className="text-right pt-2">
            <Loader display={this.props.channel_entity.loading} />
            <button
              onClick={() => this.setState({ showChannelCreateModal: true })}
              className="btn btn-primary ml-2"
            >
              Créer un canal
            </button>
          </Col>
        </Row>
        {this.renderEntities()}
        {this.state.showChannelCreateModal ? (
          <ChannelCreateModal
            onClose={created => {
              if (created) this._fetch();
              this.setState({ showChannelCreateModal: false });
            }}
          />
        ) : null}
      </Container>
    );
  }

  renderEntities() {
    if (
      this.props.channel_entity.loading &&
      !this.props.channel_entity.lastUpdate
    )
      return <ContentLoader />;

    if (!this.props.channel_entity.index.length) {
      return <Empty />;
    }
    console.log(this.props.channel_entity.index);
    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={{ paddingLeft: 30 }}>
              Nom du canal
            </th>
            <th scope="col">
              Actif ?
            </th>
            <th scope="col" style={{ textAlign: "right", paddingRight: 30 }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.channel_entity.index.map((entity) => (
            <tr>
              <td scope="row">{entity.name}</td>
              <td scope="row">{entity.active ? 'Oui' : null}</td>
              <td style={{ textAlign: "right" }}>
                <Link
                  className="btn btn-warning mr-2"
                  to={"/entities/channel/" + entity.id}
                >
                  Modifier
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default connect(
  ({ channel_entity }) => ({ channel_entity }),
  { fetchAll, searchAll }
)(ChannelIndexPage);
