const EDITOR_START_DRAGGING = 'EDITOR_START_DRAGGING'

export function startDragging(data)
{
    return { type: EDITOR_START_DRAGGING, data }
}

const EDITOR_STOP_DRAGGING = 'EDITOR_STOP_DRAGGING'

export function stopDragging()
{
    return { type: EDITOR_STOP_DRAGGING }
}

// Store

const defaultState = {
    dragging: false,
    draggingData: null
}

// Reducer

export default function editorDragDropReducer(state = defaultState, action)
{
    switch(action.type)
    {
        //case EDITOR_START_DRAGGING:
            //return {...state, dragging: true, dragginData: action.data}
        //case EDITOR_STOP_DRAGGING:
            //return {...state, dragging: false}
    }
    return state
}
