import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_ADVERTISING_BANNER_INDEX = 'ENTITY_ADVERTISING_BANNER_INDEX'
const ENTITY_ADVERTISING_BANNER_INDEX_SUCCESS = 'ENTITY_ADVERTISING_BANNER_INDEX_SUCCESS'
const ENTITY_ADVERTISING_BANNER_INDEX_FAIL = 'ENTITY_ADVERTISING_BANNER_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_ADVERTISING_BANNER_INDEX, 'get', '/compagnes_banners')
}

export function searchAll(query)
{
    return request(ENTITY_ADVERTISING_BANNER_INDEX, 'post', '/compagnes_banners/search', { query })
}

const ENTITY_ADVERTISING_BANNERS_RETRIEVE = 'ENTITY_ADVERTISING_BANNER_RETRIEVE'

export function fetchAdvertisingBanners(id)
{
    return request(ENTITY_ADVERTISING_BANNERS_RETRIEVE, 'get', `/compagnes/${id}/banners`)
}

const ENTITY_ADVERTISING_BANNER_RETRIEVE = 'ENTITY_ADVERTISING_BANNER_RETRIEVE'

export function fetchAdvertisingBanner(id)
{
    return request(ENTITY_ADVERTISING_BANNER_RETRIEVE, 'get', "/compagnes_banners/"+id)
}

const ENTITY_ADVERTISING_BANNER_CREATE = 'ENTITY_ADVERTISING_BANNER_CREATE'

export function createAdvertisingBanner(id, data)
{
    return request(ENTITY_ADVERTISING_BANNER_CREATE, 'post', `/compagnes/${id}/banners`, data)
}

const ENTITY_ADVERTISING_BANNER_UPDATE = 'ENTITY_ADVERTISING_BANNER_UPDATE'

export function updateAdvertisingBanner(id, data)
{
    return request(ENTITY_ADVERTISING_BANNER_UPDATE, 'patch', `/compagnes_banners/${id}`, data)
}


const ENTITY_ADVERTISING_BANNER_DELETE = 'ENTITY_ADVERTISING_BANNER_DELETE'

export function deleteAdvertisingBanner(id)
{
    return request(ENTITY_ADVERTISING_BANNER_DELETE, 'delete', `/compagnes_banners/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityAdvertisingBannerReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_ADVERTISING_BANNER_INDEX:
            return {...state, loading: true}
        case ENTITY_ADVERTISING_BANNER_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_ADVERTISING_BANNER_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
