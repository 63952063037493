import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';

import Modal from "../../../components/Modal";

import {deleteUser} from "../../../data/entities/User";

class UserDeleteModal extends Component
{
    state = {
        loading: false
    }

    _submit = () => {
        this.setState({ loading: true })

        this.props.deleteUser(this.props.data.id)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(true)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data) {
                    if (e.error.response.data.error) toast.error(<FormattedMessage id={e.error.response.data.error} />)
                    else if (e.error.response.data.message) toast.error(<FormattedMessage id={e.error.response.data.message} />)
                }
                this.setState({ loading: false })
            })
    }

    render()
    {
        return (
            <Modal title="Supprimer" onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit}>
                    {this.props.data.id == this.props.user.profile.id ? (
                        <p className="text-muted">Vous ne pouvez pas supprimer votre propre compte.</p>
                    ) : (
                        this.props.data.role.name == 'ROLE_ADMIN' ? (
                            <p className="text-muted">Un compte administrateur ne peut pas être supprimé. Veuillez lui assigner un autre rôle.</p>
                        ) : (
                            <div>
                                <p>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</p>
                                <p className="text-muted">Ses commentaires seront également supprimés.</p>
                                <div style={{textAlign: 'right'}}>
                                    <Submit disabled={this.state.loading} className="btn btn-danger">Supprimer</Submit>
                                </div>
                            </div>
                        )
                    )}
                </Form>
            </Modal>
        )
    }
}

export default connect(({user})=>({user}), { deleteUser })(UserDeleteModal)