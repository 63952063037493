import React, {Component} from 'react';
import { connect } from "react-redux";
import {Helmet} from "react-helmet";
import {Button, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import "./Products.scss";
import ProductCreateModal from "./ProductCreateModal";
import classnames from 'classnames';
import ProductsView from "./ProductsView";
import {fetchAllProducts} from "../../../data/entities/StripeProduct";
import {checkPrivilege} from "../../../helpers";


class ProductsIndexPage extends Component
{
    state = {
        showProductCreateModal: false,
        type: 'recurring',
        activeTab: '1'
    };

    toggleTab = (activeTab) => {
        this.setState({ activeTab })
    }

    render() {
        return (
            <div className={'subscriptions-products'}>
                <Helmet title="Abonnements / Produits"/>
                <div>
                    <div className="media-page-head">
                        <h1>Abonnements / Produits</h1>
                        <div>
                            {
                                checkPrivilege(this.props.user, 'stripe-product:create') ?
                                    (this.state.activeTab === '1' ?
                                        <Button
                                            onClick={() => this.setState({ showProductCreateModal: true, type: 'recurring' })}
                                            className="btn btn-primary ml-2" color={''}>
                                            Créer un nouvel abonnement
                                        </Button>
                                        :
                                        <Button
                                            onClick={() => this.setState({ showProductCreateModal: true, type: 'one-time' })}
                                            className="btn btn-info ml-2" color={''}>
                                            Créer un nouveau produit
                                        </Button>)
                                    : null
                            }
                        </div>
                    </div>
                    <p>(important: module à utiliser seulement si vous avez un compte Stripe fonctionnel)</p>

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTab('1'); }}
                            >
                                Abonnements
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggleTab('2'); }}
                            >
                                Produits
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <ProductsView type={'recurring'}/>
                        </TabPane>
                        <TabPane tabId="2">
                            <ProductsView type={'one-time'}/>
                        </TabPane>
                    </TabContent>
                </div>

                {
                    this.state.showProductCreateModal &&
                    <ProductCreateModal
                        type={this.state.type}
                        onClose={(created) => {
                            this.setState({ showProductCreateModal: false }, () => created ? this.props.fetchAllProducts() : null)
                        }}
                    />
                }

            </div>
        )
    }
}

export default connect(({ stripe_products_entity, user }) => ({ stripe_products_entity, user }), {fetchAllProducts})(ProductsIndexPage);
