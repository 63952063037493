import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';

import Modal from "../../../components/Modal";

import {createMedia} from "../../../data/entities/Media";
import Loader from "../../../components/Loader";

class MediaCreateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        file: null,
        copyright: '',
        label: ''
    }

    _submit = () => {
        if (!this.fileRef || !this.fileRef.files || !this.fileRef.files.length) return;

        this.setState({ _loading: true })

        const data = {...this.state}
        delete data._loading
        delete data._formData

        data.file = this.fileRef.files[0]
        console.log("createDATA", data.file && data.file.type.startsWith("image/") && data.file.size > 500000)
        if(data.file && data.file.type.startsWith("image/") && data.file.size > 500000) {
            toast.error("L'image est trop grande.")
            this.setState({ _loading: false })
        }else {
            this.props.createMedia(data)
                .then((res) => {
                    toast.success("Fichier importé.")
                    this.props.onClose(res.payload.data)
                })
                .catch((e) => {
                    toast.error("Veuillez vérifier la taille ou le format du fichier.")
                    //if (e.error && e.error.response && e.error.response.data)
                    //    this.setState({ _formData: e.error.response.data })
                    this.setState({_loading: false})
                })
        }
    }

    render()
    {
        return (
            <Modal title={"Ajouter un média"} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
                    <div className="form-group mb-3">
                        <label htmlFor="copyright">Copyright</label>
                        <input type="text" className="form-control"
                               name="copyright" id="copyright" value={this.state.copyright}
                               onChange={(e) => this.setState({ copyright: e.target.value })} />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="label">Légende par défaut (facultatif)</label>
                        <input type="text" className="form-control"
                               name="label" id="label" value={this.state.label}
                               onChange={(e) => this.setState({ label: e.target.value })} />
                    </div>
					<div className="mb-5">
                        <label>Sélectionnez votre fichier :</label><br />
                        <input type="file"
                               name="file" id="file"
                               //value={this.state.file}
                               ref={(ref) => this.fileRef = ref}
                               onChange={(e) => this.setState({ file: true })}
                        />
                    </div>
                    <Submit disabled={this.state._loading || !this.state.file}>
                        <Loader display={this.state._loading} style={{ marginRight: 10 }}/>
                        Importer ce fichier
                    </Submit>
                    <hr/>
                    <p className="text-muted" style={{listStyle: "disc outside none", display: "list-item",marginLeft: "2%"}}>
                       Pour les vidéos, merci d'utiliser le format MP4 pour que tous les internautes puissent les regarder.
                        Il est préférable d'en réduire la taille pour faciliter la lecture sur tous les appareils.
                        Vous pouvez utiliser <a href="https://cloudconvert.com/mov-to-mp4" target="_blank">ce site</a> pour convertir en MP4.
                    </p>
                    <p className="text-muted" style={{listStyle: "disc outside none", display: "list-item",marginLeft: "2%"}}>
                       Pour les images, <b>merci d'importer une image qui ne dépasse pas 500ko.</b>
                        Il est préférable d'en réduire la taille pour faciliter l'affichage sur tous les appareils.
                        Vous pouvez utiliser <a href="https://tinypng.com" target="_blank">ce site</a> pour compresser vos images.
                    </p>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { createMedia })(MediaCreateModal)
