import React, {Component} from 'react';
import {Button, Card, CardBody, CardSubtitle, CardText, CardTitle} from "reactstrap";
import moment from "moment";
import {getDraftRssArticles, importArticle} from "../../data/Curation";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class CurationArticle extends Component
{
    state = {
        importing: false,
        imported: null
    }

    import = () => {
        if (this.state.importing) return;

        this.setState({ importing: true })
        this.props.importArticle(this.props.article.platform, this.props.article.id).then((res) => {
            console.log(res)
            this.setState({ importing: false, imported: res.payload.data })
        })
    }

    render()
    {
        const article = this.props.article
        const partner = this.props.partner

        const style = article.thumbnail ? { backgroundImage: `url(${article.thumbnail})` } : {}

        return (
            <div className={"col-md-3 mb-3 curation-article-card " + (this.state.importing || this.state.imported ? "active" : "")}>
                <Card>
                    <div className="curation-article-card-thumbnail" style={style}>
                        {!article.thumbnail ? <i className="fas fa-image"/> : null}
                        <div className="curation-article-card-actions">
                            {this.state.imported ? (
                                <a href={"/entities/article/" + this.state.imported.article_id} className="btn btn-primary" target="_blank">
                                    <i className="fa fa-edit"/>
                                    <span className="btn-tooltip">Modifier</span>
                                </a>
                            ) : (
                                <>
                                    <a href={partner.domain + article.path}
                                       target="_blank"
                                       id={"article-" + article.id}
                                       className="btn btn-secondary">
                                        <i className="fa fa-eye"/>
                                        <span className="btn-tooltip">Visualiser</span>
                                    </a>
                                    <Button onClick={this.import}>
                                        <i className={this.state.importing ? "fa fa-circle-notch fa-spin" : "fa fa-download"}/>
                                        <span className="btn-tooltip">Importer</span>
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                    <CardBody>
                        <div style={{ minHeight: 80 }}>
                            <CardTitle><strong>{article.title}</strong></CardTitle>
                        </div>
                        <CardSubtitle>
                            <i className="fa fa-globe text-muted"/>{' '}
                            <a href={partner.domain} target="_blank">
                                {partner.name}
                            </a>
                        </CardSubtitle>
                        <CardText>
                            <i className="fa fa-clock text-muted"/>{' '}
                            {moment(article.publication_start).format('DD/MM/YYYY')}
                            <br />
                            <i className="fa fa-user text-muted"/>{' '}
                            {article.author_name ? article.author_name : "Anonyme"}
                        </CardText>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default connect(null, {getDraftRssArticles,importArticle})(CurationArticle);
