import React, {Component} from 'react';

export default class Empty extends Component {
    render() {
        return (
            <div className="text-center text-muted pt-2 ml-auto mr-auto">
                <i className="fa fa-box-open" style={{fontSize: 20}}/>
                <p>{this.props.text}</p>
            </div>
        )
    }
}

Empty.defaultProps = {
    text: "Aucun résultat"
}