import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_USER_INDEX = 'ENTITY_USER_INDEX'
const ENTITY_USER_INDEX_SUCCESS = 'ENTITY_USER_INDEX_SUCCESS'
const ENTITY_USER_INDEX_FAIL = 'ENTITY_USER_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_USER_INDEX, 'get', '/users')
}

export function searchAll(query)
{
    return request(ENTITY_USER_INDEX, 'post', '/users/search', { query })
}

const ENTITY_USER_EXPLORE = 'ENTITY_USER_EXPLORE'
export function explore(data)
{
    return request(ENTITY_USER_EXPLORE, 'post', '/users/explore', data)
}

const ENTITY_USER_RETRIEVE = 'ENTITY_USER_RETRIEVE'

export function fetchUser(id)
{
    return request(ENTITY_USER_RETRIEVE, 'get', `/users/${id}`)
}

const ENTITY_USER_GRANT = 'ENTITY_USER_GRANT'

export function grantUser(id, role)
{
    return request(ENTITY_USER_GRANT, 'patch', `/users/${id}/role`, { role })
}

const ENTITY_USER_CREATE = 'ENTITY_USER_CREATE'

export function createUser(data)
{
    return request(ENTITY_USER_CREATE, 'post', '/users', data)
}

const ENTITY_USER_UPDATE = 'ENTITY_USER_UPDATE'

export function updateUser(id, data)
{
    return request(ENTITY_USER_UPDATE, 'patch', `/users/${id}`, data)
}

const ENTITY_USER_UPDATE_PASSWORD = 'ENTITY_USER_UPDATE_PASSWORD'

export function updatePassword(id, password)
{
    return request(ENTITY_USER_UPDATE_PASSWORD, 'patch', `/users/${id}/password`, { password })
}

const ENTITY_USER_ACTIVATE = 'ENTITY_USER_ACTIVATE'

export function activateUser(id)
{
    return request(ENTITY_USER_ACTIVATE, 'patch', `/users/${id}/activate`, {})
}

const ENTITY_USER_DELETE = 'ENTITY_USER_DELETE'

export function deleteUser(id)
{
    return request(ENTITY_USER_DELETE, 'delete', `/users/${id}`)
}

const ENTITY_USER_ADD_AVATAR = "ENTITY_USER_ADD_AVATAR"

export function addUserAvatar(articleId, mediaId) {
    return request(ENTITY_USER_ADD_AVATAR, "patch", `/user/${articleId}/avatar`, mediaId)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityUserReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_USER_INDEX:
            return {...state, loading: true}
        case ENTITY_USER_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_USER_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}