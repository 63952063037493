import React, {Component} from 'react';
import {Badge} from "reactstrap";
import {countAll} from "../data/entities/SuggestedArticle";
import {connect} from "react-redux";

class SuggestedArticlesCount extends Component
{
    componentDidMount() {
        if (this.props.suggested_article_entity.count === false)
            this.props.countAll()
    }

    render()
    {
        const count = this.props.suggested_article_entity.count

        if (count === false)
            return null

        return (
            <Badge color={count ? "danger" : "secondary"} className="ml-2">
                {count}
            </Badge>
        );
    }
}

export default connect(({ suggested_article_entity }) => ({ suggested_article_entity }), { countAll })(SuggestedArticlesCount);
