import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import CircleCreateModal from "./CircleCreateModal";

import {fetchAll, searchAll} from '../../../data/entities/Circle'

class CircleIndexPage extends Component
{
    state = {
        search: '',
        showCircleCreateModal: false,
    }

    _search = (search) => {
        this.setState({ search }, () => {
            this._fetch()
        })
    }

    _fetch = () => {
        if (this.state.search.length) this.props.searchAll(this.state.search)
        else this.props.fetchAll()
    }

    componentDidMount()
    {
        this.props.fetchAll()
    }

    render() {
        return (
            <div className="container">
                <Helmet title="Cercles" />
                <div className="row">
                    <div className="col">
                        <h1>Cercles d'utilisateurs</h1>
                    </div>
                    <div className="col text-right">
                        <Loader display={this.props.circle_entity.loading}/>
                        <input
                            type="text"
                            value={this.state.search}
                            onChange={(e) => this._search(e.target.value)}
                            placeholder="Rechercher..."
                            className="form-control d-inline-block ml-2"
                            style={{ maxWidth: 200 }}
                        />
                        <button
                            onClick={() => this.setState({ showCircleCreateModal: true })}
                            className="btn btn-primary ml-2"><i className="fa fa-plus" /></button>
                    </div>
                </div>
                {this.renderEntities()}
                {this.state.showCircleCreateModal ? (
                    <CircleCreateModal
                        onClose={(created) => {
                            if (created) this._fetch()
                            this.setState({ showCircleCreateModal: false })
                        }}
                    />
                ) : null}
            </div>
        )
    }

    renderEntities()
    {
        if (this.props.circle_entity.loading && !this.props.circle_entity.lastUpdate)
            return <ContentLoader/>

        if (!this.props.circle_entity.index.length) {
            return <Empty/>
        }

        return (
            <table className="table table-striped mt-3">
                {this.props.circle_entity.index.map((entity) => (
                    <tr>
                        <td>
                            <Link to={"/entities/circle/" + entity.id}>{entity.name}</Link>
                        </td>
                    </tr>
                ))}
            </table>
        )
    }
}

export default connect(({ circle_entity }) => ({ circle_entity }), { fetchAll, searchAll })(CircleIndexPage)
