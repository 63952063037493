import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Helmet } from 'react-helmet'
import './Auth.scss'

import { forgotPassword } from '../../data/OAuth'
import {Form, Input, Submit} from "react-forms";
import {toast} from "react-toastify";

class ForgotPasswordPage extends Component
{
    state = {
        email: '',
        formData: null
    }

    _submit = () => {
        this.props.forgotPassword(this.state.email)
            .then((e) => {
                toast.success(e.payload.data.message)
                this.props.history.push('/auth/login')
            })
            .catch((e) => {
                this.setState({ formData: e.error && e.error.response ? e.error.response.data : null })
            })
    }

    render()
    {
        return (
            <div className="container auth-container">
                <Helmet title="Mot de passe oublié" />
                <Form onSubmit={this._submit} data={this.state.formData} toast>
                    <h1 className="h3 mb-3 font-weight-normal">Mot de passe oublié</h1>
                    <Input
                        type="email"
                        name="email"
                        placeholder="Adresse e-mail"
                        value={this.state.email}
                        onChangeValue={(email) => this.setState({ email })}
                        required autoFocus
                    />
                    <Submit>Valider</Submit>
                </Form>
            </div>
        )
    }
}

export default connect(null, { forgotPassword })(ForgotPasswordPage)
