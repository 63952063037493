import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

import ContentLoader from "../../../components/ContentLoader";

import { fetchCategory } from "../../../data/entities/Category";
import CategoryUpdateModal from "./CategoryUpdateModal";
import CategoryDeleteModal from "./CategoryDeleteModal";

class CategoryViewPage extends Component {
  state = {
    id: this.props.match.params.id,
    data: null,
    loading: true

    // showCategoryUpdateModal: false,
    // showCategoryDeleteModal: false
  };

  componentDidMount() {
    this.props
      .fetchCategory(this.state.id)
      .then(res => {
        this.setState({ loading: false, data: res.payload.data });
      })
      .catch(e => {
        console.log(e);
        if (
          e.error &&
          e.error.response &&
          e.error.response.data &&
          e.error.response.data.error
        )
          toast.error(<FormattedMessage id={e.error.response.data.error} />);
        this.props.history.push("/entities/category");
      });
  }

  render() {
    return (
      <div className="container">
        <Helmet title="Category" />
        <h1>Category #{this.state.id}</h1>
        {this.renderEntity()}
      </div>
    );
  }

  renderEntity() {
    if (this.state.loading) return <ContentLoader />;

    return (
      <div>
        <pre>{JSON.stringify(this.state.data)}</pre>
      </div>
    );
  }
}

export default connect(
  ({ category_entity }) => ({ category_entity }),
  { fetchCategory }
)(CategoryViewPage);
