import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import ChannelMenuCreateModal from "./ChannelMenuCreateModal";

import {fetchAll} from '../../../data/entities/ChannelMenu'

class ChannelMenuIndexPage extends Component
{
    state = {
        showChannelMenuCreateModal: false,
    }

    _fetch = () => {
        this.props.fetchAll()
    }

    componentDidMount()
    {
        this.props.fetchAll()
    }

    render() {
        return (
            <div className="container">
                <Helmet title="ChannelMenu" />
                <div className="row">
                    <div className="col">
                        <h1>ChannelMenu</h1>
                    </div>
                    <div className="col text-right">
                        <Loader display={this.props.channel_menu_entity.loading}/>
                        <button
                            onClick={() => this.setState({ showChannelMenuCreateModal: true })}
                            className="btn btn-primary ml-2"><i className="fa fa-plus" /></button>
                    </div>
                </div>
                {this.renderEntities()}
                {this.state.showChannelMenuCreateModal ? (
                    <ChannelMenuCreateModal
                        onClose={(created) => {
                            if (created) this._fetch()
                            this.setState({ showChannelMenuCreateModal: false })
                        }}
                    />
                ) : null}
            </div>
        )
    }

    renderEntities()
    {
        if (this.props.channel_menu_entity.loading && !this.props.channel_menu_entity.lastUpdate)
            return <ContentLoader/>

        if (!this.props.channel_menu_entity.index.length) {
            return <Empty/>
        }

        return (
            <table className="table table-striped mt-3">
                {this.props.channel_menu_entity.index.map((entity) => (
                    <tr>
                        <td>
                            <Link to={"/entities/channel_menu/" + entity.id}>ChannelMenu #{entity.id}</Link>
                        </td>
                    </tr>
                ))}
            </table>
        )
    }
}

export default connect(({ channel_menu_entity }) => ({ channel_menu_entity }), { fetchAll })(ChannelMenuIndexPage)
