import React, {Component, Fragment } from 'react'
import { connect } from 'react-redux'

import Autocomplete from "./Autocomplete"

import { searchAll } from "../../../data/entities/Category";
import { createArticleCategory, deleteArticleCategory } from '../../../data/entities/Article'


class ManageCategory extends Component {

    state = {
        articleData: this.props.data,
        query: "",
        queryResponse: [],
        categories: this.props.data.categories,

        _formData: null
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.categories && nextProps.categories
            && this.props.categories.length != nextProps.categories.length)
            this.setState({categories: nextProps.categories});
    }

    handleChange = (e) => {
        this.setState({ query: e.target.value }, () => this.searchCategory())
    }

    searchCategory = () => {
        if (this.state.query.length > 0) {
            this.props.searchAll(this.state.query)
                .then(res => {
                    if (res.payload.data) this.setState({ queryResponse: res.payload.data.slice(0, 14)})
                })
                .catch((e) => {
                    if (e.error && e.error.response && e.error.response.data) {
                        this.setState({ _formData: e.error.response.data})
                    }
                    this.setState({ loading: false})
                })
        } else if (this.state.query.length === 0) {
            this.setState({ queryResponse: [] })
        }
    }

    deleteArticleCategory = (categoryId) => {
        this.props.deleteArticleCategory(this.state.articleData.id, {target: categoryId})
            .then(res => {
                if (res.payload.data.deleted) this.setState({
                    categories: this.state.categories.filter(category => category.id !== categoryId)
                })
            })
        document.getElementById("add-category-input").focus()
    }

    selectCategory = (categoryId) => {
        console.log("SELECTED CATEGORY ID---->", categoryId)
        if (this.state.categories.find(category => category.id === categoryId)) {
            return
        }
        this.props.createArticleCategory(this.state.articleData.id, {target: categoryId})
            .then(res => this.setState({
                categories : [
                    ...this.state.categories,
                    res.payload.data],
                query: "",
                queryResponse: []
            }))
        document.getElementById("add-category-input").focus()
    }

    renderDropdownItemCategory = (category) => {
        return (
            <span>{category.name.length > 50 ? `${category.name.slice(0, 45)}...` : category.name}</span>
        )
    }

    render() {
        console.log("CATEGORIES--->", this.state.categories)
        return (
            <div className={"entity-list"}>
                {this.state.categories &&
                    this.state.categories.map(entity => (
                        <div className={"btn btn-sm btn-primary m-1"}>
                            {entity.name}
                            <i onClick={() => this.deleteArticleCategory(entity.id)}
                               className="fas fa-times ml-2"/>
                        </div>
                    ))}

                <Autocomplete
                    query={this.state.query}
                    queryResponse={this.state.queryResponse}
                    handleChange={this.handleChange}
                    escape={() => this.setState({ query: "", queryResponse: [] })}
                    selectEntity={(categoryId) => this.selectCategory(categoryId)}
                    renderDropdownItem={(category) => this.renderDropdownItemCategory(category)}
                    createEntity={false}
                    placeholder={"Rechercher une catégorie"}
                    className={"category"}/>
            </div>
        )
    }
}

export default connect(null, { searchAll, createArticleCategory, deleteArticleCategory })(ManageCategory)
