import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {Row, Col, Container} from "reactstrap";


import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import OfferExperienceCreateModal from "./OfferExperienceCreateModal";
import OfferExperienceUpdateModal from "./OfferExperienceUpdateModal";
import OfferExperienceDeleteModal from "./OfferExperienceDeleteModal";

import {fetchAll, searchAll} from '../../../data/entities/OfferExperience'
import OfferContractUpdateModal from "../OfferContract/OfferContractIndexPage";

class OfferExperienceIndexPage extends Component
{
  state = {
    search: '',
    showOfferExperienceCreateModal: false,
    showOfferExperienceUpdateModal: null,
    showOfferExperienceDeleteModal: null
  }

  _search = (search) => {
    this.setState({ search }, () => {
      this._fetch()
    })
  }

  _fetch = () => {
    if (this.state.search.length) this.props.searchAll(this.state.search)
    else this.props.fetchAll()
  }

  componentDidMount()
  {
    this.props.fetchAll()
  }

  render() {
    return (
        <Container>
          <Helmet title="Experience" />
          <Row className={"pb-3"}>
            <Col>
              <h2>Expériences</h2>
            </Col>
            <Col  className="text-right">
              <Loader display={this.props.offer_experience_entity.loading}/>
              <input
                  type="text"
                  value={this.state.search}
                  onChange={(e) => this._search(e.target.value)}
                  placeholder="Rechercher..."
                  className="form-control d-inline-block ml-2"
                  style={{ maxWidth: 200 }}
              />
              <button
                  onClick={() => this.setState({ showOfferExperienceCreateModal: true })}
                  className="btn btn-primary ml-2">Ajouter</button>
            </Col>
          </Row>
          {this.renderEntities()}
          {this.state.showOfferExperienceCreateModal ? (
              <OfferExperienceCreateModal
                  onClose={(created) => {
                    if (created) this._fetch()
                    this.setState({ showOfferExperienceCreateModal: false })
                  }}
              />
          ) : null}
          {this.state.showOfferExperienceUpdateModal !== null ? (
              <OfferExperienceUpdateModal data={this.state.showOfferExperienceUpdateModal}
                                          onClose={(updated) => {
                                            if (updated) this._fetch();
                                            this.setState({showOfferExperienceUpdateModal: null})
                                          }}
              />
          ) : null}

          {this.state.showOfferExperienceDeleteModal !== null ? (
              <OfferExperienceDeleteModal data={this.state.showOfferExperienceDeleteModal}
                                          onClose={(deleted) => {
                                            if (deleted) this._fetch();
                                            this.setState({showOfferExperienceDeleteModal: null})
                                          }}
              />
          ) : null}
        </Container>
    )
  }


  renderEntities()
  {
    if (this.props.offer_experience_entity.loading && !this.props.offer_experience_entity.lastUpdate)
      return <ContentLoader/>

    if (!this.props.offer_experience_entity.index.length) {
      return <Empty/>
    }

    return (
        <table className="table table-striped mt-3">
          {this.props.offer_experience_entity.index.map((entity) => (
              <tr>
                <td>
                  <h5>{entity.name}</h5>
                </td>
                <td style={{textAlign: "right"}}>
                  <button
                      onClick={() => this.setState({ showOfferExperienceUpdateModal : entity })}
                      className="btn btn-primary">Modifier
                  </button>
                  <button
                      onClick={() => this.setState({ showOfferExperienceDeleteModal: entity })}
                      className="btn btn-danger ml-2">Supprimer
                  </button>
                </td>
              </tr>
          ))}
        </table>
    )
  }
}

export default connect(({ offer_experience_entity }) => ({ offer_experience_entity }), { fetchAll, searchAll })(OfferExperienceIndexPage)
