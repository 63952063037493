import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {Row, Col, Container} from "reactstrap";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import {createOfferContract, fetchAll, searchAll} from "../../../data/entities/OfferContract";
import OfferContractCreateModal from "./OfferContractCreateModal";
import OfferContractUpdateModal from "./OfferContractUpdateModal";
import OfferContractDeleteModal from "./OfferContractDeleteModal";

class OfferContractIndexPage extends Component
{
  state = {
    search: '',
    showOfferContractCreateModal: false,
    showOfferContractUpdateModal: null,
    showOfferContractDeleteModal: null
  }

  _search = (search) => {
    this.setState({ search }, () => {
      this._fetch()
    })
  }

  _fetch = () => {
    if (this.state.search.length) this.props.searchAll(this.state.search)
    else this.props.fetchAll()
  }

  componentDidMount()
  {
    this.props.fetchAll()
  }

  render() {



    return (
        <Container>
          <Helmet title="Contrat"/>
          <Row className={"pb-3"}>
            <Col>
              <h2>Contrat</h2>
            </Col>
            <Col className="text-right">
              <Loader display={this.props.offer_contract_entity.loading}/>
              <input
                  type="text"
                  value={this.state.search}
                  onChange={(e) => this._search(e.target.value)}
                  placeholder="Rechercher..."
                  className="form-control d-inline-block ml-2"
                  style={{ maxWidth: 200 }}
              />
              <button
                  onClick={() => this.setState({ showOfferContractCreateModal: true })}
                  className="btn btn-primary ml-2">Ajouter</button>
            </Col>
          </Row>
          {this.renderEntities()}
          {this.state.showOfferContractCreateModal ? (
              <OfferContractCreateModal
                  onClose={(created) => {
                    if (created) this._fetch();
                    this.setState({ showOfferContractCreateModal: false })
                  }}
              />
          ) : null}
          {this.state.showOfferContractUpdateModal !== null ? (
              <OfferContractUpdateModal data={this.state.showOfferContractUpdateModal}
              onClose={(updated) => {
              	if (updated) this._fetch();
              	this.setState({showOfferContractUpdateModal: null})
							}}
							/>
					) : null}

          {this.state.showOfferContractDeleteModal !== null ? (
          		<OfferContractDeleteModal data={this.state.showOfferContractDeleteModal}
																				onClose={(deleted) => {
																					if (deleted) this._fetch();
																					this.setState({showOfferContractDeleteModal: null})
																				}}
							/>
							) : null}
        </Container>
    )
  }

  renderEntities()
  {
    if (this.props.offer_contract_entity.loading && !this.props.offer_contract_entity.lastUpdate)
      return <ContentLoader/>

    if (!this.props.offer_contract_entity.index.length) {
      return <Empty/>
    }



    console.log(this.props.offer_contract_entity);
    return (
          <table className="table table-striped mt-3">
            {this.props.offer_contract_entity.index.map((entity) => (
                <tr>
                  <td>
                    <h5>{entity.name}</h5>
                  </td>
                  <td style={{textAlign: "right"}}>
                    <button
                        onClick={() => this.setState({ showOfferContractUpdateModal : entity })}
                        className="btn btn-primary">Modifier
                    </button>
                    <button
                        onClick={() => this.setState({ showOfferContractDeleteModal: entity })}
                        className="btn btn-danger ml-2">Supprimer
                    </button>
                  </td>
                </tr>
            ))}
          </table>
    )
  }
}

export default connect(({offer_contract_entity}) => ({offer_contract_entity}),
    { createOfferContract, fetchAll, searchAll })(OfferContractIndexPage)
