import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

import ContentLoader from "../../../components/ContentLoader";
import { fetchChannel } from "../../../data/entities/Channel";
import { updateChannelMenuPosition } from "../../../data/entities/Channel";

import {
  fetchAll,
  deleteChannelMenu,
  updateChannelMenu
} from "../../../data/entities/ChannelMenu";
import ChannelAddMenuItem from "./ChannelAddMenuItem";
import ChannelMenuItem from "./ChannelMenuItem";
import Empty from "../../../components/Empty";


class MenuViewPage extends Component {
  state = {
    id: this.props.id,
    data: this.props.data,
    itemList: [],
    showChannelAddMenuItem: false
  };

  

  _fetchItems = () => {
    this.props.fetchAll(this.state.id)
      .then(res => {
        this.setState({ itemList: res.payload.data })
        return res
      });
  };

  componentDidMount() {
    this._fetchItems();
  }

  updatePosition = (a, b) => {
    this.props.updateChannelMenuPosition(this.state.data.id, a.id, {position: b.position})
      .then(() => this._fetchItems())
  }

  render() {
    console.log("items", this.state.itemList)
    return (
      <Fragment>
        <Helmet title="Canal" />
        {this.renderEntity()}
        <br />
        {this.renderListItem()}
      </Fragment>
    );
  }

  renderListItem() {
    const items = this.state.itemList.filter(item => item.parent === null);
    if (items.length > 0)
      return (
        <div className="menu-items">
          {items.map((item, i) => (
            <ChannelMenuItem
              items={this.state.itemList}
              data={item}
              channel={this.state.id}
              onChange={() => this._fetchItems()}
              updatePositionUp={() => this.updatePosition(item, items[i-1])}
              updatePositionDown={() => this.updatePosition(item, items[i+1])}
              disableUpBtn={i === 0}
              disableDownBtn={i === items.length-1}/>
          ))}
        </div>
      );

    return <Empty />;
  }

  renderEntity() {
    if (this.state.loading) return <ContentLoader />;
    return (
      <Fragment>
        <div className="pb-3 pt-5 d-flex">
          <button
            onClick={() =>
              this.setState({
                showChannelAddMenuItem: true
              })
            }
            className="btn btn-primary ml-2"
          >
            Ajouter une catégorie
          </button>

          {this.state.showChannelAddMenuItem ? (
            <ChannelAddMenuItem
              channel={this.state.id}
              parent={null}
              onClose={created => {
                this.setState({ showChannelAddMenuItem: false });
                if (created) this._fetchItems();
              }}
            />
          ) : null}

        </div>
      </Fragment>
    );
  }
}

export default connect(
  ({ channel_entity }) => ({ channel_entity }),
  { fetchChannel, fetchAll, deleteChannelMenu, updateChannelMenu, updateChannelMenuPosition }
)(MenuViewPage);
