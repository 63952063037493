import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import Modal from "../../../components/Modal";

import {updateCategory} from "../../../data/entities/Category";
import ColorPickerModal from "./ColorPickerModal";

class CategoryUpdateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        name: this.props.data.name,
        color: this.props.data.color ? this.props.data.color : '#58ffbd',
        showColorPickerModal: false
    };

    _submit = () => {
        this.setState({ _loading: true });

        const data = {...this.state};
        delete data._loading;
        delete data._formData;
        delete data.showColorPickerModal;

        this.props.updateCategory(this.props.data.id, data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />);
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data });
                this.setState({ _loading: false })
            })
    };

    render()
    {
        return (
            <Modal title={this.props.data.name} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
					<Input
					    value={this.state.name} onChangeValue={(name) => this.setState({ name })}
					    placeholder="name" label="Nom" type="text"/>

                    <p>Couleur</p>

                    <div className={"color-div"}
                         style={{ backgroundColor: this.state.color, height: '40px', marginBottom: '20px', cursor: 'pointer' }}
                         onClick={() =>
                             this.setState({ showColorPickerModal: true })
                         }>
                    </div>

                    {this.state.showColorPickerModal ? (
                        <ColorPickerModal
                            data={this.state.color}
                            onClose={(color) => {
                                this.setState({ showColorPickerModal: false });
                                if (color){ this.setState({color: color}) }
                                console.log(color)
                            }}
                        />
                    ) : null}

                    <Submit disabled={this.state._loading}>Sauvegarder</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { updateCategory })(CategoryUpdateModal)
