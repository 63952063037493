import React, {Component} from 'react';
import ContentLoader from "../../../components/ContentLoader";

import './FrequencyEditor.scss'

class FrequencyEditor extends Component
{
    constructor(props)
    {
        super(props)

        this.weekOptions = ['L', 'Ma', 'Me', 'J', 'V', 'S', 'D']
        this.monthOptions = Array.from(Array(31), (e, i) => i + 1)
    }

    componentDidMount()
    {
        if (!(this.props.data instanceof Array))
        {
            this.props.onChangeData([])
        }
    }

    render()
    {
        if (!this.props.type || this.props.type === 'daily')
            return null;

        if (!(this.props.data instanceof Array))
            return <ContentLoader/>

        const options = this.props.type === 'weekly' ? this.weekOptions : this.monthOptions
        const rows = options.map((_, i) => options.slice(i * 7, i * 7 + 7))

        return (
            <div className="newsletter-frequency-editor">
                {rows.map((row) => this.renderRow(row))}
            </div>
        );
    }

    renderRow(options)
    {
        if (!options.length) return null

        return (
            <div
                style={{ width: options.length < 7 ? '39%' : '100%' }}
                className="newsletter-frequency-row">
                {options.map((o) => this.renderOption(o))}
            </div>
        )
    }

    renderOption(option)
    {
        const selected = this.props.data.indexOf(option) !== -1

        return (
            <div
                onClick={() => {
                    let data = this.props.data
                    if (selected) data = data.filter((v) => v != option)
                    else data.push(option)
                    this.props.onChangeData(data)
                }}
                className={"newsletter-frequency-option " + (selected ? 'selected' : '')}>
                {typeof option === 'string' ? option[0] : option}
            </div>
        )
    }
}

export default FrequencyEditor;