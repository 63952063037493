import React, {Component} from 'react';
import Modal from "../../../components/Modal";
import {Form, Submit} from "react-forms";
import {fetchAll} from '../../../data/entities/StaticPage';
import {connect} from 'react-redux';
import {updateChannelHomePage} from "../../../data/entities/Channel";
import {toast} from "react-toastify";

class ChannelHomePageModal extends Component
{
    state = {
        id: this.props.id,
        checkedTarget: null,
        pages: [],
        page: null,
        _formData: null,
        _loading: false
    };

    componentDidMount() {
        this.props.fetchAll(true)
            .then(res => this.setState({ pages: res.payload.data}))
    }

    _submit = () => {
        this.setState({ _loading: true });

        const data = { ...this.state };

        this.props
            .updateChannelHomePage(data)
            .then(res => {
                toast.success("Mise à jour effectuée.")
                this.props.onClose(true);
            })
            .catch(e => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data });
                this.setState({ _loading: false });
            });
    }

    render() {
        return (
            <Modal title={"Modifier la page d'accueil"} onClose={() => this.props.onClose()}>
                <Form onSubmit={this._submit} data={this.state._formData}>

                    <div className="my-3">
                        <input
                            onClick={() => this.setState({
                                checkedTarget: "none",
                                page: null
                            })}
                            type="radio" name="cible" aria-label="default" style={{marginLeft: '13px', marginRight: '14px'}}
                            defaultChecked={this.state.checkedTarget == 'none' || !this.props.static_page_id}
                        />
                        <label htmlFor="aucun">Page par défaut</label><br/>
                    </div>

                    <span>Page statique</span>
                    <div className="input-group mb-4 mt-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <input
                                    onClick={() => this.setState({
                                        checkedTarget: "page",
                                    })}
                                    name="cible" type="radio"
                                    defaultChecked={this.state.checkedTarget == 'page' || this.props.static_page_id}
                                />
                            </div>
                        </div>

                        <select
                            onChange={(e) => {
                                let id = isNaN(e.target.value) ? null : parseInt(e.target.value)
                                let page = this.state.pages.find((p) => p.id == id)
                                this.setState({
                                    page: id,
                                    name: page ? page.title : this.state.name
                                })
                            }}
                            disabled={this.state.checkedTarget !== "page" ? true : false}
                            className="custom-select"
                        >
                            <option>-- Pages --</option>
                            {
                                this.state.pages.map(entity => (
                                    <option key={entity.id} value={entity.id} selected={this.props.static_page_id == entity.id}>{entity.title}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="text-right pt-3">
                        <Submit disabled={this.state._loading}>Créer</Submit>
                    </div>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, {fetchAll, updateChannelHomePage})(ChannelHomePageModal);
