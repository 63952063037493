import React, {Component} from 'react';

import './EmptyLayout.scss'

class EmptyLayout extends Component
{
    render() {
        return (
            <div className="EmptyLayout">{this.props.children}</div>
        )
    }
}

export default EmptyLayout
