import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import UserCreateModal from "./UserCreateModal";

import { fetchAll, searchAll, explore } from "../../../data/entities/User";
import Pagination from '../../../components/Pagination';
import {Button} from 'reactstrap';
import {checkPrivilege} from "../../../helpers";

class UserIndexPage extends Component {
    state = {
        search: "",
        page: 1,
        pages: 0,
        count: 0,
        results: [],
        loading: true,
        showUserCreateModal: false
    };

    _search = (search) => {
        this.setState({ search }, this._fetch)
    }

    _fetch = (page = 1) => {
        this.props.explore({ page, query: this.state.search })
            .then((res) => {
                let data = res.payload.data

                this.setState({
                    page: data.page,
                    pages: data.pages,
                    count: data.count,
                    results: data.results,
                    loading: false
                })
            })
    };

    componentDidMount() {
        this._fetch(1)
    }

    render() {
        return (
            <div className="container">
                <Helmet title="User" />
                <div className="row pb-2 pt-3 d-flex align-items-center">
                    <div className="col-4">
                        <h1>Utilisateurs</h1>
                    </div>
                    <div className="col d-flex align-items-center justify-content-end">
                        <Loader display={this.state.loading} />
                        <input
                            type="text"
                            value={this.state.search}
                            onChange={e => this._search(e.target.value)}
                            placeholder="Rechercher..."
                            className="form-control d-inline-block ml-2"
                            style={{ maxWidth: 200 }}
                        />
                    </div>
                </div>
                {checkPrivilege(this.props.user, ['user:grant', 'user:edit']) ?
                    <div className="row mb-3">
                        <div className="col">
                            <Button color={'warning'} className={'float-right'}
                                    onClick={() => this.setState({ showUserCreateModal: true })}
                            ><i className="fa fa-plus ml-2"/> Créer un utilisateur</Button>
                        </div>
                    </div>
                    : null }
                {this.renderEntities()}
                <div>
                    <Pagination
                        data={this.state}
                        chunks={30}
                        onChange={(value) => {
                            this._fetch(value)
                        }}/>
                </div>
                {this.state.showUserCreateModal ? (
                    <UserCreateModal
                        onClose={created => {
                            if (created) this._fetch(1);
                            this.setState({ showUserCreateModal: false });
                        }}
                    />
                ) : null}
            </div>
        );
    }

    renderEntities() {
        if (this.state.loading)
            return <ContentLoader />;

        if (!this.state.results.length) {
            return <Empty />;
        }

        return (
            <table className="table">
                <thead>
                <tr>
                    <th scope="col" />
                    <th scope="col">Prénom</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Adresse e-mail</th>
                    <th scope="col">Rôle</th>
                    <th scope="col" />
                </tr>
                </thead>
                <tbody>
                {this.state.results.map(entity => (
                    <tr>
                        <th scope="row">{entity.id}</th>
                        <td>{entity.firstname}</td>
                        <td>{entity.lastname}</td>
                        <td>{entity.email}</td>
                        <td>{entity.role.label}</td>
                        <td style={{ textAlign: "right" }}>
                            <Link
                                className="btn btn-primary"
                                to={"/entities/user/" + entity.id}
                            >
                                <i className="fas fa-eye" />
                            </Link>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }
}

export default connect(
    ({ user, user_entity }) => ({ user, user_entity }),
    { fetchAll, searchAll, explore }
)(UserIndexPage);
