import React, { Component } from "react";
import {connect} from 'react-redux'
import Modal from "../../../components/Modal";
import {Form, Submit, Input, TextArea} from "react-forms";
import {toast} from "react-toastify";
import {updateProduct, fetchAllTaxRates, searchProductsOneShotFromCategory} from "../../../data/entities/StripeProduct";
import {FormGroup, Row, Col} from 'reactstrap';
import Autocomplete from "../Article/Autocomplete";
import { searchAll } from "../../../data/entities/Category";
import {fetchAll} from '../../../data/entities/Media'
import Empty from "../../../components/Empty";
import moment from "moment";
import Datepicker from "../../../components/Datepicker";

class ProductUpdateModal extends Component
{
    state = {
        name: this.props.product.name,
        description: this.props.product.description,
        category: this.props.product.metadata ? this.props.product.metadata.category : '',
        sub_categories: this.props.product.metadata && this.props.product.metadata.sub_categories ? (this.props.product.metadata.sub_categories instanceof Array ? this.props.product.metadata.sub_categories: JSON.parse(this.props.product.metadata.sub_categories) ) : [],
        color: this.props.product.color,
        image: this.props.product.images && this.props.product.images.length > 0 ? this.props.product.images[0] : null,
        loading: false,
        query: '',
        queryResponse: [],
        media_id: !this.props.product.recurring && this.props.product.medias.length > 0 ? this.props.product.medias[0].id : null,
        media_ids_recurring: this.props.product.recurring && this.props.product.medias.length > 0 ? this.props.product.medias.map(media => media.id) : [],
        display_documents: false,
        recurring : this.props.product.recurring,
        tax_rates: [],
        tax_rate: this.props.product.tax_rate,
        address_required: this.props.product.address_required,
        highlighted: this.props.product.highlighted,
        duaj_publication_date: this.props.product.duaj_publication_date,
        duaj_quadri_code: this.props.product.duaj_quadri_code,
        products_one_shot_same_category: [],
        display_products_one_shot_linked_ids: false,
        products_one_shot_linked_ids: this.props.product.products_one_shot_ids,
        display: this.props.product.display,
        // type_product array of string : ['premium', 'regular']
        typesProduct: ['premium', 'regular','hors-série'],
        type_product: this.props.product.type_product,
        numero_product: this.props.product.numero_product
    };

    componentDidMount() {
        console.log('infos', this.props.product)
        this.props.fetchAll('type=application');
        this.props.fetchAllTaxRates()
            .then((res) => {
                if (res.payload && res.payload.data && res.payload.data.tax_rates) {
                    this.setState({ tax_rates: res.payload.data.tax_rates });
                }
            });
        this.searchProductsOneShotFromCategory()
    }

    _submit = () => {
        this.setState({ loading: true });

        const { name, description, category, sub_categories, color, media_id, media_ids_recurring, tax_rate, address_required, highlighted, duaj_publication_date, duaj_quadri_code, products_one_shot_linked_ids,display,type_product,numero_product } = this.state;
        const { stripe_id, price_id } = this.props.product;
        const file = this.fileRef && this.fileRef.files && this.fileRef.files.length > 0 ? this.fileRef.files[0] : null;
        console.log('file', file)
        if (sub_categories.length === 0) {
            this.setState({ loading: false });
            return toast.error('Veuillez renseigner au moins une sous-catégorie.');
        }
        if (file &&  file.type.startsWith("image/") && file.size > 500000) {
            this.setState({ loading: false });
            return toast.error('Veuillez mettre une image de moins de 500ko.');
        }
        if(!type_product || type_product === ''){
            this.setState({ loading: false });
            return toast.error('Veuillez renseigner le type de produit.');
        }
        this.props.updateProduct({
            name,
            description,
            color,
            category,
            sub_categories: JSON.stringify(sub_categories),
            file,
            stripe_id,
            price_id,
            media_id,
            media_ids_recurring: JSON.stringify(media_ids_recurring),
            tax_rate: tax_rate ? tax_rate : (this.state.tax_rates.length > 0 ? this.state.tax_rates[0].id : null),
            address_required: address_required ? 1 : 0,
            highlighted: highlighted ? 1 : 0,
            duaj_publication_date,
            duaj_quadri_code,
            products_one_shot_linked_ids: JSON.stringify(products_one_shot_linked_ids),
            display: display ? 1 : 0,
            type_product,
            numero_product,
        })
            .then((res) => {
                toast.success("Entité mise à jour.");
                this.props.onClose(true);
            })
            .catch((e) => {
                console.log('ERROR MISE À JOUR ----------')
                console.log(e.error)
                if (e.error && e.error.response && e.error.response.data)
                    toast.error(e.error.response.data.message);
                this.setState({ loading: false });
            })
    }

    toggleSubCategories(id, name) {
        let { sub_categories } = this.state;

        name = typeof name === 'object' ? name.name : name;

        if (!sub_categories.filter(sub_category => sub_category.id === id).length > 0)
            sub_categories.push({ id, name })
        else {
            let index = sub_categories.findIndex(sub_category => sub_category.id === id)

            if (index > -1) {
                sub_categories.splice(index, 1);
            }
        }

        this.setState({ sub_categories, queryResponse: [], query: '' });
    }

    handleChangeCategory = (e) => {
        this.setState({ query: e.target.value }, () => this.searchCategory())
    }

    renderDropdownItemCategory = (category) => {
        category = category.name
        return (
            <span>{category.length > 50 ? `${category.slice(0, 45)}...` : category}</span>
        )
    }

    searchCategory = () => {
        if (this.state.query.length > 0) {
            this.props.searchAll(this.state.query)
                .then(res => {
                    if (res.payload.data) this.setState({ queryResponse: res.payload.data.slice(0, 14)})
                })
                .catch((e) => {
                    if (e.error && e.error.response && e.error.response.data) {
                        this.setState({ _formData: e.error.response.data})
                    }
                    this.setState({ loading: false})
                })
        } else if (this.state.query.length === 0) {
            this.setState({ queryResponse: [] })
        }
    }

    changeMediaId = (media_id) => {
        return this.setState({ media_id })
    }

    toggleMediaIdsRecurring = (media_id) => {
        let { media_ids_recurring } = this.state;

        if (!media_ids_recurring.includes(media_id))
            media_ids_recurring.push(media_id);
        else {
            let index = media_ids_recurring.indexOf(media_id);
            if (index > -1) {
                media_ids_recurring.splice(index, 1);
            }
        }

        this.setState({ media_ids_recurring, products_one_shot_linked_ids: [] });
    }

    toggleProductsOneShot = (price_id) => {

        let { products_one_shot_linked_ids } = this.state;

        if (!products_one_shot_linked_ids.includes(price_id))
            products_one_shot_linked_ids.push(price_id);
        else {
            let index = products_one_shot_linked_ids.indexOf(price_id);
            if (index > -1) {
                products_one_shot_linked_ids.splice(index, 1);
            }
        }

        this.setState({ products_one_shot_linked_ids, media_ids_recurring: [] });
    }

    toggleDocuments = () => {
        this.setState({ display_documents: !this.state.display_documents })
    }

    toggleProductsOneShotLinked = () => {
        this.setState({ display_products_one_shot_linked_ids: !this.state.display_products_one_shot_linked_ids })
    }

    handleDateChange = (input, date) => {
        console.log("INPUT --->", input, "DATE --->", date)
        this.setState({ [input]: date === null ? null :
                moment(date).format("YYYY-MM-DD HH:mm")
        });
    };

    searchProductsOneShotFromCategory = () => {
        if (!this.state.recurring) return false;

        if (this.state.recurring && this.state.category === 'Document') {
            return this.setState({ products_one_shot_same_category: [] });
        }

        this.props.searchProductsOneShotFromCategory({ category: this.state.category })
            .then(res => {
                if (res.payload.data) this.setState({ products_one_shot_same_category: res.payload.data.products });
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data) {
                    this.setState({ _formData: e.error.response.data})
                }
                this.setState({ loading: false})
            })
    }

    render() {
        console.log('article SHOW////////////////////////////')
        console.log(this.props.recurring)
        console.log(this.state.recurring)
        console.log(this.state)
        console.log(this.props)
        return (
            <Modal title="Mettre à jour un produit ou un abonnement" onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit}>

                    <FormGroup>
                        <label>Nom du produit / de l'abonnement</label>
                        <Input required type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </FormGroup>

                    <FormGroup>
                        <label>Type</label>
                        <select
                            name="type_product"
                            id="type_product"
                            onChange={e => this.setState({ type_product: e.target.value })}
                            defaultValue={this.state.type_product ? this.state.type_product.toLowerCase() : ""}
                            className={'form-control'}
                        >
                            <option key={-1} value={""}>---- Séléctionnez un Type ---- </option>
                            {
                               // 2 options : premium ou normal
                                this.state.typesProduct.map((type, index) => (
                                    <option key={index} selected={this.state.type_product ? this.state.type_product.toLowerCase() === type.toLowerCase() : null} value={type}>{type}</option>
                                ))
                            }
                        </select>
                    </FormGroup>
                    {!this.state.recurring  ?
                    <FormGroup>
                        <label>Numéro de magazine</label>
                        <Input required type="text" value={this.state.numero_product} onChange={(e) => this.setState({ numero_product: e.target.value })}  />
                    </FormGroup>
                        : null }
                    <FormGroup>
                        <label>Description</label>
                        <TextArea required value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} rows={5}/>
                    </FormGroup>

                    <FormGroup>
                        <label>Catégorie</label>
                        <Input required type="text" value={this.state.category} onChange={(e) => this.setState({ category: e.target.value })} onBlur={this.searchProductsOneShotFromCategory} />
                    </FormGroup>

                    <FormGroup>
                        <label>Sous-catégories</label>
                        <div className={"entity-list"}>
                            {
                                this.state.sub_categories && this.state.sub_categories.map(entity => (
                                    <div className={"btn btn-sm btn-primary m-1"} key={entity.id}>
                                        {entity.name}
                                        <i onClick={() => this.toggleSubCategories(entity.id, entity.name)}
                                           className="fas fa-times ml-2"/>
                                    </div>
                                ))
                            }

                            <Autocomplete
                                query={this.state.query}
                                queryResponse={this.state.queryResponse}
                                handleChange={this.handleChangeCategory}
                                escape={() => this.setState({ query: "", queryResponse: [] })}
                                selectEntity={(category_id, category) => this.toggleSubCategories(category_id, category)}
                                renderDropdownItem={(category) => this.renderDropdownItemCategory(category)}
                                createEntity={false}
                                placeholder={"Rechercher une catégorie"}
                                className={"category"}/>
                        </div>
                    </FormGroup>

                    {/*<FormGroup>*/}
                    {/*    <label>Couleur (code hexadécimal ex: #8756F2)</label>*/}
                    {/*    <Input type="text" value={this.state.color} onChange={(e) => this.setState({ color: e.target.value })} />*/}
                    {/*</FormGroup>*/}

                    {
                        this.state.image ?
                            <FormGroup>
                                <img src={this.state.image} alt={'Produit/Abonnement'} style={{ maxWidth: '100%' }}/>
                            </FormGroup>
                            : null
                    }

                    <FormGroup>
                        <label>Modifier l'image</label>
                        <input
                            className={'d-block'}
                            type="file"
                            accept={".jpeg,.png,.webp"}
                            name="file" id="file"
                            ref={(ref) => this.fileRef = ref}
                        />
                    </FormGroup>

                    {
                        this.state.category === "Document" ?
                            <FormGroup>
                                <label>Modifier le document (<b>PDF seulement</b>)</label>
                                <button type={'button'} className={'btn btn-secondary mb-3 d-block'} onClick={() => this.toggleDocuments()}>Afficher/cacher les documents disponibles</button>
                                {
                                    this.state.display_documents ?
                                        this.props.media_entity.index.index === undefined ?
                                            <Empty/> :
                                            <div className="row">
                                                {
                                                    this.props.media_entity.index.index.map(media =>
                                                        <div className={'col-12'} key={media.id}>
                                                            {
                                                                this.state.recurring ?
                                                                    <input type="checkbox" name={'media'} className={'mr-2'} onChange={() => this.toggleMediaIdsRecurring(media.id)}
                                                                           checked={this.state.media_ids_recurring.includes(media.id)}/>
                                                                    :
                                                                    <input type="radio" name={'media'} className={'mr-2'}
                                                                           onChange={() => this.changeMediaId(media.id)} checked={this.state.media_id === media.id}/>                                                            }
                                                            <label htmlFor="">{media.label}</label>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        : null
                                }
                            </FormGroup>
                            : null
                    }

                    {
                        this.state.recurring && this.state.products_one_shot_same_category.length > 0 ?
                            <FormGroup>
                                <label>Lier des produits de la même catégorie (pour liseuse)</label>
                                <button type={'button'} className={'btn btn-secondary mb-3 d-block'} onClick={() => this.toggleProductsOneShotLinked()}>Afficher/cacher les produits disponibles</button>
                                {
                                    this.state.display_products_one_shot_linked_ids ?
                                        <div className="row">
                                            {
                                                this.state.products_one_shot_same_category.map(product =>
                                                    <div className={'col-12'} key={product.price_id}>
                                                        <input type="checkbox" name={'media'} className={'mr-2'} onChange={() => this.toggleProductsOneShot(product.price_id)}
                                                               checked={this.state.products_one_shot_linked_ids.includes(product.price_id)}/>
                                                        <label htmlFor="">{product.name}</label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        : null
                                }
                            </FormGroup>
                            : null
                    }

                    {
                        this.state.tax_rates.length > 0 ?
                            <FormGroup>
                                <label>Type de T.V.A</label>
                                <select
                                    name="tax_rate"
                                    id="tax_rate"
                                    onChange={e => this.setState({ tax_rate: e.target.value })}
                                    value={this.state.tax_rate}
                                    className={'form-control'}
                                >
                                    {
                                        this.state.tax_rates.map(tax_rate => {
                                            return (
                                                <option value={tax_rate.id} key={tax_rate.id}>
                                                    {tax_rate.country} {tax_rate.description} {tax_rate.percentage}% {' '}
                                                    {
                                                        tax_rate.inclusive ? 'Incluses' : 'Non incluses'
                                                    }
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </FormGroup>
                            : null
                    }

                    {
                        this.props.product.recurring ?
                            <FormGroup>
                                <input type="checkbox" name={'address_required'} className={'mr-2'} onChange={() => this.setState({ address_required: !this.state.address_required })} checked={this.state.address_required}/>
                                <span>Adresse requise lors de l'achat</span>
                            </FormGroup>
                            : null
                    }

                    <FormGroup className={'mt-3'}>
                        <input type="checkbox" name={'highlighted'} className={'mr-2'} onChange={() => this.setState({ highlighted: !this.state.highlighted })} checked={this.state.highlighted}/>
                        <span>Mis en avant sur le site</span>
                    </FormGroup>
                    <FormGroup className={'mt-3'}>
                        <input type="checkbox" name={'display'} className={'mr-2'} onChange={() => this.setState({ display: !this.state.display })} checked={this.state.display === true}/>
                        <span>Afficher sur le site</span>
                    </FormGroup>
                    {
                        !this.props.product.recurring ?
                            <div className={'mb-5 p-3 pb-0 rounded'} style={{ backgroundColor: '#dfdfdf' }}>
                                <p className={'mb-4 font-weight-bold'}>Liseuse Demain Un Autre Jour</p>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <FormGroup>
                                            <label>
                                                Date de publication
                                            </label>
                                            <Datepicker
                                                showTodayButton={true}
                                                onOpen={() => this.handleDateChange("duaj_publication_date", moment())}
                                                type={"date"} custom-control-inline
                                                handleDateChange={(date) => this.handleDateChange("duaj_publication_date", date)}
                                                selectedDate={this.state.duaj_publication_date}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <FormGroup>
                                            <label>Code quadri</label>
                                            <Input type="text" value={this.state.duaj_quadri_code} onChange={(e) => this.setState({ duaj_quadri_code: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            : null
                    }

                            <div className={'mb-5 p-3 pb-0 rounded'} style={{ backgroundColor: 'rgb(245 245 245)' }}>
                                <p className={'mb-4'}>Pour <b>les images</b>, merci d'importer une image qui <b>ne dépasse pas 500ko</b>. Il est préférable d'en réduire la taille pour faciliter l'affichage sur tous les appareils.<br/> Vous pouvez utiliser <a href="https://tinypng.com" target="_blank">ce site</a> pour compresser vos images.</p>
                            </div>

                    <Submit disabled={this.state.loading} className="btn btn-primary">Mettre à jour</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(({media_entity}) => ({media_entity}), {updateProduct, searchAll, fetchAll, fetchAllTaxRates, searchProductsOneShotFromCategory})(ProductUpdateModal);
