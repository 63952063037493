import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Form, Option, OptionGroup, Submit} from 'react-forms'
import Modal from '../../../components/Modal'
import { fetchAll } from '../../../data/entities/Category'
import { createChannelCategory } from '../../../data/entities/ChannelCategory'

class ChannelAddCategoryModal extends Component {

  state = {
    categories: [],
    category: null,
    loading: false
  }

  _fetch = () => {
    this.props.fetchAll()
    .then(resp => {
        let category = null
        if (resp.payload.data.length) category = resp.payload.data[0]
        this.setState({ categories: resp.payload.data, category })
    })
  }

  componentDidMount() {
    this._fetch()
  }

  _submit = () => {
    this.setState({ loading: true })
    if (this.state.category) this.props.createChannelCategory(this.props.id, {
      category: this.state.category
    })
    .then(resp => {if (resp.payload.data) 
      this.props.onClose(resp.payload.data.category)
    })
    .catch(e => {
      if (e.error && e.error.response && e.error.response.data)
        this.setState({ loading: false });
    });
  }

  render() {
    return (
      <Modal
        title="Ajouter une catégorie"
        onClose={() => this.props.onClose(false)}>
        <Form onSubmit={this._submit}>
            <OptionGroup
                onChangeValue={(category) => {
                  this.setState({ category })
                }}
                value={this.state.category}>
              {this.state.categories.map((c) => (
                  <Option value={c.id}>{c.name}</Option>
              ))}
            </OptionGroup>
            <Submit className={"btn btn-primary"} disabled={this.state.loading || !this.state.category}>Ajouter</Submit>
        </Form>
      </Modal>
    )
  }
}

export default connect(null, { fetchAll, createChannelCategory })(ChannelAddCategoryModal)