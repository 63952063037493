import React, {Component} from 'react';
import {Button, Card, CardBody, CardSubtitle, CardText, CardTitle} from "reactstrap";
import moment from "moment";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ArticleDeleteModal from "../entities/Article/ArticleDeleteModal";
import ArticleOverviewModal from "../entities/Article/ArticleOverviewModal";
import ArticleUnpublishModal from "../entities/Article/ArticleUnpublishModal";
import ArticlePublishModal from "../entities/Article/ArticlePublishModal";
import ArticleDuplicateModal from "../entities/Article/ArticleDuplicateModal";
import {fetchAll} from "../../data/entities/Article";

class RssArticle extends Component
{
    state = {
        showArticleOverviewModal: false,
        showArticleDeleteModal: false,
        article: this.props.article,
        showUnpublishModal: false,
        showPublishModal: false,
        showDuplicateModal: false,
        importing: false,
        imported: null
    };


    import = () => {
        this.props.importRssArticleIntoArticles(this.state.article.id)
            .then(res => {
                this.setState({
                article: res.payload.data
            }, this.props.fetchRss)
        })

        }




    _highlight = (highlight = true) => {
        this.props.highlightArticle(this.state.article.id, highlight)
            .then(res => {
                this.setState({
                    article: res.payload.data
                })
            })
    }

    extractHostnameAndIamgeLink = (url,img) => {
        let hostname;
        var absolutelink;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        if (Array.isArray(img) && img.length > 0) {
            img = img[0];
        }

        if (img && img.indexOf("//") > -1)
        {
            absolutelink = img
        }else if(this.isImgLink(img) == true){
            absolutelink = img
        } else {
            absolutelink = 'http://'+hostname.toString().replace("\n","")+img.toString().replace("\n","");
        }
        return absolutelink;
    }

    isImgLink = (url) =>  {
        if(typeof url !== 'string') return false;
        return(url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gmi) != null);
    }

    render()
    {


        const article = this.state.article


        const { categories } = article
        const pubdate = article.rss_data.length > 0 && article.rss_data[0] && article.rss_data[0].pubDate ? article.rss_data[0].pubDate : null
        var rl =''
        if(article.rss_data.length > 0 && article.rss_data[0].link !== undefined && article.rss_data[0].link != null){
            rl = (article.rss_data[0].link).toString()
        }
        var image_enc=''
        var img =''
        if(article.rss_data.length > 0 && article.rss_data[0].enclosure !== undefined && article.rss_data[0].enclosure != null){
            image_enc = article.rss_data[0].enclosure["@attributes"]
        }
        if(article.cover && article.cover.file){
            img = article.cover.file
        }
         else if(article.rss_data.length > 0 && article.rss_data[0].image !== undefined && typeof article.rss_data[0]['imageDeleted'] === 'undefined')
        {
            img = article.rss_data[0].image
        }

        const linkimage = img  ? img : image_enc ? image_enc.url : ''
        const image_data =this.extractHostnameAndIamgeLink(rl,linkimage)
        const style = { backgroundImage: `url(${image_data})` }
        //console.log('article :::::::', article)
        const external = !!article.source_platform_name
        const published = article.publication_status && moment(article.publication_start) <= moment() && (!article.publication_end || moment(article.publication_end) > moment())
        const highlighted = article.highlight_status && moment(article.highlight_start) <= moment() && (!article.highlight_end || moment(article.highlight_end) > moment())

        if(article.active == 1){
    return  (
        <div className={"col-md-6 col-lg-4 col-xl-3 mb-3 article-card"} >
            <Card>
                <div className="article-card-thumbnail" style={style}>
                    { !((article.rss_data[0].image)) && !article.rss_data[0].image && !article.rss_data[0].enclosure || (article.rss_data[0].image && !(article.rss_data[0].image || []).length ) ? <i className="fas fa-image"/> : null}
                    <div className="article-card-actions">
                        <div>
                            {published && highlighted ? (
                                <div className="btn btn-warning"
                                     onClick={() => this._highlight(false)}>
                                    <i className="fa fa-star-half"/>
                                    <span className="btn-tooltip top">Ne pas mettre en avant</span>
                                </div>
                            ) : null}
                            {published && !highlighted ? (
                                <div className="btn btn-warning"
                                     onClick={this._highlight}>
                                    <i className="fa fa-star"/>
                                    <span className="btn-tooltip top">Mettre en avant</span>
                                </div>
                            ) : null}
                            {!published && !article.scoop ?
                                <div className="btn btn-success"
                                     onClick={this.togglePublishModal}>
                                    <i className="fa fa-check"/>
                                    <span className="btn-tooltip top">Publier</span>
                                </div>
                                : null}
                            {published ?
                                <div className="btn btn-success"
                                     onClick={this.toggleUnpublishModal}>
                                    <i className="fa fa-times"/>
                                    <span className="btn-tooltip top">Dépublier</span>
                                </div>
                                : null}
                            <div className="btn btn-info"
                                 onClick={this.toggleDuplicateModal}>
                                <i className="fa fa-copy"/>
                                <span className="btn-tooltip top">Dupliquer</span>
                            </div>
                        </div>

                        <div>
                            <div className="btn btn-primary"
                                 onClick={() => this.setState({ showArticleOverviewModal: true })}>
                                <i className="fa fa-eye"/>
                                <span className="btn-tooltip">Prévisualiser</span>
                            </div>
                            <Link to={"/editor/article/" + article.id} className="btn btn-secondary">
                                <i className="fa fa-edit"/>
                                <span className="btn-tooltip">Modifier</span>
                            </Link>
                            <div className="btn btn-danger"
                                 onClick={() => this.setState({ showArticleDeleteModal: true })}>
                                <i className="fa fa-trash"/>
                                <span className="btn-tooltip">Suppression</span>
                            </div>

                            <Button onClick={this.import}>
                                <i className="fa fa-download"/>

                                <span className="btn-tooltip">Importer</span>
                            </Button>


                        </div>
                    </div>
                </div>
                <CardBody>
                    <div className="article-badges">
                        {external ? (
                            <div className="article-badge badge-external">
                                <i className="fa fa-globe"/>
                            </div>
                        ) : null}
                        {published ? (
                            <div className="article-badge badge-published">
                                <i className="fa fa-check"/>
                            </div>
                        ) : null}
                        {highlighted ? (
                            <div className="article-badge badge-highlighted">
                                <i className="fa fa-star"/>
                            </div>
                        ) : null}
                        {article.scoop ? (
                            <div className="article-badge badge-scoop">
                                <i className="fa fa-exclamation"/>
                            </div>
                        ) : null}
                    </div>
                    <div className={'words-count'}>
                        <i>
                            { article.content_length } mot{article.content_length > 1 && 's'}
                        </i>
                    </div>
                    <div style={{ minHeight: 78 }}>
                        <CardTitle>
                            <strong>{article.title}</strong>
                        </CardTitle>
                        <CardTitle>
                            <strong>{article.active == 1 ? 'active' : 'no'}</strong>
                        </CardTitle>
                    </div>
                    <CardText>
                        <i className="fa fa-user text-muted mr-2"/>
                        {article.author_name ? article.author_name : "Anonyme"}
                    </CardText>
                    <div className={"article-card-categories"}>
                        {categories.length ?
                            categories.map((category, i) => <div className={"badge badge-" + (i == 0 ? 'primary' : 'secondary')}>{category.name}</div>)
                            : <div className={"text-muted"}>Aucune catégorie</div>
                        }
                    </div>
                    { pubdate ?
                        <div>
                            <i className="fa fa-clock text-muted"/>{' '}
                            <b>Publié</b>: { pubdate }
                        </div>
                        : null
                    }

                    {article.rss_data.length ?

                        <div >
                            <label>
                                Article importé depuis <strong> <a title="Cliquez ici pour aller vers l'article source" href={article.rss_data[0].link ? article.rss_data[0].link : '#!'}  target="_blank"> {article.rss_feed ? article.rss_feed : 'un flux RSS'} </a> </strong>
                            </label>
                        </div> : <></>
                    }

                    {this.state.showArticleDeleteModal &&
                        <ArticleDeleteModal
                            data={article}
                            onClose={deleted => {
                                this.setState({ showArticleDeleteModal: false });
                                this.props.onDelete()
                            }}
                        />}

                    {this.state.showArticleOverviewModal &&
                        <ArticleOverviewModal
                            data={article}
                            link={true}
                            onClose={() => this.setState({ showArticleOverviewModal: false })}/>
                    }
                    {this.state.showUnpublishModal &&
                        <ArticleUnpublishModal
                            data={article}
                            onClose={() => this.toggleUnpublishModal()}
                        />
                    }
                    {this.state.showPublishModal &&
                        <ArticlePublishModal
                            data={article}
                            onClose={() => this.togglePublishModal()}
                        />
                    }
                    {this.state.showDuplicateModal &&
                        <ArticleDuplicateModal
                            data={article}
                            onClose={() => this.toggleDuplicateModal()}
                            duplicated={() => this.duplicated()}
                        />
                    }
                </CardBody>
            </Card>
        </div>
    )
}else{
    return (null);
}



    }

    toggleUnpublishModal = () => {
        this.setState({ showUnpublishModal: !this.state.showUnpublishModal });
    };

    togglePublishModal = () => {
        this.setState({ showPublishModal: !this.state.showPublishModal });
    };

    toggleDuplicateModal = () => {
        this.setState({ showDuplicateModal: !this.state.showDuplicateModal });
    };

    duplicated = () => {
        this.toggleDuplicateModal();
        this.props.updated();
    };
}
export default connect((user) => (user)
)(RssArticle);

