import React, {Component, Fragment } from 'react'
import {connect} from 'react-redux'
import moment from 'moment-timezone';
import SelectArticleModal from "./SelectArticleModal"
import "./BlockArticle.scss"
import { updateBlockData } from '../../data/Editor'
import { fetchArticle } from '../../data/entities/Article'
import Loader from '../../components/Loader'
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';

class BlockArticle extends Component {
  
  state = {
    article: null,
    showSelectArticleFModal: false,
    _loading: false
  }

  componentDidMount = () => {
    this._fetch()
  }

  _fetch = (id = null) => {
    if (!id) id = this.props.data.data.article
    if (!id) return;

    this.setState({ _loading: true })
      this.props.fetchArticle(id)
      .then((res) => {
        if (res.payload.data) this.setState({ article: res.payload.data, _loading: false })
      })
      .catch((e) => {
        if (e.error && e.error.response && e.error.response.data)
          toast.error(e.error.response.data.error)
        this.setState({ _loading: false })
      })
  }

  _getArticle = (article) => {
    this.setState({ _loading: true }, () => {
      this.props.updateBlockData(this.props.data.id, {
        article: article.id
      }, () => this._fetch(article.id))
    })
  }

  render() {
    return (
      <div className="block-article">
        {
          this.state._loading ? (
            <Loader />
          ) : (
            this.state.article ? (
              <Fragment>
                <div onClick={() => { this.setState({ showSelectArticleModal: true })}} className="edit">
                    <i class="fas fa-pen"></i>
                </div>
                <div className="display-article">
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-1">{this.state.article.title}</h5>
                    <small>{moment(this.state.article.publication_start).format("MMMM Do YYYY")}</small>
                  </div>
                  <p className="mb-1">{this.state.article.author_name}</p>

                </div>
              </Fragment>
            ) : (
              <div className="select-article">
                <button 
                  onClick={() => { this.setState({ showSelectArticleModal: true })}}
                  className="btn">
                  <i className="fa fa-plus"></i>
                  <span>Sélectionner un article</span>
                </button>
            </div>
            )
          )
        }
        {this.state.showSelectArticleModal &&
                <SelectArticleModal 
                  onClose={(selected) => { 
                    if (selected) this.setState({showSelectArticleModal: false }, this._getArticle(selected))
                    this.setState({showSelectArticleModal: false })}}/>}
      </div>
    )
  }
}

export default connect(null, { updateBlockData, fetchArticle })(BlockArticle)