import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_NEWSLETTER_SUBSCRIBER_INDEX = 'ENTITY_NEWSLETTER_SUBSCRIBER_INDEX'
const ENTITY_NEWSLETTER_SUBSCRIBER_INDEX_SUCCESS = 'ENTITY_NEWSLETTER_SUBSCRIBER_INDEX_SUCCESS'
const ENTITY_NEWSLETTER_SUBSCRIBER_INDEX_FAIL = 'ENTITY_NEWSLETTER_SUBSCRIBER_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_NEWSLETTER_SUBSCRIBER_INDEX, 'get', '/newsletter_subscribers')
}

export function searchAll(query)
{
    return request(ENTITY_NEWSLETTER_SUBSCRIBER_INDEX, 'post', '/newsletter_subscribers/search', { query })
}

const ENTITY_NEWSLETTER_SUBSCRIBER_RETRIEVE = 'ENTITY_NEWSLETTER_SUBSCRIBER_RETRIEVE'

export function fetchNewsletterSubscriber(id)
{
    return request(ENTITY_NEWSLETTER_SUBSCRIBER_RETRIEVE, 'get', `/newsletter_subscribers/${id}`)
}

const ENTITY_NEWSLETTER_SUBSCRIBER_CREATE = 'ENTITY_NEWSLETTER_SUBSCRIBER_CREATE'

export function createNewsletterSubscriber(data)
{
    return request(ENTITY_NEWSLETTER_SUBSCRIBER_CREATE, 'post', '/newsletter_subscribers', data)
}

const ENTITY_NEWSLETTER_SUBSCRIBER_UPDATE = 'ENTITY_NEWSLETTER_SUBSCRIBER_UPDATE'

export function updateNewsletterSubscriber(id, data)
{
    return request(ENTITY_NEWSLETTER_SUBSCRIBER_UPDATE, 'patch', `/newsletter_subscribers/${id}`, data)
}

const ENTITY_NEWSLETTER_SUBSCRIBERS_IMPORT = 'ENTITY_NEWSLETTER_SUBSCRIBERS_IMPORT'

export function importNewsletterSubscribers(id, data)
{
    return request(ENTITY_NEWSLETTER_SUBSCRIBERS_IMPORT, 'post', `/newsletters/${id}/subscribers/import`, data)
}

const ENTITY_NEWSLETTER_SUBSCRIBERS_EXPORT = 'ENTITY_NEWSLETTER_SUBSCRIBERS_EXPORT'

export function exportNewsletterSubscribers(id)
{
    return request(ENTITY_NEWSLETTER_SUBSCRIBERS_EXPORT, 'get', `/newsletters/${id}/subscribers/export`)
}

const ENTITY_NEWSLETTER_SUBSCRIBER_DELETE = 'ENTITY_NEWSLETTER_SUBSCRIBER_DELETE'

export function deleteNewsletterSubscriber(id)
{
    return request(ENTITY_NEWSLETTER_SUBSCRIBER_DELETE, 'delete', `/newsletter_subscribers/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityNewsletterSubscriberReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_NEWSLETTER_SUBSCRIBER_INDEX:
            return {...state, loading: true}
        case ENTITY_NEWSLETTER_SUBSCRIBER_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_NEWSLETTER_SUBSCRIBER_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
