import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';
import Modal from "../../../components/Modal";
import Datepicker from "../../../components/Datepicker"

import {updateAdvertising} from "../../../data/entities/Advertising";

import "./AdvertisingCreateModal.scss"

class AdvertisingUpdateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        name: this.props.data.name,
        publication_start: this.props.data.publication_start,
        publication_end: this.props.data.publication_end,
        publication_status: this.props.data.publication_status === true ? 1 : 0,
    }

    _submit = () => {
        this.setState({ _loading: true })

        const data = {...this.state}
        delete data._loading
        delete data._formData

        this.props.updateAdvertising(this.props.data.id, data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })
    }

    handleDateChange = (input, date) => {
        this.setState({ [input]: date === null ? null : moment(date)//.format("YYYY-MM-DD HH:mm")
        });
    };

    render()
    {
        console.log("DATA", this.props.data)
        return (
            <Modal title={this.props.data.name} onClose={() => this.props.onClose(false)} additionnalClass="advertising-create-update-modal">
                <Form onSubmit={this._submit} data={this.state._formData}>
					<Input
					    value={this.state.name} onChangeValue={(name) => this.setState({ name })}
					    placeholder="Nom" label="Nom" type="text"/>
					
					<div className={"form-group mb-3"}>
                        <div className={"form-group date"}>
                            <div className="custom-control custom-switch">
                                <input type="checkbox"
                                        onChange={() => this.setState({
                                            publication_status: this.state.publication_status === 1 ? 0 : 1},
                                            () => this.setState({
                                                    publication_start: this.state.publication_status === 0 ? null : moment(),//.format("YYYY-MM-DD HH:mm"),
                                                    publication_end: null
                                                })
                                        )}
                                        checked={this.state.publication_status === 1}
                                        className="custom-control-input" id="publication_status"/>
                                <label className="custom-control-label" htmlFor="publication_status">Campagne publiée</label>
                            </div>
                        </div>

                        {
                            this.state.publication_status === 1 &&
                            <Fragment>
                                <div className={"date-pickers"}>
                                    <div>
                                        <label>Date de début de publication:</label>
                                        <Datepicker
                                            showTodayButton={true}
                                            type={"datetime"}
                                            handleDateChange={(date) => this.handleDateChange("publication_start", date)}
                                            selectedDate={this.state.publication_start}/>
                                    </div>

                                </div>
                                <div className="date-pickers">
                                    <div>
                                        <label>Date de fin de publication:</label>
                                        <div className={"radios"}>
                                            <div className="custom-control custom-radio option-1">
                                                <input
                                                    onChange={() => this.handleDateChange("publication_end", moment())}
                                                    value={"updated_at"} type="radio" id="publication-end-1"
                                                    name="publication_end" className="custom-control-input"
                                                    checked={!!this.state.publication_end}
                                                />
                                                <label className={"custom-control-label"} htmlFor="publication-end-1">
                                                    <Datepicker
                                                        showTodayButton={true}
                                                        onOpen={() => this.handleDateChange("publication_end", moment())}
                                                        type={"datetime"} custom-control-inline
                                                        handleDateChange={(date) => this.handleDateChange("publication_end", date)}
                                                        selectedDate={this.state.publication_end}/>
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio option-2">
                                                <input
                                                    onChange={() => {this.handleDateChange("publication_end", null)}}
                                                    type="radio" id="publication-end-2" name="publication_end"
                                                    className="custom-control-input"
                                                    checked={!this.state.publication_end}/>
                                                <label className="custom-control-label"
                                                        htmlFor={"publication-end-2"}>Indéterminée</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
					
                    <Submit disabled={this.state._loading}>Sauvegarder</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { updateAdvertising })(AdvertisingUpdateModal)
