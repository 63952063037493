import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit,  CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';

import Modal from "../../../components/Modal";

import {fetchALLIons} from "../../../data/entities/StaticPage";

class FontAwsomeModal extends Component
{
    state = {
        IconsData: this.props.IconsData,
        iconClass: null,
        iconBgColor: "transparent",
        selected: ""
    }

    geticonvalue = (icon) => {
        this.props.PageIcon( {icon: this.state.iconClass})
    }

    render()
    {
        console.log(this.state)
        return (
            <di>

                <Modal title={"Ajouter une icône"} onClose={() => this.props.onClose(false)} PageIcon={() => this.geticonvalue }>
                    <div className="row">
                        {
                             this.state.IconsData.map( (value, key) => (
                                 <div className="col-2 pt-3 pb-3 text-center" style={{ backgroundColor: this.state.selected === key ? "rgba(0,0,0,0.6)" : "transparent" }}>
                                     <i
                                         key={key}
                                         className={value}
                                         style={{ fontSize: '25px', cursor: "pointer" }}
                                         onClick={() => {  this.props.PageIcon(value);;this.setState({ iconClass: value }); this.setState({selected: key});  } }
                                     ></i>
                                 </div>
                             ))
                        }
                    </div>
                </Modal>


            </di>
        )
    }
}

export default connect(null, { fetchALLIons })(FontAwsomeModal)
