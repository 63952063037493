import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_ARTICLE_CHANNEL_INDEX = 'ENTITY_ARTICLE_CHANNEL_INDEX'
const ENTITY_ARTICLE_CHANNEL_INDEX_SUCCESS = 'ENTITY_ARTICLE_CHANNEL_INDEX_SUCCESS'
const ENTITY_ARTICLE_CHANNEL_INDEX_FAIL = 'ENTITY_ARTICLE_CHANNEL_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_ARTICLE_CHANNEL_INDEX, 'get', '/article_channels')
}

export function searchAll(query)
{
    return request(ENTITY_ARTICLE_CHANNEL_INDEX, 'post', '/article_channels/search', { query })
}

const ENTITY_ARTICLE_CHANNEL_RETRIEVE = 'ENTITY_ARTICLE_CHANNEL_RETRIEVE'

export function fetchArticleChannel(id)
{
    return request(ENTITY_ARTICLE_CHANNEL_RETRIEVE, 'get', `/article_channels/${id}`)
}

const ENTITY_ARTICLE_CHANNEL_CREATE = 'ENTITY_ARTICLE_CHANNEL_CREATE'

export function createArticleChannel(data)
{
    return request(ENTITY_ARTICLE_CHANNEL_CREATE, 'post', '/article_channels', data)
}

const ENTITY_ARTICLE_CHANNEL_UPDATE = 'ENTITY_ARTICLE_CHANNEL_UPDATE'

export function updateArticleChannel(id, data)
{
    return request(ENTITY_ARTICLE_CHANNEL_UPDATE, 'patch', `/article_channels/${id}`, data)
}


const ENTITY_ARTICLE_CHANNEL_DELETE = 'ENTITY_ARTICLE_CHANNEL_DELETE'

export function deleteArticleChannel(id)
{
    return request(ENTITY_ARTICLE_CHANNEL_DELETE, 'delete', `/article_channels/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityArticleChannelReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_ARTICLE_CHANNEL_INDEX:
            return {...state, loading: true}
        case ENTITY_ARTICLE_CHANNEL_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_ARTICLE_CHANNEL_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}