import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer} from 'recharts';
import moment from 'moment-timezone';
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import {fetchArticlesStats, fetchComments} from "../data/entities/Article";
import CommentDeleteModal from "./entities/Comment/CommentDeleteModal";
import Empty from "../components/Empty";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import {checkPrivilege} from "../helpers";
import {Link} from "react-router-dom";

class HomePage extends Component
{
    state = {
        stats: null,
        comments: [],
        commentsPage: 1,
        showCommentDeleteModal: null
    };

    data = [];

    componentDidMount() {
        this.fetchStats()
        this.fetchComments()
    }

    fetchComments = () => {
        this.props.fetchComments(this.state.commentsPage)
            .then(res => {this.setState({
                commentsPage: this.state.commentsPage + 1,
                comments: [...this.state.comments, ...res.payload.data]
            })})
            .catch((e) => {});
    }

    fetchStats() {
        this.props.fetchArticlesStats()
            .then(res => {
                const stats = res.payload.data;
                this.data = Object.keys(stats).map((key) => {
                    return {
                        name: moment(key).format('DD/MM'),
                        uv: stats[key],
                        pv: 2400,
                        amt: 2400
                    }
                });
                this.setState({ stats })
            })
            .catch((e) => {
                if (
                    e.error &&
                    e.error.response &&
                    e.error.response.data &&
                    e.error.response.data.error
                )
                    toast.error(<FormattedMessage id={e.error.response.data.error} />);
            });
    }

    render()
    {
        return (
            <div>
                <Helmet title={localStorage.getItem("APP_NAME")} />
                <div className="row">
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0 font-weight-bold">Derniers commentaires</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.renderComments()}
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0 font-weight-bold">Articles consultés (app. mobile)</CardTitle>
                            </CardHeader>
                            <div className="m-3">
                                {this.renderLineChart()}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }

    renderLineChart = () => (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart data={this.data}>
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} tick={{ fill: '#999999' }} />
                <CartesianGrid stroke="#ddd" />
                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
            </LineChart>
        </ResponsiveContainer>
    );

    renderComments()
    {
        return (
                <div className={"comments"} style={{ maxHeight: '70vh', overflow: 'auto'}}>
                    {this.state.comments.length ?
                        (
                            <div>
                                {this.state.comments.map(comment =>
                                    <div className={"comment bg-light p-3 mb-2"} key={comment.id}>
                                        <header>
                                            <p>
                                                <i className="fas fa-user-circle mr-2"/>
                                                <strong>{comment.author_name}</strong>{' '}({comment.author_email}){' '}
                                                <span>{moment(comment.created_at).format("YYYY-MM-DD HH:mm")}</span>
                                                {checkPrivilege(this.props.user, 'article:manage') ? (
                                                    <button
                                                        className={"btn btn-sm btn-danger float-right"}
                                                        onClick={() =>  this.setState({showCommentDeleteModal: comment.id})}>
                                                        <i className="fas fa-trash-alt"/>
                                                    </button>) : null}
                                            </p>
                                        </header>
                                        <p className={"body"}>
                                            {comment.content}
                                        </p>
                                        <p className="text-muted">
                                            Article : <Link to={"/entities/article/" + comment.article.id}>{comment.article.title}</Link>
                                        </p>
                                    </div>
                                )}
                                <button
                                    onClick={this.fetchComments}
                                    className="btn btn-primary w-100">Voir plus</button>
                            </div>
                        ) : (
                            <Empty text={"Aucun commentaire"}/>
                        )
                    }
                    {this.state.showCommentDeleteModal !== null ? (
                        <CommentDeleteModal
                            id={this.state.showCommentDeleteModal}
                            onClose={deleted => {
                                if (deleted) {
                                    this.setState({ showCommentDeleteModal: null, comments: [], commentsPage: 1 }, this.fetchComments);
                                }
                                else this.setState({ showCommentDeleteModal: null });
                            }}
                        />
                    ) : null}
                </div>
        )
    }
}

export default connect(({ user }) => ({ user }), {fetchArticlesStats, fetchComments})(HomePage)
