import { request } from "../../helpers";

// Actions

const ENTITY_CONFIDENTIAL_DOCUMENT_INDEX = "ENTITY_CONFIDENTIAL_DOCUMENT_INDEX";
const ENTITY_CONFIDENTIAL_DOCUMENT_INDEX_SUCCESS = "ENTITY_CONFIDENTIAL_DOCUMENT_INDEX_SUCCESS";
const ENTITY_CONFIDENTIAL_DOCUMENT_INDEX_FAIL = "ENTITY_CONFIDENTIAL_DOCUMENT_INDEX_FAIL";

export function fetchAll(query_params) {
    return request(ENTITY_CONFIDENTIAL_DOCUMENT_INDEX, "get",`/confidential-documents?${query_params}`);
}

const ENTITY_CONFIDENTIAL_DOCUMENT_CREATE = 'ENTITY_CONFIDENTIAL_DOCUMENT_CREATE'

export function createConfidentialDocument(data)
{
    return request(ENTITY_CONFIDENTIAL_DOCUMENT_CREATE, 'post', '/confidential-documents', data)
}

const ENTITY_CONFIDENTIAL_DOCUMENT_GET_STRIPE_PRODUCTS = 'ENTITY_CONFIDENTIAL_DOCUMENT_GET_STRIPE_PRODUCTS'

export function getConfidentialDocumentStripeProducts()
{
    return request(ENTITY_CONFIDENTIAL_DOCUMENT_GET_STRIPE_PRODUCTS, 'get', '/confidential-documents/get-stripe-products')
}

const ENTITY_CONFIDENTIAL_DOCUMENT_DELETE = 'ENTITY_CONFIDENTIAL_DOCUMENT_DELETE'

export function deleteConfidentialDocument(id)
{
    return request(ENTITY_CONFIDENTIAL_DOCUMENT_DELETE, 'delete', `/confidential-documents/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false
};

// Reducer

export default function EntityConfidentialDocumentReducer(state = defaultState, action) {
    switch (action.type) {
        case ENTITY_CONFIDENTIAL_DOCUMENT_INDEX:
            return { ...state, loading: true };
        case ENTITY_CONFIDENTIAL_DOCUMENT_INDEX_FAIL:
            return { ...state, loading: false };
        case ENTITY_CONFIDENTIAL_DOCUMENT_INDEX_SUCCESS:
            return {
                ...state,
                loading: false,
                index: action.payload.data
            };
    }
    return state;
}
