import { request } from "../../helpers";

// Actions

const ENTITY_CONTACT_INDEX = "ENTITY_CONTACT_INDEX";
const ENTITY_CONTACT_INDEX_SUCCESS = "ENTITY_CONTACT_INDEX_SUCCESS";
const ENTITY_CONTACT_INDEX_FAIL = "ENTITY_CONTACT_INDEX_FAIL";

export function fetchAll() {
    return request(ENTITY_CONTACT_INDEX, "get", '/contact');
}

const ENTITY_CONTACT_RECEIVE = "ENTITY_CONTACT_RECEIVE";
export function receiveContactMessages() {
    return request(ENTITY_CONTACT_RECEIVE, "get", "/contact/receive")
}

const ENTITY_CONTACT_DELETE = "ENTITY_CONTACT_DELETE";
export function deleteContactMessage(id) {
    return request(ENTITY_CONTACT_DELETE, "delete", `/contact/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false
};

// Reducer

export default function EntityContactReducer(state = defaultState, action) {
    switch (action.type) {
        case ENTITY_CONTACT_INDEX:
            return { ...state, loading: true };
        case ENTITY_CONTACT_INDEX_FAIL:
            return { ...state, loading: false };
        case ENTITY_CONTACT_INDEX_SUCCESS:
            return {
                ...state,
                loading: false,
                index: action.payload.data
            };
    }
    return state;
}
