import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchBlocks, createBlock, deleteBlock, updateBlock, ARTICLE_BLOCK_HTML} from "../../data/Editor";
import ContentLoader from "../../components/ContentLoader";
import moment from 'moment-timezone';
import Block from "./Block";
import DropTarget from "./DropTarget";
import Loader from "../../components/Loader";

class Builder extends Component
{
    state = {
        blocks: null,
        loading: false
    }

    componentDidMount() {
        window.DragDropContext = {
            dragging: false,
            data: null
        }

        this._loadBlocks()
    }

    _loadBlocks()
    {
        this.setState({ loading: true }, () => {
            this.props.fetchBlocks(() => {
                this.setState({ loading: false })
            })
        })
    }

    render() {
        if (this.props.editor.blocks === null || this.state.loading) return (
            <div>
                <ContentLoader/>
                <p className="text-center text-muted">
                    <Loader />
                    <span className="ml-2">Préparation de l'éditeur</span>
                </p>
                <ContentLoader/>
            </div>
        )

        return (
            <div className="Builder" style={{ marginBottom: 100 }}>
                {this.props.editor.blocks.length === 0 ? <p>Ajoutez un bloc texte pour commencer !</p> : null}
                {this.renderBlocks()}
                <DropTarget
                    parent={null}
                    type={"Horizontal"}
                    target={null}
                />
            </div>
        )
    }

    renderBlocks()
    {
        const blocks = this.props.editor.blocks.filter((block) => (block.parent == null)).sort((a, b) => (a.position - b.position))

        return blocks.map((block) => {
            return (
                <Block
                    key={block.id}
                    data={block}
                />
            )
        })
    }
}

export default connect(({editor})=>({editor}), {fetchBlocks,createBlock,deleteBlock,updateBlock})(Builder)
