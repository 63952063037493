import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";
import Pagination from "../../../components/Pagination"
import ArticleCard from "./ArticleCard";

import { fetchAll, searchAll, createArticle } from "../../../data/entities/Article";
import { initEditor } from "../../../data/Editor"

import "./ArticleIndexPage.scss"
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label} from "reactstrap";
import OrderByFilter from "./OrderByFilter";
import CategoryFilter from "./CategoryFilter";
import AuthorFilter from "./AuthorFilter";
import ArticlesTabs from "../../../components/ArticlesTabs";

class ArticleIndexPage extends Component {
    state = {
        _loading: false,
        showArticleOverviewModal: false,

        search: "",
        page: 1,
        chunks: 12,
        filters: [],
        order_direction: 'DESC',
        order_by: 'created_at',
        category: null,
        author: null,
        highlighted: false,
        scoops: false,
    };

    componentDidMount() {
        this._fetch()
    }

    _fetch = () => {
        let {page, chunks, filters, search, order_by, order_direction, author, category, highlighted, scoops} = this.state

        if (author && author != 'me') author = author.id
        else if (author != 'me') author = ''

        if (category) category = category.id
        else category = ''

        let params = `page=${page}&chunks=${chunks}&order_by=${order_by}&` +
            `order_direction=${order_direction}&query=${encodeURIComponent(search.trim())}&` +
            `author=${author}&category=${category}&highlighted=${(highlighted ? 1 : 0)}&scoops=${(scoops ? 1 : 0)}`

        this.props.fetchAll(params);
    };

    _createArticle = () => {
        this.setState({ _loading: true })

        this.props.createArticle()
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.history.push(`/editor/article/${res.payload.data.id}`)
            })
            .catch((e) => {
                this.setState({ _loading: false })
            })
    }

    render() {
        if (this.props.article_entity.loading && !this.props.article_entity.lastUpdate)
            return <ContentLoader />;

        if (!this.props.article_entity.index || !this.props.article_entity.index.index)
            return <Empty />;

        return (
            <div className={"articles-index"}>
                <Helmet title="Articles"/>
                {this.renderHeader()}
                <ArticlesTabs tab="articles"/>


                {this.renderFilters()}

                {this.renderEntities()}

                <div>
                    <Pagination
                        data={this.props.article_entity.index}
                        chunks={this.state.chunks}
                        onChange={(page) => this.setState({ page }, this._fetch)}/>
                </div>
            </div>
        )
    }

    renderHeader()
    {
        return (
            <div className={"float-right text-right"}>
                <Loader display={this.props.article_entity.loading}/>
                <button
                    onClick={() => this._createArticle()}
                    className="btn btn-primary ml-3"><i className="fa fa-pen"/> Rédiger un article</button>
            </div>
        )
    }

    renderFilters()
    {
        return (
            <div className="articles-filters">
                <div className="search-bar mb-2">
                    <input type="text" value={this.state.search}
                           placeholder="Rechercher un article..."
                           onChange={(e) => this.setState({ search: e.target.value, page: 1 }, this._fetch)}
                           className="form-control"/>
                </div>
                <div className="row">
                    <div className="col">
                        <CategoryFilter
                            value={this.state.category}
                            onChange={(category) => this.setState({ category, page: 1 }, this._fetch)}
                        />
                        <AuthorFilter
                            value={this.state.author}
                            onChange={(author) => this.setState({ author, page: 1 }, this._fetch)}
                        />
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    checked={this.state.highlighted}
                                    onChange={() => this.setState({ highlighted: !this.state.highlighted, page: 1 }, this._fetch)}
                                    type="checkbox" />{' '}
                                Articles mis en avant
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    checked={this.state.scoops}
                                    onChange={() => this.setState({ scoops: !this.state.scoops, page: 1 }, this._fetch)}
                                    type="checkbox" />{' '}
                                Articles scoops
                            </Label>
                        </FormGroup>
                    </div>
                    <div className="text-right mr-3">
                        <OrderByFilter
                            onChange={(data) => this.setState(data, this._fetch)}
                            order_direction={this.state.order_direction}
                            order_by={this.state.order_by}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderEntities()
    {
        return (
            <div className={"row my-4 article-container"}>

                {
                    this.props.article_entity.index.index.length ?
                        this.props.article_entity.index.index.map(article => this.renderArticle(article))
                        :
                        <Empty/>
                }
            </div>
        );
    }

    renderArticle(article) {
        return (
            <ArticleCard
                key={article.id}
                onDelete={this._fetch}
                article={article}
                updated={this._fetch}
            />
        )
    }
}

export default connect(
    ({ article_entity, editor }) => ({ article_entity, editor }),
    { fetchAll, searchAll, createArticle, initEditor }
)(ArticleIndexPage);
