import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";

import ContentLoader from "../../../components/ContentLoader";
import "./ArticleDashboard.scss"
import {connect} from "react-redux";
import {fetchArticleStats} from "../../../data/entities/Article";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import {LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer} from 'recharts';
import moment from 'moment-timezone';

class ArticleStats extends Component {

    state = {
        stats: null
    }

    data = []

    componentDidMount() {
        this.fetchStats()
    }

    fetchStats() {
        this.props.fetchArticleStats(this.props.id)
            .then(res => {
                const stats = res.payload.data
                this.data = Object.keys(stats).map((key) => {
                    return {
                        name: moment(key).format('DD/MM'),
                        uv: stats[key],
                        pv: 2400,
                        amt: 2400
                    }
                })
                this.setState({ stats })
            })
            .catch((e) => {
                if (
                    e.error &&
                    e.error.response &&
                    e.error.response.data &&
                    e.error.response.data.error
                )
                    toast.error(<FormattedMessage id={e.error.response.data.error} />);
                this.props.history.push("/entities/article");
            });
    }

    render() {
        console.log("dashboard props--->", this.props.id);
        return (
            <Fragment>
                <Helmet title="Article" />
                {this.renderEntity()}
            </Fragment>
        );
    }

    renderEntity() {
        if (this.props.loading) return <ContentLoader />;
        if (this.state.stats === null) return <ContentLoader/>

        return (
            <div className={"article-info-container"}>
                {this.renderLineChart()}
                <p className="text-muted text-strong text-center mt-3">Vues depuis l'application mobile</p>
            </div>
        );
    }

    // data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}, {name: 'Page A', uv: 200, pv: 2400, amt: 2400}];

    renderLineChart = () => (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart data={this.data}>
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} tick={{ fill: '#999999' }} />
                <CartesianGrid stroke="#ddd" />
                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default connect(null, {fetchArticleStats})(ArticleStats);
