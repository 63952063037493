import React, {Component} from 'react';
import {connect} from 'react-redux'
import {
    addNewsletterEntity,
    deleteNewsletterEntity,
    fetchNewsletterEntity,
    moveNewsletterEntity
} from "../../../data/entities/Newsletter";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";
import {Input} from "react-forms";
import Autocomplete from "../Article/Autocomplete";
import {searchAll} from '../../../data/entities/Article'
import {sortableElement,sortableContainer} from "react-sortable-hoc";

const SortableItem = sortableElement(({value}) => (
    <div className="list-group-item">
        <div className="row">
            <div className="col">
                {value.title}
            </div>
        </div>
    </div>
));

const SortableContainer = sortableContainer(({children}) => {
    return <div className="list-group">{children}</div>;
});

class NewsletterArticles extends Component
{
    state = {
        loading: false,
        data: null,
        query: '',
        results: [],
    }

    componentDidMount() {
        this._fetch()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.newsletter_entity
            && this.props.newsletter_entity.index
                && this.props.newsletter_entity.index.manual_articles_count != nextProps.newsletter_entity.index.manual_articles_count)
            this._fetch()
    }

    _fetch = () => {
        this.setState({ loading: true })
        this.props.fetchNewsletterEntity('articles', this.props.id).then((res) => {
            this.setState({ data: res.payload.data.sort((a, b) => a.position - b.position), loading: false })
        })
    }

    render() {
        return (
            <div>
                <div className="list-group">
                    {this.renderArticles()}
                </div>
                {this.renderForm()}
            </div>
        );
    }

    renderForm()
    {
        if (!this.props.editable) return null;

        return (
            <Autocomplete
                createEntity={false}
                selectEntity={(id) => {
                    this.setState({ results: [], query: '' })
                    this.props.addNewsletterEntity('articles', this.props.id, id).then(() => {
                        this._fetch()
                    })
                }}
                escape={() => {
                    this.setState({ results: [], query: '' })
                }}
                query={this.state.query}
                renderDropdownItem={(item) => {
                    return item.title
                }}
                className=""
                placeholder="Ajouter un article..."
                queryResponse={this.state.results}
                handleChange={(e) => {
                    this.setState({
                        query: e.target.value
                    })

                    if (!e.target.value.length) {
                        this.setState({ results: [] })
                    }
                    else {
                        this.props.searchAll(e.target.value).then((res) => {
                            this.setState({ results: res.payload.data.index.slice(0, 14) })
                        })
                    }
                }}
            />
        )
    }

    renderArticles()
    {
        if (this.state.data === null)
            return <ContentLoader />

        if (this.state.data.length === 0)
            return <Empty text="Aucun article ajouté"/>

        return (
            <div style={{ position: 'relative', opacity: this.state.loading ? 0.5 : 1, cursor: this.state.loading ? 'wait': 'move' }}>
                <SortableContainer onSortEnd={(e) => {
                    const article = this.state.data[e.oldIndex - 1]
                    if (e.newIndex == article.position) return;
                    this.setState({ loading: true })
                    this.props.moveNewsletterEntity('articles', this.props.id, article.id, e.newIndex).then(this._fetch)
                }}>
                    {this.state.data.map((article, i) => {
                        return (
                            <div style={{ position: 'relative' }}>
                                <SortableItem
                                    key={`item-${article.id}`}
                                    index={i + 1}
                                    value={article} />
                                <div style={{ position: 'absolute', right: 10, top: 7, zIndex: 1000 }}>
                                    <button className="btn btn-default" onClick={(e) => {
                                        e.preventDefault()
                                        this.setState({ loading: true })
                                        this.props.deleteNewsletterEntity('articles', this.props.id, article.id).then(this._fetch)
                                        return false;
                                    }}>
                                        <i className="fa fa-trash"/>
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </SortableContainer>
            </div>
        )
    }
}

export default connect(({newsletter_entity}) => ({newsletter_entity}), {fetchNewsletterEntity, addNewsletterEntity, moveNewsletterEntity, deleteNewsletterEntity, searchAll})(NewsletterArticles);
