import React, { Component,Fragment } from 'react';
import {connect} from 'react-redux';
import {Form, Submit, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';
import MediaDeleteModal from "./MediaDeleteModal";

import {updateMedia} from "../../../data/entities/Media";

class MediaUpdateModal extends Component
{
    state = {
        _formData: null,
        file: this.props.data.file,
        type: this.props.data.type,
        label: this.props.data.label,
        copyright: this.props.data.copyright,
        share: this.props.data.share,
        data: this.props.data.data,
        fullData: this.props.data,

        showMediaDeleteModal: false
    }

    _submit = () => {
        this.setState({ _loading: true })
    
        const data = {...this.state}
        delete data._formData
    
        this.props.updateMedia(this.props.data.id, data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(true)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })
    }

    handleFocus = (e) => {
        e.target.select()
    }

    renderToolTab() {
        return (
            <div class="tab-pane fade" id="crop" role="tabpanel" aria-labelledby="crop-tab">
                <button 
                    onClick={() => {this.props.toggleCrop()}}
                    className="btn btn-outline-dark btn-sm crop-btn"
                    type="button"
                    container="body"
                    data-toggle="tooltip" 
                    data-placement="right" 
                    title="Rogner">
                    <i class="fas fa-crop-alt"></i>
                </button>
                /* {
                    this.props.allowCrop ? (
                        <Form >
                            <Input
                                className="form-control form-control-sm"
                                label="Largeur (px)"
                                value=""/>
                            <Input
                                className="form-control form-control-sm"
                                label="Hauteur (px)"
                                value=""/>
                            <Input
                                className="form-control form-control-sm"
                                label="Position X (px)"
                                value=""/>
                            <Input
                                className="form-control form-control-sm"
                                label="Position Y (px)"
                                value=""/>
                            <Submit className="btn btn-sm btn-primary">Sauvegarder</Submit>
                            <button onClick={() => this.props.updateImage()}>blob</button>
                        </Form>
                    ) : (
                        null
                    )
                } */
                <div>
                </div>
            </div>
        )
    }

    render()
    {
        return (
            <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                <div className="file-info">
                    <div>
                        <i className="fas fa-file"/>
                        <span>Fichier {this.state.type}</span>
                    </div>
                    <div className="button-group">
                        <button className="btn btn-info" onClick={() => window.open(this.state.file)}>Télécharger
                        </button>
                        <button
                            onClick={() => this.setState({showMediaDeleteModal: true})}
                            className="btn btn-danger">Supprimer
                        </button>
                    </div>
                </div>

                <Form onSubmit={this._submit} data={this.state._formData}>

                    <Input
                        value={this.state.copyright}
                        onChangeValue={(copyright) => this.setState({copyright})}
                        onFocus={(e) => this.handleFocus(e)}
                        placeholder="copyright" label="Copyright" type="text"
                        className="form-control form-control-sm"/>

                    <div>
                        <Input
                            value={this.state.label}
                            onChangeValue={(label) => this.setState({label})}
                            onFocus={(e) => this.handleFocus(e)}
                            placeholder="label" label="Légende" type="text"
                            className="form-control form-control-sm"/>
                    </div>

                    {/*<Input
                        value={this.state.share}
                        onChangeValue={(share) => this.setState({share})}
                        onFocus={(e) => this.handleFocus(e)}
                        placeholder="share" label="Share" type="number"
                        className="form-control form-control-sm"/>

                    <TextArea
                        value={this.state.data}
                        onChangeValue={(data) => this.setState({data})}
                        id="data" name="data" label="Data"/>*/}

                    <Submit disabled={this.state._loading}>Sauvegarder</Submit>
                </Form>

                {this.state.showMediaDeleteModal ? (
                    <MediaDeleteModal
                        data={this.state.fullData}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.props.onClose(true)
                            } else {
                                this.setState({showMediaDeleteModal: false})
                            }
                        }}
                    />
                ) : (
                    null
                )}
            </div>
        )
    }
    
}

export default connect(null, { updateMedia })(MediaUpdateModal)
