import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';

import Modal from "../../../components/Modal";

import {deleteNewsletterSubscriber} from "../../../data/entities/NewsletterSubscriber";

class NewsletterSubscriberDeleteModal extends Component
{
    state = {
        loading: false
    }

    _submit = () => {
        this.setState({ loading: true })

        this.props.deleteNewsletterSubscriber(this.props.data.id)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(true)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data) {
                    if (e.error.response.data.error) toast.error(<FormattedMessage id={e.error.response.data.error} />)
                    else if (e.error.response.data.message) toast.error(<FormattedMessage id={e.error.response.data.message} />)
                }
                this.setState({ loading: false })
            })
    }

    render()
    {
        return (
            <Modal title="Supprimer" onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit}>
                    <p>Êtes-vous sûr de vouloir supprimer cette entité ?</p>
                    <Submit disabled={this.state.loading} className="btn btn-danger">Supprimer</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { deleteNewsletterSubscriber })(NewsletterSubscriberDeleteModal)