import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import moment from 'moment-timezone';

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import SuggestedArticleCreateModal from "./SuggestedArticleCreateModal";

import {fetchAll, searchAll} from '../../../data/entities/SuggestedArticle'
import ArticlesTabs from "../../../components/ArticlesTabs";
import SuggestedArticleCard from "./SuggestedArticleCard";

class SuggestedArticleIndexPage extends Component
{
    state = {
        search: '',
    }

    _search = (search) => {
        this.setState({ search }, () => {
            this._fetch()
        })
    }

    _fetch = () => {
        if (this.state.search.length) this.props.searchAll(this.state.search)
        else this.props.fetchAll()
    }

    componentDidMount()
    {
        this.props.fetchAll()
    }

    render() {
        return (
            <div className="suggested-articles-index">
                <Helmet title="Articles suggérés" />

                <div className="float-right text-right">
                    <input
                        type="text"
                        value={this.state.search}
                        onChange={(e) => this._search(e.target.value)}
                        placeholder="Rechercher..."
                        className="form-control d-block ml-2"
                        style={{ maxWidth: 200 }}
                    />
                    <Loader display={this.props.suggested_article_entity.loading}/>
                </div>

                <ArticlesTabs tab="suggested"/>

                <br/>
                {this.renderEntities()}
            </div>
        )
    }

    renderEntities()
    {
        if (this.props.suggested_article_entity.loading && !this.props.suggested_article_entity.lastUpdate)
            return <ContentLoader/>;

        if (!this.props.suggested_article_entity.index.length) {
            return <Empty/>;
        }

        return (
            <div className="row">
                {this.props.suggested_article_entity.index.map(entity => (
                    <SuggestedArticleCard article={entity} key={entity.id}/>
                ))}
            </div>
        )
    }

}

export default connect(({ suggested_article_entity }) => ({ suggested_article_entity }), { fetchAll, searchAll })(SuggestedArticleIndexPage)
