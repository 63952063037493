import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import RoleCreateModal from "./RoleCreateModal";

import { fetchAll, searchAll } from "../../../data/entities/Role";

class RoleIndexPage extends Component {
  state = {
    search: "",
    showRoleCreateModal: false
  };

  _search = search => {
    this.setState({ search }, () => {
      this._fetch();
    });
  };

  _fetch = () => {
    if (this.state.search.length) this.props.searchAll(this.state.search);
    else this.props.fetchAll();
  };

  componentDidMount() {
    this.props.fetchAll();
  }

  render() {
    return (
      <div className="container">
        <Helmet title="Role" />
        <div className="row pb-5">
          <div className="col">
            <h1>Rôles</h1>
          </div>
          <div className="col text-right pt-2">
            <Loader display={this.props.role_entity.loading} />
            <input
              type="text"
              value={this.state.search}
              onChange={e => this._search(e.target.value)}
              placeholder="Rechercher..."
              className="form-control d-inline-block ml-2"
              style={{ maxWidth: 200 }}
            />
            <button
              onClick={() => this.setState({ showRoleCreateModal: true })}
              className="btn btn-primary ml-2"
            >
              Créer un rôle
            </button>
          </div>
        </div>

        {this.renderEntities()}
        {this.state.showRoleCreateModal ? (
          <RoleCreateModal
            onClose={created => {
              if (created) this._fetch();
              this.setState({ showRoleCreateModal: false });
            }}
          />
        ) : null}
      </div>
    );
  }

  renderEntities() {
    if (this.props.role_entity.loading && !this.props.role_entity.lastUpdate)
      return <ContentLoader />;

    if (!this.props.role_entity.index.length) {
      return <Empty />;
    }
    return (
      <table class="table">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col">Nom du rôle</th>
            <th scope="col" />
            <td scope="col">Identifiant technique</td>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {this.props.role_entity.index.map(entity => (
            <tr>
              <th scope="row">{entity.id}</th>
              <td>{entity.label}</td>
              <td />
              <td class="text-muted">{entity.name}</td>
              <td style={{ textAlign: "right" }}>
                <Link
                  className="btn btn-primary"
                  to={"/entities/role/" + entity.id}
                >
                  <i class="fas fa-eye" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default connect(
  ({ role_entity }) => ({ role_entity }),
  { fetchAll, searchAll }
)(RoleIndexPage);
