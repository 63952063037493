import React, {Component} from 'react';
import {fetchAll} from "../../../data/entities/ConfidentialDocument";
import { connect } from "react-redux";
import {Helmet} from "react-helmet";
import ContentLoader from "../../../components/ContentLoader";
import Pagination from "../../../components/Pagination";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import {Row, Col, Card, CardBody, CardText, Button, CardTitle} from 'reactstrap';
import moment from 'moment-timezone';
import "./ConfidentialDocument.scss";
import ConfidentialDocumentCreateModal from "./ConfidentialDocumentCreateModal";
import ConfidentialDocumentDeleteModal from "./ConfidentialDocumentDeleteModal";


class ConfidentialDocumentIndexPage extends Component
{
    state = {
        data: null,
        showConfidentialDocumentCreateModal: false,
        filters: [
            {
                name: "chunks",
                value: "24"
            },
            {
                name: "page",
                value: 1
            }
        ],
        ready: false,
        showConfidentialDocumentDeleteModal: false,
        delete_id: null
    };

    componentDidMount() {
        this._fetch();
    }

    _fetch = (params = null) => {

        this.setState({ loading: true });

        this.props
            .fetchAll(params)
            .then(res => {
                this.setState({ loading: false, data: res.payload.data, ready: true });
            })
            .catch(e => {
                if (
                    e.error &&
                    e.error.response &&
                    e.error.response.data &&
                    e.error.response.data.error
                )
                    toast.error(<FormattedMessage id={e.error.response.data.error} />);
                this.props.history.push("/");
            });
    };

    getEntitiesPerPage = () => {
        if (this.state.filters.find(filter => filter.name === "chunks") === undefined) {
            return 12
        } else {
            return this.state.filters.find(filter => filter.name === "chunks")
        }
    };

    handleChange = (name, value) => {
        if (name !== "page") {
            this.setState(
                { filters: this.state.filters.map(filter => {
                        if (filter.name === "page")
                            filter.value = 1
                        return filter
                    })
                })
        }

        if (value === "default") {
            this.setState(
                { filters: this.state.filters.filter(filter => filter.name !== name)} ,
                () => this._submitFilters())
        }

        else if (this.state.filters.find(filter => filter.name === name)) {
            this.setState(
                { filters:
                        this.state.filters.map(filter => {
                            if (filter.name === name) { filter.value = value }
                            return filter
                        })
                },
                () => this._submitFilters())
        }

        else {
            this.setState(
                { filters: [...this.state.filters, { name: name, value: value }] },
                () => this._submitFilters())
        }
    }

    _submitFilters = () => {
        console.log("submiting filters")
        const filters = this.state.filters

        if (filters.length) {
            let query_params = ""
            for (let i = 0; i < filters.length; i++) {
                if (i === filters.length -1) {
                    query_params = `${query_params}${filters[i].name}=${filters[i].value}`
                } else {
                    query_params = `${query_params}${filters[i].name}=${filters[i].value}&`
                }
            }
            this._fetch(query_params)
        }
        else {
            this._fetch()
        }
    }

    toggleDeleteModal = (id = null) => {
        this.setState({ delete_id: id ? id : null, showConfidentialDocumentDeleteModal: !this.state.showConfidentialDocumentDeleteModal });
    };

    render() {
        return (
            <div className={'confidential-documents'}>
                <Helmet title="Documents confidentiels"/>
                <div>
                    <div className="media-page-head">
                        <h1>Documents confidentiels</h1>
                        <div>
                            <Button
                                onClick={() => this.setState({ showConfidentialDocumentCreateModal: true })}
                                className="btn btn-primary ml-2" color={''}>
                                Créer un nouveau document
                            </Button>
                        </div>
                    </div>
                    <p>(important: module à utiliser seulement si vous avez un compte Stripe fonctionnel)</p>
                    {this.renderEntities()}
                    {this.renderPagination()}
                </div>

                {
                    this.state.showConfidentialDocumentCreateModal &&
                    <ConfidentialDocumentCreateModal
                        onClose={(created) => {
                            if (created) this._fetch()
                            this.setState({ showConfidentialDocumentCreateModal: false })
                        }}
                    />
                }

                {
                    this.state.showConfidentialDocumentDeleteModal &&
                    <ConfidentialDocumentDeleteModal
                        id={this.state.delete_id}
                        onClose={(deleted) => {
                            if (deleted) this._fetch()
                            this.setState({ showConfidentialDocumentDeleteModal: false })
                        }}
                    />
                }
            </div>
        )
    }

    renderEntities() {

        const {data} = this.state;

        if (!this.state.ready) return <ContentLoader/>;

        return (
            <Row>
                {data.index.map((document) => {

                    const images_length = document.images.length;
                    const image = images_length > 0 ? document.images[0] : '';

                    return (
                        <Col lg={3} md={4} key={document.id} className={'article-card my-3'}>
                            <Card>
                                <div className={'delete-button'}>
                                    <Button
                                        color={'danger'}
                                        onClick={() => this.toggleDeleteModal(document.id)}
                                    >Supprimer</Button>
                                </div>
                                <div className={'card-container'}>
                                    <div className={'image'} style={{ backgroundImage: `url(${image})` }}/>
                                    <CardBody>
                                        <CardTitle>
                                            <strong>{document.title}</strong>
                                        </CardTitle>

                                        <CardText>
                                            <div>
                                                {document.products.map((product) => {
                                                    return <p className={"my-1"} style={{ color: product.color }}>{product.name}</p>
                                                })}
                                            </div>
                                            <div className={'date'}>
                                            <span className={'d-block'}>
                                                {images_length}{' '}
                                                {
                                                    images_length === 1 ?
                                                        'image' :
                                                        'images'
                                                }
                                            </span>
                                                Ajouté le {moment(document.created_at).format('D/M/Y')} à {moment(document.created_at).format('HH:mm')}
                                            </div>
                                        </CardText>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        )
    }

    renderPagination() {
        return (
            <div className="row">
                <div className="col-9">
                    <Pagination
                        data={this.props.confidential_document_entity.index}
                        chunks={this.getEntitiesPerPage()}
                        onChange={(value) => this.handleChange('page', value)}/>
                </div>
                <div className={"col-3 text-right"}>
                    <select name={"chunks"}
                            onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                            className={"custom-select custom-select-sm"}>
                        <option value={"24"}>24 éléments par page</option>
                        <option value={"48"}>48 éléments par page</option>
                        <option value={"96"}>96 éléments par page</option>
                    </select>
                </div>
            </div>
        )
    }
}

export default connect(({ confidential_document_entity }) => ({ confidential_document_entity }), {fetchAll})(ConfidentialDocumentIndexPage);
