import React, {Component} from 'react';
import {Card, CardBody, CardText, CardTitle} from "reactstrap";
import {connect} from "react-redux";
import './ArticleCard.scss'


class ArticleNewsletterCard extends Component
{
    state = {
        article: this.props.article
    };

    render()
    {
        const article = this.state.article
        const { categories } = article
        const style = article.cover && article.cover.file_thumbnail ? { backgroundImage: `url(${article.cover.file_thumbnail})` } : {}

        return (
            <div
                className={"col-md-3 mb-3 article-card article-newsletter-card"}
                key={'article-' + article.id}
                onClick={() => this.props.selectArticle(article.id)}>
                <Card className={this.props.selected ? 'selected' : ''}>
                    <div className="article-card-thumbnail" style={style}>
                        {!article.cover || !article.cover.file_thumbnail ? <i className="fas fa-image"/> : null}
                    </div>
                    <CardBody>
                        <div style={{ minHeight: 78 }}>
                            <CardTitle>
                                <strong>{article.title}</strong>
                            </CardTitle>
                        </div>
                        <CardText>
                            <i className="fa fa-user text-muted mr-2"/>
                            {article.author_name ? article.author_name : "Anonyme"}
                        </CardText>
                        <div className={"article-card-categories"}>
                            {categories.length ?
                                categories.map((category, i) => <div className={"badge badge-" + (i == 0 ? 'primary' : 'secondary')}>{category.name}</div>)
                                : <div className={"text-muted"}>Aucune catégorie</div>
                            }
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default connect(null)(ArticleNewsletterCard);
