import React, { Component, Fragment } from 'react'
import "./Media.scss"

export default class Media extends Component {

  getMediaModal = (data, type) => {
      if (this.props.allowModal) this.props.getMediaModal(data, type)
  }

  render() {
      let data = this.props.data
      let crop = this.props.labelCrop

      if (data.type.startsWith("image/")) {
          return (
              <div onClick={() => this.getMediaModal(data, "image")} className={"image media"}>
                  <div>
                      <img src={this.props.data.file} alt={this.props.data.type}></img>
                  </div>
              </div>
          )
      }
      else if (data.type.startsWith("video/")) {
          return (
              <div onClick={() => this.getMediaModal(data, "video")} className="video media">
                  <div>
                      <video width="250">
                          <source type="video/mp4" src={this.props.data.file}/>
                      </video>
                      <figcaption>{data.label.length > 30 ? `${data.label.slice(0, 30)} ...` :  data.label}</figcaption>
                  </div>
              </div>
          )
      }
      else if (data.type.startsWith("audio/")) {
          return (
              <div onClick={() => this.getMediaModal(data, "audio")} className="audio media">
                  <div className="thumbnail">
                      <div>
                          <i className="fas fa-music"></i>
                      </div>
                  </div>
                  <label>{data.label.length > crop ? `${data.label.slice(0, crop-crop/2)} ... ${data.label.slice(data.label.length -10, data.label.length)}` :  data.label}</label>
              </div>
          )
      }
      else if (data.type.startsWith("application")) {
          return (
              <div onClick={() => this.getMediaModal(data, "application")} className={"application media"}>
                  <div className={"thumbnail"}>
                      <div>
                          <i className="fas fa-file"></i>
                      </div>
                  </div>
                  <label>{data.label.length > crop ? `${data.label.slice(0, crop-crop/2)} ... ${data.label.slice(data.label.length -10, data.label.length)}` :  data.label}</label>
              </div>
          )
      }

      else {
          return (
              <div className={"unknown-type-file media"}>
                  <div className={"thumbnail"}>
                      <div>
                          <i className="fas fa-question"></i>
                      </div>
                  </div>
                  <label>{data.label.length > crop ? `${data.label.slice(0, crop-crop/2)} ... ${data.label.slice(data.label.length -10, data.label.length)}` :  data.label}</label>
              </div>
          )
      }
  }
}

