import React, {Component} from 'react';
import {fetchAll} from '../../../data/entities/Category';
import {connect} from 'react-redux';
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";
import {Button} from 'reactstrap';
import Modal from '../../../components/Modal';
import Pagination from '../../../components/Pagination';
import CategoryCard from "../Category/CategoryCard";
import './ArticleCategoriesModal.scss';
import {toast} from "react-toastify";
import {createArticleCategories} from "../../../data/entities/Article";


class ArticleCategoriesModal extends Component
{
    state = {
        selected_categories: [],
        page: 1
    };

    componentDidMount() {
        this._fetch();
    };

    _fetch = () => {
        let {page} = this.state;
        let params = `page=${page}`;

        this.props.fetchAll(params);
    };

    _submit = () => {
        const {selected_categories} = this.state;

        this.props.createArticleCategories(this.props.id, selected_categories)
            .then((res) => {
                this.props.updated(res.payload.data);
            })
            .catch(e => {
                if (
                    e.error &&
                    e.error.response &&
                    e.error.response.data &&
                    e.error.response.data.error
                )
                    toast.error(e.error.response.data.error);
            });
    };

    render() {

        if (this.props.category_entity.loading &&
            !this.props.category_entity.lastUpdate
        )
            return <ContentLoader />;

        if (!this.props.category_entity.index || !this.props.category_entity.index.index) {
            return <Empty />;
        }

        return(
            <div className="article-categories-modal">
                <Modal title={"Veuillez cliquer sur une catégorie pour pouvoir l'ajouter à l'article"} onClose={() => this.props.onClose()}>

                    {this.renderEntities()}

                    <div className={'mt-4'}>
                        <Pagination
                            data={this.props.category_entity.index}
                            onChange={(page) => this.setState({ page }, this._fetch)}
                            className={'m-auto'}
                        />
                    </div>

                    <div className="row mt-4">
                        <div className="col text-center">
                            <Button color={'primary mr-2'} onClick={() => this._submit()}>Confirmer l'ajout</Button>
                            <Button color={'danger'} onClick={() => this.props.onClose()}>Annuler</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    renderEntities() {

        return(
            <div className="row mt-4">
                {this.props.category_entity.index.index.length ?
                    this.props.category_entity.index.index.map(entity =>
                        this.renderCategory(entity)
                    ) : <Empty/>}
            </div>
        );
    }

    renderCategory(entity) {

        return (
            <CategoryCard
                category={entity}
                selectCategory={(id) => this.toggleSelectedCategories(id)}
                selected={this.state.selected_categories.includes(entity.id)}
            />
        );
    }

    toggleSelectedCategories = (category_id) => {
        let { selected_categories } = this.state;

        if (!selected_categories.includes(category_id))
            selected_categories.push(category_id);
        else {
            let index = selected_categories.indexOf(category_id);
            if (index > -1) {
                selected_categories.splice(index, 1);
            }
        }

        this.setState({ selected_categories });
    }
}

export default connect(({category_entity}) => ({category_entity}), {fetchAll, createArticleCategories})(ArticleCategoriesModal);
