import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';

import Modal from "../../../components/Modal";
import Datepicker from "../../../components/Datepicker"
import "./OfferModal.scss"

import {createOffer} from "../../../data/entities/Offer";
import {fetchAll as fetchAllOfferContracts} from "../../../data/entities/OfferContract"
import {fetchAll as fetchAllActivityAreas} from "../../../data/entities/OfferActivityArea"
import {fetchAll as fetchAllOfferExperiences} from "../../../data/entities/OfferExperience"
import {fetchAll as fetchAllOfferFunctions} from "../../../data/entities/OfferFunction"
import {fetchAll as fetchAllOfferStudyLevels} from "../../../data/entities/OfferStudyLevel"

class OfferCreateModal extends Component
{
    constructor(props)
    {
        super(props)

        this.input = React.createRef();
        this.input_job_description = React.createRef();

        this.state = {
            _formData: null,
            _loading: false,

            offer_activity_areas: [],
            offer_contracts: [],
            experiences: [],
            offer_functions: [],
            offer_study_levels: [],

            name: null,
            company: null,
            contact_name: '',
            contact_email: '',
            contact_phone: '',
            city: null,
            district: null,
            country: null,
            salary: null,
            description: null,
            skills: null,
            offer_experience: null,
            company_description: null,
            offer_activity_area: null,
            offer_contract: null,
            offer_study_level: null,
            offer_function: null,

            logo: null,
            job_description: null,
            external_url: null,

            publication_start: null,
            publication_end: null,
            applications_stop_at: null,
            publication_status: 0
        }
    }

    componentDidMount() {
        this.props.fetchAllActivityAreas()
            .then(res => this.setState({ offer_activity_areas: res.payload.data }))
        this.props.fetchAllOfferContracts()
            .then(res => this.setState({ offer_contracts: res.payload.data }))
        this.props.fetchAllOfferExperiences()
            .then(res => this.setState({ experiences: res.payload.data}))
        this.props.fetchAllOfferFunctions()
            .then(res => this.setState({ offer_functions: res.payload.data}))
        this.props.fetchAllOfferStudyLevels()
            .then(res => this.setState({ offer_study_levels: res.payload.data}))
    }

    handleDateChange = (input, date) => {
        this.setState({ [input]: date === null ? null : moment(date)//.format("YYYY-MM-DD HH:mm")
        });
    };

    _select(e) {
        e.target.select()
    }

    _hideAlert() {
        this.setState({ _formData: null })
    }

    _submit = () => {
        this.setState({ _loading: true })

        if (this.state.name === null ||
            this.state.company === null ||
            this.state.city === null ||
            this.state.country === null
            //|| this.state.offer_contract === null
        ) {
                this.setState({ _formData: "Incomplete Form", _loading: false})
                return
        }

        const data = {...this.state}

        if (data.logo === null)
            delete data.logo
        else
            data.logo = this.input.current.files[0]

        if (data.job_description === null)
            delete data.job_description
        else
            data.job_description = this.input_job_description.current.files[0]

        delete data._loading
        delete data._formData
        delete data.offer_activity_areas
        delete data.experiences
        delete data.offer_contracts
        delete data.offer_functions
        delete data.offer_study_levels

        Object.keys(data).map(key => {
            if (data[key] === "" || data[key] === null)
                delete data[key]
        })
        if((data.logo && data.logo.size > 500000 && data.logo.type.startsWith("image/")) || (data.job_description && data.job_description.size > 500000 && data.job_description.type.startsWith("image/"))){
            toast.error("L'image est trop grande");
            this.setState({_loading: false})
        }else {
            this.props.createOffer(data)
                .then((res) => {
                    if (res.payload.data.message)
                        toast.success(<FormattedMessage id={res.payload.data.message}/>)
                    this.props.onClose(res.payload.data)
                })
                .catch((e) => {
                    if (e.error && e.error.response && e.error.response.data)
                        this.setState({_formData: e.error.response.data})
                    this.setState({_loading: false})
                })
        }
    }


    render() {
        return (
            <Modal title={"Créer une offre"} onClose={() => this.props.onClose(false)} additionnalClass={"offer-form"}>
                <Form onSubmit={this._submit} data={this.state._formData}>

                    {this.state._formData === "Incomplete Form" &&
                        <div className={"alert alert-danger"}>
                            Veuillez compléter le formulaire.
                        </div>}

                    <label>Intitulé du poste <span>*</span></label>
					<Input
                        value={this.state.name} onChangeValue={(name) => this.setState({ name })}
                        onFocus={() => this._hideAlert()}
					    placeholder="Intitulé du poste" type="text"/>

                    <label>Nom du recruteur</label>
                    <Input
                        value={this.state.contact_name} onChangeValue={(contact_name) => this.setState({ contact_name })}
                        onFocus={() => this._hideAlert()} placeholder="Optionnel" type="text"/>

                    <label>Adresse e-mail du recruteur</label>
                    <Input
                        value={this.state.contact_email} onChangeValue={(contact_email) => this.setState({ contact_email })}
                        onFocus={() => this._hideAlert()} placeholder="Optionnel" type="text"/>

                    <label>Téléphone du recruteur</label>
                    <Input
                        value={this.state.contact_phone} onChangeValue={(contact_phone) => this.setState({ contact_phone })}
                        onFocus={() => this._hideAlert()} placeholder="Optionnel" type="text"/>

                    <label>Entreprise <span>*</span></label>
					<Input
                        value={this.state.company} onChangeValue={(company) => this.setState({ company })}
                        onFocus={() => this._hideAlert()}
					    placeholder="Entreprise" type="text"/>

                    <label>Logo d'entreprise</label>
                    {this.state.logo ? (
                        <img src={this.state.logo}
                             key={"logo_data"}
                             className="d-inline-block"
                             style={{ maxWidth: 400, width: 'fit-content' }} />
                    ) : null}
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            this.input.current.click()
                            return false
                        }}
                        className="btn btn-secondary mb-3 btn-inline-block w-50">Sélectionner une image</button>

                    <br />

                    <label>Ville <span>*</span></label>
					<Input
                        value={this.state.city} onChangeValue={(city) => this.setState({ city })}
                        onFocus={() => this._hideAlert()}
					    placeholder="Ville" type="text"/>

					<Input
					    value={this.state.district} onChangeValue={(district) => this.setState({ district })}
					    placeholder="Région" label="Région" type="text"/>

                    <label>Pays <span>*</span></label>
					<Input
                        value={this.state.country} onChangeValue={(country) => this.setState({ country })}
                        onFocus={() => this._hideAlert()}
					    placeholder="Pays" type="text"/>

                    <Input
                        value={this.state.salary} onChangeValue={(salary) => this.setState({ salary })}
                        placeholder="Rémunération" label="Rémunération" type="text"/>


                    <label>Expériences</label>
                    <select
                        value={this.state.offer_experience}
                        onChange={(e) => this.setState({offer_experience: parseInt(e.target.value)})}
                        className="custom-select mb-3 form-control" id="page">
                        <option>-- Expérience --</option>
                        {
                            this.state.experiences.map(entity => (
                                <option
                                    value={entity.id}
                                    className="dropdown-item"
                                    href="#">{entity.name}</option>
                            ))
                        }
                    </select>

                    <label>Fonction</label>
                    <select
                        value={this.state.offer_function}
                        onChange={(e) => this.setState({offer_function: parseInt(e.target.value)})}
                        className="custom-select mb-3 form-control" id="page">
                        <option>-- Fonction --</option>
                        {
                            this.state.offer_functions.map(entity => (
                                <option
                                    value={entity.id}
                                    className="dropdown-item"
                                    href="#">{entity.name}</option>
                            ))
                        }
                    </select>


                    <label>Niveau d'études</label>
                    <select
                        value={this.state.offer_study_level}
                        onChange={(e) => this.setState({offer_study_level: parseInt(e.target.value)})}
                        className="custom-select mb-3 form-control" id="page">
                        <option>-- Niveau d'étude --</option>
                        {
                            this.state.offer_study_levels.map(entity => (
                                <option
                                    value={entity.id}
                                    className="dropdown-item"
                                    href="#">{entity.name}</option>
                            ))
                        }
                    </select>

                    <label>Secteur d'activité</label>
                    <select
                        value={this.state.offer_activity_area}
                        onChange={(e) => this.setState({offer_activity_area: parseInt(e.target.value)})}
                        className="custom-select mb-3 form-control" id="page">
                        <option>-- Secteur d'activité --</option>
                        {
                            this.state.offer_activity_areas.map(entity => (
                                <option
                                    value={entity.id}
                                    className="dropdown-item"
                                    href="#">{entity.name}</option>
                            ))
                        }
                    </select>

                    <label>Type de contrat</label>
                    <select
                        value={this.state.offer_contract}
                        onChange={(e) => this.setState({offer_contract: parseInt(e.target.value)})}
                        onFocus={() => this._hideAlert()}
                        className="custom-select mb-3 form-control" id="page">
                        <option>-- Type de contrat --</option>
                        {
                            this.state.offer_contracts.map(entity => (
                                <option
                                    value={entity.id}
                                    className="dropdown-item"
                                    href="#">{entity.name}</option>
                            ))
                        }
                    </select>

					<TextArea
					    value={this.state.description}
					    onChangeValue={(description) => this.setState({ description })}
					    id="description" name="description" label="Description du poste" rows="4"/>
					<TextArea
					    value={this.state.skills}
					    onChangeValue={(skills) => this.setState({ skills })}
					    id="skills" name="skills" label="Compétences" rows="4"/>
					<TextArea
					    value={this.state.company_description}
					    onChangeValue={(company_description) => this.setState({ company_description })}
					    id="company_description" name="company_description" label="Description de l'entreprise" rows="4"/>

                    <label>Fiche de poste</label>
                    {this.state.job_description ? (
                        <img src={this.state.job_description}
                             key={"job_description_data"}
                             className="d-inline-block"
                             style={{ maxWidth: 400, width: 'fit-content' }} />
                    ) : null}
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            this.input_job_description.current.click()
                            return false
                        }}
                        className="btn btn-secondary mb-3 btn-inline-block w-50">Sélectionner un document</button>

                    <label>URL de candidature externe</label>
                    <Input
                        value={this.state.external_url} onChangeValue={(external_url) => this.setState({ external_url })}
                        onFocus={() => this._hideAlert()}
                        placeholder="Optionnel" type="text"/>

                    <div className={"form-group dates"}>
                        <div className={"form-group date"}>
                            <div className="custom-control custom-switch">
                                <input type="checkbox"
                                        onChange={() => this.setState({
                                            publication_status: this.state.publication_status === 1 ? 0 : 1},
                                            () => this.setState({
                                                    publication_start: this.state.publication_status === 0 ? null : moment(),
                                                    publication_end: null
                                                })
                                        )}
                                        checked={this.state.publication_status === 1}
                                        className="custom-control-input" id="publication_status"/>
                                <label className="custom-control-label" htmlFor="publication_status">Offre publiée</label>
                            </div>
                        </div>

                        {
                            this.state.publication_status === 1 &&
                            <Fragment>
                                <div className={"date-pickers"}>
                                    <div>
                                        <label>Date de début de publication:</label>
                                        <Datepicker
                                            showTodayButton={true}
                                            type={"datetime"}
                                            handleDateChange={(date) => this.handleDateChange("publication_start", date)}
                                            selectedDate={this.state.publication_start}/>
                                    </div>

                                </div>
                                <div className="date-pickers">
                                    <div>
                                        <label>Date de fin de publication:</label>
                                        <div className={"radios"}>
                                            <div className="custom-control custom-radio option-1">
                                                <input
                                                    onChange={() => this.handleDateChange("publication_end", moment())}
                                                    value={"updated_at"} type="radio" id="publication-end-1"
                                                    name="publication_end" className="custom-control-input"
                                                    checked={!!this.state.publication_end}
                                                />
                                                <label className={"custom-control-label"} htmlFor="publication-end-1">
                                                    <Datepicker
                                                        showTodayButton={true}
                                                        onOpen={() => this.handleDateChange("publication_end", moment())}
                                                        type={"datetime"}custom-control-inline
                                                        handleDateChange={(date) => this.handleDateChange("publication_end", date)}
                                                        selectedDate={this.state.publication_end}/>
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio option-2">
                                                <input
                                                    onChange={() => {this.handleDateChange("publication_end", null)}}
                                                    type="radio" id="publication-end-2" name="publication_end"
                                                    className="custom-control-input"
                                                    checked={!this.state.publication_end}/>
                                                <label className="custom-control-label"
                                                        htmlFor={"publication-end-2"}>Indéterminée</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="date-pickers">
                                    <div>
                                        <label>Date limite de candidature:</label>
                                        <div className={"radios"}>
                                            <div className="custom-control custom-radio option-1">
                                                <input
                                                    onChange={() => this.handleDateChange("applications_stop_at", moment())}
                                                    value={"updated_at"} type="radio" id="applications-stop-at-1"
                                                    name="applications_stop_at" className="custom-control-input"
                                                    checked={!!this.state.applications_stop_at}
                                                />
                                                <label className={"custom-control-label"} htmlFor="applications-stop-at-1">
                                                    <Datepicker
                                                        showTodayButton={true}
                                                        onOpen={() => this.handleDateChange("applications_stop_at", moment())}
                                                        type={"datetime"}custom-control-inline
                                                        handleDateChange={(date) => this.handleDateChange("applications_stop_at", date)}
                                                        selectedDate={this.state.applications_stop_at}/>
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio option-2">
                                                <input
                                                    onChange={() => {this.handleDateChange("applications_stop_at", null)}}
                                                    type="radio" id="applications-stop-at-2" name="applications_stop_at"
                                                    className="custom-control-input"
                                                    checked={!this.state.applications_stop_at}/>
                                                <label className="custom-control-label"
                                                        htmlFor={"applications-stop-at-2"}>Indéterminée</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                    <div className="form-group upload mb-4 px-4 pt-3" style={{backgroundColor: "#eee"}}>
                        <p className="text-muted" style={{listStyle: "disc outside none", display: "list-item",marginLeft: "2%"}}>
                            <b>Pour les images</b>, merci d'importer une image qui <b>ne dépasse pas 500ko</b>.
                            Il est préférable d'en réduire la taille pour faciliter l'affichage sur tous les appareils.
                            Vous pouvez utiliser <a href="https://tinypng.com" target="_blank">ce site</a> pour compresser vos images.
                        </p>
                    </div>
                    <span className="required"><span>* </span>Champs requis</span>

                    <Submit disabled={this.state._loading} className="btn btn-primary submit">Créer</Submit>


                </Form>

                <input
                    className="d-none"
                    ref={this.input}
                    type="file"
                    accept={"image/png, image/jpeg, image/gif ,image/webp"}
                    onChange={(e) => {
                        if (!e.target.files.length) return;

                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({ logo: reader.result })
                        }
                        reader.readAsDataURL(e.target.files[0]);
                    }}
                />

                <input
                    className="d-none"
                    ref={this.input_job_description}
                    type="file"
                    accept={"image/png, image/jpeg, application/pdf"}
                    onChange={(e) => {
                        if (!e.target.files.length) return;

                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({ job_description: reader.result })
                        }
                        reader.readAsDataURL(e.target.files[0]);
                    }}
                />
            </Modal>
        )
    }
}

export default connect(
    null, {
    createOffer,
    fetchAllActivityAreas,
    fetchAllOfferContracts,
    fetchAllOfferExperiences,
    fetchAllOfferFunctions,
    fetchAllOfferStudyLevels
})(OfferCreateModal)
