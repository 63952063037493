import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import {Col, Row, Tooltip} from "reactstrap";

import ContentLoader from "../../../components/ContentLoader";
import "./NewsletterUpdateForm.scss"

import {
    fetchNewsletter,
    triggerNewsletter
} from "../../../data/entities/Newsletter";
import NewsletterUpdateForm from "./NewsletterUpdateForm";
import NewsletterDeleteModal from "./NewsletterDeleteModal";
import NewsletterSubscribersModal from "./NewsletterSubscribersModal";
import NewsletterPreviewModal from "./NewsletterPreviewModal";
import {checkPrivilege} from "../../../helpers";

class NewsletterViewPage extends Component {
    state = {
        id: this.props.match.params.id,
        data: null,
        loading: true,
        newsletter_sending: false,
        tooltipOpen: false,

        showNewsletterSubscribersModal: false,
        showNewsletterDeleteModal: false,
        showNewsletterPreviewModal: false,
    };

    componentDidMount() {
        this._fetch();
    }

    _fetch = () => {
        this.props
            .fetchNewsletter(this.state.id)
            .then(res => {
                this.setState({ loading: false, data: res.payload.data });
            })
            .catch(e => {
                console.log(e);
                if (
                    e.error &&
                    e.error.response &&
                    e.error.response.data &&
                    e.error.response.data.error
                )
                    toast.error(<FormattedMessage id={e.error.response.data.error} />);
                this.props.history.push("/entities/newsletter");
            });
    }

    triggerNewsletter() {
        this.setState({ newsletter_sending: true });
        this.props.triggerNewsletter(this.state.id)
            .then(res => {
                toast.success(res.payload.data.message);
                this.setState({ newsletter_sending: false });
            })
            .catch(e => {
                console.error(e);
            })
    }

    render() {
        return (
            <div className="container">
                <Helmet title="Newsletter" />
                <h1 className="pb-3">Newsletter</h1>
                {this.renderEntity()}
            </div>
        );
    }

    toggle = () => {
        this.setState({ tooltipOpen: !this.state.tooltipOpen })
    }

    renderEntity() {
        if (this.state.loading) return <ContentLoader />;

        return (
            <Fragment>
                <p>
                    <button
                        onClick={() =>
                            this.setState({ showNewsletterPreviewModal: true })
                        }
                        className="btn btn-primary"
                    >
                        <i className="fas fa-eye pr-2" /> Prévisualiser
                    </button>

                    <button
                        onClick={() =>
                            this.setState({ showNewsletterSubscribersModal: true })
                        }
                        className="btn btn-secondary ml-2"
                        disabled={!checkPrivilege(this.props.user, 'newsletter:subscribers')}
                    >
                        <i className="fas fa-users pr-2" /> {this.state.data.subscribers} abonnés
                    </button>

                    {checkPrivilege(this.props.user, 'newsletter:test') ? (
                        <button
                            id="TooltipExample"
                            onClick={() => this.triggerNewsletter()}
                            className="btn btn-warning ml-2 button_send_newsletter"
                            disabled={this.state.newsletter_sending}
                        >
                            <i className="fas fa-envelope pr-2" /> {this.state.data.manual ? "Tester maintenant" : "Tester manuellement"}
                        </button>
                    ) : null}

                    {checkPrivilege(this.props.user, 'newsletter:delete') ? (
                        <button
                            onClick={() => this.setState({ showNewsletterDeleteModal: true })}
                            className="btn btn-danger ml-2"
                        >
                            Supprimer
                        </button>
                    ) : null}

                    <Tooltip placement="top" isOpen={this.state.tooltipOpen} target="TooltipExample" toggle={this.toggle}>
                        La newsletter vous sera envoyée sur votre adresse.
                    </Tooltip>
                </p>

                <hr/>

                <NewsletterUpdateForm data={this.state.data} updated={() => this._fetch()}/>

                {this.state.showNewsletterDeleteModal ? (
                    <NewsletterDeleteModal
                        data={this.state.data}
                        onClose={deleted => {
                            if (deleted) {
                                this.props.history.push("/entities/newsletter");
                            } else this.setState({ showNewsletterDeleteModal: false });
                        }}
                    />
                ) : null}
                {this.state.showNewsletterPreviewModal ? (
                    <NewsletterPreviewModal
                        data={this.state.data}
                        onClose={() => {
                            this.setState({ showNewsletterPreviewModal: false });
                        }}
                    />
                ) : null}
                {this.state.showNewsletterSubscribersModal ? (
                    <NewsletterSubscribersModal
                        id={this.state.data.id}
                        onUpdateSubscribers={() => {
                            this.props.fetchNewsletter(this.state.id).then(res => {
                                this.setState({ data: res.payload.data });
                            });
                        }}
                        onClose={() =>
                            this.setState({ showNewsletterSubscribersModal: false })
                        }
                    />
                ) : null}
            </Fragment>
        );
    }
}

export default connect(
    ({ newsletter_entity, user }) => ({ newsletter_entity, user }),
    { fetchNewsletter, triggerNewsletter }
)(NewsletterViewPage);
