import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";
import moment from 'moment-timezone';


import NewsletterCreateModal from "./NewsletterCreateModal";

import {createNewsletter, fetchAll, searchAll} from "../../../data/entities/Newsletter";

class NewsletterIndexPage extends Component {
  state = {
    search: "",
    creating: false
  };

  _search = search => {
    this.setState({ search }, () => {
      this._fetch();
    });
  };

  _fetch = () => {
    if (this.state.search.length) this.props.searchAll(this.state.search);
    else this.props.fetchAll();
  };

  _createNewsletter = () => {
    this.setState({ creating: true }, () => {
      this.props.createNewsletter().then((res) => {
        const newsletter = res.payload.data
        this.setState({ creating: false })
        console.log(newsletter)
        this.props.fetchAll();
      })
    })
  }

  componentDidMount() {
    this.props.fetchAll();
  }

  render() {
    console.log(this.props.newsletter_entity);
    return (
      <div>
        <Helmet title="Newsletter" />
        <div className="row pb-5 pt-3">
          <div className="col-4">
            <h1>Newsletters</h1>
          </div>
          <div className="col text-right pt-2">
            <Loader display={this.props.newsletter_entity.loading} />
            <input
              type="text"
              value={this.state.search}
              onChange={e => this._search(e.target.value)}
              placeholder="Rechercher..."
              className="form-control d-inline-block ml-2"
              style={{ maxWidth: 200 }}
            />
            <button
              onClick={() => this._createNewsletter()}
              className="btn btn-primary ml-2"
              disabled={this.state.creating}
            >
              Créer une newsletter
            </button>
          </div>
        </div>

        {this.renderEntities()}
      </div>
    );
  }

  renderEntities() {
    if (
      this.props.newsletter_entity.loading &&
      !this.props.newsletter_entity.lastUpdate
    )
      return <ContentLoader />;

    if (!this.props.newsletter_entity.index.length) {
      return <Empty />;
    }

    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Nom</th>
            <th scope="col">Statut</th>
            <th scope="col">Type</th>
            <th scope="col">Envoi programmé</th>
            <th scope="col">Abonnés</th>
            <th scope="col">Par défaut ?</th>
            <th scope="col" style={{ textAlign: "right" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.props.newsletter_entity.index.map(entity => (
            <tr>
              <td scope="row">{entity.name ? entity.name : "Newsletter sans nom"}</td>
              <td>{entity.publication_status ? "Activée" : "Désactivée"}</td>
              <td>{entity.manual ? 'Manuelle' : 'Automatique'}</td>
              <td>
                {this.renderNextSending(entity)}
              </td>
              <td>{entity.subscribers} abonnés</td>
              <td>{entity.is_default ? 'Oui' : 'Non'}</td>
              <td style={{ textAlign: "right" }}>
                <Link
                  className="btn btn-primary"
                  to={"/entities/newsletter/" + entity.id}
                >
                  <i className="fas fa-eye" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderNextSending(entity)
  {
    if (!entity.publication_status) return '-'

    if (entity.manual) {
      if (entity.schedule && entity.schedule_date) {
        return moment(entity.schedule_date).format('DD/MM/YY') + ' à ' + moment(entity.schedule).format('HH:mm')
      }
    }
    else {
      return 'Automatique'
    }

    return '-'
  }
}

export default connect(
  ({ newsletter_entity }) => ({ newsletter_entity }),
  { fetchAll, searchAll, createNewsletter }
)(NewsletterIndexPage);
