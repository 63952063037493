import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';

import Modal from "../../../components/Modal";

import {createNewsletter} from "../../../data/entities/Newsletter";

class NewsletterCreateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        name: null,
        publication_status: '0',
        frequency: null,
        visibility: null,
        default_newsletter: false,
    }

    _submit = () => {
        this.setState({ _loading: true })

        const data = {...this.state}
        delete data._loading
        delete data._formData

        this.props.createNewsletter(data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })
    }

    render()
    {
        return (
            <Modal title={"Créer Newsletter"} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
					<Input
					    value={this.state.name} onChangeValue={(name) => this.setState({ name })}
					    placeholder="name" label="name" type="text"/>

					<Input
					    value={this.state.publication_status} onChangeValue={(publication_status) => this.setState({ publication_status })}
					    placeholder="publication_status" label="publication_status" type="number"/>
					<Input
					    value={this.state.frequency} onChangeValue={(frequency) => this.setState({ frequency })}
					    placeholder="frequency" label="frequency" type="text"/>

					<Input
					    value={this.state.visibility} onChangeValue={(visibility) => this.setState({ visibility })}
					    placeholder="visibility" label="visibility" type="text"/>

					<CheckBox
					    name="default_newsletter" id="default_newsletter" value={this.state.default_newsletter}
					    onChangeValue={(default_newsletter) => this.setState({ default_newsletter })}>{'default_newsletter'}</CheckBox>

                    <Submit disabled={this.state._loading}>Créer</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { createNewsletter })(NewsletterCreateModal)
