import React, {Component} from 'react';
import Modal from "../../../components/Modal";
import ContentLoader from "../../../components/ContentLoader";
import moment from 'moment';
import {Row, Col} from 'reactstrap';

class ContactModal extends Component
{
    render() {

        const contact = this.props.data;

        if (!contact)
            return <ContentLoader/>;

        const fontSize = {
            fontSize: '16px'
        };

        const fontStyle = {
            fontStyle: 'italic'
        };

        return(
            <Modal title={'Message'} onClose={() => this.props.onClose()}>
                <div style={fontSize}>
                    <Row className={'my-4'}>
                        <Col md={8}>
                        <span>
                            De
                        </span>
                            <span className={'font-weight-bold ml-2'}>
                           {contact.email}
                       </span>
                        </Col>
                        <Col md={4}>
                       <span className={'float-right'}>
                           Le {moment(contact.sent_at).format('H:mm DD/MM/Y')}
                       </span>
                        </Col>
                    </Row>

                    <Row className={'my-4'}>
                        <Col>
                            <span className={'font-weight-bold'}>Objet</span> : {contact.subject}
                        </Col>
                    </Row>

                    <Row className={'my-4'}>
                        <Col>
                            <p className={'font-weight-bold'}>Message :</p>
                            {contact.message.split("\n").map((i,key) => {
                                return <p key={key} className={'ml-3'} style={fontStyle}>{i}</p>;
                            })}
                        </Col>
                    </Row>

                    <div className="text-center">
                        <button
                            onClick={() => this.props.onClose()}
                            className="btn btn-primary m-auto"
                        >
                            Fermer
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ContactModal;
