import {request} from '../helpers'

// Actions

const PLATFORM_GET = 'PLATFORM_GET'

export function getPlatform()
{
    return request(PLATFORM_GET, 'get', '/platform')
}

const PLATFORM_UPDATE = 'PLATFORM_UPDATE'

export function updatePlatform(key, value)
{
    return request(PLATFORM_UPDATE, 'patch', '/platform', { key, value })
}

const PLATFORM_UPLOAD_IMAGE = 'PLATFORM_UPLOAD_IMAGE'

export function uploadImagePlatform(key, image)
{
    return request(PLATFORM_UPLOAD_IMAGE, 'post', '/platform', { key, image })
}

const PLATFORM_DELETE_IMAGE = 'PLATFORM_DELETE_IMAGE'

export function deleteImagePlatform(key, image)
{
    return request(PLATFORM_DELETE_IMAGE, 'delete', '/platform', { key, image })
}

const PLATFORM_WIDGETS_ADD = 'PLATFORM_WIDGETS_ADD'

export function addWidgetPlatform(section, id, data)
{
    return request(PLATFORM_WIDGETS_ADD, 'put', '/platform/widgets', { section, id, data })
}

const PLATFORM_WIDGETS_MOVE = 'PLATFORM_WIDGETS_MOVE'

export function moveWidgetPlatform(section, id, position)
{
    return request(PLATFORM_WIDGETS_MOVE, 'patch', '/platform/widgets', { section, id, position })
}

const PLATFORM_WIDGETS_DELETE = 'PLATFORM_WIDGETS_DELETE'

export function deleteWidgetPlatform(section, id)
{
    return request(PLATFORM_WIDGETS_DELETE, 'delete', '/platform/widgets', { section, id })
}

// Store

const defaultState = {}

// Reducer

export default function PlatformReducer(state = defaultState, action)
{
    switch(action.type)
    {
        //
    }
    return state
}