export const checkImageExists = (imageUrl) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = imageUrl;
    });
};

export const verifyImageCover = async (dataImages) => {
    try {
        const results = await Promise.all(
            Object.values(dataImages).map(async (imageUrl) => {
                const exists = await checkImageExists(imageUrl);
                return exists ? 200 : 404;
            })
        );
        console.log("Status codes:", results);
        return results;
    } catch (error) {
        console.error("Error checking image existence:", error);
        // Return an array of 500 status codes for any error
        return Object.values(dataImages).map(() => 500);
    }
};
