import React, {Component} from 'react';
import {CheckBox, Form, Submit} from "react-forms";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import {toast} from "react-toastify";
import {createConfidentialDocument, getConfidentialDocumentStripeProducts} from "../../../data/entities/ConfidentialDocument";
import {connect} from 'react-redux';
import {Row, Col} from 'reactstrap';


class ConfidentialDocumentCreateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        file: null,
        title: null,
        stripeProductOptions: [],
        stripe_ids: [],
        tell_subscribers: true
    };

    componentDidMount() {

        this.setState({ _loading: true });

        this.props.getConfidentialDocumentStripeProducts()
            .then((res) => {
                this.setState({ stripeProductOptions: res.payload.data })
                this.setState({ _loading: false });

            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    toast.error(e.error.response.data.message)
                this.setState({ _loading: false })
            })
    }

    _submit = () => {
        if (!this.fileRef || !this.fileRef.files || !this.fileRef.files.length) return;

        this.setState({ _loading: true });

        const { stripe_ids, title, tell_subscribers } = this.state;
        const files = this.fileRef.files;

        if (stripe_ids.length === 0) {
            this.setState({ _loading: false });
            return toast.error('Veuillez choisir au moins un type d\'abonnement.');
        }

        this.props.createConfidentialDocument({
            stripe_ids,
            title,
            files,
            tell_subscribers: tell_subscribers ? 1 : 0
        })
            .then((res) => {
                toast.success("Fichier importé.");
                this.props.onClose(true);
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    toast.error(e.error.response.data.message);
                this.setState({ _loading: false });
            })
    };

    _changeStripeIds = (stripe_id) => {

        let { stripe_ids } = this.state;

        if (!stripe_ids.includes(stripe_id)) {
            stripe_ids.push(stripe_id);
        } else {
            let index = stripe_ids.indexOf(stripe_id);
            if (index > -1) {
                stripe_ids.splice(index, 1);
            }
        }

        this.setState({ stripe_ids });
    };

    render() {
        return (
            <Modal title={"Ajouter un document confidentiel"} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
                    <div className="form-group mb-3">
                        <label htmlFor="copyright">Titre</label>
                        <input required type="text" className="form-control"
                               name="title" id="title" value={this.state.title}
                               onChange={(e) => this.setState({ title: e.target.value })} />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="label">Type d'abonnement</label>
                        <Row>
                            {
                                this.state.stripeProductOptions.map((product) => {
                                    return (
                                        <Col md={4} style={{ color: product.color }}>
                                            <input type="checkbox" onChange={() => this._changeStripeIds(product.stripe_id)}/>{' '}{product.name}
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>

                    <div className="mb-3">
                        <label>Sélectionnez votre fichier :</label><br />
                        <input
                            required
                            multiple
                            type="file"
                            accept={"image/*"}
                            name="file" id="file"
                            ref={(ref) => this.fileRef = ref}
                            onChange={(e) => this.setState({ file: true })}
                        />
                    </div>

                    <div className="mb-5">
                        <CheckBox
                            name="tell_subscribers"
                            id="tell_subscribers"
                            value={this.state.tell_subscribers}
                            onChangeValue={(tell_subscribers) => this.setState({ tell_subscribers })}
                        >
                            Envoyer un e-mail à tous les abonnés
                        </CheckBox>
                    </div>

                    <Submit disabled={this.state._loading || !this.state.file}>
                        <Loader display={this.state._loading} style={{ marginRight: 10 }}/>
                        Ajouter ce document
                    </Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, {createConfidentialDocument, getConfidentialDocumentStripeProducts})(ConfidentialDocumentCreateModal);
