import React, { Component, Fragment } from "react";

import CategoryUpdateModal from "./CategoryUpdateModal";
import CategoryDeleteModal from "./CategoryDeleteModal";

export default class TrCategoryModal extends Component {
    state = {
        showCategoryUpdateModal: false,
        showCategoryDeleteModal: false
    };

    render() {
        const entity = this.props.data
        return (
            <Fragment>
                <tr>
                    <td>{entity.name}</td>
                    <td style={{backgroundColor: entity.color}}>{entity.color}</td>
                    <td className="text-right">
                        <button
                            onClick={() =>
                                this.setState({
                                    showCategoryUpdateModal: true
                                })
                            }
                            className="btn btn-primary"
                        >
                            Modifier
                        </button>
                        <button
                            onClick={() =>
                                this.setState({
                                    showCategoryDeleteModal: true
                                })
                            }
                            className="btn btn-danger ml-2"
                        >
                            Supprimer
                        </button>
                    </td>
                </tr>
                {this.state.showCategoryUpdateModal ? (
                    <CategoryUpdateModal
                        data={this.props.data}
                        onClose={data => {
                            if (data) this.props.onChange();
                            this.setState({ showCategoryUpdateModal: false });
                        }}
                    />
                ) : null}
                {this.state.showCategoryDeleteModal ? (
                    <CategoryDeleteModal
                        data={this.props.data}
                        onClose={deleted => {
                            if (deleted) {
                                this.props.onChange()
                            } else this.setState({ showCategoryDeleteModal: false });
                        }}
                    />
                ) : null}
            </Fragment>)
    }}
