import React, {Component} from 'react';
import {connect} from 'react-redux'
import {sortableElement,sortableContainer} from "react-sortable-hoc";
import {Option, OptionGroup} from "react-forms";
import {addWidgetPlatform, deleteWidgetPlatform, moveWidgetPlatform, getPlatform} from "../../data/Platform";


const widgetTypes = {
    social: {
        label: 'Liens sociaux',
    },
    twitter: {
        label: 'Timeline Twitter',
    },
    newsletter: {
        label: 'Newsletter',
    },
    advertising: {
        label: 'Publicité',
    },
    live: {
        label: 'Articles en live',
    },
    recents: {
        label: 'Articles récents',
    },
    related: {
        label: 'Articles de la même catégorie'
    },
    marketplace: {
        label: 'Produits du marketplace'
    },
    confidential_documents: {
        label: 'Documents confidentiels'
    },
    e_shop: {
        label: 'Produit/abonnement en avant'
    },
    expert: {
        label: 'Parole d\'expert',
    }
};

const SortableItem = sortableElement(({value}) => {

    const widget = widgetTypes[value.id]

    return (
        <div className="list-group-item">
            <div className="row">
                <div className="col">
                    {widget ? widget.label : 'Widget inconnu'}
                </div>
            </div>
        </div>
    )
});

const SortableContainer = sortableContainer(({children}) => {
    return <div className="list-group">{children}</div>;
});

class PlatformWidgets extends Component
{
    state = {
        loading: false,
        selected: null,
        data: this.props.data.filter((w) => w.section == this.props.section),
        config: null
    }

    componentDidMount() {
        this.props.getPlatform().then((res) => {
            this.setState({ config: res.payload.data })
        })
    }

    render() {
        return (
            <div>
                <div className="list-group mb-3">
                    {this.renderWidgets()}
                </div>
                <OptionGroup
                    value={this.state.selected}
                    onChangeValue={(value) => {
                        if (value == '') return;

                        this.setState({ selected: value, loading: true })

                        this.props.addWidgetPlatform(this.props.section, value, '')
                            .then((res) => {
                                this.setState({
                                    loading: false,
                                    selected: null,
                                    data: res.payload.data.widgets.filter((w) => w.section == this.props.section)
                                })
                            })

                    }}>
                    <Option value={''}>Ajouter un widget...</Option>
                    {Object.keys(widgetTypes).map((id) => {
                        if (id == 'related' && this.props.section !== 'article') return null;
                        if (id == 'marketplace' && this.props.section !== 'article') return null;

                        // We don't display widgets relative to e_shop if e_shop not activated
                        if (id === 'e_shop' && this.state.config && this.state.config['e_shop.on'] === 0) {
                            return null;
                        }

                        return (
                            <Option value={id}>{widgetTypes[id].label}</Option>
                        )
                    })}
                </OptionGroup>
            </div>
        );
    }

    renderWidgets()
    {
        return (
            <div style={{ position: 'relative', opacity: this.state.loading ? 0.5 : 1, cursor: this.state.loading ? 'wait': 'move' }}>
                <SortableContainer onSortEnd={(e) => {
                    const widget = this.state.data[e.oldIndex - 1]

                    if (e.newIndex == widget.position + 1) return;
                    this.setState({ loading: true })

                    this.props.moveWidgetPlatform(this.props.section, widget.id, e.newIndex - 1)
                        .then((res) => {
                            this.setState({
                                loading: false,
                                data: res.payload.data.widgets.filter((w) => w.section == this.props.section)
                            })
                        })
                }}>
                    {this.state.data.sort((a,b) => a.position - b.position).map((widget, i) => {

                        // We don't display widgets relative to e_shop if e_shop not activated
                        if (widget.id === 'e_shop' && this.state.config && this.state.config['e_shop.on'] === 0) {
                            return null;
                        }

                        return (
                            <div style={{ position: 'relative' }}>
                                <SortableItem
                                    key={`item-${widget.id}`}
                                    index={i + 1}
                                    value={widget}
                                />
                                <div style={{ position: 'absolute', right: 10, top: 7, zIndex: 1000 }}>
                                    <button className="btn btn-default" onClick={(e) => {
                                        e.preventDefault()
                                        this.setState({ loading: true })

                                        this.props.deleteWidgetPlatform(this.props.section, widget.id)
                                            .then((res) => {
                                                this.setState({
                                                    loading: false,
                                                    data: res.payload.data.widgets.filter((w) => w.section == this.props.section)
                                                })
                                            })

                                        return false;
                                    }}>
                                        <i className="fa fa-trash"/>
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </SortableContainer>
            </div>
        )
    }
}

export default connect(null, {addWidgetPlatform, moveWidgetPlatform, deleteWidgetPlatform, getPlatform})(PlatformWidgets);
