import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {toast} from 'react-toastify'

import './DropTarget.scss'
import BlockCreateModal from "./BlockCreateModal";
import Loader from "../../components/Loader";
import {moveBlock} from "../../data/Editor";

class DropTarget extends Component
{
    state = {
        active: false,
        dragging: false,
        showBlockCreateModal: false,
        loading: false
    }

    _ticker = null

    componentDidMount() {
        this._ticker = setInterval(this._tick, 200)
    }

    componentWillUnmount() {
        clearInterval(this._ticker)
    }

    _tick = () => {
        if (this.state.dragging !== window.DragDropContext.dragging)
        {
            this.setState({ dragging: window.DragDropContext.dragging })
        }
    }

    _onDragOver = (e) => {
        e.preventDefault()
        if (this.state.loading) return false
        this.setState({ active: true })
        return false
    }

    _onDragLeave = (e) => {
        e.preventDefault()
        this.setState({ active: false })
        return false
    }

    _onDrop = (e) => {
        e.preventDefault()
        this.setState({ active: false, loading: true })

        console.log('move', window.DragDropContext.data, 'to parent', this.props.parent, 'at position of', this.props.target)

        this.props.moveBlock(window.DragDropContext.data.id, {
            parent: this.props.parent,
            target: this.props.target ? this.props.target.id : null
        }, (success) => {
            if (!success) {
                toast.error('Vous ne pouvez pas déplacer ici')
            }
            this.setState({ loading: false })
        })

        return false
    }

    render() {
        return (
            <Fragment>
                <div
                    onDragOver={this._onDragOver}
                    onDragLeave={this._onDragLeave}
                    onDrop={this._onDrop}
                    onClick={() => this.setState({ showBlockCreateModal: !this.state.loading })}
                    className={`DropTarget ${this.state.active ? 'active' : ''} ${this.state.dragging ? 'dragging' : ''} ${this.state.loading ? 'loading' : ''} DropTarget${this.props.type}`}>
                    {this.state.dragging ? (
                        <span>Déplacer ici</span>
                    ) : (this.state.loading ? (
                        <Loader/>
                    ) : (
                        <Fragment>
                            <i className="fa fa-plus"/>
                            <span>Ajouter un bloc</span>
                        </Fragment>
                    ))}
                </div>
                {this.state.showBlockCreateModal ? (
                    <BlockCreateModal
                        parent={this.props.parent}
                        target={this.props.target}
                        onClose={() => this.setState({ showBlockCreateModal: false })}
                    />
                ) : null}
            </Fragment>
        )
    }
}

export default connect(({editor_drag_drop})=>({editor_drag_drop}), {moveBlock})(DropTarget);
