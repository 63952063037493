import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import ContentLoader from "../../../components/ContentLoader";
import {
    fetchALLIons,
    fetchStaticPage,
    updateStaticPage
} from "../../../data/entities/StaticPage";
import StaticPageDeleteModal from "./StaticPageDeleteModal";
import { Input } from "react-forms";
import Builder from "../../editor/Builder";
import "./StaticViewPage.scss"
import {initEditor,updateEditorEntity} from "../../../data/Editor";
import FontAwsomeModal from "./FontAwsomeModal";


class StaticPageViewPage extends Component {
    state = {
        id: this.props.match.params.id,
        data: null,
        loading: true,

        title: "",
        content: "",
        slug: "",
        meta_title: "",
        short_title: "",
        meta_description: "",
        meta_keywords: "",
        publication_status: "",

        showStaticPageUpdateModal: false,
        showStaticPageDeleteModal: false,
        show_in_footer: false,
        key: 0,
        type: 'static_page',
        show_in_top_header: false,
        showFonstAwsomModal: false,
        linkedtoicon: false,
        IconsData: null,
        icon_class: null,
        page_type: null
    };

    _submit = () => {
        this.setState({ _loading: true })

        const data = {...this.state}
        delete data.data
        delete data.loading
        delete data._formData
        delete data.showStaticPageUpdateModal
        delete data.showStaticPageDeleteModal

        this.props.updateStaticPage(this.state.id, data)
            .then((res) => {
                toast.success("Parfait ! Les modifications ont été appliquées.")
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data) {
                    this.setState({ _formData: e.error.response.data })
                    toast.error(<FormattedMessage id={e.error.response.data.error} />)
                }
                this.setState({ _loading: false })
            })
    }

    componentDidMount() {
        this.props
            .fetchStaticPage(this.state.id)
            .then(res => {
                let data = res.payload.data;
                console.log(data);
                this.setState({
                    loading: false,
                    data: data,
                    title: data.title,
                    short_title: data.short_title,
                    content: data.content,
                    slug: data.slug,
                    meta_title: data.meta_title,
                    meta_description: data.meta_description,
                    meta_keywords: data.meta_keywords,
                    publication_status: data.publication_status,
                    show_in_footer: data.show_in_footer,
                    show_in_top_header: data.show_in_top_header,
                    icon_class: data.icon_class,
                    page_type: data.page_type,
                    key: 1
                }, () => {
                    this.props.initEditor(this.state.type, data);
                });
            })
            .catch(e => {
                if (
                    e.error &&
                    e.error.response &&
                    e.error.response.data &&
                    e.error.response.data.error
                )
                    toast.error(<FormattedMessage id={e.error.response.data.error} />);
                this.props.history.push("/entities/static_page");
            });

        this.props.fetchALLIons()
            .then(res => this.setState({ IconsData: res.payload.data}))
    }

    setPageIcon = (iconClass) => {
        this.setState({ icon_class: iconClass})
    }

    removeIcon = () => {
        this.setState({
            icon_class: null
        })
    }

    getPageType = (e) =>{
        this.setState({
            page_type: e.target.value,
        })
        console.log('type de page ::::', e.target.value)
    }

    render() {
        return (
            <div className="container static-view-page">
                <Helmet title="StaticPage" />
                {this.renderEntity()}

                {
                    this.state.showStaticPageDeleteModal &&
                    <StaticPageDeleteModal
                        data={this.state.data}
                        onClose={deleted => {
                            if (deleted) {
                                this.props.history.push("/entities/static_page");
                            } else this.setState({ showStaticPaegDeleteModal: false });
                        }}
                    />
                }
            </div>
        );
    }

    renderEntity() {
        if (this.state.loading) return <ContentLoader />;

        return (
            <div className={'my-5'}>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "30px",
                        paddingTop: "20px"
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <h1>Page statique</h1>
                    </div>
                    <div style={{ display: "flex" }}>
                        <button
                            onClick={() => this.setState({ showStaticPageDeleteModal: true })}
                            className="btn btn-danger ml-2">
                            Supprimer
                        </button>
                    </div>
                </div>

                <div className="bg-light rounded py-5 px-4">

                    <Input
                        value={this.state.title}
                        onChangeValue={(title) => this.setState({ title })}
                        placeholder={'Titre de la page'}
                        label={'Titre'}
                        type={'text'}
                    />

                    <Input
                        value={this.state.short_title}
                        onChangeValue={(short_title) => this.setState({ short_title })}
                        placeholder={'Titre court de la page'}
                        label={'Titre court'}
                        type={'text'}
                    />

                    { this.state.page_type != "page-authors" ?
                        <Input
                            value={this.state.slug}
                            onChangeValue={(slug) => this.setState({ slug })}
                            placeholder={"Champ d'indexation dans l'URL"}
                            label={"Index dans l'URL"}
                            type={'text'}
                        />
                        :
                        <Input
                            value={this.state.slug}
                            placeholder={"Champ d'indexation dans l'URL"}
                            label={"Index dans l'URL"}
                            type={'text'}
                            disabled="true"
                        />
                    }

                    { this.state.page_type != "page-authors" ?
                        <>

                        <label>Type de page</label>
                        <select
                            onChange={ this.getPageType }
                            value={this.state.page_type}
                            className="custom-select mb-2"
                        >
                            <option selected="true" disabled="disabled">-- Type de page --</option>
                            <option value="page">Page</option>
                            <option value="link">Lien</option>
                        </select>

                        </>
                        :
                        null
                    }

                    <Input
                        value={this.state.meta_title} onChangeValue={(meta_title) => this.setState({ meta_title })}
                        placeholder="Titre affiché sur les moteurs de recherche" label="Titre référencé" type="text"/>

                    <Input
                        value={this.state.meta_description} onChangeValue={(meta_description) => this.setState({ meta_description })}
                        placeholder="Description affichée sur les moteurs de recherche" label="Description référencée" type="text"/>

                    <Input
                        value={this.state.meta_keywords} onChangeValue={(meta_keywords) => this.setState({ meta_keywords })}
                        placeholder="exemple1,exemple2" label="Mots-clefs référencés" type="text"/>
                    {
                         typeof this.state.icon_class !== 'undefined' && this.state.icon_class ?
                        <div>
                            <span><b>Icône de page :</b> <i className={this.state.icon_class} style={{ fontSize: "20px" }}></i> </span>
                            <span title="Suppromer l'icon" style={{fontSize: "30px", cursor: "pointer"}}
                                  onClick={this.removeIcon}
                            ><i className="fas fa-trash-alt ml-5 pl-5"></i></span>
                        </div>
                            :
                            null
                    }


                    <div className={"form-group"}>
                        <div className="custom-control custom-switch mt-4">
                            <input
                                type="checkbox"
                                onChange={() => this.setState(
                                    { publication_status: this.state.publication_status === 1 ? -1 : 1}
                                )}
                                checked={this.state.publication_status === 1 ? true : false}
                                className="custom-control-input" id="publication_status"/>
                            <label className="custom-control-label" htmlFor="publication_status">Page publiée</label>
                        </div>
                    </div>

                    <div className={"form-group"}>
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                   onChange={(show_in_footer) => this.setState({ show_in_footer: show_in_footer.target.checked})}
                                   checked={this.state.show_in_footer}
                                   className="custom-control-input" id="show_in_footer"/>
                            <label className="custom-control-label" htmlFor="show_in_footer">Afficher dans le bas de page.</label>
                        </div>
                    </div>

                    <div className={"form-group"}>
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                   onChange={(show_in_top_header) => this.setState({ show_in_top_header: show_in_top_header.target.checked})}
                                   checked={this.state.show_in_top_header}
                                   className="custom-control-input" id="show_in_top_header"/>
                            <label className="custom-control-label" htmlFor="show_in_top_header">Publié sur le menu d'admin.</label>
                        </div>
                    </div>

                    <div className={"form-group"}>
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                   onChange={(show_in_top_header) => this.setState({ linkedtoicon: !this.state.linkedtoicon})}
                                   className="custom-control-input" id="liee_a_une_icon"/>
                            <label className="custom-control-label" htmlFor="liee_a_une_icon">Associer une icône ?</label>
                        </div>
                    </div>

                    {
                        this.state.linkedtoicon ?
                            <div className={"form-group"}>
                                <a
                                    onClick={() => this.setState({showFonstAwsomModal: true})}
                                    className="btn btn-primary mb-3"
                                > Ajouter une icône
                                </a>
                            </div>
                            :
                            null
                    }

                    <div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <button
                                onClick={() => this._submit()}
                                className="btn btn-primary">
                                Enregistrer les modifications
                            </button>
                        </div>
                    </div>
                </div>
                {this.renderBuilder()}

                {this.state.showFonstAwsomModal ? (
                    <FontAwsomeModal
                        onClose={created => {
                            this.setState({ showFonstAwsomModal: false });
                        }}
                        IconsData = {this.state.IconsData}

                        PageIcon = { (ic) => {this.setPageIcon(ic)}}

                    />

                ) : null}
            </div>
        );
    }

    renderBuilder()
    {
        return (
            <div className="py-3">
                <p className="alert alert-info"> Toutes vos modifications dans la section suivante sont sauvegardées automatiquement.</p>
                <p className="alert alert-warning"><strong>Note</strong> : si du texte disparait après le déplacement d'un bloc, actualisez la page pour qu'il réapparaisse.</p>
                <Builder key={this.state.key.toString()} />
            </div>
        )
    }
}

export default connect(
    ({ static_page_entity }) => ({ static_page_entity }),
    { fetchStaticPage, updateStaticPage, initEditor, updateEditorEntity, fetchALLIons }
)(StaticPageViewPage);
