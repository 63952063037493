import React, {Component} from 'react';
import ChannelHomePageModal from "./ChannelHomePageModal";
import {connect} from 'react-redux';

class ChannelHomePage extends Component
{
    state = {
        showModal: false
    };

    render() {
        const spanStyle = {
            color: 'red'
        };

        return(
            <div className={'channel-homepage pl-3'}>
                <p className={'mt-5'}>La page d'accueil de votre site est la page
                    <span style={spanStyle}>{' '}

                    {this.props.channel_entity.index.homepage ?
                        this.props.channel_entity.index.homepage.title :
                        'par défaut'
                    }
                    </span>.
                </p>

                <button
                    onClick={() => this.setState({ showModal: true })}
                    className="add-category-btn btn btn-primary mt-4">Modifier</button>

                {this.state.showModal ?
                    <ChannelHomePageModal
                        id={this.props.id}
                        onClose={() => this.toggleModal()}
                        static_page_id={this.props.channel_entity.index.homepage ? this.props.channel_entity.index.homepage.id : null}
                    />
                    : null
                }
            </div>
        )
    }

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    }
}

export default connect(({channel_entity}) => ({channel_entity}), null)(ChannelHomePage);
