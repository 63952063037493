import React, { Component } from 'react'
import { connect } from 'react-redux'
import './CategoryViewPage.scss'

import ChannelAddCategoryModal from './ChannelAddCategoryModal'

import { fetchChannel } from "../../../data/entities/Channel";
import { fetchAll, createChannelCategory, deleteChannelCategory, updateChannelCategory } from '../../../data/entities/ChannelCategory'

class CategoryViewPage extends Component {

  state = {
    showModal: false,

    _loading: false
  }

  componentDidMount() {
    this._fetch()
  }

  _fetch = () => {
    return this.props.fetchAll(this.props.id)
  }

  _deleteChannelCategory = (id, entity) => {
    this.props.deleteChannelCategory(id, entity)
    .then((resp) => this._fetch())
  }

  updatingPosition = false;

  updatePosition = (a, b) => {
    if (this.updatingPosition) return false
    this.updatingPosition = true

    let position = b.position

    b.position = a.position // on donne à b la position de a
    a.position = position // on donne à a la position de b

    Promise.all([
      this.props.updateChannelCategory(this.props.id, a.id, a),
      this.props.updateChannelCategory(this.props.id, b.id, b)
    ]).then(() => {
        this._fetch().then(() => {
          this.updatingPosition = false
        })
      })
  }

  sortEntities = () => {
    return this.props.channel_category_entity.index.sort(function(a, b) {
      return a.position - b.position
    })
  }

  render() {
    console.log("STATE:", this.props.channel_category_entity.index)
    return (
      <div className="category-view-page">
        {
          this.props.channel_category_entity.index.length ? (
            this.renderChannelCategories()
          ) : (
            <span className="no-categories">Aucune catégorie à afficher.</span>
          )
        }
        <button
          onClick={() => this.setState({ showModal: true })}
          className="add-category-btn btn btn-primary">Ajouter une catégorie</button>

        {
          this.state.showModal ? (
            <ChannelAddCategoryModal id={this.props.id}
              onClose={(category) => {
                if (category) this.setState({ showModal: false }, () => this._fetch())
                this.setState({ showModal: false })
              }}
              />
          ) : (
            null
          )
        }
      </div>
    )
  }

  renderChannelCategories() {
    let entities = this.props.channel_category_entity.index
    entities = this.sortEntities()

    return (
      <table className="table">
        <thead>
        <tr>
          <th scope="col">Catégories</th>
          <th scope="col" className="actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          entities.map((entity, i) => (
            <tr key={entity.id}>
              <td>{entity.category.name}</td>
              <td>
                <div className="action-btn">

                  <button
                    onClick={() => this.updatePosition(entity, entities[i - 1])}
                    disabled={i === 0}
                    className="btn btn-primary">
                    <i class="fas fa-arrow-up"></i>
                  </button>

                  <button
                    onClick={() => this.updatePosition(entity, entities[i + 1])}
                    disabled={i === entities.length - 1}
                    className="btn btn-primary">
                    <i class="fas fa-arrow-down"></i>
                  </button>

                  <button
                    onClick={() =>
                      this._deleteChannelCategory(this.props.id, entity.id)
                    }
                    className="btn btn-danger"><i class="fas fa-trash"></i></button>
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
      </table>
    )
  }
}

export default connect(({channel_category_entity}) => ({channel_category_entity}), { fetchAll, createChannelCategory, deleteChannelCategory, updateChannelCategory })(CategoryViewPage)
