import React, {Component} from 'react';

import './Modal.scss';

class Modal extends Component
{
  componentDidMount() {
    document.body.style.overflow = 'none';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  render = () => {
    return (
      <div className={this.props.additionnalClass ? `bc-modal-container ${this.props.additionnalClass}` : "bc-modal-container"}>
        <div className={"bc-modal"} style={this.props.style}>
          {this.renderTitle()}
          {this.renderBody()}
        </div>
      </div>
    )
  }

  renderTitle() {
    return (
      <h1 className="bc-modal-title">
        {this.props.title}
        {this.props.onClose !== false ? (
          <span className="bc-modal-close" onClick={this.props.onClose}>
            <i className="fa fa-times"/>
          </span>
        ) : null}
      </h1>
    )
  }

  renderBody() {
    return (
      <div className="bc-modal-body">
        {this.props.children}
      </div>
    )
  }
}

Modal.defaultProps = {
  onClose: false,
  title: null,
  additionnalClass: false,
  style: {}
};

export default Modal;
