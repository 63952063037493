import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import StaticPageCreateModal from "./StaticPageCreateModal";

import {
  fetchAll,
  searchAll,
  updateStaticPagePosition
} from "../../../data/entities/StaticPage";

class StaticPageIndexPage extends Component {
  state = {
    search: "",
    showStaticPageCreateModal: false,
    pages: null
  };

  updatePosition = (a, b) => {
    console.log("A --->", a, "B---->", b)

    this.props.updateStaticPagePosition(a.id, {position: b.position})
      .then(() => this._fetch());
  };


  _search = search => {
    this.setState({ search }, () => {
      this._fetch();
    });
  };

  _fetch = () => {
    if (this.state.search.length)
      return this.props.searchAll(this.state.search);
    else return this.props.fetchAll();
  };

  deleteStaticPage(id) {
    this.props.deleteStaticPage(id)
        .then(() => this._fetch())
  }

  componentDidMount() {
    this._fetch();
    this.props.fetchAll()
        .then(res => this.setState({ pages: res.payload.data}));
  }

  render() {
    console.log("DATA", this.props.static_page_entity.index)
    return (
      <div className="container">
        <Helmet title="Pages statiques" />
        <div className="row pt-3 pb-5">
          <div className="col">
            <h1>Pages statiques</h1>
          </div>
          <div className="col text-right pt-1">
            <Loader display={this.props.static_page_entity.loading} />
            <input
              type="text"
              value={this.state.search}
              onChange={e => this._search(e.target.value)}
              placeholder="Rechercher..."
              className="form-control d-inline-block ml-2"
              style={{ maxWidth: 200 }}
            />
            <button
              onClick={() => this.setState({ showStaticPageCreateModal: true })}
              className="btn btn-primary ml-2"
            >
              Créer une page
            </button>
          </div>
        </div>
        {this.renderEntities()}
        {this.state.showStaticPageCreateModal ? (
          <StaticPageCreateModal
            onClose={created => {
              if (created) this._fetch();
              this.setState({ showStaticPageCreateModal: false });
            }}
            allPages = {this.state.pages}
          />
        ) : null}
      </div>
    );
  }

  renderEntities() {
    if (
      this.props.static_page_entity.loading &&
      !this.props.static_page_entity.lastUpdate
    )
      return <ContentLoader />;

    if (!this.props.static_page_entity.index.length) {
      return <Empty />;
    }
    let entities = this.props.static_page_entity.index.sort((a, b) => {
      return a.position - b.position
    });

    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Titre</th>
            <th scope="col" />
            <th scope="col" />
            <th scope="col" />
            <th scope="col" />
            <th scope="col" />
            <th scope="col" style={{ textAlign: "right" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {entities.map((entity, i) => (
            <tr key={entity.id}>
              <td scope="row">{entity.title}</td>
              <td scope="row" />
              <td scope="row" />
              <td scope="row" />
              <td scope="row" />
              <td scope="row" />
              <td style={{ textAlign: "right" }}>
                <Link
                  className="btn btn-primary mr-2"
                  to={"/entities/static_page/" + entity.id}
                >
                  Modifier
                </Link>
                <button
                  className="btn btn-primary mr-2"
                  disabled={i === 0}
                  onClick={() => this.updatePosition(entity, entities[i - 1])}
                >
                  <i className="fas fa-caret-square-up" />
                </button>
                <button
                  className="btn btn-primary"
                  disabled={i === entities.length - 1}
                  onClick={() => this.updatePosition(entity, entities[i + 1])}
                >
                  <i className="fas fa-caret-square-down" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default connect(
  ({ static_page_entity }) => ({ static_page_entity }),
  { fetchAll, searchAll, updateStaticPagePosition }
)(StaticPageIndexPage);
