import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_OFFER_ACTIVITY_AREA_INDEX = 'ENTITY_OFFER_ACTIVITY_AREA_INDEX'
const ENTITY_OFFER_ACTIVITY_AREA_INDEX_SUCCESS = 'ENTITY_OFFER_ACTIVITY_AREA_INDEX_SUCCESS'
const ENTITY_OFFER_ACTIVITY_AREA_INDEX_FAIL = 'ENTITY_OFFER_ACTIVITY_AREA_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_OFFER_ACTIVITY_AREA_INDEX, 'get', '/offer_activity_areas')
}

export function searchAll(query)
{
    return request(ENTITY_OFFER_ACTIVITY_AREA_INDEX, 'post', '/offer_activity_areas/search', { query })
}

const ENTITY_OFFER_ACTIVITY_AREA_RETRIEVE = 'ENTITY_OFFER_ACTIVITY_AREA_RETRIEVE'

export function fetchOfferActivityArea(id)
{
    return request(ENTITY_OFFER_ACTIVITY_AREA_RETRIEVE, 'get', `/offer_activity_areas/${id}`)
}

const ENTITY_OFFER_ACTIVITY_AREA_CREATE = 'ENTITY_OFFER_ACTIVITY_AREA_CREATE'

export function createOfferActivityArea(data)
{
    return request(ENTITY_OFFER_ACTIVITY_AREA_CREATE, 'post', '/offer_activity_areas', data)
}

const ENTITY_OFFER_ACTIVITY_AREA_UPDATE = 'ENTITY_OFFER_ACTIVITY_AREA_UPDATE'

export function updateOfferActivityArea(id, data)
{
    return request(ENTITY_OFFER_ACTIVITY_AREA_UPDATE, 'patch', `/offer_activity_areas/${id}`, data)
}


const ENTITY_OFFER_ACTIVITY_AREA_DELETE = 'ENTITY_OFFER_ACTIVITY_AREA_DELETE'

export function deleteOfferActivityArea(id)
{
    return request(ENTITY_OFFER_ACTIVITY_AREA_DELETE, 'delete', `/offer_activity_areas/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityOfferActivityAreaReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_OFFER_ACTIVITY_AREA_INDEX:
            return {...state, loading: true}
        case ENTITY_OFFER_ACTIVITY_AREA_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_OFFER_ACTIVITY_AREA_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}