import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_ADVERTISING_BANNER_TYPE_INDEX = 'ENTITY_ADVERTISING_BANNER_TYPE_INDEX'
const ENTITY_ADVERTISING_BANNER_TYPE_INDEX_SUCCESS = 'ENTITY_ADVERTISING_BANNER_TYPE_INDEX_SUCCESS'
const ENTITY_ADVERTISING_BANNER_TYPE_INDEX_FAIL = 'ENTITY_ADVERTISING_BANNER_TYPE_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_ADVERTISING_BANNER_TYPE_INDEX, 'get', '/compagnes_banner_types')
}

export function searchAll(query)
{
    return request(ENTITY_ADVERTISING_BANNER_TYPE_INDEX, 'post', '/compagnes_banner_types/search', { query })
}

const ENTITY_ADVERTISING_BANNER_TYPE_RETRIEVE = 'ENTITY_ADVERTISING_BANNER_TYPE_RETRIEVE'

export function fetchAdvertisingBannerType(id)
{
    return request(ENTITY_ADVERTISING_BANNER_TYPE_RETRIEVE, 'get', `/compagnes_banner_types/${id}`)
}

const ENTITY_ADVERTISING_BANNER_TYPE_CREATE = 'ENTITY_ADVERTISING_BANNER_TYPE_CREATE'

export function createAdvertisingBannerType(data)
{
    return request(ENTITY_ADVERTISING_BANNER_TYPE_CREATE, 'post', '/compagnes_banner_types', data)
}

const ENTITY_ADVERTISING_BANNER_TYPE_UPDATE = 'ENTITY_ADVERTISING_BANNER_TYPE_UPDATE'

export function updateAdvertisingBannerType(id, data)
{
    return request(ENTITY_ADVERTISING_BANNER_TYPE_UPDATE, 'patch', `/compagnes_banner_types/${id}`, data)
}


const ENTITY_ADVERTISING_BANNER_TYPE_DELETE = 'ENTITY_ADVERTISING_BANNER_TYPE_DELETE'

export function deleteAdvertisingBannerType(id)
{
    return request(ENTITY_ADVERTISING_BANNER_TYPE_DELETE, 'delete', `/compagnes_banner_types/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityAdvertisingBannerTypeReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_ADVERTISING_BANNER_TYPE_INDEX:
            return {...state, loading: true}
        case ENTITY_ADVERTISING_BANNER_TYPE_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_ADVERTISING_BANNER_TYPE_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
