import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Card, CardHeader, CardBody, Collapse, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import PlatformProperty from "./PlatformProperty";
import {getPlatform, updatePlatform, uploadImagePlatform, deleteImagePlatform} from "../../data/Platform";
import {fetchAllProducts} from "../../data/entities/StripeProduct";
import ContentLoader from "../../components/ContentLoader";
import classnames from 'classnames';
import PlatformWidgets from "./PlatformWidgets";


class PlatformPage extends Component
{
    state = {
        toggle: [],
        config: null,
        activeTab: '1'
    }

    componentDidMount() {
        this._load()
        this.props.fetchAllProducts();
    }

    _load = () => {
        this.props.getPlatform().then((res) => {
            this.setState({ config: res.payload.data })
        })
    }

    _update = (key, value) => {
        return new Promise((resolve, reject) => {
            this.props.updatePlatform(key, value).then((res) => {
                this.setState({ config: res.payload.data })
                resolve(res.payload.data)
            })
        })
    }

    _upload = (key, file) => {
        return new Promise((resolve, reject) => {
            this.props.uploadImagePlatform(key, file).then((res) => {
                this.setState({ config: res.payload.data })
                resolve(res.payload.data)
            })
        })
    }

    _delete = (key) => {
        return new Promise((resolve, reject) => {
            this.props.deleteImagePlatform(key).then((res) => {
                this.setState({ config: res.payload.data })
                resolve(res.payload.data)
            })
        })
    }

    _toggle(id) {
        this.setState({ activeTab: id })
    }

    render()
    {
        return (
            <div className="container">
                <h1 className="mb-3">Plateforme</h1>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this._toggle('1'); }}
                        >
                            Identité
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this._toggle('2'); }}
                        >
                            Identité graphique
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '6' })}
                            onClick={() => { this._toggle('6'); }}
                        >
                            Thème
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this._toggle('3'); }}
                        >
                            Widgets
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '4' })}
                            onClick={() => { this._toggle('4'); }}
                        >
                            Informations techniques
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '5' })}
                            onClick={() => { this._toggle('5'); }}
                        >
                            Extra
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="mt-3">
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    renderContent()
    {
        if (this.state.config === null)
            return <ContentLoader/>

        return (
            <div>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        {this.renderProperty("Nom du site", 'identity.name')}
                        {this.renderProperty("Slogan", 'identity.slogan')}
                        {this.renderProperty("Facebook", 'identity.social.facebook', 'facebook')}
                        {this.renderProperty("Twitter", 'identity.social.twitter', 'twitter')}
                        {this.renderProperty("LinkedIn", 'identity.social.linkedin', 'linkedin')}
                        {this.renderProperty("Instagram", 'identity.social.instagram', 'instagram')}
                        {this.renderProperty("Youtube", 'identity.social.youtube', 'youtube')}
                    </TabPane>
                    <TabPane tabId="2">
                        {this.renderProperty("Logo", 'images.logo', 'image')}
                        {this.renderProperty("Bannière", 'images.banner', 'image')}
                        {this.renderProperty("Icône", 'images.favicon', 'image')}
                    </TabPane>
                    <TabPane tabId="3">
                        <div className="row mt-3">
                            <div className="col">
                                <h6>Page d'accueil</h6>
                                {this.renderWidgets('home')}
                            </div>
                            <div className="col">
                                <h6>Catégorie</h6>
                                {this.renderWidgets('category')}
                            </div>
                            <div className="col">
                                <h6>Article</h6>
                                {this.renderWidgets('article')}
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="4">
                        {this.renderProperty("Langue du site", 'locale', 'locale')}
                        {this.renderProperty("Nom indexé du site", 'meta.title')}
                        {this.renderProperty("Nom indexé de la page d'accueil", 'meta.home.title')}
                        {this.renderProperty("Description indexée de la page d'accueil", 'meta.home.description')}
                        {this.renderProperty("Mots clefs indexée de la page d'accueil", 'meta.home.keywords')}
                        {this.renderProperty("Fournisseur Analytics", 'analytics.provider', 'analytics.provider')}
                        {this.renderProperty("Identifiant Analytics", 'analytics.identifier')}
                        {this.renderProperty("Identifiant (SKU) de l'application iOS", 'meta.ios_sku')}
                        {this.renderProperty("Script", 'script', 'textarea')}
                    </TabPane>
                    <TabPane tabId="5">
                        {this.renderProperty("Article en Une", 'extra.highlighted_article', 'article')}
                        {this.renderProperty("Fournisseur externe d'offres d'emploi", 'offers.provider_endpoint')}
                        {this.renderProperty("Token d'échange entre Rewyer et le Marketplace", 'extra.marketplace_token')}
                        {this.renderProperty("Endpoint du Marketplace", 'extra.marketplace_endpoint')}
                        {this.renderProperty("E-boutique", 'e_shop.on', 'e_shop')}
                        {this.renderProperty("Id Stripe du tax rate par défaut", 'extra.default_tax_rate', 'default_tax_rate')}
                        {this.renderProperty("Abonnements rattachés par défaut aux articles Premium", 'extra.article_subscriptions', 'article_subscriptions')}
                        {this.renderProperty("Produits rattachés par défaut aux articles Premium", 'extra.article_products', 'article_products')}
                        {this.renderProperty('Label du widget "Produit/Abonnement en avant"', 'extra.widget_product_label', 'widget_product_label')}
                    </TabPane>
                    <TabPane tabId="6">
                        {this.renderProperty("Thème", 'theme.id', 'theme')}
                        {this.renderProperty("Couleur primaire", 'theme.colors.primary', 'color', "Cette couleur affecte le texte du site. Elle doit être lisible au dessus de la couleur secondaire.")}
                        {this.renderProperty("Couleur secondaire", 'theme.colors.secondary', 'color', "Cette couleur affecte les blocs et la barre de navigation.")}
                        {this.renderProperty("Couleur de fond", 'theme.colors.background', 'color', "Cette couleur correspond à la couleur de fond de page.")}
                    </TabPane>
                </TabContent>
            </div>
        )
    }

    renderWidgets(section)
    {
        return (
            <PlatformWidgets
                section={section}
                data={this.state.config.widgets}
            />
        )
    }

    renderProperty(label, identifier, type = 'string', description = null)
    {
        const value = this.state.config[identifier]
        const open = this.state.toggle.indexOf(identifier) !== -1

        if ((identifier === "extra.article_subscriptions" || identifier === "extra.article_products" || identifier === "extra.default_tax_rate" || identifier === "extra.widget_product_label") && !this.state.config['e_shop.on']) {
            return null;
        }

        return (
            <div className="mb-3">
                <Card>
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                        if (open) this.setState({ toggle: this.state.toggle.filter((n) => n != identifier) })
                        else this.setState({ toggle: [...this.state.toggle, identifier] })
                    }}>
                        <CardHeader>{label}</CardHeader>
                    </div>
                    <Collapse isOpen={true}>
                        <CardBody>
                            <PlatformProperty
                                label={label}
                                identifier={identifier}
                                value={type === 'image' ? {
                                    'url': this.state.config[`${identifier}.url`],
                                    'custom': this.state.config[`${identifier}.custom`],
                                } : value}
                                type={type}
                                description={description}
                                onChange={(value, callback) => {
                                    this._update(identifier, value).then((config) => {
                                        callback(config[identifier])
                                    })
                                }}
                                onUpload={(file, callback) => {
                                    this._upload(identifier, file).then((config) => {
                                        callback({
                                            'url': config[`${identifier}.url`],
                                            'custom': config[`${identifier}.custom`],
                                        })
                                    })
                                }}
                                onDelete={(callback) => {
                                    this._delete(identifier).then((config) => {
                                        callback({
                                            'url': config[`${identifier}.url`],
                                            'custom': config[`${identifier}.custom`],
                                        })
                                    })
                                }}
                                stripe_products={this.props.stripe_products_entity}
                            />
                        </CardBody>
                    </Collapse>
                </Card>
            </div>
        )
    }
}

export default connect(({ stripe_products_entity }) => ({ stripe_products_entity }), {getPlatform, updatePlatform, uploadImagePlatform, deleteImagePlatform, fetchAllProducts})(PlatformPage)
