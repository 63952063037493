import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Card, CardBody } from "reactstrap";

import ContentLoader from "../../../components/ContentLoader";

import {fetchRole, updateRolePrivilege} from "../../../data/entities/Role";
import RoleUpdateModal from "./RoleUpdateModal";
import RoleDeleteModal from "./RoleDeleteModal";

class RoleViewPage extends Component {
  state = {
    id: this.props.match.params.id,
    data: null,
    loading: true,
    showRoleUpdateModal: false,
    showRoleDeleteModal: false
  };

  componentDidMount() {
    this.props
      .fetchRole(this.state.id)
      .then(res => {
        this.setState({ loading: false, data: res.payload.data });
      })
      .catch(e => {
        console.log(e);
        if (
          e.error &&
          e.error.response &&
          e.error.response.data &&
          e.error.response.data.error
        )
          toast.error(<FormattedMessage id={e.error.response.data.error} />);
        this.props.history.push("/entities/role");
      });
  }

  render() {
    if (this.state.loading) return <ContentLoader />;
    
    console.log("STATE DATA-->", this.state.data)

    return (
      <div className="container pb-5">
        <Helmet title="Role" />
        <div className="row mt-2 mb-4">
          <div className="col">
            <h2>
              Rôle : {this.state.data.label}
            </h2>
          </div>
          <div className="col text-right">
            <button
                onClick={() => this.setState({ showRoleUpdateModal: true })}
                className="btn btn-primary"
            >
              Modifier
            </button>
              {(this.state.data.name !== 'ROLE_ADMIN' && this.state.data.name !== 'ROLE_USER') ? <button
                onClick={() => this.setState({ showRoleDeleteModal: true })}
                className="btn btn-danger ml-2"
            >
              Supprimer
            </button> : null}
          </div>
        </div>
        {this.renderEntity()}
        {this.state.showRoleUpdateModal ? (
            <RoleUpdateModal
                data={this.state.data}
                onClose={data => {
                  if (data) this.setState({ 
                    data: {
                      id: data.id,
                      label: data.label,
                      name: data.name,
                      list: this.state.data.list,
                      privileges: this.state.data.privileges
                    } 
                  });
                  this.setState({ showRoleUpdateModal: false });
                }}
            />
        ) : null}
        {this.state.showRoleDeleteModal ? (
            <RoleDeleteModal
                data={this.state.data}
                onClose={po => {
                  if (po) {
                    this.props.history.push("/entities/role");
                  } else this.setState({ showRoleDeleteModal: false });
                }}
            />
        ) : null}
      </div>
    );
  }

  renderEntity()
  {
    if (!this.state.data)
      return <ContentLoader/>

    return (
      <Fragment>
        <Card>
          <CardBody>
            {this.renderPrivilegesEditor()}
          </CardBody>
        </Card>
      </Fragment>
    );
  }

  renderPrivilegesEditor()
  {
    const role = this.state.data.name
    if (role == 'ROLE_ADMIN' || role == 'ROLE_USER')
      return (<p>Ce rôle est générique. Ses permissions ne peuvent donc pas être modifiées.</p>)

    const privileges = this.state.data.privileges

    return (
        <div className="mb-5">
          <div>
            {Object.keys(this.state.data.list).map((key) => this.renderPrivilege(key, this.state.data.list[key]))}
          </div>
        </div>
    )
  }

  renderPrivilege(key, description)
  {
    const level = key.split(':').length - 1
    let privileges = this.state.data.privileges

    return (
        <div className="custom-control custom-switch" style={{ marginLeft: 30 * level, marginTop: level == 0 ? 30 : 10 }} key={key}>
          <input type="checkbox"
                 onChange={() => {
                   if (privileges.indexOf(key) !== -1)
                     privileges = privileges.filter((p) => (p != key))
                   else privileges.push(key)
                   this.setState({ data: {...this.state.data, privileges} })

                   this.props.updateRolePrivilege(this.state.id, {
                     privilege: key
                   })
                 }}
                 checked={privileges.indexOf(key) !== -1}
                 className="custom-control-input" id={key}/>
          <label className="custom-control-label" htmlFor={key}>{description}</label>
        </div>
    )

    return (
        <div style={{ marginLeft: 30 * level, marginTop: level == 0 ? 30 : 10 }}>{description}</div>
    )
  }
}

export default connect(
  ({ role_entity }) => ({ role_entity }),
  { fetchRole, updateRolePrivilege }
)(RoleViewPage);
