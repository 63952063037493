import React, { Component, Fragment } from "react";
import moment from "moment";
import ContactModal from "./ContactModal";
import ContactDeleteModal from "./ContactDeleteModal";
import {checkPrivilege} from "../../../helpers";
import {connect} from 'react-redux';

class TrContact extends Component
{
    state = {
        showContactModal: false,
        showContactDeleteModal: false
    };

    render() {
        const entity = this.props.data;

        return (
            <Fragment>
                <tr>
                    <td>{moment(entity.sent_at).format('H:mm DD/MM/YY')}</td>
                    <td>{entity.email}</td>
                    <td>{entity.subject}</td>
                    <td className="text-right">
                        <button
                            onClick={() => this.toggleContactModal()}
                            className="btn btn-primary"
                        >
                            Lire le message
                        </button>
                        {checkPrivilege(this.props.user, 'contact-messages:delete') ?
                            <button
                                onClick={() => this.toggleContactDeleteModal()}
                                className="btn btn-danger ml-3"
                            >
                                <i className="fas fa-trash-alt"/>
                            </button>
                            : null}
                    </td>
                </tr>
                {this.state.showContactModal ? (
                    <ContactModal
                        data={entity}
                        onClose={() => this.toggleContactModal()}
                    />
                ) : null}

                {this.state.showContactDeleteModal ? (
                    <ContactDeleteModal
                        data={entity}
                        onClose={() => this.toggleContactDeleteModal()}
                        onDelete={() => this.props.onDelete()}
                    />
                ) : null}
            </Fragment>)
    }

    toggleContactModal = () => {
        this.setState({ showContactModal: !this.state.showContactModal});
    };

    toggleContactDeleteModal = () => {
        this.setState({ showContactDeleteModal: !this.state.showContactDeleteModal});
    }
}

export default connect(({user}) => ({user}))(TrContact);
