import React, {Component} from 'react'

import "./Autocomplete.scss"

export default class Autocomplete extends Component {

    state = {
        position: null
    }

    changePosition = (e) => {
        const key = e.nativeEvent.key
        let position = this.state.position

        if (key === "ArrowUp") {
            e.preventDefault()
            if (position === null) {
                position = this.props.queryResponse.length - 1
            }
            else if (position === 0) {
                position = null
            }
            else {
                position --
            }
            this.setState({
                position: position
            })
            return false
        }
        else if (key === "ArrowDown") {
            e.preventDefault()
            if (position === null) {
                position = 0
            }
            else if (position === this.props.queryResponse.length -1) {
                position = null
            }
            else {
                position ++
            }
            this.setState({
                position: position
            })
            return false
        }
        else if (key === "Enter") {
            e.preventDefault()

            if (this.state.position === null && this.props.createEntity !== false) {
                this.props.createEntity()
                this.setState({ position: null })
            }
            else {
                if (this.props.queryResponse[position] !== undefined) {
                    this.props.selectEntity(this.props.queryResponse[position].id, this.props.queryResponse[position])
                    this.setState({ position: null })
                }
            }
            return false
        }
        else if (key === "Escape") {
            e.target.blur()
            this.props.escape()
            this.setState({ position: null })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({ position: null })
    }

    render() {
        return (
            <div className={this.props.className}>
                <div className={"entity-select"}>
                    <input
                        onChange={(e) => this.props.handleChange(e)}
                        onKeyDown={(e) => this.changePosition(e)}
                        value={this.props.query}
                        id={`add-${this.props.className}-input`}
                        placeholder={this.props.placeholder}
                        onFocus={() => this.setState({ alert: false })}
                        autoComplete={"off"}
                    />

                    {this.props.queryResponse.length > 0 &&
                        <div className={"entity-options"} id={"entity-options"}>
                            {this.props.queryResponse.map((entity, index) => (
                                <div key={entity.id} onClick={() => {
                                        this.props.selectEntity(entity.id, entity)
                                        this.setState({ position: null });}}
                                    className={index === this.state.position ? "entity-option selected" : "entity-option" }>
                                    { this.props.renderDropdownItem(entity) }
                                </div>
                            ))}
                        </div>}
                </div>
            </div>
        )
    }
}
