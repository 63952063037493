import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify'
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";

import ContentLoader from "../../../components/ContentLoader";

import {fetchSuggestedArticle,transformSuggestedArticle} from '../../../data/entities/SuggestedArticle'
import SuggestedArticleDeleteModal from "./SuggestedArticleDeleteModal";
import moment from 'moment-timezone';
import "./SuggestedArticleViewPage.scss"

class SuggestedArticleViewPage extends Component
{
    state = {
        id: this.props.match.params.id,
        data: null,
        loading: true,
        transforming: false,

        showSuggestedArticleUpdateModal: false,
        showSuggestedArticleDeleteModal: false
    }

    componentDidMount()
    {
        this.props.fetchSuggestedArticle(this.state.id)
            .then((res) => {
                this.setState({ loading: false, data: res.payload.data })
            })
            .catch((e) => {
                console.log(e)
                if (e.error && e.error.response && e.error.response.data && e.error.response.data.error)
                    toast.error(<FormattedMessage id={e.error.response.data.error} />)
                this.props.history.push('/entities/suggested_article')
            })
    }

    transform()
    {
        this.setState({ transforming: true })

        this.props.transformSuggestedArticle(this.state.id)
            .then((res) => {
                //this.setState({ transforming: false })
                toast.success("Article importé.")
                this.props.history.push('/editor/article/' + res.payload.data.id)
            })
            .catch((e) => {
                toast.error("Une erreur s'est produite.")
                this.setState({ transforming: false })
            })
    }

    render() {
        return (
            <div className="container">
                <Helmet title="Article suggéré" />
                <h1>Article suggéré</h1>
                {this.renderEntity()}
            </div>
        )
    }

    renderEntity()
    {
        if (this.state.loading) return <ContentLoader/>

        return (
            <div className="suggested-article">

                <div className="row my-3">
                    <div className="col">
                        <h1>{this.state.data.title}</h1>
                    </div>
                    <div className="button-group col text-right">
                        <button
                            onClick={() => this.transform()}
                            className="btn btn-success"
                            disabled={this.state.transforming}>
                            <i className={this.state.transforming ? 'fa fa-circle-notch fa-spin' : "fa fa-check"}/>{' '}
                            Transformer en article
                        </button>{' '}
                        <button
                            disabled={this.state.transforming}
                            onClick={() => this.setState({ showSuggestedArticleDeleteModal: true })}
                            className="btn btn-danger">Refuser</button>
                    </div>
                </div>

                <p className="text-muted">Redigé par <strong>{this.state.data.user.fullname}</strong>.</p>

                {this.state.data.cover ? (
                    <div className="mb-3">
                        <img src={this.state.data.cover} alt="Cover" style={{ maxWidth: 500, maxHeight: 200, borderRadius: 5 }}/>
                    </div>
                ) :null}

                <div className="content" style={{ whiteSpace: 'pre-line' }}>{this.state.data.content}</div>

                {this.state.showSuggestedArticleDeleteModal ? (
                    <SuggestedArticleDeleteModal
                        data={this.state.data}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.props.history.push('/entities/suggested_article')
                            }
                            else this.setState({ showSuggestedArticleDeleteModal: false })
                        }}
                    />
                ) : null}
            </div>
        )
    }
}

export default connect(({ suggested_article_entity }) => ({ suggested_article_entity }), { fetchSuggestedArticle, transformSuggestedArticle })(SuggestedArticleViewPage)
