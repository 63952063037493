import Article from './Article';
import RSSArticle from '../RssArticle';
import Category from './Category';
import Tag from './Tag';
import ArticleType from './ArticleType';
import ArticleBlock from './ArticleBlock';
import BlockType from './BlockType';
import Media from './Media';
import SuggestedArticle from './SuggestedArticle';
import Advertising from './Advertising';
import Newsletter from './Newsletter';
import Offer from './Offer';
import OfferContract from './OfferContract';
import OfferExperience from './OfferExperience';
import OfferStudyLevel from './OfferStudyLevel';
import StaticPage from './StaticPage';
import Channel from './Channel';
import ChannelCategory from './ChannelCategory';
import ChannelMenu from './ChannelMenu';
import User from './User';
import Role from './Role';
import ArticleChannel from './ArticleChannel';
import ArticleCategory from './ArticleCategory';
import ArticleTag from './ArticleTag';
import UserCircle from './UserCircle';
import AdvertisingBanner from './AdvertisingBanner';
import AdvertisingBannerType from './AdvertisingBannerType';
import OfferFunction from './OfferFunction';
import NewsletterSubscriber from './NewsletterSubscriber';
import Circle from './Circle';
import NewsletterBlock from './NewsletterBlock';
import OfferActivityArea from './OfferActivityArea';
import Contact from './Contact';
import ConfidentialDocument from "./ConfidentialDocument";
import StripeProduct from './StripeProduct';

const reducers = {
    article_entity: Article,
    category_entity: Category,
    tag_entity: Tag,
    article_type_entity: ArticleType,
    article_block_entity: ArticleBlock,
    block_type_entity: BlockType,
    media_entity: Media,
    suggested_article_entity: SuggestedArticle,
    advertising_entity: Advertising,
    newsletter_entity: Newsletter,
    offer_entity: Offer,
    offer_contract_entity: OfferContract,
    offer_experience_entity: OfferExperience,
    offer_study_level_entity: OfferStudyLevel,
    static_page_entity: StaticPage,
    channel_entity: Channel,
    channel_category_entity: ChannelCategory,
    channel_menu_entity: ChannelMenu,
    user_entity: User,
    role_entity: Role,
    article_channel_entity: ArticleChannel,
    article_category_entity: ArticleCategory,
    article_tag_entity: ArticleTag,
    user_circle_entity: UserCircle,
    advertising_banner_entity: AdvertisingBanner,
    advertising_banner_type_entity: AdvertisingBannerType,
    offer_function_entity: OfferFunction,
    newsletter_subscriber_entity: NewsletterSubscriber,
    circle_entity: Circle,
    newsletter_block_entity: NewsletterBlock,
    offer_activity_area_entity: OfferActivityArea,
    contact_entity: Contact,
    confidential_document_entity: ConfidentialDocument,
    stripe_products_entity: StripeProduct,
    article_rss_entity: RSSArticle,
};

export default reducers
