import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_OFFER_FUNCTION_INDEX = 'ENTITY_OFFER_FUNCTION_INDEX'
const ENTITY_OFFER_FUNCTION_INDEX_SUCCESS = 'ENTITY_OFFER_FUNCTION_INDEX_SUCCESS'
const ENTITY_OFFER_FUNCTION_INDEX_FAIL = 'ENTITY_OFFER_FUNCTION_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_OFFER_FUNCTION_INDEX, 'get', '/offer_functions')
}

export function searchAll(query)
{
    return request(ENTITY_OFFER_FUNCTION_INDEX, 'post', '/offer_functions/search', { query })
}

const ENTITY_OFFER_FUNCTION_RETRIEVE = 'ENTITY_OFFER_FUNCTION_RETRIEVE'

export function fetchOfferFunction(id)
{
    return request(ENTITY_OFFER_FUNCTION_RETRIEVE, 'get', `/offer_functions/${id}`)
}

const ENTITY_OFFER_FUNCTION_CREATE = 'ENTITY_OFFER_FUNCTION_CREATE'

export function createOfferFunction(data)
{
    return request(ENTITY_OFFER_FUNCTION_CREATE, 'post', '/offer_functions', data)
}

const ENTITY_OFFER_FUNCTION_UPDATE = 'ENTITY_OFFER_FUNCTION_UPDATE'

export function updateOfferFunction(id, data)
{
    return request(ENTITY_OFFER_FUNCTION_UPDATE, 'patch', `/offer_functions/${id}`, data)
}


const ENTITY_OFFER_FUNCTION_DELETE = 'ENTITY_OFFER_FUNCTION_DELETE'

export function deleteOfferFunction(id)
{
    return request(ENTITY_OFFER_FUNCTION_DELETE, 'delete', `/offer_functions/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityOfferFunctionReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_OFFER_FUNCTION_INDEX:
            return {...state, loading: true}
        case ENTITY_OFFER_FUNCTION_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_OFFER_FUNCTION_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}