import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_ARTICLE_BLOCK_INDEX = 'ENTITY_ARTICLE_BLOCK_INDEX'
const ENTITY_ARTICLE_BLOCK_INDEX_SUCCESS = 'ENTITY_ARTICLE_BLOCK_INDEX_SUCCESS'
const ENTITY_ARTICLE_BLOCK_INDEX_FAIL = 'ENTITY_ARTICLE_BLOCK_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_ARTICLE_BLOCK_INDEX, 'get', '/article_blocks')
}

export function searchAll(query)
{
    return request(ENTITY_ARTICLE_BLOCK_INDEX, 'post', '/article_blocks/search', { query })
}

const ENTITY_ARTICLE_BLOCK_RETRIEVE = 'ENTITY_ARTICLE_BLOCK_RETRIEVE'

export function fetchArticleBlock(id)
{
    return request(ENTITY_ARTICLE_BLOCK_RETRIEVE, 'get', `/article_blocks/${id}`)
}

const ENTITY_ARTICLE_BLOCK_CREATE = 'ENTITY_ARTICLE_BLOCK_CREATE'

export function createArticleBlock(data)
{
    return request(ENTITY_ARTICLE_BLOCK_CREATE, 'post', '/article_blocks', data)
}

const ENTITY_ARTICLE_BLOCK_UPDATE = 'ENTITY_ARTICLE_BLOCK_UPDATE'

export function updateArticleBlock(id, data)
{
    return request(ENTITY_ARTICLE_BLOCK_UPDATE, 'patch', `/article_blocks/${id}`, data)
}


const ENTITY_ARTICLE_BLOCK_DELETE = 'ENTITY_ARTICLE_BLOCK_DELETE'

export function deleteArticleBlock(id)
{
    return request(ENTITY_ARTICLE_BLOCK_DELETE, 'delete', `/article_blocks/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityArticleBlockReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_ARTICLE_BLOCK_INDEX:
            return {...state, loading: true}
        case ENTITY_ARTICLE_BLOCK_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_ARTICLE_BLOCK_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
