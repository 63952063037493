import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_OFFER_INDEX = 'ENTITY_OFFER_INDEX'
const ENTITY_OFFER_INDEX_SUCCESS = 'ENTITY_OFFER_INDEX_SUCCESS'
const ENTITY_OFFER_INDEX_FAIL = 'ENTITY_OFFER_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_OFFER_INDEX, 'get', '/offers')
}

const ENTITY_OFFER_PROVIDER = 'ENTITY_OFFER_PROVIDER'
const ENTITY_OFFER_PROVIDER_SUCCESS = 'ENTITY_OFFER_PROVIDER_SUCCESS'

export function fetchProvider()
{
    return request(ENTITY_OFFER_PROVIDER, 'get', '/offers/provider')
}

export function searchAll(query)
{
    return request(ENTITY_OFFER_INDEX, 'post', '/offers/search', { query })
}

const ENTITY_OFFER_RETRIEVE = 'ENTITY_OFFER_RETRIEVE'

export function fetchOffer(id)
{
    return request(ENTITY_OFFER_RETRIEVE, 'get', `/offers/${id}`)
}

const ENTITY_OFFER_CANDIDATES = 'ENTITY_OFFER_CANDIDATES'

export function fetchOfferCandidates(id)
{
    return request(ENTITY_OFFER_CANDIDATES, 'get', `/offers/${id}/candidates`)
}

const ENTITY_OFFER_CREATE = 'ENTITY_OFFER_CREATE'

export function createOffer(data)
{
    return request(ENTITY_OFFER_CREATE, 'post', '/offers', data)
}

const ENTITY_OFFER_UPDATE = 'ENTITY_OFFER_UPDATE'

export function updateOffer(id, data)
{
    return request(ENTITY_OFFER_UPDATE, 'patch', `/offers/${id}`, data)
}


const ENTITY_OFFER_DELETE = 'ENTITY_OFFER_DELETE'

export function deleteOffer(id)
{
    return request(ENTITY_OFFER_DELETE, 'delete', `/offers/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null,
    provider: null
};

// Reducer

export default function EntityOfferReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_OFFER_INDEX:
            return {...state, loading: true}
        case ENTITY_OFFER_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_OFFER_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
        case ENTITY_OFFER_PROVIDER_SUCCESS:
            return {...state, provider: action.payload.data}
    }
    return state
}
