import React, {Component} from 'react';
import {Form, Submit} from "react-forms";
import Modal from "../../../components/Modal";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import {deleteConfidentialDocument} from "../../../data/entities/ConfidentialDocument";
import {connect} from 'react-redux';


class ConfidentialDocumentDeleteModal extends Component
{
    state = {
        loading: false
    };

    _submit = () => {
        this.setState({ loading: true })

        this.props.deleteConfidentialDocument(this.props.id)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(true)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data) {
                    if (e.error.response.data.error) toast.error(<FormattedMessage id={e.error.response.data.error} />)
                    else if (e.error.response.data.message) toast.error(<FormattedMessage id={e.error.response.data.message} />)
                }
                this.setState({ loading: false })
            })
    }

    render() {
        return (
            <Modal title="Supprimer ce document" onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit}>
                    <p style={{ color: '#000' }}>
                        <strong>Attention !</strong> Si vous supprimez ce document, il ne sera plus visible par vos abonnés et sera irrécupérable.
                    </p>
                    <Submit disabled={this.state.loading} className="btn btn-danger">OK, supprimer</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, {deleteConfidentialDocument})(ConfidentialDocumentDeleteModal);
