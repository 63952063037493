import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux';
import Modal from "../../../components/Modal";
import Loader from "../../../components/Loader";
import Empty from "../../../components/Empty"
import Pagination from "../../../components/Pagination"
import Media from "./Media"
import {createMedia} from "../../../data/entities/Media";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import "./MediaSelectModal.scss";

import {fetchAll, searchAll} from '../../../data/entities/Media'

class MediaSelectModal extends Component {

  state = {
    search: "",
    medias: [],
    _loading: false,
    _error: false,
    file: false,
    copyright: '',
    label: '',

    filters: [
      {
        name: "chunks",
        value: "12"
      },
      {
        name: "page",
        value: 1
      }
    ]
  }

  _search = (search) => {
    this.setState(
        { search },
        () => this.handleChange("query", this.state.search)
    )
  }

  _fetch = () => {
      this.props.fetchAll()
  }

  selectMedia = (media) => {
    this.props.onClose(media)
  }

  _upload = () => {
    if (!this.fileRef || !this.fileRef.files || !this.fileRef.files.length) return;

    this.setState({ _loading: true })

    let data = {}
    data.file = this.fileRef.files[0]
    data.copyright = this.state.copyright
    data.label = this.state.label
    console.log("editDATA", data.file && data.file.type.startsWith("image/") && data.file.size > 500000)
    if(data.file && data.file.type.startsWith("image/") && data.file.size > 500000) {
      toast.error("L'image est trop grande.")
      this.setState({ _loading: false })
    }else {
      this.props.createMedia(data)
          .then((res) => {
            if (this.props.requiredType === "image") {
              console.log("RESPONSE22", res.payload.data)
              // if res.payload.data.message is defined, it means that the file is an image but it is too big
              if (res.payload.data.message) {
                toast.error(res.payload.data.message)
                this.setState({file: false, _loading: false, _error: true})
              } else {
                toast.info("Fichier importé.")
              }
              console.log("required type")
              if (res.payload.data.type && res.payload.data.type.startsWith("image/")) this.props.onClose(res.payload.data)
              else {
                this.setState({file: false, _loading: false, _error: true})
              }
            } else {
              console.log("no required type")
              if (res.payload.data.message) {
                toast.error(res.payload.data.message)
                this.setState({file: false, _loading: false, _error: true})
              } else {
                toast.info("Fichier importé.")
                this.props.onClose(res.payload.data)
              }
            }
          })
          .catch((e) => {
            toast.error("Veuillez vérifier la taille et le format du fichier.")
            this.setState({_loading: false})
          })
    }
  }

  handleChange = (name, value) => {
    console.log("NAME:", name, "VALUE:", value)
    if (name !== "page") {
      this.setState(
          { filters: this.state.filters.filter(filter => filter.name !== "page")})
    }

    if (value === "default") {
      this.setState(
          { filters: this.state.filters.filter(filter => filter.name !== name)} ,
          () => this._submitFilters())
    }

    else if (this.state.filters.find(filter => filter.name === name)) {
      this.setState(
          { filters:
                this.state.filters.map(filter => {
                  if (filter.name === name) { filter.value = value }
                  return filter
                })
          },
          () => this._submitFilters())
    }

    else {
      this.setState(
          { filters: [...this.state.filters, { name: name, value: value }] },
          () => this._submitFilters())
    }
  }

  _submitFilters = () => {
    const filters = this.state.filters

    if (filters.length) {
      let query_params = ""
      for (let i = 0; i < filters.length; i++) {
        if (i === filters.length -1) {
          query_params = `${query_params}${filters[i].name}=${filters[i].value}`
        } else {
          query_params = `${query_params}${filters[i].name}=${filters[i].value}&`
        }
      }
      this.props.fetchAll(query_params)
      console.log("QUERY PARAMS:", query_params)
    }
    else {
      this._fetch()
    }
  }

  getEntitiesPerPage = () => {
    if (this.state.filters.find(filter => filter.name === "chunks") === undefined) {
      return 12
    } else {
      return this.state.filters.find(filter => filter.name === "chunks")
    }
  }

  componentDidMount() {
    if (this.props.requiredType) {
      this.handleChange("type", this.props.requiredType)
    } else {
      this.props.fetchAll()
    }
  }

  render() {
    console.log("media entity --->", this.props.media_entity)
    return (
      <Modal
        title="Choisir un média"
        onClose={() => this.props.onClose(false)}
        additionnalClass="media-select-modal">

        <div className="choose">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="media-tab" data-toggle="tab" href="#media" role="tab" aria-controls="media" aria-selected="true">Médiathèque</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="file-tab" data-toggle="tab" href="#file" role="tab" aria-controls="file" aria-selected="true">Importer un fichier</a>
            </li>
          </ul>
        </div>

        {this.renderMedias()}
      </Modal>
    )
  }

  renderMedias() {
    if (this.props.media_entity.index.index === undefined) {
      return <Empty />
    }
    else {
      return (
          <div className={this.props.media_entity.index.chunks === 12 ? "tab-content" : "tab-content scroll-active"} id="myTabContent">
            <div className="tab-pane fade show active" id="media" role="tabpanel" aria-labelledby="media-tab">

              <div className={"filters pr-1"}>
                <div className="search">
                  <input
                      value={this.state.search}
                      onChange={(e) => this._search(e.target.value)}
                      className="form-control"
                      placeholder="Rechercher"></input>
                </div>
                {
                  !this.props.requiredType &&
                    <select name={"type"} onChange={(e) => this.handleChange(e.target.name, e.target.value)} className={"form-control"}>
                      <option value={"default"}>Tous les types</option>
                      <option value={"image"}>Images</option>
                      <option value={"video"}>Vidéos</option>
                      <option value={"audio"}>Audio</option>
                      <option value={"application"}>Documents</option>
                    </select>
                }
              </div>

              <div className="medias">
                {
                  this.props.media_entity.index.index.map( media =>
                      (
                          <div
                              onClick={() => this.selectMedia(media)}
                              className="thumb-media">
                            <Media
                                allowModal={false}
                                data={media}
                                labelCrop={20}/>
                          </div>
                      ))
                }
              </div>

              {this.renderPagination()}

            </div>
            <div className="tab-pane fade select-file" id="file" role="tabpanel" aria-labelledby="file-tab">
              {this.state._loading ? (
                  <div style={{ color: '#000' }} className="p-3"><Loader /> Chargement du fichier...</div>
              ) : (
                  <div>
                    <div className="form-group mb-2 mt-3">
                      <label htmlFor="copyright" style={{ color: '#000' }}>Saisissez le copyright (facultatif) :</label>
                      <input type="text" className="form-control"
                             name="copyright" id="copyright" value={this.state.copyright}
                             onChange={(e) => this.setState({ copyright: e.target.value })} />
                    </div>
                    <div className="form-group mb-2 mt-3">
                      <label htmlFor="label" style={{ color: '#000' }}>Saisissez la légende par défaut (facultatif) :</label>
                      <input type="text" className="form-control"
                             placeholder="Laisser vide pour que ce soit le nom du fichier"
                             name="label" id="label" value={this.state.label}
                             onChange={(e) => this.setState({ label: e.target.value })} />
                    </div>
                    <div className={"file-picker-container"}>
                      <div className={"my-3"} style={{ color: '#000' }}></div>
                      <input type="file"
                             //value={this.state.file}
                             ref={(ref) => this.fileRef = ref}
                             //onChange={(e) => this.setState({ file: e.target.value }, () => this._upload())}
                             onChange={(e) => this.setState({ file: true }, () => this._upload())}
                      />
                    </div>
                    <hr/>
                    <p className="text-muted" style={{listStyle: "disc outside none", display: "list-item",marginLeft: "2%"}}>
                      Pour les vidéos, merci d'utiliser le format MP4 pour que tous les internautes puissent les regarder.
                      Il est préférable d'en réduire la taille pour faciliter la lecture sur tous les appareils.
                      Vous pouvez utiliser <a href="https://cloudconvert.com/mov-to-mp4" target="_blank">ce site</a> pour convertir en MP4.
                    </p>
                    <p className="text-muted" style={{listStyle: "disc outside none", display: "list-item",marginLeft: "2%"}}>
                      Pour les images, <b>merci d'importer une image qui ne dépasse pas 500ko.</b>
                      Il est préférable d'en réduire la taille pour faciliter l'affichage sur tous les appareils.
                      Vous pouvez utiliser <a href="https://tinypng.com" target="_blank">ce site</a> pour compresser vos images.
                    </p>
                  </div>
              )}
            </div>
          </div>
      )
    }

  }

  renderPagination() {
    return (
        <div className={"pagination-container"}>
          <Pagination
              data={this.props.media_entity.index}
              chunks={this.getEntitiesPerPage()}
              onChange={(value) => this.handleChange('page', value)}/>

          <div className={"chunks"}>
            <label>Medias par page:</label>
            <select name={"chunks"}
                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                    className={"custom-select custom-select-sm"}>
              <option value={"default"}>12</option>
              <option value={"24"}>24</option>
              <option value={"48"}>48</option>
              <option value={"96"}>96</option>
            </select>
          </div>
        </div>
    )
  }
}

export default connect(({media_entity}) => ({media_entity}), { fetchAll, searchAll, createMedia })(MediaSelectModal)
