import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_OFFER_EXPERIENCE_INDEX = 'ENTITY_OFFER_EXPERIENCE_INDEX'
const ENTITY_OFFER_EXPERIENCE_INDEX_SUCCESS = 'ENTITY_OFFER_EXPERIENCE_INDEX_SUCCESS'
const ENTITY_OFFER_EXPERIENCE_INDEX_FAIL = 'ENTITY_OFFER_EXPERIENCE_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_OFFER_EXPERIENCE_INDEX, 'get', '/offer_experiences')
}

export function searchAll(query)
{
    return request(ENTITY_OFFER_EXPERIENCE_INDEX, 'post', '/offer_experiences/search', { query })
}

const ENTITY_OFFER_EXPERIENCE_RETRIEVE = 'ENTITY_OFFER_EXPERIENCE_RETRIEVE'

export function fetchOfferExperience(id)
{
    return request(ENTITY_OFFER_EXPERIENCE_RETRIEVE, 'get', `/offer_experiences/${id}`)
}

const ENTITY_OFFER_EXPERIENCE_CREATE = 'ENTITY_OFFER_EXPERIENCE_CREATE'

export function createOfferExperience(data)
{
    return request(ENTITY_OFFER_EXPERIENCE_CREATE, 'post', '/offer_experiences', data)
}

const ENTITY_OFFER_EXPERIENCE_UPDATE = 'ENTITY_OFFER_EXPERIENCE_UPDATE'

export function updateOfferExperience(id, data)
{
    return request(ENTITY_OFFER_EXPERIENCE_UPDATE, 'patch', `/offer_experiences/${id}`, data)
}


const ENTITY_OFFER_EXPERIENCE_DELETE = 'ENTITY_OFFER_EXPERIENCE_DELETE'

export function deleteOfferExperience(id)
{
    return request(ENTITY_OFFER_EXPERIENCE_DELETE, 'delete', `/offer_experiences/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityOfferExperienceReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_OFFER_EXPERIENCE_INDEX:
            return {...state, loading: true}
        case ENTITY_OFFER_EXPERIENCE_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_OFFER_EXPERIENCE_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}