import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import ChannelCategoryCreateModal from "./ChannelCategoryCreateModal";

import { fetchAll, searchAll } from "../../../data/entities/ChannelCategory";

class ChannelCategoryIndexPage extends Component {
  state = {
    search: "",
    showChannelCategoryCreateModal: false
  };

  _search = search => {
    this.setState({ search }, () => {
      this._fetch();
    });
  };

  _fetch = () => {
    if (this.state.search.length) this.props.searchAll(this.state.search);
    else this.props.fetchAll();
  };

  componentDidMount() {
    this.props.fetchAll();
  }

  render() {
    return (
      <div className="container">
        <Helmet title="ChannelCategory" />
        <div className="row">
          <div className="col">
            <h1>ChannelCategory</h1>
          </div>
          <div className="col text-right">
            <Loader display={this.props.channel_category_entity.loading} />
            <input
              type="text"
              value={this.state.search}
              onChange={e => this._search(e.target.value)}
              placeholder="Rechercher..."
              className="form-control d-inline-block ml-2"
              style={{ maxWidth: 200 }}
            />
            <button
              onClick={() =>
                this.setState({ showChannelCategoryCreateModal: true })
              }
              className="btn btn-primary ml-2"
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
        {this.renderEntities()}
        {this.state.showChannelCategoryCreateModal ? (
          <ChannelCategoryCreateModal
            onClose={created => {
              if (created) this._fetch();
              this.setState({ showChannelCategoryCreateModal: false });
            }}
          />
        ) : null}
      </div>
    );
  }

  renderEntities() {
    if (
      this.props.channel_category_entity.loading &&
      !this.props.channel_category_entity.lastUpdate
    )
      return <ContentLoader />;

    if (!this.props.channel_category_entity.index.length) {
      return <Empty />;
    }

    return (
      <table className="table table-striped mt-3">
        {this.props.channel_category_entity.index.map(entity => (
          <tr>
            <td>
              <Link to={"/entities/channel_category/" + entity.id}>
                ChannelCategory #{entity.id}
              </Link>
            </td>
          </tr>
        ))}
      </table>
    );
  }
}

export default connect(
  ({ channel_category_entity }) => ({ channel_category_entity }),
  { fetchAll, searchAll }
)(ChannelCategoryIndexPage);
