import React, {Component} from 'react';
import { Helmet } from 'react-helmet'

export default class NotFoundPage extends Component
{
    render()
    {
        return (
            <div className="container text-center mt-5 pt-5 pb-5">
                <Helmet title="Erreur 404" />
                <h1>404</h1>
                <h5 className="text-muted">Cette page n'existe pas 😲</h5>
            </div>
        )
    }
}
