import React, {Component} from 'react';
import Modal from "../../../components/Modal"
import Datepicker from "../../../components/Datepicker";
import moment from "moment-timezone";
import {connect} from 'react-redux';
import {Form, Submit} from "react-forms";
import {toast} from "react-toastify";
import {updateArticle} from "../../../data/entities/Article";
import "./ArticleUpdateForm.scss";

import { verifyImageCover } from "../../../components/imageUtils";


class ArticlePublishModal extends Component
{
    imgs = {
        file: this.props.data.cover ? this.props.data.cover.file : null,
        file_thumbnail: this.props.data.cover ? this.props.data.cover.file_thumbnail : null,
        file_thumbnail_medium: this.props.data.cover ? this.props.data.cover.file_thumbnail_medium : null,
        file_thumbnail_small: this.props.data.cover ? this.props.data.cover.file_thumbnail_small : null,
        file_thumbnail_wp: this.props.data.cover ? this.props.data.cover.file_thumbnail_wp : null
    }
    state = {
        loading: false,
        publication_start: this.props.data.publication_start,
        publication_end: this.props.data.publication_start,
        publication_status: true,
        publishAdmin: this.props.data.publishAdmin,
        data_images:this.imgs
    };

    handleDateChange = (input, date) => {
        this.setState({ [input]: date === null ? null : moment(date) });
        if(input == "publication_start"){
            this.setState({ publishAdmin: true });
        }
    };

    _submit = () => {

        if (!this.state.publication_start)
            return;

        if (this.state.publication_end && (this.state.publication_end < this.state.publication_start || this.state.publication_end < moment()))
            return;

        this.setState({ _loading: true });

        const data = Object.assign(this.props.data, {...this.state});
        console.log('data:::: ', this.props.data)
        console.log('images:::: ', this.state.data_images)
        if (
            this.state.data_images.file !== null
            || this.state.data_images.file_thumbnail !== null
            || this.state.data_images.file_thumbnail_medium !== null
            || this.state.data_images.file_thumbnail_small !== null
            || this.state.data_images.file_thumbnail_wp !== null )
        {
            verifyImageCover(this.state.data_images).then((statusCodes) => {
                console.log('status code ::', statusCodes)
                if (statusCodes.includes(404) || statusCodes.includes(500)) {
                    toast.error("Votre image uploadée n’est pas reconnue, veuillez en choisir une autre pour pouvoir publier votre article.")
                    this.setState({_loading: false})
                    return
                }else{
                    this.props.updateArticle(this.props.data.id, data)
                        .then((res) => {
                            toast.success("Parfait ! Les modifications ont été appliquées.");
                            this.setState({ _loading: false });
                            this.props.onClose()
                        })
                        .catch((e) => {
                            if (e.error && e.error.response && e.error.response.data);
                            this.setState({ _formData: e.error.response.data });
                            this.setState({ _loading: false })
                        })
                }
            });
        }else if(
            this.state.data_images.file === null
            && this.state.data_images.file_thumbnail === null
            && this.state.data_images.file_thumbnail_medium === null
            && this.state.data_images.file_thumbnail_small === null
            && this.state.data_images.file_thumbnail_wp === null
        ){
            this.props.updateArticle(this.props.data.id, data)
                .then((res) => {
                    toast.success("Parfait ! Les modifications ont été appliquées.");
                    this.setState({ _loading: false });
                    this.props.onClose()
                })
                .catch((e) => {
                    if (e.error && e.error.response && e.error.response.data);
                    this.setState({ _formData: e.error.response.data });
                    this.setState({ _loading: false })
                })
        }



    };

    render() {
        console.log('this.state.publication_start')
        console.log(this.state.publication_start)
        console.log(this.state)
        const buttonStyle = {
            width: 'fit-content'
        };

        return(
            <Modal title={'Publier un article'} onClose={() => this.props.onClose()}>
                <div className="article-update-form">
                    <Form onSubmit={this._submit}>
                        <div className={"date-pickers"}>
                            <div>
                                <label>Date de début de publication:</label>
                                <Datepicker
                                    showTodayButton={true}
                                    type={"datetime"}
                                    handleDateChange={(date) => this.handleDateChange("publication_start", date)}
                                    selectedDate={this.state.publication_start}/>
                            </div>

                        </div>
                        <div className="date-pickers">
                            <div>
                                <label>Date de fin de publication:</label>
                                <div className={"radios"}>
                                    <div className="custom-control custom-radio option-1">
                                        <input
                                            onChange={() => this.handleDateChange("publication_end", moment())}
                                            value={"updated_at"} type="radio" id="publication-end-1"
                                            name="publication_end" className="custom-control-input"
                                            checked={!!this.state.publication_end}
                                        />
                                        <label className={"custom-control-label"} htmlFor="publication-end-1">
                                            <Datepicker
                                                showTodayButton={true}
                                                onOpen={() => this.handleDateChange("publication_end", moment())}
                                                type={"datetime"}custom-control-inline
                                                handleDateChange={(date) => this.handleDateChange("publication_end", date)}
                                                selectedDate={this.state.publication_end}/>
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio option-2">
                                        <input
                                            onChange={() => {this.handleDateChange("publication_end", null)}}
                                            type="radio" id="publication-end-2" name="publication_end"
                                            className="custom-control-input"
                                            checked={!this.state.publication_end}/>
                                        <label className="custom-control-label"
                                               htmlFor={"publication-end-2"}>Indéterminée</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Submit disabled={this.state.loading} className="btn btn-primary" style={buttonStyle}>Publier</Submit>
                    </Form>
                </div>
            </Modal>
        )
    }
}

export default connect(null, {updateArticle})(ArticlePublishModal);
