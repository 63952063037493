import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_MEDIA_INDEX = 'ENTITY_MEDIA_INDEX'
const ENTITY_MEDIA_INDEX_SUCCESS = 'ENTITY_MEDIA_INDEX_SUCCESS'
const ENTITY_MEDIA_INDEX_FAIL = 'ENTITY_MEDIA_INDEX_FAIL'

export function fetchAll(query_params)
{
    return request(ENTITY_MEDIA_INDEX, 'get', `/media?${query_params}`)
}

export function searchAll(query)
{
    return request(ENTITY_MEDIA_INDEX, 'get', '/media?query=' + encodeURIComponent(query))
}

const ENTITY_MEDIA_RETRIEVE = 'ENTITY_MEDIA_RETRIEVE'

export function fetchMedia(id)
{
    return request(ENTITY_MEDIA_RETRIEVE, 'get', `/media/${id}`)
}

const ENTITY_MEDIA_CREATE = 'ENTITY_MEDIA_CREATE'

export function createMedia(data)
{
    return request(ENTITY_MEDIA_CREATE, 'post', '/media', data)
}

const ENTITY_MEDIA_UPDATE = 'ENTITY_MEDIA_UPDATE'

export function updateMedia(id, data)
{
    return request(ENTITY_MEDIA_UPDATE, 'patch', `/media/${id}`, data)
}

const ENTITY_MEDIA_DELETE = 'ENTITY_MEDIA_DELETE'

export function deleteMedia(id)
{
    return request(ENTITY_MEDIA_DELETE, 'delete', `/media/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityMediaReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_MEDIA_INDEX:
            return {...state, loading: true}
        case ENTITY_MEDIA_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_MEDIA_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
