import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_SUGGESTED_ARTICLE_INDEX = 'ENTITY_SUGGESTED_ARTICLE_INDEX'
const ENTITY_SUGGESTED_ARTICLE_INDEX_SUCCESS = 'ENTITY_SUGGESTED_ARTICLE_INDEX_SUCCESS'
const ENTITY_SUGGESTED_ARTICLE_INDEX_FAIL = 'ENTITY_SUGGESTED_ARTICLE_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_SUGGESTED_ARTICLE_INDEX, 'get', '/suggested_articles')
}

const ENTITY_SUGGESTED_ARTICLE_COUNT = 'ENTITY_SUGGESTED_ARTICLE_COUNT'
const ENTITY_SUGGESTED_ARTICLE_COUNT_SUCCESS = 'ENTITY_SUGGESTED_ARTICLE_COUNT_SUCCESS'

export function countAll()
{
    return request(ENTITY_SUGGESTED_ARTICLE_COUNT, 'get', '/suggested_articles/count')
}

export function searchAll(query)
{
    return request(ENTITY_SUGGESTED_ARTICLE_INDEX, 'post', '/suggested_articles/search', { query })
}

const ENTITY_SUGGESTED_ARTICLE_RETRIEVE = 'ENTITY_SUGGESTED_ARTICLE_RETRIEVE'

export function fetchSuggestedArticle(id)
{
    return request(ENTITY_SUGGESTED_ARTICLE_RETRIEVE, 'get', `/suggested_articles/${id}`)
}

const ENTITY_SUGGESTED_ARTICLE_TRANSFORM = 'ENTITY_SUGGESTED_ARTICLE_TRANSFORM'

export function transformSuggestedArticle(id)
{
    return request(ENTITY_SUGGESTED_ARTICLE_TRANSFORM, 'put', '/suggested_articles/' + id)
}

/*const ENTITY_SUGGESTED_ARTICLE_UPDATE = 'ENTITY_SUGGESTED_ARTICLE_UPDATE'

export function updateSuggestedArticle(id, data)
{
    return request(ENTITY_SUGGESTED_ARTICLE_UPDATE, 'patch', `/suggested_articles/${id}`, data)
}*/

const ENTITY_SUGGESTED_ARTICLE_DELETE = 'ENTITY_SUGGESTED_ARTICLE_DELETE'

export function deleteSuggestedArticle(id) // Reject
{
    return request(ENTITY_SUGGESTED_ARTICLE_DELETE, 'delete', `/suggested_articles/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null,
    count: false
};

// Reducer

export default function EntitySuggestedArticleReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_SUGGESTED_ARTICLE_INDEX:
            return {...state, loading: true}
        case ENTITY_SUGGESTED_ARTICLE_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_SUGGESTED_ARTICLE_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
        case ENTITY_SUGGESTED_ARTICLE_COUNT_SUCCESS:
            return {...state, count: action.payload.data.count}
    }
    return state
}
