import React, {Component} from 'react';
import Modal from "../../../components/Modal"
import {Form, Submit} from "react-forms";
import {toast} from "react-toastify";
import {updateArticle} from "../../../data/entities/Article";
import {connect} from 'react-redux';


class ArticleUnpublishModal extends Component
{
    state = {
        loading: false,
        publication_start: null,
        publication_status: 0,
        publishAdmin: false,
        publishExpert: false,
    };

    _submit = () => {

        this.setState({ _loading: true });

        const data = Object.assign(this.props.data, {...this.state});

        this.props.updateArticle(this.props.data.id, data)
            .then((res) => {
                toast.success("Parfait ! Les modifications ont été appliquées.");
                console.log("DATA ON CLOSE: " ,  res.payload.data);
                this.setState({ _loading: false });
                this.props.onClose()
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data);
                    this.setState({ _formData: e.error.response.data });
                this.setState({ _loading: false })
            })
    };

    render() {

        return(
            <Modal title={'Dépublier un article'} onClose={() => this.props.onClose()}>
                <Form onSubmit={this._submit}>
                    <p>Êtes-vous sûr de vouloir dépublier cet article ?</p>
                    <Submit disabled={this.state.loading} className="btn btn-danger">Dépublier</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, {updateArticle})(ArticleUnpublishModal);
