import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';

import "./MediaLightbox.scss"

import MediaUpdateModal from "./MediaUpdateModal"

class MediaLightbox extends Component {

  state = {
    url: this.props.data.file,
    showToolbar: false,
    allowCrop: false,
    allowSubmitCrop: false,
    crop: {
      x: "",
      y: "", 
      width: "",
      height: ""
    },
    croppedImageUrl: ""
  }

  toggleCrop = () => {
    this.setState({
      allowCrop: !this.state.allowCrop
    })
  }

  disableCrop = () => {
    this.setState({
      allowCrop: false
    })
  }


  onImageLoaded = (image) => {
    this.imageRef = image

  }

  onCropChange = (crop) => {
    this.setState({ crop })
  }

  getCroppedImage = (image, pixelCrop, fileName) => {
    const canvas = document.createElement('canvas')
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    const ctx = canvas.getContext('2d')

    image.crossOrigin = 'anonymous'

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    )

    const base64Image = canvas.toDataURL();
    console.log("base64image", base64Image)
    return base64Image
  }

  makeClientCrop = (crop, pixelCrop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImage(
        this.imageRef,
        pixelCrop, 
        this.state.url)
      this.setState({ croppedImageUrl }, console.log("setstate:", this.state.croppedImageUrl))
    }
  }

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop)
    this.setState({ allowSubmitCrop: true })
  }

  updateImage = () => {
      console.log("heyo", this.props.data.id)
      console.log("croppedimgurl", this.props.croppedImageUrl)
      if (this.state.croppedImageUrl) {
        this.setState({
          url: this.props.data.file,
          allowCrop: false,
          allowSubmitCrop: false,
          crop: {
            x: "",
            y: "",
            width: "",
            height: ""
          },
          croppedImageUrl: ""
        })
    }
  }

  renderMedia() {
    let data = this.props.data

    if (data.type.startsWith("image/")) {
      return (
          <div className="lightbox-image">
            <div className="img-container">
              {
                this.state.allowCrop ? (
                    <Fragment>
                      <ReactCrop
                          crossorigin={"anonymous"}
                          src={this.props.data.file_proxy}
                          crop={this.state.crop}
                          onImageLoaded={(image) => this.onImageLoaded(image)}
                          onComplete={(crop, pixelCrop) => {this.onCropComplete(crop, pixelCrop)}}
                          onChange={(crop) => this.onCropChange(crop)} />

                      {this.state.allowSubmitCrop &&
                          <button
                              className="btn btn-light submit-crop-btn"
                              onClick={() => this.updateImage()}>
                            Séléctionner
                          </button>}
                    </Fragment>


                ) : (
                    <Fragment>
                      <img src={this.state.url} alt={this.props.data.label}/>
                      {/*<div
                          onClick={() => {this.toggleCrop()}}
                          className="crop-btn"
                          container="body"
                          data-toggle="tooltip"
                          data-placement="left"
                          title="Rogner">
                        <i className="fas fa-crop-alt"></i>
                      </div>*/}
                    </Fragment>
                )
              }
            </div>
          </div>
      )
    }
    else if (data.type.startsWith("video/")) {
      return (
          <video controls width="100%">
            <source type="video/mp4" src={this.props.data.file}/>
          </video>
      )
    }
    else if (data.type === "application/pdf") {
      console.log("file type", data.file_proxy)
      return (
          <div className={"document"}>
            <object type="application/pdf"
                    data={data.file_proxy}
                    width="700"
                    height="700">
            </object>
          </div>
      )
    }
    else if (data.type.startsWith("application/")) {
      return (
          <div className={"document"}>
            <div className={"empty"}>
              <i className="fas fa-box-open"></i>
              <span>L'aperçu n'est pas disponible pour ce fichier.</span>
            </div>
          </div>
      )
    }

    else if (data.type.startsWith("audio/")) {
      return (
          <div className={"audio"}>
            <figure>
              <audio
                  controls
                  src={data.file_proxy}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
      )
    }

    else {
      return <i className="fas fa-question"></i>
    }
  }

  renderToolbar() {
    if (this.state.showToolbar) {
      return (
          <div className="lightbox-toolbar">
            <MediaUpdateModal
                data={this.props.data}
                onClose={(changes) => {
                  this.props.onClose(changes)
                  this.setState({ showToolbar: false})
                }}
                allowCrop={this.state.allowCrop}
                toggleCrop={() => this.toggleCrop()}
                disableCrop={() => this.disableCrop()}
                updateImage={() => this.updateMedia()}/>
          </div>
        )}
  }

  renderHeader() {
    return (
        <div className="header">
          <h1>{this.state.showToolbar ?
              this.props.data.label.length > 90 ? `${this.props.data.label.slice(0, 90)} ...` : this.props.data.label
              :
              this.props.data.label.length > 30 ? `${this.props.data.label.slice(0, 30)} ...` : this.props.data.label}</h1>
          <div className="top-buttons">
            <i
                onClick={() => this.setState({ showToolbar : !this.state.showToolbar})}
                className={this.state.showToolbar ? "fas fa-pen active" : "fas fa-pen"}/>
            <i
                onClick={() => this.props.onClose(false)}
                className="fas fa-times"/>
          </div>
        </div>
    )
  }

  render() {
    return (
      <div className="lightbox-background">
        <div className={this.state.showToolbar ? "lightbox lightbox-active" : "lightbox"}>
            {this.renderHeader()}
          <div className="edit-block">
            {this.renderMedia()}
            {this.renderToolbar()}
          </div>
            
        </div>
      </div>
    )
  }
}

export default connect(null, null )(MediaLightbox)