import React, { Component } from 'react'
import { MuiPickersUtilsProvider,
    DatePicker,
    TimePicker,
    DateTimePicker
} from 'material-ui-pickers';
import moment from 'moment-timezone';
import "moment/locale/fr";
import "moment/locale/ru";
import MomentUtils from "@date-io/moment";

//import HUE from '@material-ui/core/colors/HUE';

moment.locale("fr");

export default class Datepicker extends Component {

    /*materialTheme = createMuiTheme({
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: lightBlue.A200,
                },
            },
            MuiPickersCalendarHeader: {
                switchHeader: {
                    // backgroundColor: lightBlue.A200,
                    // color: "white",
                },
            },
            MuiPickersDay: {
                day: {
                    color: lightBlue.A700,
                },
                isSelected: {
                    backgroundColor: lightBlue["400"],
                },
                current: {
                    color: lightBlue["900"],
                },
            },
            MuiPickersModal: {
                dialogAction: {
                    color: lightBlue["400"],
                },
            },
        },
    }); */

    render() {
        return (
            <div id={"datepicker"}>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale={"fr"}>
                    {this.renderPicker()}
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    renderPicker = () => {
        if (this.props.type === "datetime") {
            return <DateTimePicker
                        onOpen={this.props.onOpen}
                        onClose={this.props.onClose}
                        disabled={this.props.disabled}
                        emptyLabel={"Choisir une date"}
                        ampm={false}
                        onError={console.log}
                        showTodayButton={this.props.showTodayButton}
                        format={"LLL"}
                        value={this.props.selectedDate}
                        cancelLabel="annuler"
                        todayLabel={"aujourd'hui"}
                        maxDate={this.props.maxDate}
                        onChange={(date) => this.props.handleDateChange(date)} />
        }

        else if (this.props.type === "time") {
            return <TimePicker
                        emptyLabel={"Choisir une heure"}
                        ampm={false}
                        value={this.props.selectedDate}
                        onChange={(time) => this.props.handleDateChange(time)} />
        }

        else if (this.props.type === "date") {
            return <DatePicker
                        emptyLabel={"Choisir une date"}
                        showTodayButton
                        format={"L"}
                        value={this.props.selectedDate}
                        onChange={(date) => this.props.handleDateChange(date)} />
        }
    }
}
