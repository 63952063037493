import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone';
import Modal from '../../components/Modal'
import Empty from '../../components/Empty'
import Pagination from "../../components/Pagination";

import "./SelectArticleModal.scss"

import {fetchAll, searchAll} from '../../data/entities/Article'
import ContentLoader from "../../components/ContentLoader";

class SelectArticleModal extends Component {

  state = {
    search: "",

    _formData: null,
    _loading: false,
    file: null,

    filters: [
      {
        name: "page",
        value: 1
      },
      {
        name: "chunks",
        value: 12
      }
    ]
  }

  _search = search => {
    this.setState({ search }, () => {
      if (search.length) {
        this.handleChange("query", this.state.search)
      } else {
        this.handleChange("query", "default")
      }
    });
  };


  _fetch = () => {
    this.props.fetchAll()
  }

  handleChange = (name, value) => {
    if (name !== "page") {
      this.setState(
          { filters: this.state.filters.map(filter => {
              if (filter.name === "page")
                filter.value = 1
              return filter
            })
          })
    }

    if (value === "default") {
      this.setState(
          { filters: this.state.filters.filter(filter => filter.name !== name)} ,
          () => this._submitFilters())
    }

    else if (this.state.filters.find(filter => filter.name === name)) {
      this.setState(
          { filters:
                this.state.filters.map(filter => {
                  if (filter.name === name) { filter.value = value }
                  return filter
                })
          },
          () => this._submitFilters())
    }

    else {
      this.setState(
          { filters: [...this.state.filters, { name: name, value: value }] },
          () => this._submitFilters())
    }
  }

  _submitFilters = () => {
    console.log("submiting filters")
    const filters = this.state.filters

    if (filters.length) {
      let query_params = ""
      for (let i = 0; i < filters.length; i++) {
        if (i === filters.length -1) {
          query_params = `${query_params}${filters[i].name}=${filters[i].value}`
        } else {
          query_params = `${query_params}${filters[i].name}=${filters[i].value}&`
        }
      }
      this.props.fetchAll(query_params)
    }
    else {
      this._fetch()
    }
  }

  getEntitiesPerPage = () => {
    if (this.state.filters.find(filter => filter.name === "chunks") === undefined) {
      return 12
    } else {
      return this.state.filters.find(filter => filter.name === "chunks")
    }
  }

  selectArticle = (article) => {
    this.props.onClose(article)
  }

  componentDidMount() {
    console.log("hello")
    this.props.fetchAll()
  }

  render() {
    return (
      <Modal
        title="Séléctionner un article"
        onClose={() => this.props.onClose(false)}
        additionnalClass="select-article-modal">

        <div className="search-article">
          <input
            value={this.state.search}
            onChange={(e) => {this._search(e.target.value)}}
            className="form-control"
            placeholder="Rechercher"></input>
        </div>

        {this.renderArticles()}
        {this.renderPagination()}
      </Modal>
    )
  }

  renderArticles() {
    if (this.props.article_entity.loading && !this.props.article_entity.lastUpdate)
      return <ContentLoader/>

    if (!this.props.article_entity.index) {
      return <Empty/>
    }
    else {
      return (
          <div className="list-group">
            {
              this.props.article_entity.index.index.map( article => (
                  <a
                      onClick={() => this.selectArticle(article)}
                      href="#"
                      class="list-group-item list-group-item-action flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                      <h5 class="mb-1">{article.title}</h5>
                      <small>{moment(article.publication_start).format("MMMM Do YYYY")}</small>
                    </div>
                    <p class="mb-1">{article.author_name}</p>
                  </a>
              ))
            }
          </div>
      )
    }
  }

  renderPagination() {
    if (this.props.article_entity.loading && !this.props.article_entity.lastUpdate)
      return <ContentLoader/>

    if (!this.props.article_entity.index) {
      return <Empty/>
    }

    else {
      return (
          this.props.article_entity.index.pages > 1 &&
          <div className={"pagination-container"}>
            <Pagination
                data={this.props.article_entity.index}
                chunks={this.getEntitiesPerPage()}
                onChange={(value) => this.handleChange('page', value)}/>

            <div className={"chunks"}>
              <label>Medias par page:</label>
              <select name={"chunks"}
                      onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                      className={"custom-select custom-select-sm"}>
                <option value={"default"}>12</option>
                <option value={"24"}>24</option>
                <option value={"48"}>48</option>
                <option value={"96"}>96</option>
              </select>
            </div>
          </div>
      )
    }
  }
}

export default connect(({article_entity}) => ({article_entity}), { fetchAll, searchAll })(SelectArticleModal)
