import React, {Component} from 'react';
import {Nav,NavItem, NavLink} from 'reactstrap'
import {Link} from "react-router-dom";
import {checkPrivilege} from "../helpers";
import {connect} from "react-redux";

class ArticlesTabs extends Component
{
    render()
    {
        const active = this.props.tab

        return (
            <div>
                <Nav tabs>
                    {checkPrivilege(this.props.user, 'article') ? <NavItem>
                        <Link to="/entities/article" className={active == 'articles' ? 'nav-link active' : 'nav-link'}>
                            Articles
                        </Link>
                    </NavItem> : null}
                    {checkPrivilege(this.props.user, 'Contribution') ? <NavItem>
                        <Link to="/Contribution" className={active == 'Contribution' ? 'nav-link active' : 'nav-link'}>
                            Contributions
                        </Link>
                    </NavItem> : null }
                    {checkPrivilege(this.props.user, 'curation') ? <NavItem>
                        <Link to="/curation" className={active == 'curation' ? 'nav-link active' : 'nav-link'}>
                            Curation
                        </Link>
                    </NavItem> : null}
                    {checkPrivilege(this.props.user, 'flux-rss') ? <NavItem>
                        <Link to="/flux-rss" className={active == 'flux-rss' ? 'nav-link active' : 'nav-link'}>
                            Flux RSS
                        </Link>
                    </NavItem> : null}
                </Nav>
            </div>
        );
    }
}

export default connect(({ user}) => ({ user }))(ArticlesTabs);
