import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Submit, Input } from "react-forms";
import { FormattedMessage } from "react-intl";
import { toast } from 'react-toastify';
import { isUrl } from '../../../helpers';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import Modal from "../../../components/Modal";
import ContentLoader from "../../../components/ContentLoader";

import { createAdvertisingBanner } from "../../../data/entities/AdvertisingBanner";
import { fetchAll as fetchAllBannersTypes } from "../../../data/entities/AdvertisingBannerType";
import { fetchAll as fetchAllNewsletters } from "../../../data/entities/Newsletter";

import "./AdvertisingBannerModal.scss"

class AdvertisingBannerCreateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        image: null,

        _banners_types: [],
        _newsletters: [],

        id: this.props.id,
        advertising_banner_type: null,
        image_type: null,
        target_newsletter: null,
        newsletter: null,
        link: null,
        html: null,
        publication_status: 0,

        activeTab: '2',
        activeTabImage: '3'
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleImageTab(tab) {
        if (this.state.activeTabImage !== tab) {
            this.setState({
                activeTabImage: tab
            });
        }
    }

    _submit = () => {
        this.setState({ _loading: true });
        console.log("this.state.image_type", this.state.image)
        if (this.state.advertising_banner_type === null || this.state.image === "") {
            toast.error("Ajoutez une image");
            this.setState({ _loading: false });
        }


    else if (this.state.image_type === "link" && (this.state.link === null || this.state.link === "" || !isUrl(this.state.link))) {
            this.setState({ newsletter: null, _loading: false })
            toast.error("Vérifiez le format de l'URL")
        }

        else if (this.state.image_type === "newsletter" && this.state.newsletter === null) {
            this.setState({ link: null, _loading: false })
        }

        else {
            const data = {...this.state}
            delete data._loading
            delete data._formData
            delete data._banners_types
            delete data._newsletters
            delete data.activeTab
            delete data.activeTabImage
            data.advertising_banner_type = data.advertising_banner_type.id
            data.image = this.fileRef.files[0]

            if (data.image_type === "link") {
                delete data.newsletter            }
            else if (data.image_type === "newsletter") {
                delete data.link
            }
            
            if (data.html !== null) {
                data.image = "";
            } else {
                delete data.html;
            }

            if (!data.target_newsletter) delete data.target_newsletter

            if(data.image && data.image.size > 500000) {
                toast.error("L'image est trop grande");
                this.setState({ _loading: false });
                return;
            }
           else{
                this.props.createAdvertisingBanner(data.id, data)
                    .then((res) => this.props.onClose(res.payload.data))
                    .catch((e) => {
                        if (e.error && e.error.response && e.error.response.data)
                            this.setState({ _formData: e.error.response.data });
                        this.setState({ _loading: false })
                    })
            }
        }
    };

    componentDidMount() {
        this.setState({ _loading: true })

        this.props.fetchAllBannersTypes()
            .then(res => this.setState(
                { _banners_types: res.payload.data },
                () => this.setState({ _loading: false })
            ))
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data });
                this.setState({ _loading: false });
                console.log('error', e);
            });


        this.props.fetchAllNewsletters()
            .then(res => this.setState({ _newsletters: res.payload.data }))
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data });
                this.setState({ _loading: false })
            })
    }

    handleTypeChange = (e) => {
        const banner_type = this.state._banners_types.find(banner_type => banner_type.id === parseInt(e.target.value));
        this.setState({ advertising_banner_type: banner_type, activeTab: '2', target_newsletter: null });
    };

    uploadImage = (e) => {

        console.log("file ref", this.fileRef.files[0])
        console.log("banner type image dimensions", this.state.advertising_banner_type)

        if (this.fileRef.files[0].type.startsWith("image/")) {
            let img = new Image();

            img.src = window.URL.createObjectURL(this.fileRef.files[0]);

            console.log("e.target value", e.target.value)

            let value = e.target.value

            img.onload = () => {
                console.log("value --->", value)

                const width = img.naturalWidth
                const height = img.naturalHeight

                console.log("dimensions", width, height)

                console.log("VALUE IN IF STATEMENT --->", value)
                this.setState({ image: value, _formData: null  })

            }

        }
        else {
            this.setState({ image: "", _formData: "Veuillez choisir une image."})
        }
    };

    onChangeLink(link) {
        this.setState({ link });
        this.setState({ image_type: 'link' });
    }

    onChangeNewsletter(newsletter) {
        this.setState({ newsletter: newsletter });
        this.setState({ image_type: 'newsletter' });
    }

    renderImageInput() {

        return(
            <div className="form-group upload mb-4 px-4" style={{backgroundColor: "#eee"}}>
                <label className={"d-none"}>Image</label>
                <div className="mt-5">
                    <input type="file"
                           name="file" id="file"
                           ref={(ref) => this.fileRef = ref}
                           onChange={(e) => this.uploadImage(e)} />
                </div>
                <div className="mt-3 mb-4 text-primary text-muted">
                    Dimensions recommandées:
                    hauteur: {this.state.advertising_banner_type.height}px
                    largeur: {this.state.advertising_banner_type.width}px
                </div>
                <div>
                    <p><strong>Action au clic :</strong></p>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTabImage === '3' })}
                                onClick={() => { this.toggleImageTab('3'); }}
                            >
                                Ouvrir une page web
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTabImage === '4' })}
                                onClick={() => { this.toggleImageTab('4'); }}
                            >
                                Inscription à une newsletter
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTabImage}>
                        <TabPane tabId="3">
                            <Input
                                value={this.state.link} onChangeValue={(link) => this.onChangeLink(link)}
                                placeholder="Veuillez saisir l'URL de la page web" label="" type="text" className="form-control my-4"/>
                        </TabPane>
                        <TabPane tabId="4">
                            <div className="form-group my-4">
                                <label>Choisissez votre newsletter</label>
                                <select
                                    onChange={(e) => this.onChangeNewsletter(e.target.value)}
                                    className="custom-select">
                                    <option>--- Newsletter ---</option>
                                    {
                                        this.state._newsletters.length &&
                                        this.state._newsletters.map(newsletter =>
                                            <option value={newsletter.id}>{newsletter.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </TabPane>
                    </TabContent>
                    <hr/><p className="text-muted" style={{listStyle: "disc outside none", display: "list-item",marginLeft: "2%"}}>
                        Pour les images, <b>merci d'importer une image qui ne dépasse pas 500ko.</b>
                        Il est préférable d'en réduire la taille pour faciliter l'affichage sur tous les appareils.
                        Vous pouvez utiliser <a href="https://tinypng.com" target="_blank">ce site</a> pour compresser vos images.
                    </p>
                </div>
            </div>
        )
    };

    renderHtmlInput() {
        return(
            <div className="form-group upload mb-4 px-4" style={{backgroundColor: "#eee"}}>
                <textarea className={"form-control my-4"} placeholder={"Veuillez saisir le code html"} onChange={(e) => this.setState({ html: e.target.value } )}></textarea>
            </div>
        )
    }

    render()
    {
        if (this.state._loading)  return <ContentLoader />

        return (
            <Modal title={"Créer une bannière"} onClose={() => this.props.onClose(false)} additionnalClass="advertising-banner-create-modal">
                <Form onSubmit={this._submit} data={this.state._formData}>

                    {this.state._formData === "Veuillez choisir une image." &&
                    <div className="alert alert-danger" role="alert">{this.state._formData}</div>}

                    <div className="form-group mb-3">
                        <label>Type de bannière</label>
                        <select
                            value={this.state.advertising_banner_type ? this.state.advertising_banner_type.id : null}
                            onChange={(e) => this.handleTypeChange(e)}
                            className="custom-select">
                            <option value={null}>--- Type ---</option>
                            {
                                this.state._banners_types.length &&
                                this.state._banners_types.map(type =>
                                    <option value={type.id}>{type.name}</option>
                                )
                            }
                        </select>
                    </div>
                    {this.state.advertising_banner_type ?
                        <div>
                            {
                                this.state.advertising_banner_type.slug == 'newsletter' ? (
                                    <div className="form-group my-4">
                                        <label>Dans quelle newsletter doit-elle apparaitre ?</label>
                                        <select
                                            onChange={(e) => {
                                                const value = parseInt(e.target.value)
                                                this.setState({
                                                    target_newsletter: isNaN(value) ? null : value
                                                })
                                            }}
                                            value={this.state.target_newsletter}
                                            className="custom-select">
                                            <option value={null}>Toutes</option>
                                            {
                                                this.state._newsletters.length &&
                                                this.state._newsletters.map(newsletter =>
                                                    <option value={newsletter.id}>{newsletter.name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                ) : null
                            }
                            {['mobile-banner', 'mobile-splash'].indexOf(this.state.advertising_banner_type.slug) === -1 ? (
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggle('2'); }}
                                        >
                                            Image
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => { this.toggle('1'); }}
                                        >
                                            HTML
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            ) : null}
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    {this.renderHtmlInput()}
                                </TabPane>
                                <TabPane tabId="2">
                                    {this.renderImageInput()}
                                </TabPane>
                            </TabContent>
                        </div>
                        : null}

                    <div className={"form-group date mb-4"}>
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                onChange={() => this.setState({ publication_status: this.state.publication_status === 1 ? 0 : 1})}
                                checked={this.state.publication_status === 1}
                                className="custom-control-input" id="publication_status"/>
                            <label className="custom-control-label" htmlFor="publication_status">Bannière active</label>
                        </div>
                    </div>

                    <Submit disabled={this.state._loading}>Créer</Submit>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, {
    createAdvertisingBanner,
    fetchAllBannersTypes,
    fetchAllNewsletters
})(AdvertisingBannerCreateModal)
