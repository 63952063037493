import React, {Component} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import Autocomplete from "./Autocomplete";
import {searchAll} from "../../../data/entities/Category";
import {connect} from "react-redux";

class CategoryFilterMultiple extends Component
{
    state = {
        open: false,
        loading: false,
        query: '',
        queryResponse: []
    }

    _fetch = () => {
        const {query} = this.state

        if (query.trim().length === 0)
            return this.setState({ queryResponse: [], loading: false })

        this.setState({ loading: true })

        this.props.searchAll(this.state.query)
            .then(res => {
                if (res.payload.data)
                    this.setState({ queryResponse: res.payload.data.slice(0, 14) })
            })
            .catch((e) => {
                this.setState({ loading: false})
            })
    }
    removeCategory = (category) => {
        this.props.onChange(category,true)
    }
    render() {
        console.log('state')
        console.log(this.state,this.props)
        return (
            <>
                <div className="d-inline-block category-filter mr-2">{this.renderDropdown()}</div>
            </>
        );
    }

    renderDropdown()
    {
        return (
            <Dropdown isOpen={this.state.open}
                      toggle={() => this.setState({ open: !this.state.open })}>
                <DropdownToggle caret>
                    {this.renderLabel(this.props.value)}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem
                        onClick={() => this.props.onChange(null)}>
                        Toutes les catégories
                    </DropdownItem>
                    <div>
                        <Autocomplete
                            createEntity={false}
                            placeholder={"Rechercher une catégorie..."}
                            className={"filter"}
                            renderDropdownItem={(category) => (
                                <span>{category.name}</span>
                            )}

                            query={this.state.query}
                            queryResponse={this.state.queryResponse}
                            handleChange={(e) => this.setState({ query: e.target.value }, this._fetch)}
                            escape={() => this.setState({ query: '', queryResponse: [] })}
                            selectEntity={(id, category) => {
                                this.props.onChange(category)
                                this.setState({ query: '', queryResponse: [], open: false })
                            }}
                        />
                        {this.renderSelectedCategories(this.props.value)}
                    </div>
                </DropdownMenu>
            </Dropdown>
        )
    }

    renderLabel(category)
    {
        if (category == null  || category.length === 0) {
            return "Toutes les catégories"
        }

        return (
            // <span><i className="fa fa-folder"/> {category.name}</span>
            <div className={"d-inline-block"}>
                <span> Catégories Filtrées</span><br/>
            </div>
        )
    }
    renderSelectedCategories(category)
    {
        if (category == null  || category.length === 0 ) {
            return ""
        }

        else{return (


            <div>
                <div className={"ml-2"}>
                {category.map((c) => {
                    return typeof c.name  !== 'undefined' ? (
                        (   <div className={"text-left"}>
                            <span className={"d-inline-block ml-1"} style={{ fontSize: '14px' }}> {c.name}</span>
                            <i className="text-right icon-delete-cat fa fa-light fa-times" style={{ fontSize: '14px',float:'right',padding:'3% 5% 0' }} onClick={() => this.props.onDelete(c)} />
                        </div>)
                    ) : null
                    ;
                })}
                </div>
            </div>

            // <div>
            //     <div className={"ml-2"}>
            //         {category.map(c =>
            //
            //               <div className={"text-left"}>
            //         {/*<input className={"text-left"} type={"checkbox"} checked={category.includes(c) === true} onChange={this.props.onDelete(c)}/>*/}
            //         {/*<input className={"text-left"} type={"checkbox"} checked={category.includes(c) === true}   onClick={() => this.props.onDelete(c)}/>*/}
            //
            //                 <span className={"d-inline-block ml-1"} style={{ fontSize: '14px' }}> {c.name}</span>
            //                 <i className="text-right icon-delete-cat fa fa-light fa-times" style={{ fontSize: '14px',float:'right',padding:'3% 5% 0' }} onClick={() => this.props.onDelete(c)} />
            //             </div>
            //             )
            //         }
            //     </div>
            //     {/*<div className={"text-center"}>*/}
            //     {/*   <button className={"btn btn-secondary btn-block mt-2 ml-3"} style={{ width: "80%" }}>Filtrer</button>*/}
            //     {/* </div>*/}
            // </div>
        )}
    }
}

export default connect(null, {searchAll})(CategoryFilterMultiple);
