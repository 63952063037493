import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify'
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";

import ContentLoader from "../../../components/ContentLoader";

import {fetchBlockType} from '../../../data/entities/BlockType'
import BlockTypeUpdateModal from "./BlockTypeUpdateModal";
import BlockTypeDeleteModal from "./BlockTypeDeleteModal";

class BlockTypeViewPage extends Component
{
    state = {
        id: this.props.match.params.id,
        data: null,
        loading: true,

        showBlockTypeUpdateModal: false,
        showBlockTypeDeleteModal: false
    }

    componentDidMount()
    {
        this.props.fetchBlockType(this.state.id)
            .then((res) => {
                this.setState({ loading: false, data: res.payload.data })
            })
            .catch((e) => {
                console.log(e)
                if (e.error && e.error.response && e.error.response.data && e.error.response.data.error)
                    toast.error(<FormattedMessage id={e.error.response.data.error} />)
                this.props.history.push('/entities/block_type')
            })
    }

    render() {
        return (
            <div className="container">
                <Helmet title="BlockType" />
                <h1>BlockType #{this.state.id}</h1>
                {this.renderEntity()}
            </div>
        )
    }

    renderEntity()
    {
        if (this.state.loading) return <ContentLoader/>

        return (
            <div>
                <p>Données de l'entitié :</p>
                <pre>{JSON.stringify(this.state.data)}</pre>
                <p>Actions :</p>
                <p>
                    <button
                        onClick={() => this.setState({ showBlockTypeUpdateModal: true })}
                        className="btn btn-primary">Modifier</button>
                    <button
                        onClick={() => this.setState({ showBlockTypeDeleteModal: true })}
                        className="btn btn-danger ml-2">Supprimer</button>
                </p>
                {this.state.showBlockTypeUpdateModal ? (
                    <BlockTypeUpdateModal
                        data={this.state.data}
                        onClose={(data) => {
                            if (data) this.setState({ data })
                            this.setState({ showBlockTypeUpdateModal: false })
                        }}
                    />
                ) : null}
                {this.state.showBlockTypeDeleteModal ? (
                    <BlockTypeDeleteModal
                        data={this.state.data}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.props.history.push('/entities/block_type')
                            }
                            else this.setState({ showBlockTypeDeleteModal: false })
                        }}
                    />
                ) : null}
            </div>
        )
    }
}

export default connect(({ block_type_entity }) => ({ block_type_entity }), { fetchBlockType })(BlockTypeViewPage)
