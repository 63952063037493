import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {Row, Col, Container} from "reactstrap";

import Loader from "../../../components/Loader";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";

import OfferFunctionCreateModal from "./OfferFunctionCreateModal";

import {fetchAll, searchAll} from '../../../data/entities/OfferFunction';
import OfferFunctionUpdateModal from "./OfferFunctionUpdateModal";
import OfferFunctionDeleteModal from "./OfferFunctionDeleteModal";

class OfferFunctionIndexPage extends Component {
	state = {
		search: '',
		showOfferFunctionCreateModal: false,
		showOfferFunctionUpdateModal: null,
		showOfferFunctionDeleteModal: null
	}

	_search = (search) => {
		this.setState({search}, () => {
			this._fetch()
		})
	}

	_fetch = () => {
		if (this.state.search.length) this.props.searchAll(this.state.search)
		else this.props.fetchAll()
	}

	componentDidMount() {
		this.props.fetchAll()
	}

	render() {
		return (
				<Container>
					<Helmet title={"Fonctions"}/>
					<Row className={"pb-3"}>
						<Col>
							<h2>Fonctions</h2>
						</Col>
						<Col className="text-right">
							<Loader display={this.props.offer_function_entity.loading}/>
							<input
									type="text"
									value={this.state.search}
									onChange={(e) => this._search(e.target.value)}
									placeholder="Rechercher..."
									className="form-control d-inline-block ml-2"
									style={{maxWidth: 200}}
							/>
							<button
									onClick={() => this.setState({showOfferFunctionCreateModal: true})}
									className="btn btn-primary ml-2">Ajouter
							</button>
						</Col>
					</Row>
					{this.renderEntities()}
					{this.state.showOfferFunctionCreateModal ? (
							<OfferFunctionCreateModal
									onClose={(created) => {
										if (created) this._fetch()
										this.setState({showOfferFunctionCreateModal: false})
									}}
							/>
					) : null}
					{this.state.showOfferFunctionUpdateModal !== null ? (
							<OfferFunctionUpdateModal data={this.state.showOfferFunctionUpdateModal}
																				onClose={(updated) => {
																					if (updated) this._fetch();
																					this.setState({showOfferFunctionUpdateModal: null})
																				}}
							/>
					) : null}
					{this.state.showOfferFunctionDeleteModal !== null ? (
							<OfferFunctionDeleteModal data={this.state.showOfferFunctionDeleteModal}
																					onClose={(deleted) => {
																						if (deleted) this._fetch();
																						this.setState({showOfferFunctionDeleteModal: null})
																					}}
							/>
					) :null }
				</Container>
		)
	}

	renderEntities() {
		if (this.props.offer_function_entity.loading && !this.props.offer_function_entity.lastUpdate)
			return <ContentLoader/>

		if (!this.props.offer_function_entity.index.length) {
			return <Empty/>
		}

		return (
				<table className="table table-striped mt-3">
					{this.props.offer_function_entity.index.map((entity) => (
							<tr>
								<td>
									<h5>{entity.name}</h5>
								</td>
								<td style={{textAlign: "right"}}>
									<button
											onClick={() => this.setState({showOfferFunctionUpdateModal: entity})}
											className="btn btn-primary">Modifier
									</button>
									<button
											onClick={() => this.setState({showOfferFunctionDeleteModal: entity})}
											className="btn btn-danger ml-2">Supprimer
									</button>
								</td>
							</tr>
					))}
				</table>
		)
	}
}

export default connect(({offer_function_entity}) => ({offer_function_entity}), {
	fetchAll,
	searchAll
})(OfferFunctionIndexPage)
