import {ROLE_ADMIN, ROLE_ANONYMOUS, ROLE_USER} from "./security";

import BaseLayout from "../layouts/BaseLayout";
import AuthLayout from "../layouts/AuthLayout";
import EmptyLayout from "../layouts/EmptyLayout";

import NotFoundPage from "../pages/NotFoundPage";
import LoginPage from "../pages/auth/LoginPage";
import HomePage from "../pages/HomePage";
import RegisterPage from "../pages/auth/RegisterPage";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import SandboxPage from "../pages/SandboxPage";
import EditorPage from "../pages/editor/EditorPage";

import ArticleIndexPage from "../pages/entities/Article/ArticleIndexPage";
import ArticleViewPage from "../pages/entities/Article/ArticleViewPage";
import CategoryIndexPage from "../pages/entities/Category/CategoryIndexPage";
import CategoryViewPage from "../pages/entities/Category/CategoryViewPage";
import TagIndexPage from "../pages/entities/Tag/TagIndexPage";
import TagViewPage from "../pages/entities/Tag/TagViewPage";
import ArticleTypeIndexPage from "../pages/entities/ArticleType/ArticleTypeIndexPage";
import ArticleTypeViewPage from "../pages/entities/ArticleType/ArticleTypeViewPage";
import ArticleBlockIndexPage from "../pages/entities/ArticleBlock/ArticleBlockIndexPage";
import ArticleBlockViewPage from "../pages/entities/ArticleBlock/ArticleBlockViewPage";
import BlockTypeIndexPage from "../pages/entities/BlockType/BlockTypeIndexPage";
import BlockTypeViewPage from "../pages/entities/BlockType/BlockTypeViewPage";
import MediaIndexPage from "../pages/entities/Media/MediaIndexPage";
import MediaViewPage from "../pages/entities/Media/MediaViewPage";
import SuggestedArticleIndexPage from "../pages/entities/SuggestedArticle/SuggestedArticleIndexPage";
import SuggestedArticleViewPage from "../pages/entities/SuggestedArticle/SuggestedArticleViewPage";
import AdvertisingIndexPage from "../pages/entities/Advertising/AdvertisingIndexPage";
import AdvertisingViewPage from "../pages/entities/Advertising/AdvertisingViewPage";
import NewsletterIndexPage from "../pages/entities/Newsletter/NewsletterIndexPage";
import NewsletterViewPage from "../pages/entities/Newsletter/NewsletterViewPage";
import OfferIndexPage from "../pages/entities/Offer/OfferIndexPage";
import OfferViewPage from "../pages/entities/Offer/OfferViewPage";
import OfferContractIndexPage from "../pages/entities/OfferContract/OfferContractIndexPage";
import OfferContractViewPage from "../pages/entities/OfferContract/OfferContractViewPage";
import OfferExperienceIndexPage from "../pages/entities/OfferExperience/OfferExperienceIndexPage";
import OfferExperienceViewPage from "../pages/entities/OfferExperience/OfferExperienceViewPage";
import OfferStudyLevelIndexPage from "../pages/entities/OfferStudyLevel/OfferStudyLevelIndexPage";
import OfferStudyLevelViewPage from "../pages/entities/OfferStudyLevel/OfferStudyLevelViewPage";
import StaticPageIndexPage from "../pages/entities/StaticPage/StaticPageIndexPage";
import StaticPageViewPage from "../pages/entities/StaticPage/StaticPageViewPage";
import ChannelIndexPage from "../pages/entities/Channel/ChannelIndexPage";
import ChannelViewPage from "../pages/entities/Channel/ChannelViewPage";
import ChannelCategoryIndexPage from "../pages/entities/ChannelCategory/ChannelCategoryIndexPage";
import ChannelCategoryViewPage from "../pages/entities/ChannelCategory/ChannelCategoryViewPage";
import ChannelMenuIndexPage from "../pages/entities/ChannelMenu/ChannelMenuIndexPage";
import UserIndexPage from "../pages/entities/User/UserIndexPage";
import UserViewPage from "../pages/entities/User/UserViewPage";
import RoleIndexPage from "../pages/entities/Role/RoleIndexPage";
import RoleViewPage from "../pages/entities/Role/RoleViewPage";
import ArticleChannelIndexPage from "../pages/entities/ArticleChannel/ArticleChannelIndexPage";
import ArticleChannelViewPage from "../pages/entities/ArticleChannel/ArticleChannelViewPage";
import ArticleCategoryIndexPage from "../pages/entities/ArticleCategory/ArticleCategoryIndexPage";
import ArticleCategoryViewPage from "../pages/entities/ArticleCategory/ArticleCategoryViewPage";
import ArticleTagIndexPage from "../pages/entities/ArticleTag/ArticleTagIndexPage";
import ArticleTagViewPage from "../pages/entities/ArticleTag/ArticleTagViewPage";
import UserCircleIndexPage from "../pages/entities/UserCircle/UserCircleIndexPage";
import UserCircleViewPage from "../pages/entities/UserCircle/UserCircleViewPage";
import AdvertisingBannerIndexPage from "../pages/entities/AdvertisingBanner/AdvertisingBannerIndexPage";
import AdvertisingBannerViewPage from "../pages/entities/AdvertisingBanner/AdvertisingBannerViewPage";
import AdvertisingBannerTypeIndexPage from "../pages/entities/AdvertisingBannerType/AdvertisingBannerTypeIndexPage";
import AdvertisingBannerTypeViewPage from "../pages/entities/AdvertisingBannerType/AdvertisingBannerTypeViewPage";
import OfferFunctionIndexPage from "../pages/entities/OfferFunction/OfferFunctionIndexPage";
import OfferFunctionViewPage from "../pages/entities/OfferFunction/OfferFunctionViewPage";
import NewsletterSubscriberIndexPage from "../pages/entities/NewsletterSubscriber/NewsletterSubscriberIndexPage";
import NewsletterSubscriberViewPage from "../pages/entities/NewsletterSubscriber/NewsletterSubscriberViewPage";
import CircleIndexPage from "../pages/entities/Circle/CircleIndexPage";
import CircleViewPage from "../pages/entities/Circle/CircleViewPage";
import NewsletterBlockIndexPage from "../pages/entities/NewsletterBlock/NewsletterBlockIndexPage";
import NewsletterBlockViewPage from "../pages/entities/NewsletterBlock/NewsletterBlockViewPage";
import OfferActivityAreaIndexPage from "../pages/entities/OfferActivityArea/OfferActivityAreaIndexPage";
import OfferActivityAreaViewPage from "../pages/entities/OfferActivityArea/OfferActivityAreaViewPage";
import LargeLayout from "../layouts/LargeLayout";
import PlatformPage from '../pages/platform/PlatformPage'
import AdsPage from '../pages/Ads/AdsPage'
import CurationPage from "../pages/curation/CurationPage";
import CurationRssPage from "../pages/curation/RssArticlePage";
import ContributionsArticles from "../pages/curation/ContributionsArticlesPage";
import ContactIndexPage from "../pages/entities/Contact/ContactIndexPage";
import ConfidentialDocumentIndexPage from "../pages/entities/ConfidentialDocument/ConfidentialDocumentIndexPage";
import StripeProductsIndexPage from "../pages/entities/StripeProducts/ProductsIndexPage";


const routes = [
    // Auth
    {
        path: '/auth/login',
        exact: true,
        page: LoginPage,
        layout: AuthLayout,
        security: {
            roles: [ROLE_ANONYMOUS]
        }
    },
    {
        path: '/auth/register',
        exact: true,
        page: RegisterPage,
        layout: AuthLayout,
        security: {
            roles: [ROLE_ANONYMOUS]
        }
    },
    {
        path: '/auth/forgot-password',
        exact: true,
        page: ForgotPasswordPage,
        layout: AuthLayout,
        security: {
            roles: [ROLE_ANONYMOUS]
        }
    },
    //
    {
        path: '/',
        exact: true,
        page: HomePage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/sandbox',
        exact: true,
        page: SandboxPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/platform',
        exact: true,
        page: PlatformPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_ADMIN]
        }
    },

    {
        path: '/manage-ads',
        exact: true,
        page: AdsPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_ADMIN]
        }
    },
    // Entities
    {
        path: '/entities/article',
        exact: true,
        page: ArticleIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article/:id',
        exact: true,
        page: ArticleViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/category',
        exact: true,
        page: CategoryIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/category/:id',
        exact: true,
        page: CategoryViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/tag',
        exact: true,
        page: TagIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/tag/:id',
        exact: true,
        page: TagViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article_type',
        exact: true,
        page: ArticleTypeIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article_type/:id',
        exact: true,
        page: ArticleTypeViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article_block',
        exact: true,
        page: ArticleBlockIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article_block/:id',
        exact: true,
        page: ArticleBlockViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/block_type',
        exact: true,
        page: BlockTypeIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/block_type/:id',
        exact: true,
        page: BlockTypeViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/media',
        exact: true,
        page: MediaIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/media/:id',
        exact: true,
        page: MediaViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/suggested_article',
        exact: true,
        page: SuggestedArticleIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/suggested_article/:id',
        exact: true,
        page: SuggestedArticleViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/advertising',
        exact: true,
        page: AdvertisingIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/manage-ads',
        exact: true,
        page: AdsPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_ADMIN]
        }
    },
    {
        path: '/entities/advertising/:id',
        exact: true,
        page: AdvertisingViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/newsletter',
        exact: true,
        page: NewsletterIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/newsletter/:id',
        exact: true,
        page: NewsletterViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer',
        exact: true,
        page: OfferIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer/:id',
        exact: true,
        page: OfferViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer_contract',
        exact: true,
        page: OfferContractIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer_contract/:id',
        exact: true,
        page: OfferContractViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer_experience',
        exact: true,
        page: OfferExperienceIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer_experience/:id',
        exact: true,
        page: OfferExperienceViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer_study_level',
        exact: true,
        page: OfferStudyLevelIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer_study_level/:id',
        exact: true,
        page: OfferStudyLevelViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/static_page',
        exact: true,
        page: StaticPageIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/static_page/:id',
        exact: true,
        page: StaticPageViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/channel',
        exact: true,
        page: ChannelIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/channel/:id',
        exact: true,
        page: ChannelViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/channel_category',
        exact: true,
        page: ChannelCategoryIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/channel_category/:id',
        exact: true,
        page: ChannelCategoryViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/channel_menu',
        exact: true,
        page: ChannelMenuIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/user',
        exact: true,
        page: UserIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/user/:id',
        exact: true,
        page: UserViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/role',
        exact: true,
        page: RoleIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/role/:id',
        exact: true,
        page: RoleViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article_channel',
        exact: true,
        page: ArticleChannelIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article_channel/:id',
        exact: true,
        page: ArticleChannelViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article_category',
        exact: true,
        page: ArticleCategoryIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article_category/:id',
        exact: true,
        page: ArticleCategoryViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article_tag',
        exact: true,
        page: ArticleTagIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/article_tag/:id',
        exact: true,
        page: ArticleTagViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/user_circle',
        exact: true,
        page: UserCircleIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/user_circle/:id',
        exact: true,
        page: UserCircleViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/advertising_banner',
        exact: true,
        page: AdvertisingBannerIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/advertising_banner/:id',
        exact: true,
        page: AdvertisingBannerViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/advertising_banner_type',
        exact: true,
        page: AdvertisingBannerTypeIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/advertising_banner_type/:id',
        exact: true,
        page: AdvertisingBannerTypeViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer_function',
        exact: true,
        page: OfferFunctionIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer_function/:id',
        exact: true,
        page: OfferFunctionViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/newsletter_subscriber',
        exact: true,
        page: NewsletterSubscriberIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/newsletter_subscriber/:id',
        exact: true,
        page: NewsletterSubscriberViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/circle',
        exact: true,
        page: CircleIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/circle/:id',
        exact: true,
        page: CircleViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/newsletter_block',
        exact: true,
        page: NewsletterBlockIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/newsletter_block/:id',
        exact: true,
        page: NewsletterBlockViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer_activity_area',
        exact: true,
        page: OfferActivityAreaIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/offer_activity_area/:id',
        exact: true,
        page: OfferActivityAreaViewPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/editor/:type/:id',
        exact: true,
        page: EditorPage,
        layout: LargeLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/curation',
        exact: true,
        page: CurationPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/flux-rss',
        exact: true,
        page: CurationRssPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/Contribution',
        exact: true,
        page: ContributionsArticles,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/contact',
        exact: true,
        page: ContactIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/confidential-documents',
        exact: true,
        page: ConfidentialDocumentIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    {
        path: '/entities/stripe-products',
        exact: true,
        page: StripeProductsIndexPage,
        layout: BaseLayout,
        security: {
            roles: [ROLE_USER]
        }
    },
    // Misc
    {
        path: '/',
        exact: false,
        page: NotFoundPage,
        layout: EmptyLayout
    }
]

export default routes
