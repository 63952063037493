import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_CATEGORY_INDEX = 'ENTITY_CATEGORY_INDEX'
const ENTITY_CATEGORY_INDEX_SUCCESS = 'ENTITY_CATEGORY_INDEX_SUCCESS'
const ENTITY_CATEGORY_INDEX_FAIL = 'ENTITY_CATEGORY_INDEX_FAIL'

export function fetchAll(query_params = null)
{
    let url = '/categories';

    if(query_params)
        url += '?' + query_params;

    return request(ENTITY_CATEGORY_INDEX, 'get', url)
}

export function searchAll(query)
{
    return request(ENTITY_CATEGORY_INDEX, 'post', '/categories/search', { query })
}

const ENTITY_CATEGORY_RETRIEVE = 'ENTITY_CATEGORY_RETRIEVE'

export function fetchCategory(id)
{
    return request(ENTITY_CATEGORY_RETRIEVE, 'get', `/categories/${id}`)
}

const ENTITY_CATEGORY_CREATE = 'ENTITY_CATEGORY_CREATE'

export function createCategory(data)
{
    return request(ENTITY_CATEGORY_CREATE, 'post', '/categories', data)
}

const ENTITY_CATEGORY_UPDATE = 'ENTITY_CATEGORY_UPDATE'

export function updateCategory(id, data)
{
    return request(ENTITY_CATEGORY_UPDATE, 'patch', `/categories/${id}`, data)
}


const ENTITY_CATEGORY_DELETE = 'ENTITY_CATEGORY_DELETE'

export function deleteCategory(id)
{
    return request(ENTITY_CATEGORY_DELETE, 'delete', `/categories/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityCategoryReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_CATEGORY_INDEX:
            return {...state, loading: true}
        case ENTITY_CATEGORY_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_CATEGORY_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}
