import React, { Component, Fragment} from "react";
import {Form, Submit, Input, OptionGroup, Option, TextArea} from 'react-forms';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

import ContentLoader from "../../../components/ContentLoader";

import {
    fetchUser,
    updateUser,
    grantUser,
    updatePassword,
    activateUser,
    addUserAvatar
} from "../../../data/entities/User";
import { fetchAll } from '../../../data/entities/Role'

import "./UserViewPage.scss"
import UserDeleteModal from "./UserDeleteModal";
import {checkPrivilege} from "../../../helpers";
import {createMedia} from "../../../data/entities/Media";
import MediaSelectModal from "../Media/MediaSelectModal";
import {getPlatform} from "../../../data/Platform";

class UserViewPage extends Component {
    state = {
        id: this.props.match.params.id,
        _formData: null,
        showMediaSelectModal: false,
        data: null,
        fullname: null,
        pseudo: null,
        email: null,
        role: null,
        password: '',
        showUserDeleteModal: false,
        loading: true,
        roleLoading: false,
        passwordLoading: false,
        activationLoading: false,
        bio: null,
        facebook_url: null,
        linkedin_url: null,
        twitter_url: null,
        instagram_url: null,
        journey: null,
        avatar: null
    };

    roleEditable = false

    componentDidMount() {
        this.props.fetchAll()

        this.props
            .fetchUser(this.state.id)
            .then(res => {

                this.roleEditable = checkPrivilege(this.props.user, 'user:grant') && this.props.user.profile.id != res.payload.data.id && (this.props.user.profile.role.name == 'ROLE_ADMIN' || res.payload.data.role.name != 'ROLE_ADMIN')

                this.setState({
                    loading: false,
                    data: res.payload.data,
                    pseudo: res.payload.data.pseudo,
                    firstname: res.payload.data.firstname,
                    lastname: res.payload.data.lastname,
                    email: res.payload.data.email,
                    role: res.payload.data.role.id,
                    bio: res.payload.data.bio,
                    facebook_url: res.payload.data.facebook_url,
                    linkedin_url: res.payload.data.linkedin_url,
                    twitter_url: res.payload.data.twitter_url,
                    instagram_url: res.payload.data.instagram_url,
                    youtube_url: res.payload.data.youtube_url,
                    //journey: res.payload.data.journey,
                    avatar: res.payload.data.avatar
                });
            })
            .catch(e => {
                if (
                    e.error &&
                    e.error.response &&
                    e.error.response.data &&
                    e.error.response.data.error
                )
                    toast.error(<FormattedMessage id={e.error.response.data.error} />);
                this.props.history.push("/entities/user");
            });
    }

    _grant = () => {
        this.setState({ roleLoading: true })

        this.props.grantUser(this.state.id, this.state.role)
            .then((res) => {
                this.setState({
                    roleLoading: false,
                    data: {
                        ...this.state.data,
                        role: res.payload.data.role
                    }
                })
            })
    }

    _updatePassword = () => {
        this.setState({ passwordLoading: true })

        this.props.updatePassword(this.state.id, this.state.password)
            .then((res) => {
                this.setState({
                    passwordLoading: false,
                    password: ''
                })

                toast.success(<FormattedMessage id={'validator.password.updated'} />)
            })
            .catch((e) => {
                this.setState({ passwordLoading: false })
            })
    }

    _activate = () => {
        this.setState({ activationLoading: true })

        this.props.activateUser(this.state.id)
            .then((res) => {
                this.setState({
                    activationLoading: false,
                    data: res.payload.data
                })
            })
            .catch((e) => {
                this.setState({ activationLoading: false })
            })
    }

    _submit = () => {
        this.setState({ loading: true })
        const data = {...this.state}

        delete data.loading
        delete data.showUserDeleteModal
        delete data.data
        delete data.role

        this.props.updateUser(this.state.id, data)
            .then(res => this.setState({
                fullname: res.payload.data.fullname,
                pseudo: res.payload.data.pseudo,
                bio: res.payload.data.bio,
                facebook_url: res.payload.data.facebook_url,
                linkedin_url: res.payload.data.linkedin_url,
                twitter_url: res.payload.data.twitter_url,
                instagram_url: res.payload.data.instagram_url,
                youtube_url: res.payload.data.youtube_url,
                //journey: res.journey,
                loading: false
            }))
    }

    uploadMedia = (userId, media) => {
        this.props.addUserAvatar(this.props.match.params.id, {media: media.id})
            .then(res => this.setState({ avatar: res.payload.data.avatar }, () => {
                console.log("Avatar ::: --->", this.state.avatar)})
            )
    }

    render()
    {
        if (this.state.loading) return <ContentLoader />;

        const editable = checkPrivilege(this.props.user, 'user:edit')
        return (
            <div className="container user-view-page mb-3">
                <Helmet title="Utilisateur" />

                {this.state.showUserDeleteModal ? <UserDeleteModal data={this.state.data} onClose={(deleted) => deleted ? this.props.history.push('/entities/user') : this.setState({ showUserDeleteModal: false })}/> : null}

                <div className="info">
                    <div className="row">
                        <div className="col">
                            <h2 className="mb-5"><i className="fas fa-user-circle"/> Utilisateur</h2>
                        </div>
                        <div className="col text-right">
                            {this.state.id != this.props.user.profile.id && checkPrivilege(this.props.user, 'user:delete') ? (
                                <button className="btn btn-danger" onClick={() => this.setState({ showUserDeleteModal: true })}>Supprimer cet utilisateur</button>
                            ) : null}
                        </div>
                    </div>
                </div>

                {editable ? this.renderActivation() : null}

                <Form onSubmit={() => this._submit()} className="edit-form">

                    <Input
                        value={this.state.email} disabled
                        label="Adresse e-mail" type="text"/>

                    <Input
                        value={this.state.firstname} onChangeValue={(firstname) => this.setState({firstname})}
                        label="Prénom" type="text" disabled={!editable}/>

                    <Input
                        value={this.state.lastname} onChangeValue={(lastname) => this.setState({lastname})}
                        label="Nom de famille" type="text" disabled={!editable}/>

                    <Input
                        value={this.state.pseudo} onChangeValue={(pseudo) => this.setState({pseudo})}
                        label="Pseudo" type="text" disabled={!editable}/>

                    <TextArea value={this.state.bio} onChangeValue={(bio) => this.setState({bio})}  label="Biographie" disabled={!editable} placeholder="Ecrivez votre biographie ici " ></TextArea>

                    {/*<Input*/}
                    {/*    value={this.state.journey} onChangeValue={(journey) => this.setState({journey})}*/}
                    {/*    label="Journey" type="text" disabled={!editable} placeholder="Type your journey her " />*/}

                    <Input
                        value={this.state.facebook_url} onChangeValue={(facebook_url) => this.setState({facebook_url})}
                        label="Facebook" type="text" disabled={!editable} placeholder="Ex. https://www.facebook.com/votrenom" />

                    <Input
                        value={this.state.linkedin_url} onChangeValue={(linkedin_url) => this.setState({linkedin_url})}
                        label="Linkedin" type="text" disabled={!editable} placeholder="Ex. https://www.linkedin.com/votrenom" />

                    <Input
                        value={this.state.twitter_url} onChangeValue={(twitter_url) => this.setState({twitter_url})}
                        label="Twitter" type="text" disabled={!editable} placeholder="Ex. https://www.twitter.com/votrenom" />
                    <Input
                        value={this.state.instagram_url} onChangeValue={(instagram_url) => this.setState({instagram_url})}
                        label="Instagram" type="text" disabled={!editable} placeholder="Ex. https://www.instagram.com/votrenom" />

                    <Input
                        value={this.state.youtube_url} onChangeValue={(youtube_url) => this.setState({youtube_url})}
                        label="Youtube" type="text" disabled={!editable} placeholder="Ex. https://www.youtube.com/votrenom" />
                    <>
                        <hr />
                        <label>Photo de profil</label>
                        {this.state.avatar ?
                            <div className={"cover"} >
                                <img src={this.state.avatar.file} alt={this.state.avatar.label} className={"w-25 mb-3"}/>
                            </div>
                            :
                            <div className={"cover empty"} >
                                <i className="fas fa-image" style={{fontSize: "30px"}}></i>
                            </div>
                        }
                        <div onClick={() => this.setState({showMediaSelectModal: true})}
                             className={"btn btn-sm btn-outline-primary mr-2 cover-btn"}>
                            {this.state.avatar ? "Modifier la photo de profil" : "Ajouter une photo de profil"}
                        </div>
                        <hr />
                    </>

                    {editable ? <Submit disabled={this.state.loading} className="btn btn-primary">Sauvegarder</Submit> : null}
                </Form>

                {this.roleEditable ? this.renderRole() : null}

                {editable ? this.renderPassword() : null}

                {this.state.showMediaSelectModal &&
                    <MediaSelectModal
                        onClose={(uploaded) => {
                            if (uploaded) this.setState({showMediaSelectModal: false}, this.uploadMedia(null, uploaded))
                            this.setState({showMediaSelectModal: false})
                        }}
                        requiredType={"image"}/>}

            </div>
        );
    }

    renderRole()
    {
        if (!this.props.role_entity.index)
            return <ContentLoader/>

        return (
            <div>
                <hr/>
                <OptionGroup
                    disabled={this.state.roleLoading}
                    value={this.state.role}
                    onChangeValue={(role) => this.setState({role})}
                    label="Rôle"
                >
                    {this.props.role_entity.index.map((role) => {
                        return (
                            <Option value={role.id}>{role.label}</Option>
                        )
                    })}
                </OptionGroup>
                <button
                    onClick={this._grant}
                    disabled={this.state.roleLoading}
                    className="btn btn-warning">Modifier</button>
            </div>
        )
    }

    renderPassword()
    {
        return (
            <div>
                <hr/>
                <Input
                    value={this.state.password} onChangeValue={(password) => this.setState({password})}
                    label="Nouveau mot de passe" type="password"/>
                <button
                    onClick={this._updatePassword}
                    disabled={this.state.passwordLoading}
                    className="btn btn-warning">Mettre à jour</button>
            </div>
        )
    }

    renderActivation()
    {
        if (this.state.data.activated) return null;

        return (
            <p className="alert alert-danger">
                <button
                    onClick={this._activate}
                    disabled={this.state.activationLoading}
                    className="btn btn-outline-danger float-right btn-sm">Activer</button>
                Cet utilisateur n'est pas activé.
            </p>
        )
    }
}

export default connect(
    ({ user_entity, role_entity, user }) => ({ user_entity, role_entity, user }),
    { fetchUser, updateUser, fetchAll, grantUser, updatePassword, activateUser, createMedia, addUserAvatar, getPlatform}
)(UserViewPage);
