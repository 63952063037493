import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit, CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';

import Modal from "../../../components/Modal";

//import {createSuggestedArticle} from "../../../data/entities/SuggestedArticle";

class SuggestedArticleCreateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        title: null,
        content: null,
    }

    _submit = () => {
        this.setState({ _loading: true })

        const data = {...this.state}
        delete data._loading
        delete data._formData

        this.props.createSuggestedArticle(data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })
    }

    render()
    {
        return (
            <Modal title={"Créer SuggestedArticle"} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
					<Input
					    value={this.state.title} onChangeValue={(title) => this.setState({ title })}
					    placeholder="title" label="title" type="text"/>
					
					<TextArea
					    value={this.state.content}
					    onChangeValue={(content) => this.setState({ content })}
					    id="content" name="content" label="content" />
					
                    <Submit disabled={this.state._loading}>Créer</Submit>
                </Form>
            </Modal>
        )
    }
}
// createSuggestedArticle
export default connect(null, {  })(SuggestedArticleCreateModal)
