import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form, Submit, Input } from "react-forms";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import moment from 'moment-timezone';
import Modal from "../../../components/Modal";

import {countStatus , createChannelMenu} from "../../../data/entities/ChannelMenu";
import { fetchAll as fetchAllCategories } from "../../../data/entities/Category"
import { fetchAll as fetchAllPages } from "../../../data/entities/StaticPage"
import ColorPickerModal from "../Category/ColorPickerModal";

class ChannelAddMenuItem extends Component {
    state = {
        name: "",
        category: null,
        page: null,
        url: null,
        color: '#58ffbd',

        checkedTarget: "none",
        status: 'permanent',
        categories: [],
        pages:[],
        count_data :"",

        _formData: null,
        _loading: false,
        showColorPickerModal: false
    };

    componentDidMount() {
        this.props.fetchAllCategories()
            .then(res => this.setState({ categories: res.payload.data}))
        this.props.fetchAllPages(true)
            .then(res => this.setState({ pages: res.payload.data}))
    }

    _submit = () => {
        this.setState({ _loading: true });

        const data = { ...this.state };
        delete data._loading;
        delete data._formData;
        delete data.categories;
        delete data.pages;
        delete data.checkedTarget;

        data.parent = this.props.parent;
        this.props.countStatus(this.props.channel).then(res => {
            this.setState({ count_data: res.payload.data})
        } );

        this.props
            .createChannelMenu(this.props.channel, data)
            .then(res => {
                var c= this.state.count_data
                var status= this.state.status
                 if(c >= 5 && status === "temporaire"){
                     toast.warn("Attention! Il y a déjà 5 catégories Temporaire");
                 }else{
                     if( data.parent === null){
                         toast.success("Parfait, la catégorie à été bien ajoutée");
                     }else{
                         toast.success("Parfait, la sous catégorie à été bien ajoutée");
                     }

                 }

                this.setState({ _loading: false });
                this.props.onClose(true);
            })
            .catch(e => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data });
                    this.setState({ _loading: false });
            });
    };

    render() {

        return (
            <Modal
                title="Ajouter un élément"
                onClose={() => this.props.onClose(false)}
            >
                <Form onSubmit={this._submit} data={this.state._formData}>
                    <p className="pt-3 pb-3 font-weight-bold">Cible</p>

                    <div className="ml-3">
                        <div className="my-3">
                            <input
                                onClick={() => this.setState({
                                    checkedTarget: "none",
                                    url: null,
                                    category: null,
                                    page: null
                                })}
                                type="radio" name="cible" aria-label="aucun" style={{marginLeft: '13px', marginRight: '14px'}}/>
                            <label htmlFor="aucun">Aucun</label><br/>
                        </div>

                        <span>Page web</span>
                        <div className="input-group mb-4 mt-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <input
                                        onClick={() => this.setState({
                                            checkedTarget: "url",
                                            category: null,
                                            page: null
                                        })}
                                        name="cible" type="radio"/>
                                </div>
                            </div>
                            <input
                                onChange={(e) => this.setState({ url: e.target.value })}
                                disabled={this.state.checkedTarget !== "url" ? true : false}
                                type="text" className="form-control" placeholder="Adresse URL"/>
                        </div>

                        <span>Catégorie</span>
                        <div className="input-group mb-4 mt-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <input
                                        onClick={() => this.setState({
                                            checkedTarget: "category",
                                            url: null,
                                            page: null
                                        })}
                                        name="cible" type="radio"/>
                                </div>
                            </div>
                            <select
                                onChange={(e) => {
                                    let id = isNaN(e.target.value) ? null : parseInt(e.target.value)
                                    let category = this.state.categories.find((c) => c.id == id)
                                    console.log(e.target.value, id, category)
                                    this.setState({
                                        category: id,
                                        name: category ? category.name : this.state.name,
                                        color: category ? category.color : this.state.color,
                                    })
                                }}
                                disabled={this.state.checkedTarget !== "category" ? true : false}
                                className="custom-select">
                                <option>-- Catégories --</option>
                                {
                                    this.state.categories.map(entity => (
                                        <option value={entity.id}>{entity.name}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <span>Page statique</span>
                        <div className="input-group mb-4 mt-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <input
                                        onClick={() => this.setState({
                                            checkedTarget: "page",
                                            url: null,
                                            category: null
                                        })}
                                        name="cible" type="radio"/>
                                </div>
                            </div>

                            <select
                                onChange={(e) => {
                                    let id = isNaN(e.target.value) ? null : parseInt(e.target.value)
                                    let page = this.state.pages.find((p) => p.id == id)
                                    this.setState({
                                        page: id,
                                        name: page ? page.title : this.state.name
                                    })
                                }}
                                disabled={this.state.checkedTarget !== "page" ? true : false}
                                className="custom-select">
                                <option>-- Pages --</option>
                                {
                                    this.state.pages.map(entity => (
                                        <option value={entity.id}>{entity.title}</option>
                                    ))
                                }
                            </select>
                        </div>

                        {this.props.parent ? (
                            <Input
                                className={"color-div mt-2 ml-2"}
                                type={'hidden'}
                                value={this.state.status}
                            />
                        ) : (
                            <Input
                                className={"color-div mt-2 ml-2"}
                                type={'checkbox'}
                                value={this.state.status}
                                checked = {this.state.status ==='temporaire'? true: false}
                                onChange={(value) => this.setState({ status: value.target.checked ? "temporaire" : "permanent" })}
                                label="Catégorie temporaire : "
                            />
                        )}

                    </div>

                    <hr/>

                    <Input
                        value={this.state.name}
                        onChangeValue={name => this.setState({ name })}
                        placeholder="Nom de l'élément"
                        label="Nom"
                        type="text"
                    />

                    <span>Couleur</span>

                    <div className={"color-div mt-2"}
                         style={{ backgroundColor: this.state.color, height: '40px', marginBottom: '20px', cursor: 'pointer' }}
                         onClick={() =>
                             this.setState({ showColorPickerModal: true })
                         }>
                    </div>

                    {this.state.showColorPickerModal ? (
                        <ColorPickerModal
                            data={this.state.color}
                            onClose={(color) => {
                                this.setState({ showColorPickerModal: false });
                                if (color){ this.setState({color: color}) }
                                console.log(color)
                            }}
                        />
                    ) : null}

                    <div className="text-right pt-3">
                        <Submit disabled={this.state._loading}>Sauvegarder</Submit>
                    </div>
                </Form>
            </Modal>
        );
    }
}

export default connect(
    null,
    {
        countStatus,
        createChannelMenu,
        fetchAllCategories,
        fetchAllPages
    }
)(ChannelAddMenuItem);
