import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import axios from 'axios'
import {toast} from 'react-toastify';
import moment from 'moment-timezone';
import Modal from "../../../components/Modal";
import "./OfferModal.scss"

import {createOffer} from "../../../data/entities/Offer";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";
import {Link} from "react-router-dom";

class OfferImportModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        _importing: false,

        import: null,
        data: null,
        page: 1,
        name: null,
    }

    componentDidMount() {
        this._fetch()
    }

    _fetch = (page = 1) => {
        this.setState({ page, _loading: true })
        const endpoint = `${this.props.offer_entity.provider.endpoint}?page=${page}`

        axios.get(endpoint)
            .then((res) => {
                this.setState({
                    _loading: false,
                    data: res.data,
                    name: res.data.provider
                })
            })
    }

    _import = (offer) => {
        this.setState({ _importing: true, import: offer })

        const data = {
            description: offer.description,
            company_description: offer.company_description,
            name: offer.name,
            company: offer.company,
            city: offer.city,
            country: offer.country,
            external_link: offer.url,
            external_logo: offer.company_logo ? offer.company_logo : this.state.data.logo,
            publication_status: 1,
            publication_start: moment()
        }

        this.props.createOffer(data)
            .then((res) => {
                toast.success("Offre importée !")
                this.props.onClose(true)
            })
            .catch((e) => {
                this.setState({ _importing: false, import: null })
            })
    }


    render()
    {
        return (
            <Modal title={"Offres externes"} onClose={() => this.props.onClose(false)}>
                {this.renderProvider()}
                {this.renderOffers()}
                {this.renderPagination()}
            </Modal>
        )
    }

    renderProvider()
    {
        const {data} = this.state;

        if (!data)
            return null

        return (
            <p>Les offres listées ci-dessous sont issues de <img style={{ width: 25 }} src={data.logo} alt={data.provider}/> <strong>{data.provider}</strong> <Link to="/platform"><i className="fa fa-pen"/></Link></p>
        )
    }

    renderOffers()
    {
        if (!this.state.data || !this.state.data.offers || this.state._loading)
            return <ContentLoader/>

        if (!this.state.data.offers.index.length)
            return <Empty/>

        return this.state.data.offers.index.map((offer) => this.renderOffer(offer))
    }

    renderOffer(offer)
    {
        return (
            <div key={offer.url} className="row rounded bg-light p-3 mb-2">
                <div className="col-md-2 align-items-center">
                    <img src={offer.company_logo ? offer.company_logo : this.state.data.logo}
                         className="rounded"
                         style={{ maxWidth: '100%' }}
                         alt="Logo"/>
                </div>
                <div className="col-md-6">
                    <p className="mb-0"><strong>{offer.name}</strong></p>
                    <p className="mb-0">{offer.company}</p>
                </div>
                <div className="col-md-4 text-right">
                    <a href={offer.url} target="_blank" className="btn btn-link mr-2"><i className="fa fa-external-link-alt"/></a>
                    <button
                        disabled={this.state._importing}
                        onClick={() => this._import(offer)}
                        className="btn btn-primary"><i className="fa fa-download"/></button>
                </div>
            </div>
        )
    }

    renderPagination()
    {
        if (!this.state.data || !this.state.data.offers || this.state._loading)
            return null

        const offers = this.state.data.offers

        if (offers.pages_count < 2)
            return null

        return (
            <div className="row">
                <div className="col">
                    {offers.page > 1 ? (
                        <button
                            onClick={() => this._fetch(parseInt(offers.page) - 1)}
                            className="btn btn-link"><i className="fa fa-angle-left mr-2"/> Plus récentes</button>
                    ) : null}
                </div>
                <div className="col-text-right">
                    {offers.page < offers.pages_count ? (
                        <button
                            onClick={() => this._fetch(parseInt(offers.page) + 1)}
                            className="btn btn-link">Plus anciennes <i className="fa fa-angle-right ml-2"/> </button>
                    ) : null}
                </div>
            </div>
        )
    }
}

export default connect(({ offer_entity }) => ({ offer_entity }), {createOffer})(OfferImportModal)
