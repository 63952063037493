import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import './Block.scss'
import {
    ARTICLE_BLOCK_ARTICLE,
    ARTICLE_BLOCK_COLUMN,
    ARTICLE_BLOCK_EMBED,
    ARTICLE_BLOCK_HTML,
    ARTICLE_BLOCK_MEDIA,
    ARTICLE_BLOCK_ROW,
    STATIC_PAGE_BLOCK_COLUMN,
    STATIC_PAGE_BLOCK_HTML,
    STATIC_PAGE_BLOCK_EMBED,
    STATIC_PAGE_BLOCK_MEDIA,
    STATIC_PAGE_BLOCK_ROW,
    STATIC_PAGE_BLOCK_HEADER,
    STATIC_PAGE_BLOCK_BUTTON,
    createBlock,
    deleteBlock,
    fetchBlocks,
    updateBlock,
    updateBlockData,
} from "../../data/Editor";
import {
    startDragging,
    stopDragging
} from "../../data/EditorDragDrop";
import DropTarget from "./DropTarget";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from './ClassicEditor';

import BlockMedia from './BlockMedia';
import BlockArticle from './BlockArticle';
import Loader from "../../components/Loader";
import BlockButton from "./BlockButton";
import {Alert} from 'reactstrap';


class Block extends Component
{
    constructor(props) {
        super(props)

        this.state = {
            updating: false,
            block: props.data,
            data: props.data.data
        }
    }

    ticker = null
    lastChangeId = 0
    lastUpdateId = 0

    componentDidMount() {
        this.ticker = setTimeout(this._tick, 2000)
    }

    componentWillUnmount() {
        if (this.ticker) clearInterval(this.ticker)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            block: nextProps.data
        })
    }

    _tick = () => {
        if (this.lastChangeId !== this.lastUpdateId && !this.state.updating)
        {
            console.log('updating #', this.lastChangeId)
            const lastChangeId = this.lastChangeId
            this.setState({
                updating: true
            }, () => {
                this.props.updateBlockData(this.state.block.id, this.state.data, () => {
                    this.lastUpdateId = lastChangeId
                    this.setState({
                        updating: false
                    }, () => {
                        this.ticker = setTimeout(this._tick, 2000)
                    })
                })
            })
        }
        else this.ticker = setTimeout(this._tick, 2000)
    }

    _onChangeData = (data) => {
        this.setState({ data }, () => {
            this.lastChangeId = this.lastChangeId + 1
        })
    }

    _getChildren = () => {
        return this.props.editor.blocks.filter((block) => (block.parent == this.state.block.id)).sort((a, b) => (a.position - b.position))
    }

    render()
    {
        const block = this.state.block
        return (
            <Fragment>
                {!this.props.ignoreDropTarget ? (
                    <DropTarget
                        parent={block.parent}
                        type={"Horizontal"}
                        target={block}
                    />
                ) : null}
                <div className={"Block " + block.type}>
                    <header
                        key={'block_header_' + block.id}
                        draggable="true"
                        onDragStart={() => {
                            window.DragDropContext.dragging = true
                            window.DragDropContext.data = block
                        }}
                        onDragEnd={() => {
                            window.DragDropContext.dragging = false
                        }}>
                        <h1>{this.renderType(block.type)}</h1>
                        <span className="text-white">
                            <Loader display={this.state.updating || this.lastChangeId != this.lastUpdateId} />
                        </span>
                        <button onClick={() => this.props.deleteBlock(block.id)}><i className="fa fa-trash"/></button>
                    </header>
                    <div className="BlockContent">
                        {block.type == STATIC_PAGE_BLOCK_HEADER ?
                            <Alert color="warning" className={'mb-2'}>
                                <strong>Note:</strong> un block de type "Média" sera pris en compte pour l'image de fond du header. Si plusieurs blocks de type "Média" sont ajoutés le fond du header sera en défilement automatique.
                            </Alert>
                            : null}
                        {this.renderBlock()}
                    </div>
                </div>
            </Fragment>
        )
    }

    renderType(type)
    {
        switch(type)
        {
            case ARTICLE_BLOCK_HTML:
            case STATIC_PAGE_BLOCK_HTML:
                return 'Texte';
            case ARTICLE_BLOCK_ROW:
            case STATIC_PAGE_BLOCK_ROW:
                return 'Colonnes';
            case ARTICLE_BLOCK_COLUMN:
            case STATIC_PAGE_BLOCK_COLUMN:
                return 'Lignes';
            case ARTICLE_BLOCK_MEDIA:
            case STATIC_PAGE_BLOCK_MEDIA:
                return 'Média';
            case ARTICLE_BLOCK_ARTICLE:
                return 'Article';
            case ARTICLE_BLOCK_EMBED:
            case STATIC_PAGE_BLOCK_EMBED:
                return 'Code HTML';
            case STATIC_PAGE_BLOCK_HEADER:
                return 'Header (ce bloc unique sera toujours placé en premier sur la page)';
            case STATIC_PAGE_BLOCK_BUTTON:
                return 'Bouton';
        }
        return 'Bloc'
    }

    renderBlock()
    {
        const block = this.state.block
        if (block.type === ARTICLE_BLOCK_ROW || block.type === STATIC_PAGE_BLOCK_ROW)
        {
            return (
                <div className="row row-eq-height">
                    {this.renderChildren()}
                    <DropTarget
                        target={null}
                        parent={block.id}
                        type={(block.type == ARTICLE_BLOCK_ROW || block.type == STATIC_PAGE_BLOCK_ROW) ? "Vertical" : "Horizontal"}
                    />
                </div>
            )
        }
        else if (block.type === ARTICLE_BLOCK_COLUMN || block.type === STATIC_PAGE_BLOCK_COLUMN)
        {
            return (
                <div>
                    {this.renderChildren()}
                    <DropTarget
                        target={null}
                        parent={block.id}
                        type={"Horizontal"}
                    />
                </div>
            )
        }
        else if(block.type === ARTICLE_BLOCK_HTML || block.type === STATIC_PAGE_BLOCK_HTML)
        {
            return (
                <CKEditor
                    editor={ClassicEditor}
                    data={this.state.data.html ? this.state.data.html : ''}
                    onChange={(e, editor) => {
                        this._onChangeData({
                            ...this.state.data,
                            html: editor.getData()
                        })
                    }}
                />
            )
        }
        else if(block.type === ARTICLE_BLOCK_EMBED || block.type === STATIC_PAGE_BLOCK_EMBED)
        {
            return (
                <textarea
                    className="BlockEmbedInput"
                    value={this.state.data.html ? this.state.data.html : ''}
                    onChange={(e) => {
                        this._onChangeData({
                            ...this.state.data,
                            html: e.target.value
                        })
                    }}
                />
            )
        }
        else if (block.type === ARTICLE_BLOCK_ARTICLE) {
            return (
                <BlockArticle
                    onChange={(block) => {
                        this._onChangeData({
                            ...this.state.data,
                            ...block
                        })
                    }}
                    data={block}
                />
            )
        }
        else if (block.type === ARTICLE_BLOCK_MEDIA || block.type === STATIC_PAGE_BLOCK_MEDIA) {
            return (
                <BlockMedia
                    onChange={(block) => {
                        this._onChangeData({
                            ...this.state.data,
                            ...block
                        })
                    }}
                    data={block}
                />
            )
        }
        else if (block.type === STATIC_PAGE_BLOCK_HEADER)
        {
            return (
                <div>
                    {this.renderChildren()}
                    <DropTarget
                        target={null}
                        parent={block.id}
                        type={"Horizontal"}
                    />
                </div>
            )
        }
        else if (block.type === STATIC_PAGE_BLOCK_BUTTON) {
            return (
                <div>
                    <BlockButton
                        onChange={(block) => {
                            this._onChangeData({
                                ...this.state.data,
                                ...block
                            })
                        }}
                        target={block}
                    />
                </div>
            )
        }

        return null
    }

    renderChild(data)
    {
        const block = this.state.block

        let props = {}

        if (block.type == ARTICLE_BLOCK_ROW || block.type == STATIC_PAGE_BLOCK_ROW)
            props.ignoreDropTarget = true

        const child = (
            <Block
                {...this.props}
                data={data}
                {...props}
                key={data.id}
            />
        )

        if (block.type == ARTICLE_BLOCK_ROW || block.type == STATIC_PAGE_BLOCK_ROW) {
            return (
                <Fragment key={data.id}>
                    <DropTarget
                        parent={block.id}
                        type="Vertical"
                        target={data}
                        key={block.id}
                    />
                    <div className="col">{child}</div>
                </Fragment>
            )
        }

        if (block.type == ARTICLE_BLOCK_COLUMN || block.type == STATIC_PAGE_BLOCK_COLUMN) {
            return (
                <Fragment key={data.id}>
                    <div>{child}</div>
                </Fragment>
            )
        }

        return child
    }

    renderChildren = () => {
        const blocks = this._getChildren()

        return blocks.map((block) => {
            return this.renderChild(block)
        })
    }
}

export default connect(({editor}) => ({editor}), {fetchBlocks, createBlock, deleteBlock, updateBlock, updateBlockData, startDragging, stopDragging})(Block)
