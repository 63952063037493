import moment from 'moment-timezone';
import {request} from '../../helpers'

// Actions

const ENTITY_USER_CIRCLE_INDEX = 'ENTITY_USER_CIRCLE_INDEX'
const ENTITY_USER_CIRCLE_INDEX_SUCCESS = 'ENTITY_USER_CIRCLE_INDEX_SUCCESS'
const ENTITY_USER_CIRCLE_INDEX_FAIL = 'ENTITY_USER_CIRCLE_INDEX_FAIL'

export function fetchAll()
{
    return request(ENTITY_USER_CIRCLE_INDEX, 'get', '/user_circles')
}

export function searchAll(query)
{
    return request(ENTITY_USER_CIRCLE_INDEX, 'post', '/user_circles/search', { query })
}

const ENTITY_USER_CIRCLE_RETRIEVE = 'ENTITY_USER_CIRCLE_RETRIEVE'

export function fetchUserCircle(id)
{
    return request(ENTITY_USER_CIRCLE_RETRIEVE, 'get', `/user_circles/${id}`)
}

const ENTITY_USER_CIRCLE_CREATE = 'ENTITY_USER_CIRCLE_CREATE'

export function createUserCircle(data)
{
    return request(ENTITY_USER_CIRCLE_CREATE, 'post', '/user_circles', data)
}

const ENTITY_USER_CIRCLE_UPDATE = 'ENTITY_USER_CIRCLE_UPDATE'

export function updateUserCircle(id, data)
{
    return request(ENTITY_USER_CIRCLE_UPDATE, 'patch', `/user_circles/${id}`, data)
}


const ENTITY_USER_CIRCLE_DELETE = 'ENTITY_USER_CIRCLE_DELETE'

export function deleteUserCircle(id)
{
    return request(ENTITY_USER_CIRCLE_DELETE, 'delete', `/user_circles/${id}`)
}

// Store

const defaultState = {
    index: [],
    loading: false,
    lastUpdate: null
};

// Reducer

export default function EntityUserCircleReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case ENTITY_USER_CIRCLE_INDEX:
            return {...state, loading: true}
        case ENTITY_USER_CIRCLE_INDEX_FAIL:
            return {...state, loading: false}
        case ENTITY_USER_CIRCLE_INDEX_SUCCESS:
            return {...state, loading: false, index: action.payload.data, lastUpdate: moment()}
    }
    return state
}