import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify'
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";

import ContentLoader from "../../../components/ContentLoader";

import {fetchArticleCategory} from '../../../data/entities/ArticleCategory'
import ArticleCategoryUpdateModal from "./ArticleCategoryUpdateModal";
import ArticleCategoryDeleteModal from "./ArticleCategoryDeleteModal";

class ArticleCategoryViewPage extends Component
{
    state = {
        id: this.props.match.params.id,
        data: null,
        loading: true,

        showArticleCategoryUpdateModal: false,
        showArticleCategoryDeleteModal: false
    }

    componentDidMount()
    {
        this.props.fetchArticleCategory(this.state.id)
            .then((res) => {
                this.setState({ loading: false, data: res.payload.data })
            })
            .catch((e) => {
                console.log(e)
                if (e.error && e.error.response && e.error.response.data && e.error.response.data.error)
                    toast.error(<FormattedMessage id={e.error.response.data.error} />)
                this.props.history.push('/entities/article_category')
            })
    }

    render() {
        return (
            <div className="container">
                <Helmet title="ArticleCategory" />
                <h1>ArticleCategory #{this.state.id}</h1>
                {this.renderEntity()}
            </div>
        )
    }

    renderEntity()
    {
        if (this.state.loading) return <ContentLoader/>

        return (
            <div>
                <p>Données de l'entitié :</p>
                <pre>{JSON.stringify(this.state.data)}</pre>
                <p>Actions :</p>
                <p>
                    <button
                        onClick={() => this.setState({ showArticleCategoryUpdateModal: true })}
                        className="btn btn-primary">Modifier</button>
                    <button
                        onClick={() => this.setState({ showArticleCategoryDeleteModal: true })}
                        className="btn btn-danger ml-2">Supprimer</button>
                </p>
                {this.state.showArticleCategoryUpdateModal ? (
                    <ArticleCategoryUpdateModal
                        data={this.state.data}
                        onClose={(data) => {
                            if (data) this.setState({ data })
                            this.setState({ showArticleCategoryUpdateModal: false })
                        }}
                    />
                ) : null}
                {this.state.showArticleCategoryDeleteModal ? (
                    <ArticleCategoryDeleteModal
                        data={this.state.data}
                        onClose={(deleted) => {
                            if (deleted) {
                                this.props.history.push('/entities/article_category')
                            }
                            else this.setState({ showArticleCategoryDeleteModal: false })
                        }}
                    />
                ) : null}
            </div>
        )
    }
}

export default connect(({ article_category_entity }) => ({ article_category_entity }), { fetchArticleCategory })(ArticleCategoryViewPage)
