import React, {Component, Fragment} from 'react'
import MediaSelectModal from '../entities/Media/MediaSelectModal'
import "./BlockMedia.scss"
import { connect } from 'react-redux';
import {updateBlock, updateBlockData} from '../../data/Editor'
import ReactCrop from "react-image-crop";
import {toast} from "react-toastify";

class BlockMedia extends Component
{
  state = {
      showMediaSelectModal: false,
      media: this.props.data.media,
      data: typeof this.props.data.data_decoded == 'object' ? this.props.data.data_decoded : {
          label: this.props.data.media ? this.props.data.media.label : ''
      }
  }

  showMedia = (media) => {
      const data = {
          ...this.state.data,
          media: media.id,
          label: media.label
      }

      this.setState({ media, data })

      this.props.updateBlock(this.props.data.id, {
          ...this.props.data,
          media: media.id,
          data
      })
      /*this.props.onChange({
          media: media.id
      })*/
  }

  updateLabel = () => {
      console.log(this.state.data)
      this.props.updateBlockData(this.props.data.id, this.state.data)
      toast.success("Légende enregistrée")
  }

  renderMedia() {
    let media = this.state.media

    if (media.type && media.type.startsWith("image/")) {
      return (
          <>
              <div className="media-img-container">
                  <img src={media.file} alt={media.file}/>
                  {this.renderLabel()}
              </div>
          </>
      )
    }
    else if (media.type && media.type.startsWith("video/")) {
      return (
          <div className={"video"}>
            <video controls width={"100%"}>
              <source type="video/mp4" src={media.file}/>
            </video>
              {this.renderLabel()}
          </div>
      )
    }
    else if (media.type && media.type === "application/pdf") {
      return (
          <div className={"document"}>
            <object type="application/pdf"
                    data={media.file_proxy}
                    width="600"
                    height="600"/>
              {this.renderLabel()}
          </div>
      )
    }
    else if (media.type && media.type.startsWith("application/")) {
      return (
          <div className={"document"}>
            <div className={"empty"}>
              <i className="fas fa-box-open"/>
              <span>L'aperçu n'est pas disponible pour ce fichier.</span>
            </div>
              {this.renderLabel()}
          </div>
      )
    }

    else if (media.type && media.type.startsWith("audio/")) {
      return (
          <div className={"audio"}>
              {this.renderLabel()}
            <audio
              controls
              src={media.file_proxy}>
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </div>
      )
    }

    else {
      return <i className="fas fa-question"/>
    }
  }

  renderLabel()
  {
      const {media} = this.state

      return (
          <div className="my-2">
              Légende :
              <input
                  className="rounded border-primary px-2 py-1 mx-2"
                type="text"
                value={this.state.data.label ? this.state.data.label : ''}
                onChange={(e) => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            label: e.target.value
                        }
                    })
                }}
              />
              <button
                  className="btn btn-sm btn-primary"
                  onClick={this.updateLabel}>Enregistrer</button>
          </div>
      )
  }

  render() {
    return (
      <div className="block-media">
        
          {
            this.state.media ?
            <>
              <div onClick={() => this.setState({showMediaSelectModal: true})} className="edit">
                <i className="fas fa-pen"/>
              </div>
              <div className={"editor-media"}>
                  {this.renderMedia()}
              </div>
            </>
            :
            <div className="choose-media">
              <button 
                onClick={() => { this.setState({ showMediaSelectModal: true })}}
                className="btn">
                  <i className="fa fa-plus"/>
                  <span>Ajouter un média</span></button>
            </div>
          }

          {
            this.state.showMediaSelectModal ? 
              <MediaSelectModal 
                onClose={(created) => {
                  if (created) this.setState({ showMediaSelectModal: false }, this.showMedia(created) )
                    this.setState({ showMediaSelectModal: false })
                  }} />
            :
              null
          }
        
      </div>
    )
  }
}

export default connect(null, {updateBlock, updateBlockData})(BlockMedia)