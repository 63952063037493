import moment from 'moment-timezone';
import { request } from "../../helpers";

// Actions

const ENTITY_CHANNEL_INDEX = "ENTITY_CHANNEL_INDEX";
const ENTITY_CHANNEL_INDEX_SUCCESS = "ENTITY_CHANNEL_INDEX_SUCCESS";
const ENTITY_CHANNEL_INDEX_FAIL = "ENTITY_CHANNEL_INDEX_FAIL";

export function fetchAll() {
  return request(ENTITY_CHANNEL_INDEX, "get", "/channels");
}

export function searchAll(query) {
  return request(ENTITY_CHANNEL_INDEX, "post", "/channels/search", { query });
}

const ENTITY_CHANNEL_RETRIEVE = "ENTITY_CHANNEL_RETRIEVE";
const ENTITY_CHANNEL_RETRIEVE_SUCCESS = "ENTITY_CHANNEL_RETRIEVE_SUCCESS";

export function fetchChannel(id) {
  return request(ENTITY_CHANNEL_RETRIEVE, "get", `/channels/${id}`);
}

const ENTITY_CHANNEL_CREATE = "ENTITY_CHANNEL_CREATE";

export function createChannel(data) {
  return request(ENTITY_CHANNEL_CREATE, "post", "/channels", data);
}

const ENTITY_CHANNEL_UPDATE = "ENTITY_CHANNEL_UPDATE";

export function updateChannel(id, data) {
  return request(ENTITY_CHANNEL_UPDATE, "patch", `/channels/${id}`, data);
}

const ENTITY_CHANNEL_UPDATE_MENU_POSITION = "ENTITY_CHANNEL_UPDATE_MENU_POSITION"

export function updateChannelMenuPosition(channelId, menuId, position) {
  return request(ENTITY_CHANNEL_UPDATE_MENU_POSITION, "patch", `/channel/${channelId}/menus/${menuId}/move`, position)
}

const ENTITY_CHANNEL_DELETE = "ENTITY_CHANNEL_DELETE";

export function deleteChannel(id) {
  return request(ENTITY_CHANNEL_DELETE, "delete", `/channels/${id}`);
}

const ENTITY_CHANNEL_HOMEPAGE = "ENTITY_CHANNEL_HOMEPAGE";
const ENTITY_CHANNEL_HOMEPAGE_SUCCESS = "ENTITY_CHANNEL_HOMEPAGE_SUCCESS";

export function updateChannelHomePage(data) {
  return request(ENTITY_CHANNEL_HOMEPAGE, "post", '/channels/homepage', data);
}

// Store

const defaultState = {
  index: [],
  loading: false,
  lastUpdate: null
};

// Reducer

export default function EntityChannelReducer(state = defaultState, action) {
  switch (action.type) {
    case ENTITY_CHANNEL_INDEX:
      return { ...state, loading: true };
    case ENTITY_CHANNEL_INDEX_FAIL:
      return { ...state, loading: false };
    case ENTITY_CHANNEL_INDEX_SUCCESS:
    case ENTITY_CHANNEL_RETRIEVE_SUCCESS:
    case ENTITY_CHANNEL_HOMEPAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        index: action.payload.data,
        lastUpdate: moment()
      };
  }
  return state;
}
