import {request} from '../helpers'

export const ARTICLE_BLOCK_COLUMN = 'ARTICLE_BLOCK_COLUMN';
export const ARTICLE_BLOCK_MEDIA = 'ARTICLE_BLOCK_MEDIA';
export const ARTICLE_BLOCK_ROW = 'ARTICLE_BLOCK_ROW';
export const ARTICLE_BLOCK_HTML = 'ARTICLE_BLOCK_HTML';
export const ARTICLE_BLOCK_EMBED = 'ARTICLE_BLOCK_EMBED';
export const ARTICLE_BLOCK_ARTICLE = 'ARTICLE_BLOCK_ARTICLE';

export const STATIC_PAGE_BLOCK_COLUMN = 'STATIC_PAGE_BLOCK_COLUMN';
export const STATIC_PAGE_BLOCK_MEDIA = 'STATIC_PAGE_BLOCK_MEDIA';
export const STATIC_PAGE_BLOCK_ROW = 'STATIC_PAGE_BLOCK_ROW';
export const STATIC_PAGE_BLOCK_HTML = 'STATIC_PAGE_BLOCK_HTML';
export const STATIC_PAGE_BLOCK_EMBED = 'STATIC_PAGE_BLOCK_EMBED';
export const STATIC_PAGE_BLOCK_HEADER = 'STATIC_PAGE_BLOCK_HEADER';
export const STATIC_PAGE_BLOCK_BUTTON = 'STATIC_PAGE_BLOCK_BUTTON';

const EDITOR_INIT = 'EDITOR_INIT'

export function initEditor(type, entity)
{
    return {
        type: EDITOR_INIT,
        data: { type, entity }
    }
}

const EDITOR_UPDATE_ENTITY = 'EDITOR_UPDATE_ENTITY'

export function updateEditorEntity(entity)
{
    return {
        type: EDITOR_UPDATE_ENTITY,
        data: { entity }
    }
}

const EDITOR_FETCH_BLOCKS = 'EDITOR_FETCH_BLOCKS'
const EDITOR_FETCH_BLOCKS_SUCCESS = 'EDITOR_FETCH_BLOCKS_SUCCESS'

export function fetchBlocks(callback)
{
    return (dispatch, getState) => {
        const state = getState().editor

        console.log("GET STATE --->", state)

        dispatch(request(EDITOR_FETCH_BLOCKS, `get`, `/${state.type}/${state.entity.id}/blocks`, {}, {})).then(callback)
    }
}

const EDITOR_CREATE_BLOCK = 'EDITOR_CREATE_BLOCK'
const EDITOR_CREATE_BLOCK_SUCCESS = 'EDITOR_CREATE_BLOCK_SUCCESS'

export function createBlock(data, callback)
{
    return (dispatch, getState) => {
        const state = getState().editor
        dispatch(request(EDITOR_CREATE_BLOCK, `post`, `/${state.type}/${state.entity.id}/blocks`, data, {data}))
            .then(() => {
                callback()
            })
    }
}

const EDITOR_UPDATE_BLOCK = 'EDITOR_UPDATE_BLOCK'
const EDITOR_UPDATE_BLOCK_SUCCESS = 'EDITOR_UPDATE_BLOCK_SUCCESS'

export function updateBlock(target, data)
{
    if (typeof data.data == 'object') {
        data.data = JSON.stringify(data.data)
    }
    return (dispatch, getState) => {
        const state = getState().editor
        dispatch(request(EDITOR_UPDATE_BLOCK, `patch`, `/${state.type}/${state.entity.id}/blocks/${target}`, data, { id: target }))
    }
}

const EDITOR_UPDATE_BLOCK_DATA = 'EDITOR_UPDATE_BLOCK_DATA'
const EDITOR_UPDATE_BLOCK_DATA_SUCCESS = 'EDITOR_UPDATE_BLOCK_DATA_SUCCESS'

export function updateBlockData(target, data, callback)
{
    return (dispatch, getState) => {
        const state = getState().editor
        dispatch(request(EDITOR_UPDATE_BLOCK_DATA, `patch`, `/${state.type}/${state.entity.id}/blocks/${target}/data`, {
            data: JSON.stringify(data)
        }, { id: target, data })).then(callback)
    }
}

const EDITOR_MOVE_BLOCK = 'EDITOR_MOVE_BLOCK'
const EDITOR_MOVE_BLOCK_SUCCESS = 'EDITOR_MOVE_BLOCK_SUCCESS'

export function moveBlock(id, data, callback)
{
    return (dispatch, getState) => {
        const state = getState().editor
        dispatch(request(EDITOR_MOVE_BLOCK, `patch`, `/${state.type}/${state.entity.id}/blocks/${id}/move`, data))
            .then(() => {callback(true)})
            .catch(() => {callback(false)})
    }
}

const EDITOR_DELETE_BLOCK = 'EDITOR_DELETE_BLOCK'
const EDITOR_DELETE_BLOCK_SUCCESS = 'EDITOR_DELETE_BLOCK_SUCCESS'

export function deleteBlock(target)
{
    return (dispatch, getState) => {
        const state = getState().editor
        dispatch(request(EDITOR_DELETE_BLOCK, `delete`, `/${state.type}/${state.entity.id}/blocks/${target}`, {}, {id: target}))
    }
}

// Store

const defaultState = {
    blocks: [],
    entity: null,
    type: null
}

// Reducer

export default function editorReducer(state = defaultState, action)
{
    switch(action.type)
    {
        case EDITOR_INIT:
            return {...defaultState, ...action.data}
        case EDITOR_UPDATE_ENTITY:
            return {...state, ...action.data}
        case EDITOR_FETCH_BLOCKS_SUCCESS:
            return {...state, blocks: prepareBlocks(action.payload.data)}
        case EDITOR_CREATE_BLOCK_SUCCESS:
            return {...state, blocks: prepareBlocks(action.payload.data)}
        case EDITOR_MOVE_BLOCK_SUCCESS:
            return {...state, blocks: prepareBlocks(action.payload.data)}
        case EDITOR_UPDATE_BLOCK_SUCCESS:
            return {...state, blocks: [...state.blocks.map((b) => {
                if (b.id == action.meta.previousAction.id)
                    return prepareBlock(action.payload.data)
                return b
            })]}
        case EDITOR_UPDATE_BLOCK_DATA_SUCCESS:
            return {...state, blocks: [...state.blocks.map((b) => {
                    if (b.id == action.meta.previousAction.id)
                        b.data = action.meta.previousAction.data
                    return b
                })]}
        case EDITOR_DELETE_BLOCK_SUCCESS:
            return {...state, blocks: state.blocks.filter((block) => {
                return block.id != action.meta.previousAction.id
            })}
    }
    return state
}

function prepareBlocks(blocks)
{
   return blocks.map((block) => {
       return prepareBlock(block)
   })
}

export function prepareBlock(block)
{
    const data = block.data
    block.data = null
    try {
        block.data = JSON.parse(data)
    }
    catch (e) {}
    if (!block.data) block.data = {}
    return block
}
