import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Submit,
  CheckBox,
  FilePicker,
  DatePicker,
  Input,
  TextArea
} from "react-forms";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import moment from 'moment-timezone';

import Modal from "../../../components/Modal";

import { createChannel } from "../../../data/entities/Channel";

class ChannelCreateModal extends Component {
  state = {
    _formData: null,
    _loading: false,
    name: null,
    active: false
  };

  _submit = () => {
    this.setState({ _loading: true });

    const data = { ...this.state };
    delete data._loading;
    delete data._formData;

    this.props
      .createChannel(data)
      .then(res => {
        if (res.payload.data.message)
          toast.success(<FormattedMessage id={res.payload.data.message} />);
        this.props.onClose(res.payload.data);
      })
      .catch(e => {
        if (e.error && e.error.response && e.error.response.data)
          this.setState({ _formData: e.error.response.data });
        this.setState({ _loading: false });
      });
  };

  render() {
    return (
      <Modal title={"Créer un canal"} onClose={() => this.props.onClose(false)}>
        <Form onSubmit={this._submit} data={this.state._formData}>
          <Input
            value={this.state.name}
            onChangeValue={name => this.setState({ name })}
            placeholder="Nom"
            label="Nom"
            type="text"
          />

          <CheckBox
              name="actif" id="actif" value={this.state.active}
              onChangeValue={(active) => this.setState({ active })}>Actif</CheckBox>

          <div className="text-right pt-3">
            <Submit disabled={this.state._loading}>Créer</Submit>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default connect(
  null,
  { createChannel }
)(ChannelCreateModal);
