import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import {fetchAll, receiveContactMessages} from "../../../data/entities/Contact";
import { connect } from "react-redux";
import ContentLoader from "../../../components/ContentLoader";
import Empty from "../../../components/Empty";
import TrContact from "./TrContact";
import {toast} from 'react-toastify';

class ContactIndexPage extends Component
{
    state = {
        contact_checked: this.props.user.profile.receive_contact_messages
    };

    componentDidMount() {
        this.props.fetchAll();
    }

    toggleReceiveContact = () => {
        this.props.receiveContactMessages()
            .then((res) => {
                this.setState({contact_checked: res.payload.data.receive_contact_messages});
                toast.success('Mise à jour effectuée');
            })
            .catch((res) => toast.error(res.error.response.data.message));
    };

    render() {
        return(
            <Container>
                <Helmet title="Contact" />
                <Row className="pb-5 pt-3">
                    <Col md={8}>
                        <h1>Demandes de contact</h1>
                    </Col>
                    <Col md={4}>
                        <input
                            type={'checkbox'}
                            className={'mr-2 mt-4'}
                            id={'contact'}
                            onChange={() => this.toggleReceiveContact()}
                            checked={this.state.contact_checked}
                        />
                        <label htmlFor="contact">
                            Recevoir un e-mail à chaque message envoyé
                        </label>
                    </Col>
                </Row>
                {this.renderEntities()}
            </Container>
        )
    }

    renderEntities() {

        if (this.props.contact_entity.loading)
            return <ContentLoader />;

        if (!this.props.contact_entity.index.length)
            return <Empty />;

        return (
            <Fragment>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Envoyé le</th>
                        <th>Adresse e-mail</th>
                        <th>Objet</th>
                        <th className="text-right">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.contact_entity.index.map((entity) => {
                        return (
                            <TrContact key={entity.id}
                                       data={entity}
                                       onDelete={() => this.props.fetchAll()}
                            />
                        )
                    })}
                    </tbody>
                </table>
            </Fragment>
        );
    }
}

export default connect(({contact_entity, user}) => ({contact_entity, user}), {fetchAll, receiveContactMessages})(ContactIndexPage);
