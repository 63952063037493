import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

import './AuthLayout.scss'
import {logout} from "../data/OAuth";

class AuthLayout extends Component
{
    render() {
        return (
            <div className="AuthLayout">
                <header>
                    <h1>{localStorage.getItem("APP_NAME")}</h1>
                </header>
                {this.props.children}
            </div>
        )
    }
}

export default connect(({ user, oauth }) => ({ user, oauth }), { logout })(AuthLayout)
