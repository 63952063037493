import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Submit,  CheckBox, FilePicker, DatePicker, Input, TextArea} from "react-forms";
import {FormattedMessage} from "react-intl";
import {toast} from 'react-toastify';
import moment from 'moment-timezone';
import  FontAwsomeModal from './FontAwsomeModal';

import Modal from "../../../components/Modal";

import {createStaticPage, fetchAll, fetchALLIons} from "../../../data/entities/StaticPage";

class StaticPageCreateModal extends Component
{
    state = {
        _formData: null,
        _loading: false,
        slug: null,
        title: null,
        short_title: null,
        show_in_footer: true,
        show_in_top_header: false,
        IconsData: null,
        showFonstAwsomModal: false,
        linkedtoicon: false,
        icon_class: null,
        page_type: 'page',
        pages: null,
        authorPageExist: false
    }

    componentDidMount() {
        this.props.fetchALLIons()
            .then(res => this.setState({ IconsData: res.payload.data}))
        this.checkPage(this.props.allPages)
    }

    _submit = () => {
        this.setState({ _loading: true })

        const data = {...this.state}
        delete data._loading
        delete data._formData

        this.props.createStaticPage(data)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />)
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data })
                this.setState({ _loading: false })
            })
    }
    setPageIcon = (iconClass) => {
        this.setState({ icon_class: iconClass})
    }

    getPageType = (e) =>{
        this.setState({
            page_type: e.target.value,
        })
        console.log('type de page ::::', e.target.value)
    }

    checkPage = (pages) => {
        pages.map((p) => {
            if(p.page_type == 'page-authors'){
                this.setState({
                    authorPageExist: true
                })
            }
        })
    }

    render()
    {

        return (
            <di>
            <Modal title={"Créer une page"} onClose={() => this.props.onClose(false)}>
                <Form onSubmit={this._submit} data={this.state._formData}>
                    <Input
                        value={this.state.title} onChangeValue={(title) => this.setState({ title })}
                        placeholder="Titre de la page" label="Titre" type="text"/>

                    <Input
                        value={this.state.short_title} onChangeValue={(short_title) => this.setState({ short_title })}
                        placeholder="Titre court de la page" label="Titre court" type="text"/>

					<Input
					    value={this.state.slug} onChangeValue={(slug) => this.setState({ slug })}
					    placeholder="http://exemple.com/page/*********" label="URL (champ correspondant aux étoiles)" type="text"/>

                    {
                        !this.state.authorPageExist ?

                            <select
                                onChange={ this.getPageType }
                                value={this.state.page_type}
                                className="custom-select mb-2"
                            >
                                <option selected="true" disabled="disabled">-- Type de page --</option>
                                <option value="page">Page</option>
                                <option value="page-authors">Page d'auteurs</option>
                                <option value="link">Lien</option>
                            </select>

                            :
                            null
                    }

                    <div className={"form-group"}>
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                   onChange={(show_in_footer) => this.setState({ show_in_footer: show_in_footer.target.checked})}
                                   checked={this.state.show_in_footer}
                                   className="custom-control-input" id="show_in_footer"/>
                            <label className="custom-control-label" htmlFor="show_in_footer">Afficher dans le bas de page.</label>
                        </div>
                    </div>

                    <div className={"form-group"}>
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                   onChange={(show_in_top_header) => this.setState({ show_in_top_header: show_in_top_header.target.checked})}
                                   checked={this.state.show_in_top_header}
                                   className="custom-control-input" id="show_in_top_header"/>
                            <label className="custom-control-label" htmlFor="show_in_top_header">Publié dans le menu d'admin.</label>
                        </div>
                    </div>

                    <div className={"form-group"}>
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                   onChange={(show_in_top_header) => this.setState({ linkedtoicon: !this.state.linkedtoicon})}
                                   className="custom-control-input" id="liee_a_une_icon"/>
                            <label className="custom-control-label" htmlFor="liee_a_une_icon">Liée à une icône.</label>
                        </div>
                    </div>

                    {
                        this.state.linkedtoicon ?
                            <div className={"form-group"}>
                                <a
                                    onClick={() => this.setState({showFonstAwsomModal: true})}
                                    className="btn btn-primary mb-3"
                                > Ajouter une icône
                                </a>
                            </div>
                            :
                            null
                    }

                    <Submit disabled={this.state._loading}>Créer</Submit>
                </Form>
            </Modal>
                {this.state.showFonstAwsomModal ? (
                    <FontAwsomeModal
                        onClose={created => {
                            this.setState({ showFonstAwsomModal: false });
                        }}
                        IconsData = {this.state.IconsData}

                        PageIcon = { (ic) => {this.setPageIcon(ic)}}

                    />

                ) : null}
            </di>
        )
    }
}

export default connect(null, { createStaticPage, fetchALLIons, fetchAll })(StaticPageCreateModal)
