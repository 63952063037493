import React, { Component } from 'react'

export default class Pagination extends Component {

    renderPages = () => {
        let pages = []
        let currentPage = this.props.data.page
        let totalPages = this.props.data.pages

        if (totalPages <= 10) {
            for (let i=0; i<totalPages; i++) {
                pages.push(i+1)
            }
        }
        else {
            if (currentPage >=1 && currentPage <=2  || currentPage >= totalPages-1) {
                for (let i=0; i<3; i++) {
                    pages.push(i+1)
                }
                pages.push(null)
                for (let i=totalPages -3; i<totalPages; i++) {
                    pages.push(i+1)
                }
            }

            else if (currentPage === 3) {
                for (let i=0; i<4; i++) {
                    pages.push(i+1)
                }
                pages.push(null)
                for (let i=totalPages -3; i<totalPages; i++) {
                    pages.push(i+1)
                }
            }

            else if (currentPage >=4 && currentPage <=5) {
                pages.push(1, null)
                pages.push(currentPage-1, currentPage, currentPage+1)
                pages.push(null)
                for (let i=totalPages -3; i<totalPages; i++) {
                    pages.push(i+1)
                }
            }

            else if (currentPage === totalPages-2) {
                for (let i=0; i<3; i++) {
                    pages.push(i+1)
                }
                pages.push(null)
                for (let i=totalPages -4; i<totalPages; i++) {
                    pages.push(i+1)
                }
            }

            else if (currentPage <= totalPages-3 && currentPage >= totalPages-4) {
                for (let i=0; i<3; i++) {
                    pages.push(i+1)
                }
                pages.push(null)
                pages.push(currentPage-1, currentPage, currentPage+1)

                pages.push(null)
                pages.push(totalPages)
            }

            else {
                for (let i=0; i<3; i++) {
                    pages.push(i+1)
                }
                pages.push(null)
                pages.push(currentPage-1, currentPage, currentPage+1)

                pages.push(null)
                for (let i=totalPages -3; i<totalPages; i++) {
                    pages.push(i+1)
                }
            }
        }
        return pages
    }

    goNextPage = () => {
        if (this.props.data.page !== this.props.data.pages) {
            this.props.onChange(this.props.data.page +1)
        }
    }

    goPrevPage = () => {
        if (this.props.data.page !== 1) {
            this.props.onChange(this.props.data.page -1)
        }
    }

    render() {
        let pages = this.renderPages()
        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li onClick={() => this.goPrevPage()}
                        className={this.props.data.page === 1 ? "page-item disabled" : "page-item"}>
                        <a className="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    {
                        pages.map(item => (
                            item === null ?
                                <li className={"page-item"}><a className="page-link" href="#">...</a></li>
                            :
                                <li onClick={() => this.props.onChange(item)}
                                    className={this.props.data.page === item ? "page-item active" : "page-item"}>
                                    <a className="page-link" href="#">{item}</a>
                                </li>
                        ))
                    }
                    <li onClick={() => this.goNextPage()}
                        className={this.props.data.page === this.props.data.pages ? "page-item disabled" : "page-item"}>
                        <a className="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        )
    }
}
