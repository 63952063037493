import React, {Component} from 'react';
import Loader from "../../components/Loader";
import {Option, OptionGroup} from "react-forms";
import Autocomplete from "../entities/Article/Autocomplete";
import {connect} from 'react-redux'
import {searchAll as searchArticle, fetchArticle} from '../../data/entities/Article'
import ColorPicker from "../../components/ColorPicker";
import ContentLoader from "../../components/ContentLoader";

class PlatformProperty extends Component
{
    state = {
        value: this.props.value,
        loading: false,
        hash: 1,

        query: '',
        results: [],
        article: null,
    }

    input = React.createRef()

    componentDidMount() {
        if (this.props.type == 'article' && this.props.value !== null) {
            this.props.fetchArticle(this.props.value).then((res) => {
                this.setState({ article: res.payload.data })
            })
        }
    }

    toggleStripeSubscriptions = (e) => {
        let selected_ids = Array.from(e.target.selectedOptions, option => option.value);
        selected_ids = selected_ids.join();
        this.setState({ value: selected_ids })
    }

    toggleStripeProducts = (e) => {
        let selected_ids = Array.from(e.target.selectedOptions, option => option.value);
        selected_ids = selected_ids.join();
        this.setState({ value: selected_ids })
    }

    render()
    {

        console.log('this.state')
        console.log(this.state)
        console.log('this.props')
        console.log(this.props)
        if (this.props.type == 'image')
        {
            let url = localStorage.getItem('API_ENDPOINT').replace('/api', this.state.value.url)

            return (
                <div>
                    <div style={{ backgroundColor: '#aaa', textAlign: 'center', padding: 30 }}>
                        <img
                            style={{ maxWidth: '100%', maxHeight: 160 }}
                            src={url + '?' + this.state.hash}
                        />
                    </div>
                    {this.state.loading ? (
                        <p className="mt-3 text-muted">
                            <Loader/> Mise à jour en cours
                        </p>
                    ) : (
                        <div className="row mt-3">
                            <div className="col">
                                <button
                                    onClick={() => this.input.current.click()}
                                    className="btn btn-secondary">Sélectionner une image</button>
                                <span className="text-muted ml-2">Format accepté : {this.props.identifier != 'images.banner' ? 'PNG' : 'JPG'}</span>
                            </div>
                            <div className="col text-right">
                                {this.state.value.custom ? (
                                    <button
                                        onClick={(e) => {
                                            this.setState({ loading: true })
                                            this.props.onDelete((value) => {
                                                this.setState({ loading: false, value, hash: this.state.hash + 1 })
                                            })
                                        }}
                                        className="btn btn-danger">Réinitialiser</button>
                                ) : (
                                    <p className="mt-1">Image par défaut</p>
                                )}
                            </div>
                        </div>
                    )}
                    <input
                        className="d-none"
                        ref={this.input}
                        type="file"
                        onChange={(e) => {
                            this.setState({ loading: true })
                            this.props.onUpload(e.target.files[0], (value) => {
                                this.setState({ loading: false, value, hash: this.state.hash + 1 })
                            })
                        }}
                        accept={this.props.identifier != 'images.banner' ? "image/png" : "image/jpeg"}
                    />
                </div>
            )
        }

        if (this.props.type == 'analytics.provider') return (
            <div>
                <OptionGroup value={this.state.value} onChangeValue={(value) => this.setState({ value })}>
                    <Option value="">Aucun fournisseur</Option>
                    <Option value="google">Google Analytics</Option>
                </OptionGroup>
                <button
                    disabled={this.state.loading}
                    onClick={() => {
                        this.setState({ loading: true })
                        this.props.onChange(this.state.value, (value) => {
                            this.setState({ loading: false, value })
                        })
                    }}
                    className="btn btn-primary mt-2">Mettre à jour</button>
            </div>
        );

        if (this.props.type == 'locale') return (
            <div>
                <OptionGroup value={this.state.value} onChangeValue={(value) => this.setState({ value })}>
                    <Option value="fr">Français</Option>
                    <Option value="en">Anglais</Option>
                </OptionGroup>
                <button
                    disabled={this.state.loading}
                    onClick={() => {
                        this.setState({ loading: true })
                        this.props.onChange(this.state.value, (value) => {
                            this.setState({ loading: false, value })
                        })
                    }}
                    className="btn btn-primary mt-2">Mettre à jour</button>
            </div>
        );

        if (this.props.type == 'e_shop') return (
            <div>
                <div className="d-block">
                    <input
                        type={'checkbox'}
                        checked={this.state.value}
                        onChange={(value) => this.setState({ value: value.target.checked ? 1 : 0 })}
                    />{' '} Activée
                </div>
                <button
                    disabled={this.state.loading}
                    onClick={() => {
                        this.setState({ loading: true })
                        this.props.onChange(this.state.value, (value) => {
                            this.setState({ loading: false, value })
                        })
                    }}
                    className="btn btn-primary mt-2">Mettre à jour</button>
            </div>
        );

        if (this.props.type == 'article') {
            if (this.state.value == null) {
                return (
                    <div>
                        <p>Aucun article en Une.</p>
                        <div style={{ backgroundColor: '#EEEEEE' }}>
                            <Autocomplete
                                createEntity={false}
                                selectEntity={(id) => {
                                    this.setState({ loading: true, value: id, article: null })
                                    this.props.onChange(id, (value) => {
                                        this.setState({ loading: false, value })

                                        this.props.fetchArticle(value).then((res) => {
                                            this.setState({ article: res.payload.data })
                                        })
                                    })
                                }}
                                escape={() => {
                                    this.setState({ results: [], query: '' })
                                }}
                                query={this.state.query}
                                renderDropdownItem={(item) => {
                                    return item.title
                                }}
                                className=""
                                placeholder="Choisir un article..."
                                queryResponse={this.state.results}
                                handleChange={(e) => {
                                    this.setState({
                                        query: e.target.value
                                    })

                                    if (!e.target.value.length) {
                                        this.setState({ results: [] })
                                    }
                                    else {
                                        this.props.searchArticle(e.target.value).then((res) => {
                                            this.setState({ results: res.payload.data.index.slice(0, 14) })
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <p>Article : <strong>{this.state.article ? this.state.article.title : <Loader/>}</strong></p>
                        <button
                            className="btn btn-danger"
                            onClick={() => {
                                this.setState({ loading: true })
                                this.props.onChange(null, (value) => {
                                    this.setState({ loading: false, value })
                                })
                            }}>Retirer</button>
                    </div>
                )
            }
        }

        if (this.props.type == 'textarea')
        {
            return (
                <div>
                    <textarea
                        placeholder="Valeur par défaut"
                        className="form-control"
                        value={this.state.value}
                        disabled={this.state.loading}
                        onChange={(e) => {
                            this.setState({ value: e.target.value })
                        }}
                        ref={this.input}
                    />
                    <button
                        disabled={this.state.loading}
                        onClick={() => {
                            this.setState({ loading: true })
                            this.props.onChange(this.state.value, (value) => {
                                this.setState({ loading: false, value })
                            })
                        }}
                        className="btn btn-primary mt-2">Mettre à jour</button>
                </div>
            );
        }

        if (this.props.type == 'theme') return (

            <div className={'row'}>
                <div className={'col-md-12'}>
                    <OptionGroup value={this.state.value} onChangeValue={(value) => this.setState({ value })}>
                        <Option value="default">Thème par défaut</Option>
                        <Option value="1">Thème 1</Option>
                        <Option value="2">Thème 2</Option>
                        <Option value="3">Thème 3</Option>
                        <Option value="4">Thème 4</Option>
                        <Option value="5">Thème 5</Option>
                    </OptionGroup>
                    {this.props.description ? <p className="text-muted">{this.props.description}</p> : null}
                    <button
                        disabled={this.state.loading}
                        onClick={() => {
                            this.setState({ loading: true })
                            this.props.onChange(this.state.value, (value) => {
                                this.setState({ loading: false, value })
                            })
                        }}
                        className="btn btn-primary mt-2 mb-5">Mettre à jour</button>
                </div>


                <hr/>
                <div className={'col-md-6 mt-4'}>
                    <h5>Thème par défaut : </h5>
                    <img src={'./themes/default.png'} style={{maxWidth:'100%'}}/>
                </div>


                <div className={'col-md-6 mt-4'}>
                    <h5>Thème 1 : </h5>
                    <img src={'./themes/theme1.png'} style={{maxWidth:'100%'}}/>
                    <p><em>Le thème 1 nécessite de rajouter le widget 'Actualité en live' sur la page d'accueil </em></p>
                </div>

                <div className={'col-md-6 mt-4'}>
                    <h5>Thème 2 : </h5>
                    <img src={'./themes/theme2.png'} style={{maxWidth:'100%'}}/>
                    <p><em>Pour le thème 2, il est recommandé d'ajouter le widget 'Actualité en live' sur la page d'accueil </em></p>
                </div>

                <div className={'col-md-6 mt-4'}>
                    <h5>Thème 3 : </h5>
                    <img src={'./themes/theme3.png'} style={{maxWidth:'100%'}}/>
                    <p><em>Pour le thème 3, il est recommandé d'ajouter le widget 'Actualité en live' sur la page d'accueil </em></p>
                </div>

                <div className={'col-md-6 mt-4'}>
                    <h5>Thème 4 : </h5>
                    <img src={'./themes/theme4.png'} style={{maxWidth:'100%'}}/>
                </div>
                <div className={'col-md-6 mt-4'}>
                    <h5>Thème 5: </h5>
                    <img src={'./themes/theme5.png'} style={{maxWidth:'100%'}}/>
                </div>

            </div>

        );

        if (this.props.type == 'color') return (
            <div>
                <ColorPicker
                    value={this.state.value}
                    onChange={(value) => this.setState({ value })}
                />
                {this.props.description ? <p className="text-muted">{this.props.description}</p> : null}
                <button
                    disabled={this.state.loading}
                    onClick={() => {
                        this.setState({ loading: true })
                        this.props.onChange(this.state.value, (value) => {
                            this.setState({ loading: false, value })
                        })
                    }}
                    className="btn btn-primary mt-2">Mettre à jour</button>
            </div>
        );

        if (this.props.type == 'article_subscriptions') {
            if (this.props.stripe_products.loading)
                return <ContentLoader />;

            return (
                <div>
                    <select name="stripe-subscriptions" id="stripe-subscriptions" className={'form-control'} multiple
                            onChange={(e) => this.toggleStripeSubscriptions(e)}>
                        {
                            this.props.stripe_products.index && this.props.stripe_products.index.recurring && this.props.stripe_products.index.recurring.map((product) => {
                                return (
                                    <option value={product.price_id}
                                            selected={this.state.value.includes(product.price_id)}
                                            key={product.stripe_id}>{product.name}</option>
                                )
                            })
                        }
                    </select>
                    <button
                        disabled={this.state.loading}
                        onClick={() => {
                            this.setState({loading: true})
                            this.props.onChange(this.state.value, (value) => {
                                this.setState({loading: false, value})
                            })
                        }}
                        className="btn btn-primary mt-2">Mettre à jour
                    </button>
                </div>
            )
        };

        if (this.props.type == 'article_products') {
            if (this.props.stripe_products.loading)
                return <ContentLoader />;

            return (
                <div>
                    <select name="stripe-products" id="stripe-products" className={'form-control'} multiple
                            onChange={(e) => this.toggleStripeProducts(e)}>
                        {
                            this.props.stripe_products.index && this.props.stripe_products.index.one_time && this.props.stripe_products.index.one_time.map((product) => {
                                return (
                                    <option value={product.price_id}
                                            selected={this.state.value.includes(product.price_id)}
                                            key={product.stripe_id}>{product.name}</option>
                                )
                            })
                        }
                    </select>
                    <button
                        disabled={this.state.loading}
                        onClick={() => {
                            this.setState({loading: true})
                            this.props.onChange(this.state.value, (value) => {
                                this.setState({loading: false, value})
                            })
                        }}
                        className="btn btn-primary mt-2">Mettre à jour
                    </button>
                </div>
            )
        };

        return (
            <div>
                <input
                    placeholder="Valeur par défaut"
                    type="text"
                    className="form-control"
                    value={this.state.value}
                    disabled={this.state.loading}
                    onChange={(e) => {
                        this.setState({ value: e.target.value })
                    }}
                    ref={this.input}
                />
                <button
                    disabled={this.state.loading}
                    onClick={() => {
                        this.setState({ loading: true })
                        this.props.onChange(this.state.value, (value) => {
                            this.setState({ loading: false, value })
                        })
                    }}
                    className="btn btn-primary mt-2">Mettre à jour</button>
            </div>
        );
    }
}

export default connect(null, { searchArticle, fetchArticle })(PlatformProperty);
