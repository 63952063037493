import React, {Component} from 'react';
import {connect} from 'react-redux';
import ContentLoader from "../../components/ContentLoader";
import Builder from "./Builder";

import {fetchArticle} from '../../data/entities/Article'
import {initEditor,updateEditorEntity} from "../../data/Editor";
import ArticleUpdateForm from "../entities/Article/ArticleUpdateForm";
import {Button, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {Link} from "react-router-dom";
import ArticleOverviewModal from "../entities/Article/ArticleOverviewModal";

class EditorPage extends Component
{
    state = {
        type: this.props.match.params.type,
        id: this.props.match.params.id,
        tab: '1',
        key: 0,
        showArticleOverviewModal: false
    }

    componentDidMount() {
        this._loadEntity()
    }

    _loadEntity()
    {
        this.props.fetchArticle(this.state.id)
            .then((res) => {
                this.props.initEditor(this.state.type, res.payload.data)
                this.setState({ key: 1 })
            })
    }

    render()
    {
        return (
            <div className="EditorPage m-5">
                {this.renderEditor()}
            </div>
        )
    }

    renderEditor()
    {
        if (this.state.entity === null || !this.props.editor.entity || this.props.editor.entity.id != this.state.id)
            return <ContentLoader/>

        return (
            <div>
                {this.renderEntity()}
                <Button outline
                        className="float-right align-self-center m-2"
                        style={{ justifySelf: 'end' }}
                        onClick={() => this.setState({ showArticleOverviewModal: true })}>
                    <i className="fa fa-eye ml-2"/> Prévisualiser
                </Button>
                {this.renderMessage()}
                {this.renderForm()}
                {this.renderBuilder()}
                {this.state.showArticleOverviewModal &&
                <ArticleOverviewModal
                    data={this.props.editor.entity}
                    onClose={() => this.setState({ showArticleOverviewModal: false })}/>
                }
            </div>
        )
    }

    renderEntity()
    {
        return (
            <div>
                <p><Link to={"/entities/article"} className="btn btn-primary"><i className="fa fa-arrow-left"/> Retour au back-office</Link></p>
                <h1>{this.props.editor.entity.title}</h1>
                <p><strong>{this.props.editor.entity.hat}</strong></p>
            </div>
        )
    }

    renderForm()
    {
        return (
            <div className="bg-light pt-3 rounded">
                <ArticleUpdateForm
                    data={this.props.editor.entity}
                    onClose={(entity) => {
                        this.props.updateEditorEntity({ ...this.props.editor.entity, ...entity })
                        this.setState({ key: this.state.key + 1 })
                    }}
                />
            </div>
        )
    }

    renderMessage()
    {
        return (
            <div className="pt-3">
                <p className="alert alert-info col-6"> Toutes vos modifications sont sauvegardées automatiquement.</p>
            </div>
        )
    }
    renderBuilder()
    {
        return (
            <div className="pt-3">
                <p className="alert alert-warning"><strong>Note</strong> : si du texte disparait après le déplacement d'un bloc, actualisez la page pour qu'il réapparaisse.</p>
                <Builder key={this.state.key.toString()} />
            </div>
        )
    }
}

export default connect(({editor}) => ({editor}), {fetchArticle,initEditor,updateEditorEntity})(EditorPage)
