import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Form, Input, Submit, TextArea} from "react-forms";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import {addAds} from "./AdsFunctions";


class AdsPage extends Component {
    state = {
        _formData: null,
        _loading: false,
        content: null,
    };

    _submit = () => {
        this.setState({ _loading: true });
        const data = {...this.state};
        delete data._loading;
        delete data._formData;

        this.props.addAds(this.state.content)
            .then((res) => {
                if (res.payload.data.message)
                    toast.success(<FormattedMessage id={res.payload.data.message} />);
                this.props.onClose(res.payload.data)
            })
            .catch((e) => {
                if (e.error && e.error.response && e.error.response.data)
                    this.setState({ _formData: e.error.response.data });
                this.setState({ _loading: false });
            })

    };

    render()
    {
        return (
            <div className="container">
                <h1 className="mb-3">ADS FILE</h1>
                <Form onSubmit={this._submit} data={this.state._formData}>

                    <TextArea
                        value={this.state.content}
                        onChangeValue={(content) => this.setState({ content })}
                        id="text" name="content" label="Contenu"/>
                    <Submit  disabled={this.state._loading}>Ajouter</Submit>
                </Form>
            </div>
        )
    }
}


export default connect(null,{addAds})(AdsPage);
