import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from "../../components/Modal";
import {
    ARTICLE_BLOCK_ARTICLE,
    ARTICLE_BLOCK_COLUMN,
    ARTICLE_BLOCK_HTML,
    ARTICLE_BLOCK_EMBED,
    ARTICLE_BLOCK_MEDIA,
    ARTICLE_BLOCK_ROW,
    STATIC_PAGE_BLOCK_COLUMN,
    STATIC_PAGE_BLOCK_HTML,
    STATIC_PAGE_BLOCK_EMBED,
    STATIC_PAGE_BLOCK_MEDIA,
    STATIC_PAGE_BLOCK_ROW,
    STATIC_PAGE_BLOCK_HEADER,
    STATIC_PAGE_BLOCK_BUTTON,
    createBlock, deleteBlock, fetchBlocks, updateBlock
} from "../../data/Editor";

class BlockCreateModal extends Component
{
    state = {
        externalModal: false,
        types: []
    };

    componentDidMount(){
        if (this.props.editor.type == 'static_page') {
            this.setState({types: [
                    STATIC_PAGE_BLOCK_HEADER,
                    STATIC_PAGE_BLOCK_HTML,
                    STATIC_PAGE_BLOCK_MEDIA,
                    STATIC_PAGE_BLOCK_ROW,
                    STATIC_PAGE_BLOCK_COLUMN,
                    STATIC_PAGE_BLOCK_EMBED,
                    STATIC_PAGE_BLOCK_BUTTON
                ]})
        } else {
            this.setState({types : [
                    ARTICLE_BLOCK_HTML,
                    ARTICLE_BLOCK_MEDIA,
                    ARTICLE_BLOCK_ARTICLE,
                    ARTICLE_BLOCK_ROW,
                    ARTICLE_BLOCK_COLUMN,
                    ARTICLE_BLOCK_EMBED
                ]})
        }
    }

    _select = (type) => {

        /*if (type == 'ARTICLE_EXTERNAL') {
            this.setState({ externalModal: true })
            return;
        }*/

        let data = ''

        switch (type)
        {
            case ARTICLE_BLOCK_HTML:
            case STATIC_PAGE_BLOCK_HTML:
                data = 'Exemple de contenu'
                break;
        }

        const block = {
            parent: this.props.parent,
            target: this.props.target ? this.props.target.id : null,
            type,
            data,
        }

        this.props.createBlock(block, () => {
            this.props.onClose(true)
        })
    }

    render() {
        return (
            <Modal onClose={() => this.props.onClose(false)} title="Ajouter un bloc">
                <p className="text-muted">Sélectionnez le type de bloc que vous souhaitez ajouter.</p>
                {this.state.types.length > 0 && this.state.types.map((type, key) => (
                    <div className="mt-1" key={key}>
                        <button onClick={() => this._select(type)} className="btn btn-secondary w-100">{this.renderType(type)}</button>
                    </div>
                ))}

                {this.state.externalModal ? (
                    <Modal onClose={() => this.setState({ externalModal: false })} title="Vidéo externe">
                        <p>Il a été convenu que durant la période du Bourget les vidéos externes ne sont pas prises en charge.</p>
                        <p><strong>Pour ajouter une vidéo externe, veuillez la télécharger sur votre ordinateur, puis l'ajouter à la médiathèque.</strong></p>
                        <p>Voici quelques services externes pour télécharger des vidéos YouTube, Vimeo, etc. :</p>
                        <ul>
                            <li><a href="https://youtubemp4.to/" target="_blank">YoutubeMP4</a> : copiez l'URL de la vidéo, cliquez sur "CONVERT" puis "DOWNLOAD" puis faites une clic droit sur le bouton "Download" d'une ligne au format MP4, pour enfin cliquer sur "Enregistrer le lien sous".</li>
                            <li><a href="http://clipconverter.cc" target="_blank">ClipConverter</a> : copiez l'URL de la vidéo, cliquez sur "MP4" puis "Continuer" puis "Débuter" puis "Télécharger".</li>
                        </ul>
                        <p className="text-muted">Nous vous conseillons d'utiliser AdBlock pour éviter les publicités envahissantes.</p>
                    </Modal>
                ) : null}
            </Modal>
        )
    }

    renderType(type)
    {
        switch(type)
        {
            case ARTICLE_BLOCK_HTML:
            case STATIC_PAGE_BLOCK_HTML:
                return 'Texte';
            case ARTICLE_BLOCK_ROW:
            case STATIC_PAGE_BLOCK_ROW:
                return 'Colonnes';
            case ARTICLE_BLOCK_MEDIA:
            case STATIC_PAGE_BLOCK_MEDIA:
                return 'Média';
            case ARTICLE_BLOCK_ARTICLE:
                return 'Article';
            case ARTICLE_BLOCK_COLUMN:
            case STATIC_PAGE_BLOCK_COLUMN:
                return 'Suite de blocs';
            case ARTICLE_BLOCK_EMBED:
            case STATIC_PAGE_BLOCK_EMBED:
                return 'Code HTML, vidéo YouTube / Vimeo, etc.';
            case STATIC_PAGE_BLOCK_HEADER:
                return 'Header';
            case STATIC_PAGE_BLOCK_BUTTON:
                return 'Bouton (composant du Header)';
            case 'ARTICLE_EXTERNAL': return "Vidéo externe (YouTube, Vimeo, etc.)"
        }
        return 'Bloc'
    }
}

export default connect(({editor})=>({editor}), {fetchBlocks,createBlock,deleteBlock,updateBlock})(BlockCreateModal)
